/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/lazyAccordion.ts" />

// Special Education Profile
class SpecialEducationProfile {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationProfileField"];

        let specialEducationProfileSaveButton = document.getElementById("specialEducationProfileSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let profileAndPlanSpecialEducationTotalStudentEnrollmentElement = document.getElementById("profileAndPlanSpecialEducationTotalNumberOfStudents");
        if (profileAndPlanSpecialEducationTotalStudentEnrollmentElement !== null)
            profileAndPlanSpecialEducationTotalStudentEnrollmentElement.addEventListener("change", (e: Event) => this.calculateSpecialEducationPercent());

        let profileAndPlanSpecialEducationTotalEnrollmentElement = document.getElementById("profileAndPlanSpecialEducationTotalStudentEnrollment");
        if (profileAndPlanSpecialEducationTotalEnrollmentElement !== null)
            profileAndPlanSpecialEducationTotalEnrollmentElement.addEventListener("change", (e: Event) => this.calculateSpecialEducationPercent());

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("specialEducationProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("specialEducationProfileForm");
        let inputs = document.getElementsByClassName("specialEducationProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("specialEducationProfileForm");
        let inputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    calculateSpecialEducationPercent() {
        let totalNumberOfStudentsElement = <HTMLInputElement>document.getElementById("profileAndPlanSpecialEducationTotalNumberOfStudents");
        let totalEnrollmentElement = <HTMLInputElement>document.getElementById("profileAndPlanSpecialEducationTotalStudentEnrollment");
        let percentElement = <HTMLInputElement>document.getElementById("profileAndPlanSpecialEducationPercentOfSpecialEducation");

        let totalNumberOfStudents = 0;
        let totalEnrollment = 0;
        let percentOfStudents = 0.0;

        if (totalNumberOfStudentsElement !== null && totalNumberOfStudentsElement.value !== "")
            totalNumberOfStudents = parseInt(totalNumberOfStudentsElement.value);

        if (totalEnrollmentElement !== null && totalEnrollmentElement.value !== "")
            totalEnrollment = parseInt(totalEnrollmentElement.value);

        let num = (totalNumberOfStudents / totalEnrollment) * 100;

        percentElement.value = parseFloat(num.toFixed(1)).toString();
    }
}

// Special Education Steering Committee
class SpecialEducationSteeringCommittee {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationSteeringCommitteeField"];

        let specialEducationSteeringCommitteeSaveButton = document.getElementById("specialEducationSteeringCommitteeSave");
        if (specialEducationSteeringCommitteeSaveButton !== null)
            specialEducationSteeringCommitteeSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let steeringCommitteeAddRows = document.getElementById("specialEducationSteeringCommitteeAddRows");
        if (steeringCommitteeAddRows !== null)
            steeringCommitteeAddRows.addEventListener("click", (e: Event) => this.steeringCommitteeAddRows(e));

        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteSteeringCommitteeConfirmButton = document.getElementById("specialEducationSteeringCommitteeDeleteConfirm");
        if (deleteSteeringCommitteeConfirmButton !== null)
            deleteSteeringCommitteeConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteSteeringCommitteeCancelButton = document.getElementById("specialEducationSteeringCommitteeDeleteCancel");
        if (deleteSteeringCommitteeCancelButton !== null)
            deleteSteeringCommitteeCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        this.bindDeleteRowButtons();

        let wordPluralizer = document.getElementById("specialEducationSteeringCommitteeAddRowsNumber");
        if (wordPluralizer !== null)
            wordPluralizer.addEventListener("change", (e: Event) => this.checkForPlural());

        let steeringCommitteeExportToExcelButton = document.getElementById("specialEducationSteeringCommitteeExportToExcel");
        if (steeringCommitteeExportToExcelButton !== null) {
            steeringCommitteeExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }
    }

    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("specialEducationDeleteSteeringCommitteeRow", null); 
        modal.addAttributeToElement("specialEducationDeleteSteeringCommitteeRow", "#specialEducationSteeringCommitteeDeleteConfirm", "row", row);
        modal.show();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let steeringCommitteeFormElement = <HTMLDivElement>document.getElementById("specialEducationSteeringCommitteeForm");
        let inputs = document.getElementsByClassName("specialEducationSteeringCommitteeField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(steeringCommitteeFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    steeringCommitteeAddRows(e: Event) {
        let target = <HTMLButtonElement>e.target;
        let core = this._core;
        let value = (<HTMLInputElement>document.getElementById("specialEducationSteeringCommitteeAddRowsNumber"));
        let _that = this;
        let planFK = target.dataset.planfk;

        if (value.value === "") {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        } else {
            let rows = parseInt(value.value);
            let row = 0;
            let table = (<HTMLTableElement>document.getElementById("steeringCommittee"));
            let trs = table.rows;
            for (let tr of trs) {
                if (parseInt(tr.getAttribute("data-row")) > row)
                    row = parseInt(tr.getAttribute("data-row"));
            }

            row++;

            for (let i = 0; i < rows; i++) {

                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/SteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        Core.hideLoader();
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        value.value = "";

                        let rows = document.getElementsByClassName("removeSteeringCommitteeRow");
                        let row = rows[rows.length - 1];

                        _that.bindDeleteRowButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Steering Committee Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send(`row=${row}&planFK=${planFK}`);
                row++;
            }
        }
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("specialEducationDeleteSteeringCommitteeRow", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("specialEducationSteeringCommitteeForm");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        Core.showLoader();

        let committeeFields = document.getElementsByClassName("specialEducationSteeringCommitteeField");
        for (let comm of committeeFields) {
            let inputElement = <HTMLInputElement>comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-- Select One --")
                    allBlank = false;
            }
        }

        if (allBlank) {
            let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = <HTMLDivElement>thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);

                    let modal: Modal = new Modal("specialEducationDeleteSteeringCommitteeRow", null);
                    modal.hide();

                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
            }
            Core.hideLoader();
        } else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = <HTMLDivElement>thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }

                    let modal: Modal = new Modal("specialEducationDeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("specialEducationSteeringCommitteeAddRowsNumber")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }

    initializeRequiredFieldsCustom() {

        //If any field in a row is filled out, force rest of row required (except email)
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        for (let row of steeringCommitteeTableRows) {
            let elements = row.getElementsByClassName("specialEducationSteeringCommitteeField") as HTMLCollectionOf<HTMLElement>;

            for (let element of elements) {
                if (element instanceof HTMLSelectElement) {
                    if (element.selectedIndex > 0) {
                        for (let otherElement of elements) {
                            if (otherElement.dataset.propertycode !== "specialEducationSteeringCommitteeEmail") {
                                otherElement.dataset.forcerequired = "true";
                            }
                        }
                        break;
                    }
                } else if (element instanceof HTMLInputElement) {
                    if (element.value !== "") {
                        for (let otherElement of elements) {
                            if (otherElement.dataset.propertycode !== "specialEducationSteeringCommitteeEmail") {
                                otherElement.dataset.forcerequired = "true";
                            }
                        }
                        break;
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationSteeringCommitteeForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationSteeringCommitteeExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationSteeringCommittee.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}

// Special Education School District Areas Of Improvement And Planning - Indicators
class SpecialEducationIndicators {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIndicatorsField"];

        let specialEducationIndicatorsSaveButton = document.getElementById("specialEducationIndicatorsSave");
        if (specialEducationIndicatorsSaveButton !== null)
            specialEducationIndicatorsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation();
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let specialEducationIndicatorAddIndicatorTopButton = document.getElementById("specialEducationIndicatorAddIndicatorTop");
        if (specialEducationIndicatorAddIndicatorTopButton !== null)
            specialEducationIndicatorAddIndicatorTopButton.addEventListener("click", (e: Event) => this.addNewIndicator());

        let specialEducationIndicatorNotFlagged = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");
        for (let specialEducationIndicatorNotFlaggedEle of specialEducationIndicatorNotFlagged)
            specialEducationIndicatorNotFlaggedEle.addEventListener("click", (e: Event) => this.notFlagged(e));

        let deleteIndicatorConfirmButton = document.getElementById("deleteIndicatorConfirm");
        if (deleteIndicatorConfirmButton !== null)
            deleteIndicatorConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteIndicatorCancelButton = document.getElementById("deleteIndicatorCancel");
        if (deleteIndicatorCancelButton !== null)
            deleteIndicatorCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());
       
        this.bindAddImprovementAndPlanningActivityButtons();

        this.bindCheckboxes();

        this.notFlaggedLoading();

        this.bindDeleteButtons();
    }

    notFlagged(e: Event) {
        let checkboxElement = <HTMLInputElement>e.target;

        this.notFlaggedWork(checkboxElement);
    }

    notFlaggedLoading() {
        let allCheckboxes = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");

        for (let checkbox of allCheckboxes) {
            let checkboxElement = <HTMLInputElement>checkbox;

            this.notFlaggedWork(checkboxElement);
        }
    }

    notFlaggedWork(checkboxElement: HTMLInputElement) {
        let pageSectionFK = checkboxElement.dataset.pagesectionfk;
        let textElements = document.querySelectorAll(`table[data-pagesectionfk='${pageSectionFK}'] textarea`);
        let button = <HTMLButtonElement>document.querySelector(`button[data-pagesectionfk='${pageSectionFK}']`);

        const pageSectionPK = checkboxElement.dataset.pagesectionpk;
        const accordion = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-pagesectionpk='${pageSectionFK}']`);

        if (checkboxElement.checked) {
            for (let textbox of textElements) {
                let textboxElement = <HTMLInputElement>textbox;

                textboxElement.value = "";
                textboxElement.readOnly = true;
                this._core.forceElementOptional(textboxElement);
            }

            accordion.classList.add("summary-dark");
            button.disabled = true;
        } else {
            for (let textbox of textElements) {
                let textboxElement = <HTMLInputElement>textbox;

                textboxElement.readOnly = false;
                this._core.forceElementRequired(textboxElement);
            }

            accordion.classList.remove("summary-dark");
            button.disabled = false;
        }
    }

    bindAddImprovementAndPlanningActivityButtons() {
        let allButtons = document.getElementsByClassName("indicatorsAddImprovementPlanningActivity");
        for (let button of allButtons)
            button.addEventListener("click", (e: Event) => this.addNewImprovementAndPlanningActivity(e));
    }

    addNewIndicator() {
        let core = this._core;
        let indicatorSelection = <HTMLSelectElement>document.getElementById("specialEducationIndicatorsOtherIndicatorsSelection");
        let pageSectionCode = indicatorSelection[indicatorSelection.selectedIndex].dataset.pagesectioncode;
        let pageSectionFK = 0;
        let planFK = 0;
        if (indicatorSelection !== null) {
            pageSectionFK = parseInt(indicatorSelection.value);
            planFK = parseInt(indicatorSelection.dataset.planfk);
        }
        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationIndicator', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector("#indicators");

                var div = document.createElement("div");
                div.innerHTML = xhr.responseText;
                element.appendChild(div);

                let newAccordion = `indicatorsAccordion${pageSectionCode}`;
                new CustomAccordion(newAccordion);

                indicatorSelection.remove(indicatorSelection.options.selectedIndex);

                that.bindAddImprovementAndPlanningActivityButtons();
                that.bindCheckboxes();

                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&indicatorPageSectionFK=" + pageSectionFK);
    }

    addNewImprovementAndPlanningActivity(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let pageSectionFK = button.dataset.pagesectionfk;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let propertyCode = "";

        let improvementPlanningActivities = document.querySelectorAll(`.performance-data-table[data-pagesectionfk='${pageSectionFK}'] tbody .specialEducationIndicatorsField`)
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = <HTMLInputElement>improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);

            propertyCode = improvementElement.dataset.propertycode;
        }

        newRow++;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationIndicatorImprovementPlanningActivity', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`.performance-data-table[data-pagesectionfk='${pageSectionFK}'] tbody`);

                var tr = document.createElement("tr");
                tr.dataset.row = newRow.toString();
                tr.dataset.newrow = "true";
                tr.dataset.pagesectionfk = pageSectionFK;
                tr.innerHTML = xhr.responseText;
                element.appendChild(tr);

                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCode=${propertyCode}`);
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationIndicatorsFormElement = <HTMLDivElement>document.getElementById("specialEducationIndicatorsForm");
        let inputs = document.getElementsByClassName("specialEducationIndicatorsField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIndicatorsFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxInputs = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");

        for (let ele of checkboxInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIndicatorsFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationIndicators', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindCheckboxes() {
        let allCheckboxes = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");
        for (let check of allCheckboxes) {
            check.addEventListener("change", (e: Event) => this.disabledCheckbox(e));
        }
    }

    disabledCheckbox(e: Event) {
        let checkbox = <HTMLInputElement>e.target;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
    }

    initializeRequiredFieldsCustom(allClasses: string[], refresh: boolean = false) {
        let checkboxes = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");

        for (let checkbox of checkboxes) {
            let checkboxElement = <HTMLInputElement>checkbox;
            let sectionFK = checkboxElement.dataset.pagesectionfk;

            let fields = document.querySelectorAll(`tr[data-pagesectionfk='${sectionFK}'] .specialEducationIndicatorsField`);
            if (checkboxElement.checked) {
                for (let element of fields) {
                    let ele = <HTMLElement>element;

                    this._core.forceElementOptional(ele);
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    doCustomValidation() {
        let checkboxElements = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");
        let checkboxElement = document.createElement("input");
        let isValid: boolean = true;

        for (let checkbox of checkboxElements) {
            checkboxElement = <HTMLInputElement>checkbox;


            let isCheckValid: boolean = false;

            if (checkboxElement.checked)
                isCheckValid = true;

            if (!isCheckValid) {
                let fields = document.getElementsByClassName("specialEducationIndicatorsField");
                for (let field of fields) {
                    let element = <HTMLInputElement>field;
                    if (element.dataset.percent === "1.00")
                        if (element.value === "")
                            isValid = false;
                }
            }
        }

        let showMessage = !isValid;

        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");

        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }

        this._core.doValidation(["specialEducationIndicatorsField", "specialEducationIndicatorsCheckboxField"]);
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationIndicatorsField");

        for (let deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e: Event) => this.deleteRow(e));
    }

    deleteRow(e) {
        let buttonElement = <HTMLButtonElement>e.target;
        let pageSectionFK = buttonElement.dataset.pagesectionfk;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;

        let modal: Modal = new Modal("deleteIndicatorModal", null);
        modal.addAttributeToElement("deleteIndicatorModal", "#deleteIndicatorConfirm", "row", row);
        modal.addAttributeToElement("deleteIndicatorModal", "#deleteIndicatorConfirm", "pagesectionfk", pageSectionFK);
        modal.addAttributeToElement("deleteIndicatorModal", "#deleteIndicatorConfirm", "newrow", newRow);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("deleteIndicatorModal", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;
        let pageSectionFK = buttonElement.dataset.pagesectionfk;

        let core = this._core;
        Core.showLoader();

        if (newRow === "true") {
            let thisRowElement = <HTMLTableRowElement>document.querySelector(`.performance-data-table tr[data-row='${row}'][data-pagesectionfk='${pageSectionFK}']`);
            thisRowElement.remove();

            let modal: Modal = new Modal("deleteIndicatorModal", null);
            modal.hide();

            Core.createHTMLAlert("alertMessageDiv", "The Improvement and Planning Activity has been removed.", 'success', 3000, null);
            Core.hideLoader();
        } else {

            let planProps = [];
            let thisRowElement = <HTMLTableRowElement>document.querySelector(`.performance-data-table tr[data-row='${row}'][data-pagesectionfk='${pageSectionFK}']`);
            let rowElements = thisRowElement.querySelectorAll("td textarea.specialEducationIndicatorsField");
            for (let row of rowElements) {
                let rowElement = <HTMLTableRowElement>row;

                let planProp = rowElement.dataset.planpropertypk;
                if (planProp && parseInt(rowElement.dataset.planpropertypk) > 0) {
                    planProps.push(parseInt(rowElement.dataset.planpropertypk));
                }
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteIndicatorRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    if (xhr.responseText === "true") {
                        thisRowElement.remove();
                        Core.createHTMLAlert("alertMessageDiv", "The Improvement and Planning Activity has been removed.", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this activity, please try again.", 'error', 3000, null);
                    }

                    Core.hideLoader();
                    let modal: Modal = new Modal("deleteIndicatorModal", null);
                    modal.hide();                    
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
}

// Special Education School District Areas Of Improvement And Planning - Cyclical Monitoring
class SpecialEducationCyclicalMonitoring {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationCyclicalMonitoringField", "specialEducationCyclicalMonitoringCheckboxField"];

        let specialEducationCyclicalMonitoringFormSaveButton = document.getElementById("specialEducationCyclicalMonitoringSave");
        if (specialEducationCyclicalMonitoringFormSaveButton !== null)
            specialEducationCyclicalMonitoringFormSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom(this.validationClasses);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation();
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let specialEducationCyclicalMonitoringAddCorrectiveActionButton = document.getElementById("specialEducationCyclicalMonitoringAddCorrectiveAction");
        if (specialEducationCyclicalMonitoringAddCorrectiveActionButton !== null)
            specialEducationCyclicalMonitoringAddCorrectiveActionButton.addEventListener("click", (e: Event) => this.addCorrectiveAction(e));

        let specialEducationCyclicalMonitoringCompletedCorrectiveActionButton = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        for (let element of specialEducationCyclicalMonitoringCompletedCorrectiveActionButton) {
            element.addEventListener("change", (e: Event) => this.completedCorrectiveAction());
        }

        this.completedCorrectiveAction();

        this.bindCheckboxes();

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("specialEducationDeleteDistrictMonitoringConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("specialEducationDeleteDistrictMonitoringCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        this.bindDeleteButtons();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationCyclicalMonitoringFormElement = <HTMLDivElement>document.getElementById("specialEducationCyclicalMonitoringForm");
        let inputs = document.getElementsByClassName("specialEducationCyclicalMonitoringField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationCyclicalMonitoringFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxInputs = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");

        for (let ele of checkboxInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationCyclicalMonitoringFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {

                if (element.checked) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "on",
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                } else {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "off",
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationCyclicalMonitoring', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    completedCorrectiveAction() {
        let checkboxElements = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        let checkboxElement = document.createElement("input");
        for (let checkbox of checkboxElements)
            checkboxElement = <HTMLInputElement>checkbox;

        let textElements = document.getElementsByClassName("specialEducationCyclicalMonitoringField");
        let button = <HTMLButtonElement>document.getElementById("specialEducationCyclicalMonitoringAddCorrectiveAction");

        if (checkboxElement.checked) {
            for (let textbox of textElements) {
                let textboxElement = <HTMLInputElement>textbox;

                textboxElement.value = "";
                textboxElement.readOnly = true;
                button.disabled = true;

                this._core.forceElementOptional(textboxElement);
            }
        } else {
            for (let textbox of textElements) {
                let textboxElement = <HTMLInputElement>textbox;

                textboxElement.readOnly = false;
                button.disabled = false;

                this._core.forceElementRequired(textboxElement);
            }
        }
    }

    addCorrectiveAction(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let pageSectionFK = button.dataset.pagesectionfk;
        let planFK = button.dataset.planfk;
        let newRow = 0;

        let improvementPlanningActivities = document.getElementsByClassName("specialEducationCyclicalMonitoringField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = <HTMLInputElement>improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);               
        }

        newRow++;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationCyclicalMonitoringCorrectiveAction', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`.performance-data-table[data-pagesectionfk='${pageSectionFK}'] tbody`);

                var tr = document.createElement("tr");
                tr.innerHTML = xhr.responseText;
                tr.classList.add("specialEducationDistrictMonitoringRow");
                tr.dataset.row = newRow.toString();
                tr.dataset.newrow = "true";
                element.appendChild(tr);

                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&rowNumber=" + newRow);
    }

    initializeRequiredFieldsCustom(allClasses: string[], refresh: boolean = false) {

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let checkboxElements = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        let checkboxElement = document.createElement("input");
        for (let checkbox of checkboxElements) {
            checkboxElement = <HTMLInputElement>checkbox;
        }

        let classesToValidate = formattedAllClasses.join(",");

        if (!checkboxElement.checked) {
            if (refresh) {
                let allElements = document.querySelectorAll(classesToValidate);

                for (let element of allElements) {
                    let htmlElement = <HTMLElement>element;

                    htmlElement.removeAttribute("aria-required");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null) {
                        label.classList.remove("isRequired");
                        let asterisk = label.querySelector(".required-label") as HTMLElement;
                        if (asterisk != null) {
                            asterisk.parentNode.removeChild(asterisk);
                        }
                    }
                }
            }
        }

        let allElements = document.querySelectorAll(classesToValidate);

        if (checkboxElement.checked) {
            //
        }
        else {
            for (let element of allElements) {
                let alreadyExists = false;
                let htmlElement = <HTMLElement>element;

                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                    //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                    var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                    for (let otherElement of otherElements) {
                        let otherHtmlElement = <HTMLElement>otherElement;
                        if (otherHtmlElement.hasAttribute("aria-required") && otherHtmlElement.getAttribute("aria-required") === "true") {
                            alreadyExists = true;
                        }
                    }

                    if (!alreadyExists) {

                        if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                            htmlElement.setAttribute("aria-required", "true");
                            let label = Core.findLabelForInput(htmlElement);

                            if (label !== null && !label.classList.contains("isRequired")) {
                                label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                                label.classList.add("isRequired");
                            }
                        }
                    }
                }
            }
        }
    }

    doCustomValidation() {
        let checkboxElements = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        let checkboxElement = document.createElement("input");
        for (let checkbox of checkboxElements) {
            checkboxElement = <HTMLInputElement>checkbox;
        }

        let isCheckValid: boolean = false;

        if (checkboxElement.checked)
            isCheckValid = true;

        let isValid: boolean = true;

        if (isCheckValid) {
            let fields = document.getElementsByClassName("specialEducationTargetedMonitoringField");
            for (let field of fields) {
                let element = <HTMLInputElement>field;
                if (element.dataset.percent === "1.00")
                    if (element.value === "")
                        isValid = false;
            }
        }

        let showMessage = !isValid;

        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");

        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }

    bindCheckboxes() {
        let allCheckboxes = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        for (let check of allCheckboxes) {
            check.addEventListener("change", (e: Event) => this.disabledCheckbox(e));
        }
    }

    disabledCheckbox(e: Event) {
        let checkbox = <HTMLInputElement>e.target;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }

    }

    bindDeleteButtons() {
        let buttons = document.getElementsByClassName("removeDistrictMonitoringField");

        for (let button of buttons)
            button.addEventListener("click", (e: Event) => this.deleteIdentificationMethod(e));
    }

    deleteIdentificationMethod(e: Event) {
        let button = <HTMLButtonElement>e.target;
        let row = button.dataset.row;
        let newRow = button.dataset.newrow;

        let modal: Modal = new Modal("specialEducationDistrictMonitoringDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationDistrictMonitoringDeleteRowModal", "#specialEducationDeleteDistrictMonitoringConfirm", "row", row);
        modal.addAttributeToElement("specialEducationDistrictMonitoringDeleteRowModal", "#specialEducationDeleteDistrictMonitoringConfirm", "newrow", newRow);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("specialEducationDistrictMonitoringDeleteRowModal", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core = this._core;
        Core.showLoader();

        if (buttonElement.dataset.newrow === "true") {
            let trElement = document.querySelector(`.specialEducationDistrictMonitoringRow[data-row='${row}']`);

            trElement.remove();

            let modal: Modal = new Modal("specialEducationDistrictMonitoringDeleteRowModal", null);
            modal.hide();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
        }
        else {
            let rowToDelete = document.querySelector(`.specialEducationDistrictMonitoringRow[data-row='${row}']`);
            let rowElements = rowToDelete.querySelectorAll(`textarea.specialEducationCyclicalMonitoringField[data-row='${row}']`);

            for (let ele of rowElements) {
                let element = <HTMLTextAreaElement>ele;

                let planProp = element.dataset.planpropertypk;

                if (planProp && parseInt(planProp) > 0) {
                    planProps.push(planProp);
                }
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteDistrictMonitoringRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {

                    rowToDelete.remove();

                    Core.hideLoader();
                    let modal: Modal = new Modal("specialEducationDistrictMonitoringDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
}

// Special Education Idenfication Model
class SpecialEducationIdentificationMethod {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIdentificationMethodSelectField", "specialEducationIdentificationMethodBuildingField", "specialEducationIdentificationMethodSchoolTable"];

        let specialEducationIdentificationMethodFormSaveButton = document.getElementById("specialEducationIdentificationMethodSave");
        if (specialEducationIdentificationMethodFormSaveButton !== null)
            specialEducationIdentificationMethodFormSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let identificationDropdown = document.getElementById("specialEducationIdentificationspecialEducationIdentificationIdentify");
        if (identificationDropdown !== null)
            identificationDropdown.addEventListener("change", (e: Event) => this.changeIdentification(<HTMLSelectElement>e.target));

        this.changeIdentification(<HTMLSelectElement>identificationDropdown);

        this.initializeRequiredFieldsCustom(this.validationClasses);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation();
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("specialEducationDeleteIdentificationMethodDeleteConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("specialEducationDeleteIdentificationMethodDeleteCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        let specialEducationIdentificationAddSchoolButton = document.getElementById("specialEducationIdentificationAddSchool");
        if (specialEducationIdentificationAddSchoolButton !== null)
            specialEducationIdentificationAddSchoolButton.addEventListener("click", (e: Event) => this.addSchool(e));

        let specialEducationIdentificationMethodBuildingElement = document.getElementById("specialEducationIdentificationBuildingName");
        if (specialEducationIdentificationMethodBuildingElement !== null)
            specialEducationIdentificationMethodBuildingElement.addEventListener("change", (e: Event) => this.changeBuilding(e));

        this.bindCheckboxes();

        this.bindDeleteButtons();

        const identificationMethodExportToExcelButton = document.getElementById("specialEducationIdentificationMethodExportToExcel");
        if (identificationMethodExportToExcelButton !== null) {
            identificationMethodExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }
    }
    
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationIdentificationMethodFormElement = <HTMLDivElement>document.getElementById("specialEducationIdentificationMethodForm");

        let selects = document.getElementsByClassName("specialEducationIdentificationMethodSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIdentificationMethodFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("specialEducationIdentificationMethodCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIdentificationMethodFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        planPK = parseInt(specialEducationIdentificationMethodFormElement.dataset.planfk);

        let tableData = document.getElementsByClassName("specialEducationIndentificationMethodRow");
        for (let ele of tableData) {
            let tableEle = <HTMLTableRowElement>ele;
        
            let pdeApprovedElement = <HTMLTableCellElement>tableEle.querySelector(".specialEducationIndentificationMethodRowCellPDEApproved");
            let rtiElement = <HTMLTableCellElement>tableEle.querySelector(".specialEducationIndentificationMethodRowCellRTI");
            let branchElement = <HTMLTableCellElement>tableEle.querySelector(".specialEducationIndentificationMethodRowCellBranch");
            let aunElement = <HTMLTableCellElement>tableEle.querySelector(".specialEducationIndentificationMethodRowCellAUN");
            let buildingPKElement = <HTMLTableCellElement>tableEle.querySelector(".specialEducationIndentificationMethodRowCellPK");
            let buildingNameElement = <HTMLTableCellElement>tableEle.querySelector(".specialEducationIndentificationMethodRowCellName");

            let allElements: HTMLTableCellElement[] = [];

            allElements.push(buildingPKElement);
            allElements.push(buildingNameElement);
            allElements.push(aunElement);
            allElements.push(branchElement);
            allElements.push(pdeApprovedElement);

            let row = null;

            if (tableEle.dataset.row)
                row = parseInt(tableEle.dataset.row);

            for (let element of allElements) {
                let building = <HTMLTableCellElement>element;

                let planPropertyPK = 0;
                let propertyPK = 0;
                let lookupCodePK = null;
                let textValue = null;

                if (building.dataset.propertypk)
                    propertyPK = parseInt(building.dataset.propertypk);
                if (building.dataset.textvalue)
                    textValue = building.dataset.textvalue;


                let hadValue = false;
                if (building.dataset.planpropertypk && building.dataset.planpropertypk !== "" && building.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(building.dataset.planpropertypk);
                    hadValue = true;
                }

                if (hadValue || textValue !== "") {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        IsDeletedInd: false,
                        LookupCodeFK: lookupCodePK,
                        RowNbr: row,
                        TextValue: textValue
                    };

                    allSaveElements.push(saveItem);
                }
            }

            let rtiSpans = rtiElement.querySelectorAll("span");
            for (let rti of rtiSpans) {
                let rtiSpan = <HTMLSpanElement>rti;
                let propertyElement = <HTMLDivElement>document.querySelector(".multiSelectIdentificationRTI");

                let planPropertyPK = 0;
                let propertyPK = 0;
                let lookupCodePK = null;
                let textValue = null;

                if (propertyElement.dataset.propertypk)
                    propertyPK = parseInt(propertyElement.dataset.propertypk);
                if (rtiSpan.dataset.lookupcodepk)
                    lookupCodePK = parseInt(rtiSpan.dataset.lookupcodepk);

                let hadValue = false;
                if (rtiSpan.dataset.planpropertypk && rtiSpan.dataset.planpropertypk !== "" && rtiSpan.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(rtiSpan.dataset.planpropertypk);
                    hadValue = true;
                }

                if (hadValue || lookupCodePK > 0) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        IsDeletedInd: false,
                        LookupCodeFK: lookupCodePK,
                        RowNbr: row,
                        TextValue: textValue
                    };

                    allSaveElements.push(saveItem);
                }
            } 
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationIdentificationMethod', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addSchool(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;

        let improvementPlanningActivities = document.getElementsByClassName("specialEducationIndentificationMethodRow");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = <HTMLInputElement>improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }

        newRow++;

        let buildingNameElement = <HTMLSelectElement>document.getElementById("specialEducationIdentificationBuildingName");
        let aunElement = <HTMLInputElement>document.getElementById("specialEducationIdentificationspecialEducationIdentificationAUN");
        let branchElement = <HTMLInputElement>document.getElementById("specialEducationIdentificationspecialEducationIdentificationBranchNumber");
        let pdeApprovedElement = <HTMLInputElement>document.getElementById("specialEducationIdentificationspecialEducationIdentificationPDEApproved");

        let buildingSelection = <HTMLOptionElement>buildingNameElement[buildingNameElement.selectedIndex];

        let buildingName = buildingNameElement.value;
        let aun = aunElement.value;
        let branch = branchElement.value;
        let buildingPK = 0;
        let rtiList: IIdentificationMethodAddSchoolRTI[] = [];

        if (buildingSelection.dataset.institutionpk && buildingSelection.dataset.institutionpk !== null && buildingSelection.dataset.institutionpk !== "")
            buildingPK = parseInt(buildingSelection.dataset.institutionpk);

        let rtis = document.getElementsByClassName("specialEducationIdentificationMethodMultiselect");
        for (let rti of rtis) {
            let rtiElement = <HTMLInputElement>rti;

            if (rtiElement.checked) {
                let add: IIdentificationMethodAddSchoolRTI = {
                    LookupCodePK: parseInt(rtiElement.dataset.lookupcodepk),
                    LookupCodeLabel: rtiElement.dataset.lookupcodelabel
                };

                rtiList.push(add);
            }
        }

        let data: IIdentificationMethodAddSchool = {
            RowNumber: newRow,
            PlanFK: parseInt(planFK),
            BuildingName: buildingName,
            AUN: aun,
            Branch: branch,
            BuildingPK: buildingPK,
            PDEApproved: pdeApprovedElement.checked,
            RTI: rtiList
        };

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationIdentificationSchool', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`#specialEducationIdentificationAddedBuildings tbody`);

                var tr = document.createElement("tr");
                tr.innerHTML = xhr.responseText;
                tr.classList.add("specialEducationIndentificationMethodRow");
                tr.dataset.row = newRow.toString();
                element.appendChild(tr);

                buildingNameElement.selectedIndex = 0;
                aunElement.value = "";
                branchElement.value = "";
                pdeApprovedElement.checked = false;

                let multis = document.getElementsByClassName("multiSelectIdentificationRTI");
                for (let multi of multis) {
                    let multiElement = <HTMLDivElement>multi;

                    let checkboxes = multiElement.querySelectorAll("input");
                    for (let check of checkboxes) {
                        let checkElement = <HTMLInputElement>check;
                        if (checkElement.checked)
                            checkElement.click();
                    }
                }

                let addedBuildingsMissing = <HTMLDivElement>document.getElementById("addedBuildingsMissing");
                addedBuildingsMissing.classList.add("hide");

                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(data));
    }

    changeBuilding(e: Event) {
        let buildingSelectElement = <HTMLSelectElement>e.target;

        let selection = <HTMLOptionElement>buildingSelectElement[buildingSelectElement.selectedIndex];
        let aun = selection.dataset.aun;
        let branch = selection.dataset.branch;
        let intitutionpk = selection.dataset.institutionpk;
        let building = selection.value;

        let specialEducationIdentificationspecialEducationIdentificationAUNElement = <HTMLInputElement>document.getElementById("specialEducationIdentificationspecialEducationIdentificationAUN");
        if (specialEducationIdentificationspecialEducationIdentificationAUNElement !== null)
            specialEducationIdentificationspecialEducationIdentificationAUNElement.value = aun;

        let specialEducationIdentificationspecialEducationIdentificationBranchNumberElement = <HTMLInputElement>document.getElementById("specialEducationIdentificationspecialEducationIdentificationBranchNumber");
        if (specialEducationIdentificationspecialEducationIdentificationBranchNumberElement !== null)
            specialEducationIdentificationspecialEducationIdentificationBranchNumberElement.value = branch;
    }

    initializeRequiredFieldsCustom(allClasses: string[], refresh: boolean = false) {
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        if (refresh) {
            let allElements = document.querySelectorAll(classesToValidate);

            for (let element of allElements) {
                let htmlElement = <HTMLElement>element;

                htmlElement.removeAttribute("aria-required");
                let label = Core.findLabelForInput(htmlElement);

                if (label !== null) {
                    label.classList.remove("isRequired");
                    let asterisk = label.querySelector(".required-label") as HTMLElement;
                    if (asterisk != null) {
                        asterisk.parentNode.removeChild(asterisk);
                    }
                }
            }
        }

        let isValid: boolean = true;

        let selectElement = <HTMLSelectElement>document.getElementById("specialEducationIdentificationspecialEducationIdentificationIdentify");
        let selectedOption = <HTMLOptionElement>selectElement[selectElement.selectedIndex];
        let selectedText = selectedOption.text;

        let buildings = document.getElementsByClassName("specialEducationIndentificationMethodRowCellPK");
        if (buildings.length === 0 && selectedText.toLowerCase().indexOf("discrepancy") < 0) {
            isValid = false;
            totalErrors++;

            let buildingNameElement = <HTMLSelectElement>document.getElementById("specialEducationIdentificationBuildingName");
            let rtiElement = <HTMLDivElement>document.getElementById("multi-select-plugin");

            if (!(buildingNameElement.hasAttribute("aria-required") && buildingNameElement.getAttribute("aria-required") === "true")) {
                buildingNameElement.setAttribute("aria-required", "true");
                buildingNameElement.dataset.forcerequired = "true";
                buildingNameElement.classList.add("missing-field");
                buildingNameElement.setAttribute("aria-invalid", "true");
                let label = Core.findLabelForInput(buildingNameElement);

                if (label !== null && !label.classList.contains("isRequired")) {
                    label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    label.classList.add("isRequired");
                }
            }

            if (!(rtiElement.hasAttribute("aria-required") && rtiElement.getAttribute("aria-required") === "true")) {
                rtiElement.setAttribute("aria-required", "true");
                let label = Core.findLabelForInput(rtiElement);

                if (label !== null && !label.classList.contains("isRequired")) {
                    label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    label.classList.add("isRequired");
                }
            }

            let errorMessage = document.getElementById("addedBuildingsMissing");
            if (errorMessage !== null)
                errorMessage.classList.remove("hide");
        }

        if (!isValid) {
            let message = <HTMLDivElement>document.getElementById("validationMessage");

            if (totalErrors === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            } else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");

            if (goToError !== null) {

                let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        if (firstFocusableEl.classList.contains("mce")) {
                            tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                        } else {
                            firstFocusableEl.focus();
                        }
                    });
                } else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
        }
    }

    doCustomValidation() {
        let isValid: boolean = true;

        let selectElements = document.getElementsByClassName("specialEducationIdentificationMethodSelectField");
        let selectElement = document.createElement("select");
        for (let selectBox of selectElements) {
            selectElement = <HTMLSelectElement>selectBox;
        }

        if (selectElement.dataset.planpropertypk === "")
            isValid = false;

        let selectedOption = <HTMLOptionElement>selectElement[selectElement.selectedIndex];
        let selectedText = selectedOption.text;

        let buildings = document.getElementsByClassName("specialEducationIndentificationMethodRowCellPK");
        if (buildings.length === 0 && selectedText.toLowerCase().indexOf("discrepancy") < 0)
            isValid = false;
        else {

            for (let building of buildings) {
                let buildingElement = <HTMLInputElement>building;

                if (buildingElement.dataset.planpropertypk === "")
                    isValid = false;
            }
        }

        //this._core.clientSideValidation(["specialEducationIdentificationMethodSchoolTable"]);

        let showMessage = !isValid;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");

        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }

    bindCheckboxes() {
        let allCheckboxes = document.getElementsByClassName("specialEducationIdentificationMethodCheckboxField");
        for (let check of allCheckboxes) {
            check.addEventListener("change", (e: Event) => this.disabledCheckbox(e));
        }

        let allMultiCheckboxes = document.getElementsByClassName("specialEducationIdentificationMethodMultiselect");
        for (let check of allMultiCheckboxes) {
            check.addEventListener("change", (e: Event) => this.disabledCheckbox(e));
        }
    }

    disabledCheckbox(e: Event) {
        let checkbox = <HTMLInputElement>e.target;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }

    }

    bindDeleteButtons() {
        let buttons = document.getElementsByClassName("removeIdentificationMethodButton");

        for (let button of buttons)
            button.addEventListener("click", (e: Event) => this.deleteIdentificationMethod(e));
    }

    deleteIdentificationMethod(e: Event) {
        let button = <HTMLButtonElement>e.target;
        let row = button.dataset.row;
        let newRow = button.dataset.newrow;

        let modal: Modal = new Modal("deleteIdentificationMethodRowModal", null);
        modal.addAttributeToElement("deleteIdentificationMethodRowModal", "#specialEducationDeleteIdentificationMethodDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteIdentificationMethodRowModal", "#specialEducationDeleteIdentificationMethodDeleteConfirm", "newrow", newRow);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("deleteIdentificationMethodRowModal", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core = this._core;
        Core.showLoader();

        if (buttonElement.dataset.newrow === "true") {
            let trElement = document.querySelector(`.specialEducationIndentificationMethodRow[data-row='${row}']`);

            trElement.remove();

            let modal: Modal = new Modal("deleteIdentificationMethodRowModal", null);
            modal.hide();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The identification method row has been removed.", 'success', 3000, null);
        }
        else {
            let rowToDeleteTr = document.querySelector(`.specialEducationIndentificationMethodRow[data-row='${row}']`);
            let rowElements = rowToDeleteTr.querySelectorAll(`td[data-row='${row}']`);

            for (let ele of rowElements) {
                let tdElement = <HTMLTableCellElement>ele;

                if (tdElement.classList.contains("specialEducationIndentificationMethodRowCellRTI")) {
                    let spans = tdElement.querySelectorAll("span.specialEducationIdentificationMethodRTI");

                    for (let span of spans) {
                        let spanEle = <HTMLSpanElement>span;

                        let planProp = parseInt(spanEle.dataset.planpropertypk);

                        if (planProp > 0) {
                            planProps.push(planProp);
                        }

                    }
                } else if (tdElement.classList.contains("specialEducationIndentificationMethodRowCellName")) {
                    let input = <HTMLInputElement>tdElement.querySelector("input.specialEducationIndentificationMethodRowCellPK");
                    let planProp = parseInt(input.dataset.planpropertypk);

                    if (planProp > 0) {
                        planProps.push(planProp);
                    }

                    let planPropName = parseInt(tdElement.dataset.planpropertypk);

                    if (planPropName > 0) {
                        planProps.push(planPropName);
                    }
                } else {

                    let planProp = parseInt(tdElement.dataset.planpropertypk);

                    if (planProp > 0) {
                        planProps.push(planProp);
                    }
                }
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteIdentificationMethodRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {

                    rowToDeleteTr.remove();

                    Core.hideLoader();
                    let modal: Modal = new Modal("deleteIdentificationMethodRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The identification method row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    changeIdentification(selectElement: HTMLSelectElement) {
        let userSelectionModelTitleElement = <HTMLSpanElement>document.getElementById("userSelectionModelTitle");

        let selectedOption = <HTMLOptionElement>selectElement[selectElement.options.selectedIndex];
        let addBuildingButton = <HTMLButtonElement>document.getElementById("specialEducationIdentificationAddSchool");

        let specialEducationIdentificationBuildingNameElement = <HTMLSelectElement>document.getElementById("specialEducationIdentificationBuildingName");
        let specialEducationIdentificationspecialEducationIdentificationAUNElement = <HTMLInputElement>document.getElementById("specialEducationIdentificationspecialEducationIdentificationAUN");
        let specialEducationIdentificationspecialEducationIdentificationBranchNumberElement = <HTMLInputElement>document.getElementById("specialEducationIdentificationspecialEducationIdentificationBranchNumber");
        let multiSelectIdentificationRTIElement = <HTMLDivElement>document.querySelector(".multiSelectIdentificationRTI");

        if (selectedOption.text.indexOf("Discrepancy") >= 0) {
            userSelectionModelTitleElement.textContent = "Discrepancy Model";
            addBuildingButton.disabled = true;

            this._core.forceElementOptional(multiSelectIdentificationRTIElement);
            this._core.forceElementOptional(specialEducationIdentificationspecialEducationIdentificationBranchNumberElement);
            this._core.forceElementOptional(specialEducationIdentificationspecialEducationIdentificationAUNElement);
            this._core.forceElementOptional(specialEducationIdentificationBuildingNameElement);

        } else if (selectedOption.text.indexOf("RTI") >= 0) {
            userSelectionModelTitleElement.textContent = "RTI";
            addBuildingButton.disabled = false;

            this._core.forceElementRequired(multiSelectIdentificationRTIElement);
            this._core.forceElementRequired(specialEducationIdentificationspecialEducationIdentificationBranchNumberElement);
            this._core.forceElementRequired(specialEducationIdentificationspecialEducationIdentificationAUNElement);
            this._core.forceElementRequired(specialEducationIdentificationBuildingNameElement);
        } else if (selectedOption.text.indexOf("Both") >= 0) {
            userSelectionModelTitleElement.textContent = "Both Models";
            addBuildingButton.disabled = false;

            this._core.forceElementRequired(multiSelectIdentificationRTIElement);
            this._core.forceElementRequired(specialEducationIdentificationspecialEducationIdentificationBranchNumberElement);
            this._core.forceElementRequired(specialEducationIdentificationspecialEducationIdentificationAUNElement);
            this._core.forceElementRequired(specialEducationIdentificationBuildingNameElement);
        } else { //They choose "Select One"
            userSelectionModelTitleElement.textContent = "";
            addBuildingButton.disabled = true;

            this._core.forceElementOptional(multiSelectIdentificationRTIElement);
            this._core.forceElementOptional(specialEducationIdentificationspecialEducationIdentificationBranchNumberElement);
            this._core.forceElementOptional(specialEducationIdentificationspecialEducationIdentificationAUNElement);
            this._core.forceElementOptional(specialEducationIdentificationBuildingNameElement);
        }
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIdentificationMethodForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIdentificationMethodExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationSupportServices.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}

// Special Education Data Review - Placement
class SpecialEducationDataReviewPlacement {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationDataReviewSelectField", "specialEducationDataReviewField"];

        let specialEducationDataReviewFormSaveButton = document.getElementById("specialEducationDataReviewSave");
        if (specialEducationDataReviewFormSaveButton !== null)
            specialEducationDataReviewFormSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteDataReviewRowConfirmButton = document.getElementById("deleteDataReviewRowConfirm");
        if (deleteDataReviewRowConfirmButton !== null)
            deleteDataReviewRowConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteDataReviewRowCancelButton = document.getElementById("deleteDataReviewRowCancel");
        if (deleteDataReviewRowCancelButton !== null)
            deleteDataReviewRowCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        this.bindDeleteRowButtons();

        let specialEducationDataReviewAddTrendButton = document.getElementById("specialEducationDataReviewAddTrend");
        if (specialEducationDataReviewAddTrendButton !== null)
            specialEducationDataReviewAddTrendButton.addEventListener("click", (e: Event) => this.addDataReviewPlacementTrend(e));

        let specialEducationDataReviewSignificantDisproportionalityDropdowns = document.getElementsByClassName("specialEducationDataReviewSignificantDisproportionalityDropdown");
        for (let specialEducationDataReviewSignificantDisproportionalityDropdown of specialEducationDataReviewSignificantDisproportionalityDropdowns)
            specialEducationDataReviewSignificantDisproportionalityDropdown.addEventListener("change", (e: Event) => this.setSignificantDisproportionalityRequirements(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationDataReviewFormElement = <HTMLDivElement>document.getElementById("specialEducationDataReviewPlacementForm");
        let inputs = document.getElementsByClassName("specialEducationDataReviewField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("specialEducationDataReviewSelectField");

        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationDataReviewPlacement', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addDataReviewPlacementTrend(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let propertyCodeTrends = "specialEducationDataReviewPlacementTrends";
        let propertyCodeImprovement = "specialEducationDataReviewPlacementImprovement";
        let pageCode = "specialeducationdatareviewplacement";

        let improvementPlanningActivities = document.getElementsByClassName("specialEducationDataReviewField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = <HTMLInputElement>improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }

        newRow++;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationDataReviewCommonTrend', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`#identifyTrends`);

                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("data-review-identify-trends");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);

                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCodeTrends=${propertyCodeTrends}&propertyCodeImprovement=${propertyCodeImprovement}&pageCode=${pageCode}`);
    }

    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationDataReviewField");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;

        let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "row", row);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "newrow", newRow);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;

        if (newRow.toLowerCase() === "true") {
            let thisRow = <HTMLDivElement>document.querySelector(`.data-review-identify-trends[data-row='${row}']`);

            thisRow.remove();

            let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
            modal.hide();
        } else {
            let planProps = [];
            let core = this._core;

            let thisRow = <HTMLDivElement>document.querySelector(`.data-review-identify-trends[data-row='${row}']`);
            let textElements = thisRow.querySelectorAll(`textarea.specialEducationDataReviewField[data-row='${row}']`);
            for (let text of textElements) {
                let textElement = <HTMLTextAreaElement>text;
                let planProp = textElement.dataset.planpropertypk;

                if (planProp && parseInt(planProp) > 0) {
                    planProps.push(planProp);
                }
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteDataReviewRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    thisRow.remove();

                    let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    setSignificantDisproportionalityRequirements(e: Event) {
        let dropdownElement = <HTMLSelectElement>e.target;

        let elementContent = dropdownElement[dropdownElement.selectedIndex].textContent;

        if (elementContent.indexOf("Not Flagged") >= 0) {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");

            for (let textElement of allElements) {
                let element = <HTMLTextAreaElement>textElement;

                if (element.classList.contains("trending")) 
                    this._core.forceElementRequired(element);
                else
                    this._core.forceElementOptional(element);
            }

            let topLabels = document.querySelectorAll(".tabcontent .fake-label");
            for (let labelElement of topLabels) {
                if (!labelElement.classList.contains("trending")) {
                    let span = labelElement.querySelector("span")
                    span.remove();
                }
                else if (labelElement.innerHTML.indexOf("required-label") < 0)
                    labelElement.innerHTML = labelElement.innerHTML + " <span class='required-label'>*</span>";
            }
        } else {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");

            for (let textElement of allElements) {
                let element = <HTMLTextAreaElement>textElement;

                this._core.forceElementRequired(element);

                let topLabels = document.querySelectorAll(".tabcontent .fake-label");
                for (let labelElement of topLabels) {
                    let label = <HTMLElement>labelElement;
                    if (!label.querySelector("span")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    }
                }
            }
        }
    }
}

// Special Education Data Review - Least Restrictive Environment
class SpecialEducationDataReviewDiscipline {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationDataReviewSelectField", "specialEducationDataReviewField"];

        let specialEducationDataReviewFormSaveButton = document.getElementById("specialEducationDataReviewSave");
        if (specialEducationDataReviewFormSaveButton !== null)
            specialEducationDataReviewFormSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let specialEducationDataReviewAddTrendButton = document.getElementById("specialEducationDataReviewAddTrend");
        if (specialEducationDataReviewAddTrendButton !== null)
            specialEducationDataReviewAddTrendButton.addEventListener("click", (e: Event) => this.addDataReviewDisciplineTrend(e));

        let deleteDataReviewRowConfirmButton = document.getElementById("deleteDataReviewRowConfirm");
        if (deleteDataReviewRowConfirmButton !== null)
            deleteDataReviewRowConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteDataReviewRowCancelButton = document.getElementById("deleteDataReviewRowCancel");
        if (deleteDataReviewRowCancelButton !== null)
            deleteDataReviewRowCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        this.bindDeleteRowButtons();

        let specialEducationDataReviewSignificantDisproportionalityDropdowns = document.getElementsByClassName("specialEducationDataReviewSignificantDisproportionalityDropdown");
        for (let specialEducationDataReviewSignificantDisproportionalityDropdown of specialEducationDataReviewSignificantDisproportionalityDropdowns)
            specialEducationDataReviewSignificantDisproportionalityDropdown.addEventListener("change", (e: Event) => this.setSignificantDisproportionalityRequirements(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationDataReviewFormElement = <HTMLDivElement>document.getElementById("specialEducationDisciplineForm");
        let inputs = document.getElementsByClassName("specialEducationDataReviewField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("specialEducationDataReviewSelectField");

        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationDataReviewDiscipline', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addDataReviewDisciplineTrend(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let propertyCodeTrends = "specialEducationDataReviewDisciplineIdentifyTrends";
        let propertyCodeImprovement = "specialEducationDataReviewDisciplineImprovement";
        let pageCode = "specialeducationdatareviewdisciplin";

        let that = this;

        let improvementPlanningActivities = document.getElementsByClassName("specialEducationDataReviewField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = <HTMLInputElement>improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationDataReviewCommonTrend', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`#identifyTrends`);

                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("data-review-identify-trends");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);

                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCodeTrends=${propertyCodeTrends}&propertyCodeImprovement=${propertyCodeImprovement}&pageCode=${pageCode}`);
    }

    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationDataReviewField");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;

        let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "row", row);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "newrow", newRow);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;

        if (newRow.toLowerCase() === "true") {
            let thisRow = <HTMLDivElement>document.querySelector(`.data-review-identify-trends[data-row='${row}']`);

            thisRow.remove();

            let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
            modal.hide();
        } else {
            let planProps = [];
            let core = this._core;

            let thisRow = <HTMLDivElement>document.querySelector(`.data-review-identify-trends[data-row='${row}']`);
            let textElements = thisRow.querySelectorAll(`textarea.specialEducationDataReviewField[data-row='${row}']`);
            for (let text of textElements) {
                let textElement = <HTMLTextAreaElement>text;
                let planProp = textElement.dataset.planpropertypk;

                if (planProp && parseInt(planProp) > 0) {
                    planProps.push(planProp);
                }
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteDataReviewRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    thisRow.remove();

                    let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    setSignificantDisproportionalityRequirements(e: Event) {
        let dropdownElement = <HTMLSelectElement>e.target;

        let elementContent = dropdownElement[dropdownElement.selectedIndex].textContent;

        if (elementContent.indexOf("Not Flagged") >= 0) {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");

            for (let textElement of allElements) {
                let element = <HTMLTextAreaElement>textElement;

                if (element.classList.contains("trending"))
                    this._core.forceElementRequired(element);
                else
                    this._core.forceElementOptional(element);
            }

            let topLabels = document.querySelectorAll(".tabcontent .fake-label");
            for (let labelElement of topLabels) {
                if (!labelElement.classList.contains("trending")) {
                    let span = labelElement.querySelector("span")
                    span.remove();
                }
                else if (labelElement.innerHTML.indexOf("required-label") < 0)
                    labelElement.innerHTML = labelElement.innerHTML + " <span class='required-label'>*</span>";
            }
        } else {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");

            for (let textElement of allElements) {
                let element = <HTMLTextAreaElement>textElement;

                this._core.forceElementRequired(element);

                let topLabels = document.querySelectorAll(".tabcontent .fake-label");
                for (let labelElement of topLabels) {
                    let label = <HTMLElement>labelElement;
                    if (!label.querySelector("span")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    }
                }
            }
        }
    }
}

// Special Education Data Review - Identification
class SpecialEducationDataReviewIdentification {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationDataReviewSelectField", "specialEducationDataReviewField"];

        let specialEducationDataReviewFormSaveButton = document.getElementById("specialEducationDataReviewSave");
        if (specialEducationDataReviewFormSaveButton !== null)
            specialEducationDataReviewFormSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let specialEducationDataReviewAddTrendButton = document.getElementById("specialEducationDataReviewAddTrend");
        if (specialEducationDataReviewAddTrendButton !== null)
            specialEducationDataReviewAddTrendButton.addEventListener("click", (e: Event) => this.addDataReviewIdentificationTrend(e));

        let deleteDataReviewRowConfirmButton = document.getElementById("deleteDataReviewRowConfirm");
        if (deleteDataReviewRowConfirmButton !== null)
            deleteDataReviewRowConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteDataReviewRowCancelButton = document.getElementById("deleteDataReviewRowCancel");
        if (deleteDataReviewRowCancelButton !== null)
            deleteDataReviewRowCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        this.bindDeleteRowButtons();

        let specialEducationDataReviewSignificantDisproportionalityDropdowns = document.getElementsByClassName("specialEducationDataReviewSignificantDisproportionalityDropdown");
        for (let specialEducationDataReviewSignificantDisproportionalityDropdown of specialEducationDataReviewSignificantDisproportionalityDropdowns)
            specialEducationDataReviewSignificantDisproportionalityDropdown.addEventListener("change", (e: Event) => this.setSignificantDisproportionalityRequirements(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationDataReviewFormElement = <HTMLDivElement>document.getElementById("specialEducationDataReviewIdentificationForm");
        let inputs = document.getElementsByClassName("specialEducationDataReviewField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("specialEducationDataReviewSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationDataReviewIdentification', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addDataReviewIdentificationTrend(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let propertyCodeTrends = "specialEducationDataReviewIdentificationIdentifyTrends";
        let propertyCodeImprovement = "specialEducationDataReviewIdentificationImprovement";
        let pageCode = "specialeducationdatareviewident";

        let improvementPlanningActivities = document.getElementsByClassName("specialEducationDataReviewField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = <HTMLInputElement>improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }

        newRow++;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationDataReviewCommonTrend', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`#identifyTrends`);

                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("data-review-identify-trends");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);

                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCodeTrends=${propertyCodeTrends}&propertyCodeImprovement=${propertyCodeImprovement}&pageCode=${pageCode}`);
    }

    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationDataReviewField");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;

        let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "row", row);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "newrow", newRow);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;

        if (newRow.toLowerCase() === "true") {
            let thisRow = <HTMLDivElement>document.querySelector(`.data-review-identify-trends[data-row='${row}']`);

            thisRow.remove();

            let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
            modal.hide();
        } else {
            let planProps = [];
            let core = this._core;

            let thisRow = <HTMLDivElement>document.querySelector(`.data-review-identify-trends[data-row='${row}']`);
            let textElements = thisRow.querySelectorAll(`textarea.specialEducationDataReviewField[data-row='${row}']`);
            for (let text of textElements) {
                let textElement = <HTMLTextAreaElement>text;
                let planProp = textElement.dataset.planpropertypk;

                if (planProp && parseInt(planProp) > 0) {
                    planProps.push(planProp);
                }
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteDataReviewRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    thisRow.remove();

                    let modal: Modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    setSignificantDisproportionalityRequirements(e: Event) {
        let dropdownElement = <HTMLSelectElement>e.target;

        let elementContent = dropdownElement[dropdownElement.selectedIndex].textContent;

        if (elementContent.indexOf("Not Flagged") >= 0) {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");

            for (let textElement of allElements) {
                let element = <HTMLTextAreaElement>textElement;

                if (element.classList.contains("trending"))
                    this._core.forceElementRequired(element);
                else
                    this._core.forceElementOptional(element);
            }

            let topLabels = document.querySelectorAll(".tabcontent .fake-label");
            for (let labelElement of topLabels) {
                if (!labelElement.classList.contains("trending")) {
                    let span = labelElement.querySelector("span")
                    span.remove();
                }
                else if (labelElement.innerHTML.indexOf("required-label") < 0)
                    labelElement.innerHTML = labelElement.innerHTML + " <span class='required-label'>*</span>";
            }
        } else {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");

            for (let textElement of allElements) {
                let element = <HTMLTextAreaElement>textElement;

                this._core.forceElementRequired(element);

                let topLabels = document.querySelectorAll(".tabcontent .fake-label");
                for (let labelElement of topLabels) {
                    let label = <HTMLElement>labelElement;
                    if (!label.querySelector("span")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    }
                }
            }
        }
    }
}

// Special Education Non-Resident Students Oversight
class SpecialEducationNonResidentStudentsOversight {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationNonResidentStudentsOversightField", "specialEducationNonResidentStudentsOversightSelectField", "specialEducationNonResidentStudentsOversightRadioField"];

        let specialEducationNonResidentStudentsOversightFormSaveButton = document.getElementById("specialEducationNonResidentStudentsOversightSave");
        if (specialEducationNonResidentStudentsOversightFormSaveButton !== null)
            specialEducationNonResidentStudentsOversightFormSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let radioButtonHostDistrict = document.getElementsByName("hostDistrict");
        for (let radioButtonHostDistrictElement of radioButtonHostDistrict)
            radioButtonHostDistrictElement.addEventListener("change", (e: Event) => this.hostDistrict());

        this.hostDistrict();

        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let specialEducationNonResidentStudentsOversightAddAFacilityButton = document.getElementById("specialEducationNonResidentStudentsOversightAddAFacility");
        if (specialEducationNonResidentStudentsOversightAddAFacilityButton !== null)
            specialEducationNonResidentStudentsOversightAddAFacilityButton.addEventListener("click", (e: Event) => this.addFacility(e));

        this.bindDeleteRowButtons();
        this.bindOtherDropdowns();

        let deleteFacilityRowConfirmButton = document.getElementById("deleteOversightFacilityConfirm");
        if (deleteFacilityRowConfirmButton !== null)
            deleteFacilityRowConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteFacilityRowCancelButton = document.getElementById("deleteOversightFacilityCancel");
        if (deleteFacilityRowCancelButton !== null)
            deleteFacilityRowCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationNonResidentStudentsOversightFormElement = <HTMLDivElement>document.getElementById("specialEducationNonResidentStudentsOversightForm");
        let inputs = document.getElementsByClassName("specialEducationNonResidentStudentsOversightField");
        for (let ele of inputs) {
            if (!ele.classList.contains("specialEducationNonResidentStudentsOversightFakeField")) { //This is a special case. We don't want this in the save.
                let planPropertyPK = 0;
                planPK = parseInt(specialEducationNonResidentStudentsOversightFormElement.dataset.planfk);

                let element = <HTMLInputElement>ele;
                let rowNumber = parseInt(element.dataset.row);
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let checkInputs = document.getElementsByClassName("specialEducationNonResidentStudentsOversightCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationNonResidentStudentsOversightFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let row = element.dataset.row;
            let rowNumber = 0;
            if (row)
                rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationNonResidentStudentsOversightSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationNonResidentStudentsOversightFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let row = element.dataset.row;
            let rowNumber = 0;
            if (row)
                rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("specialEducationNonResidentStudentsOversightRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationNonResidentStudentsOversight', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    hostDistrict() {
        let elements = document.getElementsByName("hostDistrict");

        let allFacilityElements = document.getElementsByClassName("specialEducationNonResidentStudentsOversightFacility");

        let yesChecked: boolean = false;

        for (let ele of elements) {
            let element = <HTMLInputElement>ele;
            if (element.checked && element.dataset.lookuplabel === "Yes")
                yesChecked = true;
        }

        let facilityTableElement = <HTMLDivElement>document.getElementById("facilityTable");

        let specialEducationNonResidentStudentsOversightProceduresElement = <HTMLTextAreaElement>document.getElementById("specialEducationNonResidentStudentsOversightProcedures");
        let specialEducationNonResidentStudentsOversightEducationalElement = <HTMLTextAreaElement>document.getElementById("specialEducationNonResidentStudentsOversightEducational");

        if (yesChecked) {
            for (let ele of allFacilityElements) {
                let element = <HTMLElement>ele;

                this._core.forceElementRequired(element);
            }
            this._core.forceElementRequired(specialEducationNonResidentStudentsOversightProceduresElement);
            this._core.forceElementRequired(specialEducationNonResidentStudentsOversightEducationalElement);

            facilityTableElement.classList.remove("hide");
        } else {
            for (let ele of allFacilityElements) {
                let element = <HTMLElement>ele;

                this._core.forceElementOptional(element);
            }
            this._core.forceElementRequired(specialEducationNonResidentStudentsOversightProceduresElement);
            this._core.forceElementRequired(specialEducationNonResidentStudentsOversightEducationalElement);

            facilityTableElement.classList.add("hide");

            let facilityErrorElement = <HTMLInputElement>document.getElementById("incompleteFacilityTableError");
            if(facilityErrorElement)
                facilityErrorElement.remove();
        }


        let facilitySelects = document.querySelectorAll(`.specialEducationNonResidentStudentsOversightFacilityWithOther`);
        for (let facilitySelect of facilitySelects) {
            let ele = <HTMLSelectElement>facilitySelect;
            let selectedOption = <HTMLOptionElement>ele[ele.selectedIndex];
            let row = ele.dataset.row;
            let otherElement = <HTMLInputElement>document.querySelector(`.specialEducationNonResidentStudentsOversightFacilityActualOther[data-row='${row}']`);

            if (selectedOption.text.indexOf("Other") > 0) {
                this._core.forceElementRequired(otherElement);
            } else {
                this._core.forceElementOptional(otherElement);
            }
        }

    }

    changeFacilityType(e: Event) {
        let row = (<HTMLElement>e.target).dataset.row;
        let selectElement = <HTMLSelectElement>document.querySelector(`.specialEducationNonResidentStudentsOversightFacilityWithOther[data-row='${row}']`);

        let selectedOption = <HTMLOptionElement>selectElement[selectElement.selectedIndex];

        let hiddenOther = <HTMLDivElement>document.querySelector(`.facilityTypeOther[data-row='${row}']`);
        let hiddenOtherText = <HTMLInputElement>document.querySelector(`.specialEducationNonResidentStudentsOversightFacilityActualOther[data-row='${row}']`);

        if (selectedOption.text === "Other") {
            if (hiddenOther !== null)
                hiddenOther.classList.remove("hide");

            if (hiddenOtherText !== null)
                this._core.forceElementRequired(hiddenOtherText);
        } else {
            if (hiddenOther !== null)
                hiddenOther.classList.add("hide");

            if (hiddenOtherText !== null)
                this._core.forceElementOptional(hiddenOtherText);
        }
    }

    addFacility(e) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;

        let improvementPlanningActivities = document.getElementsByClassName("specialEducationNonResidentStudentsOversightFacility");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = <HTMLInputElement>improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }

        newRow++;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationNonResidentStudentsOversightFacility', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`#facilities`);

                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("facility");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);

                that.bindDeleteRowButtons();
                that.bindOtherDropdowns();

                let facilitiesMissingElement = <HTMLDivElement>document.getElementById("facilitiesMissing");
                facilitiesMissingElement.classList.remove("hide");
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}`);
    }

    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationNonResidentStudentsOversightFacility");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("specialEducationFacilityDeleteRowModel", null);
        modal.addAttributeToElement("specialEducationFacilityDeleteRowModel", "#deleteOversightFacilityConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("specialEducationFacilityDeleteRowModel", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let planProps = [];

        let thisRow = <HTMLDivElement>document.querySelector(`.facility[data-row='${row}']`);
        let textElements = thisRow.querySelectorAll(`.specialEducationNonResidentStudentsOversightFacility[data-row='${row}']`);
        for (let ele of textElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteNonResidentOversightFacilityRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                thisRow.remove();

                let modal: Modal = new Modal("specialEducationFacilityDeleteRowModel", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindOtherDropdowns() {
        let specialEducationNonResidentStudentsOversightFacilityTypeSelects = document.getElementsByClassName("specialEducationNonResidentStudentsOversightFacilityWithOther");
        for (let specialEducationNonResidentStudentsOversightFacilityTypeSelect of specialEducationNonResidentStudentsOversightFacilityTypeSelects)
            specialEducationNonResidentStudentsOversightFacilityTypeSelect.addEventListener("change", (e: Event) => this.changeFacilityType(e));

    }

    initializeRequiredFieldsCustom(validationClasses: string[]) {

        let yesChecked: boolean = false;

        let hostDistrict = document.getElementsByClassName("specialEducationNonResidentStudentsOversightRadioField");
        for (let host of hostDistrict) {
            let ele = <HTMLInputElement>host;

            if (ele.checked) {
                if (ele.dataset.lookuplabel === "yes")
                    yesChecked = true;
            }
        }

        if (yesChecked) {
            let facilities = document.querySelectorAll(`.special-education-facilities .facility`);
            let facilitiesMissingElement = <HTMLDivElement>document.getElementById("facilitiesMissing");
            if (facilities.length === 0) {
                facilitiesMissingElement.classList.remove("hide");

                var fakeElementDiv = <HTMLDivElement>document.createElement("div");
                fakeElementDiv.classList.add("medium-6");
                fakeElementDiv.classList.add("column");
                fakeElementDiv.classList.add("end");
                fakeElementDiv.id = "incompleteFacilityTableError";

                var fakeElement = <HTMLInputElement>document.createElement("input");
                fakeElement.type = "text";
                fakeElement.style.opacity = "0";
                fakeElement.style.filter = "alpha(opacity=0)";
                fakeElement.style.width = "5";
                fakeElement.style.height = "0";
                fakeElement.dataset.percent = "1.0";
                fakeElement.classList.add("specialEducationNonResidentStudentsOversightField");
                fakeElement.classList.add("specialEducationNonResidentStudentsOversightFakeField");

                this._core.forceElementRequired(fakeElement);

                fakeElementDiv.appendChild(fakeElement);

                facilitiesMissingElement.appendChild(fakeElementDiv);


            } else {
                facilitiesMissingElement.classList.remove("show");

                let divElement = document.getElementById("incompleteFacilityTableError");
                if (divElement)
                    divElement.remove();
            }
        }

        this._core.initializeRequiredFields(validationClasses);
    }
}

// Special Education Incarcerated Students Oversight
class SpecialEducationIncarceratedStudentsOversight {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIncarceratedStudentsOversightField", "specialEducationIncarceratedStudentsOversightSelectField", "specialEducationIncarceratedStudentsOversightRadioField"];

        let specialEducationIncarceratedStudentsOversightSaveButton = document.getElementById("specialEducationIncarceratedStudentsOversightSave");
        if (specialEducationIncarceratedStudentsOversightSaveButton !== null)
            specialEducationIncarceratedStudentsOversightSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let radioButtonHostDistrict = document.getElementsByName("hostDistrict");
        for (let radioButtonHostDistrictElement of radioButtonHostDistrict)
            radioButtonHostDistrictElement.addEventListener("change", (e: Event) => this.adultCorrectional());

        this.adultCorrectional();

        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let specialEducationIncareratedStudentsOversightAddAFacilityButton = document.getElementById("specialEducationIncarceratedStudentsOversightAddAFacility");
        if (specialEducationIncareratedStudentsOversightAddAFacilityButton !== null)
            specialEducationIncareratedStudentsOversightAddAFacilityButton.addEventListener("click", (e: Event) => this.addFacility(e));

        this.bindDeleteRowButtons();

        let deleteFacilityRowConfirmButton = document.getElementById("deleteOversightFacilityConfirm");
        if (deleteFacilityRowConfirmButton !== null)
            deleteFacilityRowConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteFacilityRowCancelButton = document.getElementById("deleteOversightFacilityCancel");
        if (deleteFacilityRowCancelButton !== null)
            deleteFacilityRowCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationIncarceratedStudentsOversightFormElement = <HTMLDivElement>document.getElementById("specialEducationIncarceratedStudentsOversightForm");
        let inputs = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIncarceratedStudentsOversightFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIncarceratedStudentsOversightFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIncarceratedStudentsOversightFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if ((element.value !== "" && element.value !== "0") || hadValue) {
                if (parseInt(element.value) > 0) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let radioInputs = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationIncarceratedStudentsOversight', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    adultCorrectional() {
        let elements = document.getElementsByName("hostDistrict");

        let allFacilityElements = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightFacility");

        let yesChecked: boolean = false;

        for (let ele of elements) {
            let element = <HTMLInputElement>ele;
            if (element.checked && element.dataset.lookuplabel === "Yes")
                yesChecked = true;
        }

        let facilityTableElement = <HTMLDivElement>document.getElementById("facilityTable");


        if (yesChecked) {
            for (let ele of allFacilityElements) {
                let element = <HTMLInputElement>ele;

                this._core.forceElementRequired(element);
            }

            facilityTableElement.classList.remove("hide");
        } else {
            for (let ele of allFacilityElements) {
                let element = <HTMLInputElement>ele;

                this._core.forceElementOptional(element);
            }
            facilityTableElement.classList.add("hide");
        }

        this.changeRequirements();
    }

    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationIncarceratedStudentsOversightFacility");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("specialEducationFacilityDeleteRowModel", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let planProps = [];

        let thisRow = <HTMLDivElement>document.querySelector(`.facility[data-row='${row}']`);
        let textElements = thisRow.querySelectorAll(`specialEducationIncarceratedStudentsOversightFacility[data-row='${row}']`);
        for (let ele of textElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteIncarceratedOversightFacilityRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                thisRow.remove();

                let modal: Modal = new Modal("specialEducationFacilityDeleteRowModel", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    addFacility(e) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;

        let improvementPlanningActivities = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightFacility");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = <HTMLInputElement>improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }

        newRow++;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationIncarceratedStudentsOversightFacility', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`#facilities`);

                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("facility");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);

                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}`);
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("specialEducationFacilityDeleteRowModel", null);
        modal.addAttributeToElement("specialEducationFacilityDeleteRowModel", "#deleteOversightFacilityConfirm", "row", row);
        modal.show();
    }

    initializeRequiredFieldsCustom(validationClasses: string[]) {
        this.changeRequirements();

        this._core.initializeRequiredFields(this.validationClasses);
    }

    changeRequirements() {
        let textElement = <HTMLInputElement>document.getElementById("specialEducationIncarceratedStudentsDistrict");
        let selectedElement = <HTMLInputElement>document.querySelector("input.specialEducationIncarceratedStudentsOversightRadioField:checked");
        if (selectedElement && selectedElement.dataset.lookuplabel.toLowerCase() === "no") {
            this._core.forceElementOptional(textElement);
            textElement.readOnly = true;
            textElement.disabled = true;
        } else {
            this._core.forceElementRequired(textElement);
            textElement.readOnly = false;
            textElement.disabled = false;
        }
    }
}

// Special Education Least Restrictive Environment
class SpecialEducationLeastRestrictiveEnvironment {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationLeastRestrictiveEnvironmentField", "specialEducationLeastRestrictiveEnvironmentSelectField"];

        let specialEducationLeastRestrictiveEnvironmentSaveButton = document.getElementById("specialEducationLeastRestrictiveEnvironmentSave");
        if (specialEducationLeastRestrictiveEnvironmentSaveButton !== null)
            specialEducationLeastRestrictiveEnvironmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let specialEducationLeastRestrictiveEnvironmentAddAPlacementButton = document.getElementById("specialEducationLeastRestrictiveEnvironmentAddAPlacement");
        if (specialEducationLeastRestrictiveEnvironmentAddAPlacementButton !== null)
            specialEducationLeastRestrictiveEnvironmentAddAPlacementButton.addEventListener("click", (e: Event) => this.addAPlacement(e));

        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteLeastRestrictiveEnvironmentConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteLeastRestrictiveEnvironmentCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        let noOutOfDistrictPlacementsCheckbox = document.getElementById("specialEducationLeastRestrictivespecialEducationLeastRestrictiveNoOutOfDistrictPlacements");
        if (noOutOfDistrictPlacementsCheckbox !== null)
            noOutOfDistrictPlacementsCheckbox.addEventListener("change", (e: Event) => this.changeOutOfDistrict());

        this.changeOutOfDistrict();
        this.bindDeleteButtons();
        this.bindDropdowns();
        this.showOther();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationLeastRestrictiveEnvironmentFormElement = <HTMLDivElement>document.getElementById("specialEducationLeastRestrictiveEnvironmentForm");
        let inputs = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationLeastRestrictiveEnvironmentFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentCheckboxField");

        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationLeastRestrictiveEnvironmentFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let value = "off";
            if (element.checked)
                value = "on";

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let selectInputs = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationLeastRestrictiveEnvironmentFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            rowNumber = parseInt(element.dataset.row);

            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationLeastRestrictiveEnvironment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addAPlacement(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;

        let improvementPlanningActivities = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = <HTMLInputElement>improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }

        newRow++;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationLeastRestrictiveEnvironmentPlacement', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.getElementById("placementList");

                let div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("least-restrictive");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);

                that.bindDeleteButtons();
                that.bindDropdowns();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&rowNumber=" + newRow);
    }

    bindDeleteButtons() {
        let buttons = document.getElementsByClassName("specialEducationLeastRestrictiveRemove");

        for (let button of buttons)
            button.addEventListener("click", (e: Event) => this.deleteIdentificationMethod(e));
    }

    deleteIdentificationMethod(e: Event) {
        let button = <HTMLButtonElement>e.target;
        let row = button.dataset.row;
        let newRow = button.dataset.newrow;

        let modal: Modal = new Modal("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", "#deleteLeastRestrictiveEnvironmentConfirm", "row", row);
        modal.addAttributeToElement("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", "#deleteLeastRestrictiveEnvironmentConfirm", "newrow", newRow);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core = this._core;
        Core.showLoader();

        if (buttonElement.dataset.newrow === "true") {
            let divElement = document.querySelector(`.least-restrictive[data-row='${row}']`);

            divElement.remove();

            let modal: Modal = new Modal("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", null);
            modal.hide();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
        }
        else {
            let rowToDelete = document.querySelector(`.least-restrictive[data-row='${row}']`);
            let rowElements = rowToDelete.querySelectorAll(`input.specialEducationLeastRestrictiveEnvironmentField[data-row='${row}']`);

            for (let ele of rowElements) {
                let tdElement = <HTMLTableCellElement>ele;

                if (tdElement.classList.contains("specialEducationIndentificationMethodRowCellRTI")) {
                    let spans = tdElement.querySelectorAll("span.specialEducationIdentificationMethodRTI");

                    for (let span of spans) {
                        let spanEle = <HTMLSpanElement>span;

                        let planProp = parseInt(spanEle.dataset.planpropertypk);

                        if (planProp > 0) {
                            planProps.push(planProp);
                        }

                    }
                } else if (tdElement.classList.contains("specialEducationIndentificationMethodRowCellName")) {
                    let input = <HTMLInputElement>tdElement.querySelector("input.specialEducationIndentificationMethodRowCellPK");
                    let planProp = parseInt(input.dataset.planpropertypk);

                    if (planProp > 0) {
                        planProps.push(planProp);
                    }

                    let planPropName = parseInt(tdElement.dataset.planpropertypk);

                    if (planPropName > 0) {
                        planProps.push(planPropName);
                    }
                } else {

                    let planProp = parseInt(tdElement.dataset.planpropertypk);

                    if (planProp > 0) {
                        planProps.push(planProp);
                    }
                }
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteLeastRestrictiveEnvironmentRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {

                    rowToDelete.remove();

                    Core.hideLoader();
                    let modal: Modal = new Modal("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    changeFacilityType(e: Event) {
        let selectElement = <HTMLSelectElement>e.target;

        let selectedOption = <HTMLOptionElement>selectElement[selectElement.selectedIndex];

        let row = selectElement.dataset.row;

        let hiddenOther = <HTMLDivElement>document.querySelector(`.specialEducationLeastRestrictiveEnvironmentFacilityTypeOther[data-row='${row}']`);
        let hiddenOtherText = <HTMLInputElement>document.querySelector(`.specialEducationLeastRestrictiveEnvironmentFacilityTypeOtherText[data-row='${row}']`);

        if (selectedOption.text === "Other") {
            if (hiddenOther !== null)
                hiddenOther.classList.remove("hide");

            if (hiddenOtherText !== null)
                this._core.forceElementRequired(hiddenOtherText);
        } else {
            if (hiddenOther !== null)
                hiddenOther.classList.add("hide");

            if (hiddenOtherText !== null)
                this._core.forceElementOptional(hiddenOtherText);
        }
    }

    bindDropdowns() {
        let specialEducationLeastRestrictiveEnvironmentFacilityTypes = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentFacilityType");
        for (let specialEducationLeastRestrictiveEnvironmentFacilityType of specialEducationLeastRestrictiveEnvironmentFacilityTypes)
            specialEducationLeastRestrictiveEnvironmentFacilityType.addEventListener("change", (e: Event) => this.changeFacilityType(e));
    }

    changeOutOfDistrict() {
        let noOutOfDistrictPlacementsCheckbox = <HTMLInputElement>document.getElementById("specialEducationLeastRestrictivespecialEducationLeastRestrictiveNoOutOfDistrictPlacements");
        if (noOutOfDistrictPlacementsCheckbox !== null && noOutOfDistrictPlacementsCheckbox.checked) {
            let rows = document.querySelectorAll(".least-restrictive");

            for (let row of rows) {
                let div = <HTMLDivElement>row;
                let rowNumber = div.dataset.row;

                let allInputElements = document.querySelectorAll(`.least-restrictive[data-row='${rowNumber}'] input`);
                for (let thisElement of allInputElements) {
                    let input = <HTMLInputElement>thisElement;

                    input.readOnly = true;
                    this._core.forceElementOptional(input);
                }

                let allSelectElements = document.querySelectorAll(`.least-restrictive[data-row='${rowNumber}'] select`);
                for (let thisElement of allSelectElements) {
                    let input = <HTMLInputElement>thisElement;

                    input.disabled = true;
                    this._core.forceElementOptional(input);
                }
            }
        } else {
            let rows = document.querySelectorAll(".least-restrictive");
            let firstRow: boolean = true;

            for (let row of rows) {

                let div = <HTMLDivElement>row;
                let rowNumber = div.dataset.row;

                let allInputElements = document.querySelectorAll(`.least-restrictive[data-row='${rowNumber}'] input`);
                for (let thisElement of allInputElements) {
                    let input = <HTMLInputElement>thisElement;

                    input.readOnly = false;
                    if (firstRow)
                        if (!input.classList.contains("specialEducationLeastRestrictiveEnvironmentFacilityTypeOtherText"))
                            this._core.forceElementRequired(input);
                }

                let allSelectElements = document.querySelectorAll(`.least-restrictive[data-row='${rowNumber}'] select`);
                for (let thisElement of allSelectElements) {
                    let input = <HTMLInputElement>thisElement;

                    input.disabled = false;
                    if(firstRow)
                        this._core.forceElementRequired(input);
                }

                firstRow = false;
            }
        }
    }

    initializeRequiredFieldsCustom(validationClasses: string[]) {
        this.changeOutOfDistrict();

        const allFacilityNames = document.querySelectorAll(`.specialEducationLeastRestrictiveEnvironmentFacilityName`);
        for (const facility of allFacilityNames) {
            const ele = <HTMLInputElement>facility;

            if (ele.value !== "") {
                const row = ele.dataset.row;

                const allElements = document.querySelectorAll(`.specialEducationLeastRestrictiveEnvironmentFacilityData[data-type='facility'][data-row='${row}']`);
                for (const innerElement of allElements) {
                    const thisEle = <HTMLElement>innerElement;

                    this._core.forceElementRequired(thisEle);
                }
            }
        }


        this._core.initializeRequiredFields(this.validationClasses);
    }

    showOther() {
        let selects = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentFacilityType");

        for (var select of selects) {
            let element = <HTMLSelectElement>select;
            let selectedOption = <HTMLOptionElement>element[element.selectedIndex]
            let row = element.dataset.row;
            let hiddenOther = <HTMLDivElement>document.querySelector(`.specialEducationLeastRestrictiveEnvironmentFacilityTypeOther[data-row='${row}']`);
            let hiddenOtherText = <HTMLInputElement>document.querySelector(`.specialEducationLeastRestrictiveEnvironmentFacilityTypeOtherText[data-row='${row}']`);
            if (selectedOption.text === "Other") {
                if (hiddenOther !== null)
                    hiddenOther.classList.remove("hide");

                if (hiddenOtherText !== null)
                    this._core.forceElementRequired(hiddenOtherText);
            } else {
                if (hiddenOther !== null)
                    hiddenOther.classList.add("hide");

                if (hiddenOtherText !== null)
                    this._core.forceElementOptional(hiddenOtherText);
            }
        }
    }
}

// Special Education Behavior Support Services
class SpecialEducationBehaviorSupportServices {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationBehaviorSupportServicesField", "specialEducationBehaviorSupportServicesFileField", "specialEducationBehaviorSupportServicesRadioField"];

        let specialEducationBehaviorSupportServicesSaveButton = document.getElementById("specialEducationBehaviorSupportServicesSave");
        if (specialEducationBehaviorSupportServicesSaveButton !== null)
            specialEducationBehaviorSupportServicesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let fileUploader = document.getElementById("uploadMinutes");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteButtons();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationBehaviorSupportServicesFormElement = <HTMLDivElement>document.getElementById("specialEducationBehaviorSupportServicesForm");
        let inputs = document.getElementsByClassName("specialEducationBehaviorSupportServicesField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationBehaviorSupportServicesFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("specialEducationBehaviorSupportServicesRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    if (element.checked) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: "",
                            LookupCodeFK: parseInt(element.value),
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false
                        };
                        allSaveElements.push(saveItem);
                    }
                }
            }
        }


        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationBehaviorSupportServices', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = <HTMLFormElement>document.getElementById("uploadMinutes");
        let formData = new FormData(uploadSampleForm);
        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("uploadMinutes");
                        formFile.value = "";
                        formFile.dataset.hasUploaded = "true";

                        that.bindDeleteButtons();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");

        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e: Event) => this.deleteFile(e));
    }

    deleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}

// Special Education Intensive Interagency
class SpecialEducationIntensiveInteragency {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIntensiveInteragencyField"];

        let specialEducationIntensiveInteragencySaveButton = document.getElementById("specialEducationIntensiveInteragencySave");
        if (specialEducationIntensiveInteragencySaveButton !== null)
            specialEducationIntensiveInteragencySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationIntensiveInteragencyFormElement = <HTMLDivElement>document.getElementById("specialEducationIntensiveInteragencyForm");
        let inputs = document.getElementsByClassName("specialEducationIntensiveInteragencyField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIntensiveInteragencyFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationIntensiveInteragency', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Special Education Education Program (Caseload FTE)
class SpecialEducationEducationProgramCaseloadFTE {

    validationClasses: string[];
    planFK: number;
    templateFK: number;
    deleteSupportConfirmModal: Modal;
    deleteFTEConfirmModal: Modal;
    private typingTimer: number;
    private fteHashes: Object;
    private allowExceedsFTE: boolean;

    private _core: Core;
    constructor() {
        let that = this;
        this._core = new Core();

        let form = document.getElementById("specialEducationEducationProgramCaseloadFTEForm");
        if (form != null) {
            that.planFK = parseInt(form.dataset.planfk);
            that.templateFK = parseInt(form.dataset.templatefk);
        }

        let hdnAllowExceedsFTE = document.getElementById("hdnAllowExceedsFTE") as HTMLInputElement;
        if (hdnAllowExceedsFTE != null && hdnAllowExceedsFTE.value === "True") {
            that.allowExceedsFTE = true;
        } else {
            that.allowExceedsFTE = false;
        }

        new SpecialEducationProgramCaseloadFTELazyAccordion();

        that.deleteSupportConfirmModal = new Modal("deleteSupportConfirmModal", null);
        that.deleteFTEConfirmModal = new Modal("deleteFTEConfirmModal", null);

        this.validationClasses = [
            "fteID",
            "classroomLocationFTE",
            "buildingName",
            "supportTypeGroup",
            "supportType",
            "supportLevel",
            "caseLoad",
            "identifyClassroom",
            "classroomLocationSupport",
            "ageRangeFrom",
            "ageRangeEnd",
            "ageRangeJustification"
        ];

        let specialEducationEducationProgramCaseloadFTEFormSaveButton = document.getElementById("specialEducationEducationProgramCaseloadFTESave");
        if (specialEducationEducationProgramCaseloadFTEFormSaveButton !== null) {
            specialEducationEducationProgramCaseloadFTEFormSaveButton.addEventListener("click", (e: Event) => this.save("save"));
        }

        this._core.leftnav(this);
        this._core.tabLinkSave(this);
        this._core.initializeRequiredFields(this.validationClasses);

        that.initializeFTEHashes();
        that.handleClearDisabled();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        //Event Listener for creating new FTE
        let createNewFTE = document.getElementById("createNewFTE");
        if (createNewFTE != null) {
            createNewFTE.addEventListener("click", () => {
                Core.showLoader();
                that.createNewFTE()
                .then((response) => {
                    Core.hideLoader();
                    that._core.initializeRequiredFields(that.validationClasses);
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                })
                .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }

        //Event listener for deleting a support
        let deleteSupportConfirmButton = document.getElementById("deleteSupportConfirm") as HTMLButtonElement;
        if (deleteSupportConfirmButton != null) {

            deleteSupportConfirmButton.addEventListener("click", () => {
                that.deleteSupportConfirmModal.hide();
                if ("supportPk" in deleteSupportConfirmButton.dataset && "ftePk" in deleteSupportConfirmButton.dataset) {
                    Core.showLoader();
                    that.deleteSupport(parseInt(deleteSupportConfirmButton.dataset.supportPk))
                    .then((response) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                        that.handleFTESaveDisabled(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                        that.checkSupportCount(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                        that.refreshFTEPercentage(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this support. Please try again later.", 'error', 3000, null);
                } 
            });
        }

        //Event listener for confirming deletion of FTE
        let deleteFTEConfirmButton = document.getElementById("deleteFTEConfirm") as HTMLButtonElement;
        if (deleteFTEConfirmButton != null) {

            deleteFTEConfirmButton.addEventListener("click", () => {

                if ("ftePk" in deleteFTEConfirmButton.dataset) {
                    that.deleteFTE(parseInt(deleteFTEConfirmButton.dataset.ftePk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE. Please try again later.", 'error', 3000, null);
                }
            });
        }

        //Event listener for cancelling the delete of a support
        let deleteSupportCancelButton = document.getElementById("deleteSupportCancel") as HTMLButtonElement;
        if (deleteSupportCancelButton != null) {
            deleteSupportCancelButton.addEventListener("click", () => {
                that.deleteSupportConfirmModal.hide();
            });
        }

        //Event listener for cancelling the delete of an FTE
        let deleteFTECancelButton = document.getElementById("deleteFTECancel") as HTMLButtonElement;
        if (deleteFTECancelButton != null) {
            deleteFTECancelButton.addEventListener("click", () => {
                that.deleteFTEConfirmModal.hide();
            });
        }

        //Event listener for clicking the search button
        let searchButton = <HTMLButtonElement>document.getElementById("searchButton");
        if (searchButton !== null) {
            //1. Save page (will only save FTE that have changed)
            //2. Run Search
            searchButton.addEventListener("click", () => {

                Core.showLoader();
                that.promiseSave()
                .then(() => {
                    return that.search();
                })
                .then(() => {
                    that.initializeFTEHashes();
                    that._core.initializeRequiredFields(that.validationClasses);
                    //reinitialize accordions
                    new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                    Core.hideLoader();
                })
                .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });

            });
        }

        //Event listener for searching when hitting enter
        let fteIDSearch = <HTMLInputElement>document.getElementById("searchFTEId");
        if (fteIDSearch !== null) {
            fteIDSearch.addEventListener("keypress", (e: KeyboardEvent) => {
                if (e.keyCode === 13) {
                    Core.showLoader();
                    that.promiseSave()
                    .then(() => {
                        return that.search();
                    })
                    .then(() => {
                        that._core.initializeRequiredFields(that.validationClasses);
                        that.initializeFTEHashes();

                        //reinitialize accordions
                        new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }
            });

            fteIDSearch.addEventListener("input", () => {
               that.handleClearDisabled(); 
            });
        }

        //Clears the search fields
        let clearButton = document.getElementById("searchClear");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                that.clearSearch();
            });
        }

        //When changing building filter, determine whether clear button should be enabled
        let buildingSearch = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportTypeSearch = document.getElementById("searchSupportType") as HTMLSelectElement;
        if (buildingSearch != null) {
            buildingSearch.addEventListener("change", () => {
                that.handleClearDisabled();
            });
        }

        //When changing support type filter, determine whether clear button should be enabled
        if (supportTypeSearch != null) {
            supportTypeSearch.addEventListener("change", () => {
                that.handleClearDisabled();
            });
        }

        //Event listener for expanding all accordions
        let expandAll = document.getElementById("expandAll") as HTMLButtonElement;
        if (expandAll != null) {
            expandAll.addEventListener("click", () => {
                that.expandAllFTE();
            });
        }

        //Event listener for collapsing all accordions
        let collapseAll = document.getElementById("collapseAll") as HTMLButtonElement;
        if (expandAll != null) {
            collapseAll.addEventListener("click", () => {
                that.collapseAllFTE();
            });
        }

        //When user has searched, focus on search result message
        let searchResultsMessage = document.getElementById("searchResultsMessage");
        if (searchResultsMessage != null) {
            document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
            searchResultsMessage.focus();
        }

        //Event listener for exporting all data to excel
        let exportToExcelButton = document.getElementById("exportExcelFTE");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                that.exportToExcel()
                .then((response) => {
                    Core.hideLoader();
                })
                .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }

        //Event listener for exporting data to excel based on search criteria
        let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE");
        if (exportToExcelFilteredButton != null) {
            exportToExcelFilteredButton.addEventListener("click", () => {
                Core.showLoader();
                that.exportToExcel(true)
                .then((response) => {
                    Core.hideLoader();
                })
                .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }

        document.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;
            
            //Adding a support
            if (target.classList.contains("addSupportButton") && "ftePk" in target.dataset) {
                Core.showLoader();
                that.createNewSupport(parseInt(target.dataset.ftePk))
                .then((response) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    that.checkSupportCount(parseInt(target.dataset.ftePk));
                    that.adjustSupportClassroomLocation(parseInt(target.dataset.ftePk));
                })
                .then(() => {
                    that._core.initializeRequiredFields(that.validationClasses);
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                })
                .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            }
            
            //Deleting a support
            else if (target.classList.contains("deleteSupport") && "supportPk" in target.dataset && "ftePk" in target.dataset) {
                that.deleteSupportConfirmModal.show();
                deleteSupportConfirmButton.dataset.supportPk = target.dataset.supportPk;
                deleteSupportConfirmButton.dataset.ftePk = target.dataset.ftePk;
                that.deleteSupportConfirmModal.callingId = target.id;
            }
                
            //Deleting an FTE
            else if (target.classList.contains("deleteFTE") && "ftePk" in target.dataset) {
                that.deleteFTEConfirmModal.show();
                deleteFTEConfirmButton.dataset.ftePk = target.dataset.ftePk;
                that.deleteFTEConfirmModal.callingId = target.id;
            } 
            
            //Saving an FTE
            else if (target.classList.contains("saveFTEButton") && "ftePk" in target.dataset) {

                Core.showLoader();
                that.promiseSave(parseInt(target.dataset.ftePk))
                .then((response) => {

                    //Recalculate hash and handle disabling the save button for the FTE
                    that.fteHashes[parseInt(target.dataset.ftePk)] = that.calculateFTEHash(parseInt(target.dataset.ftePk));
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));

                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                })
                .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });

            }
            //Marking FTE complete if userAdmin role
            else if (target.classList.contains("markCompleteButton") && "ftePk" in target.dataset) {
                //Core.showLoader();
                that.markFTEComplete(parseInt(target.dataset.ftePk))
                    .then((response) => {
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    })
                    .catch((error) => {
                        //Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            }
        });

        document.addEventListener("change", (e) => {
            let target = e.target as HTMLElement;

            //Handle Support Type Group changing
            if (target.classList.contains("supportTypeGroup") && "supportPk" in target.dataset && "ftePk" in target.dataset) {

                //1. Refresh the support type list
                //2. Refresh the support level list (1 needs to happen first)
                //3. Refresh FTE percent (1 and 2 need to happen first)
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);

                that.refreshSupportTypeSubList(parseInt(target.dataset.supportPk))
                .then(() => {
                    return that.refreshSupportLevels(parseInt(target.dataset.supportPk));
                })
                .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    return that.refreshFTEPercentage(parseInt(target.dataset.ftePk));
                })
                .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });

            } 
            
            //Handle Support Type changing
            else if (target.classList.contains("supportType") && "supportPk" in target.dataset) {

                //1. Refresh the support level list
                //2. Refresh FTE percent (1 needs to happen first)
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.refreshSupportLevels(parseInt(target.dataset.supportPk))
                .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    return that.refreshFTEPercentage(parseInt(target.dataset.ftePk));
                })
                .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });

            } 
            
            //Handle Support Level changing
            else if (target.classList.contains("supportLevel") && "supportPk" in target.dataset) {

                //1. Refresh FTE percent
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });

            } 
            
            //Handle Classroom Location for FTE changing
            else if (target.classList.contains("classroomLocationFTE") && "ftePk" in target.dataset) {
                that.adjustSupportClassroomLocation(parseInt(target.dataset.ftePk))
                .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            } 

            //Handle Full-time/Part-time changing
            else if (target.classList.contains("fullTime") && "ftePk" in target.dataset) {

                //1. Refresh FTE percent
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });

            } 

            //Handle Classroom Location for Support changing
            else if (target.classList.contains("classroomLocationSupport") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            } 
            
            //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
            else if ("hashable" in target.dataset && "ftePk" in target.dataset && !(target instanceof HTMLInputElement) && !(target instanceof HTMLTextAreaElement)) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
            } 
        });

        document.addEventListener("input", (e) => {
            //On a slight delay so that ajax call isn't made until finished typing
            let target = e.target as HTMLElement;

            //Handle Caseload changing
            if (target.classList.contains("caseLoad") && "supportPk" in target.dataset) {

                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk))

                if (typeof (that.typingTimer) !== 'undefined') {
                    clearTimeout(that.typingTimer);
                }
                that.typingTimer = setTimeout(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);

                    that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                    .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                    .catch((error) => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }, 500);
            } 
            
            //Handle age Range From for Support changing
            else if (target.classList.contains("ageRangeFrom") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            } 

            //Handle age Range End for Support changing
            else if (target.classList.contains("ageRangeEnd") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            } 

            //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
            else if ("hashable" in target.dataset && "ftePk" in target.dataset && (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement)) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
            }
        });

        //This is called when clicking the back/forward buttons in the browser. This is using the History API
        window.addEventListener('popstate', (e) => {
            let query = e.state;

            let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
            let building = document.getElementById("searchBuilding") as HTMLSelectElement;
            let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

            if (fteID != null) {
                if (query != null && query.FTEIdentifierName != null) {
                    fteID.value = query.FTEIdentifierName;
                } else {
                    fteID.value = "";
                }
            }

            if (building != null) {
                if (query != null && query.FRCPPInstitutionFK != null) {
                    building.value = query.FRCPPInstitutionFK.toString();
                } else {
                    building.selectedIndex = 0;
                }
            }

            if (supportType != null) {
                if (query != null && query.SupportTypeCodePK != null) {
                    supportType.value = query.SupportTypeCodePK.toString();
                } else {
                    supportType.selectedIndex = 0;
                }
            }
            that.handleClearDisabled();
            Core.showLoader();
            that.promiseSave()
            .then(() => {
                return that.search(true);
            })
            .then(() => {
                that.initializeFTEHashes();

                //reinitialize accordions
                new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                Core.hideLoader();
            })
            .catch((error) => {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
            });
        });

        SpecialEducationEducationProgramCaseloadFTE.fullPageValidation(true);
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    //Save method that returns a promise. If ftePK is passed, only the specified FTE will be saved. Otherwise, all FTE on page will be saved (if there are changes)
    promiseSave(ftePK: number = null, forceSave: boolean = false) {
        let that = this;
        let core = this._core;

        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData: IEducationProgramSave[] = [];

            let fteToSave: NodeListOf<HTMLElement>;
            if (ftePK !== null) {
                fteToSave = document.querySelectorAll(`.fteContainer[data-fte-pk='${ftePK}']`) as NodeListOf<HTMLElement>;
            } else {
                //Get all the FTE on the page
                fteToSave = document.querySelectorAll(".fteContainer") as NodeListOf<HTMLElement>;
            }
            for (let fte of fteToSave) {
                let fteSupportsData: IEducationProgramFTESupportSave[] = [];

                if ("ftePk" in fte.dataset && that.fteHasChanged(parseInt(fte.dataset.ftePk))) {
                    shouldSave = true;
                    let ftePK = fte.dataset.ftePk;

                    //Get FTEId
                    let educationProgramFTEId = null;
                    let fteID = fte.querySelector(`.fteID[data-fte-pk='${ftePK}']`) as HTMLInputElement;
                    if (fteID != null && fteID.value != "") {
                        educationProgramFTEId = fteID.value;
                    }

                    //Get Classroom Location Lookup
                    let classroomLocationLookupCodeFK = null;
                    let classroomLocationFTE = fte.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
                    if (classroomLocationFTE != null && classroomLocationFTE.value != "") {
                        classroomLocationLookupCodeFK = parseInt(classroomLocationFTE.value);
                    }

                    //Get Full Time Lookup
                    let fullTimeLookupCodeFK = null;
                    let fullTimeFTE = fte.querySelector(`.fullTime[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
                    if (fullTimeFTE != null && fullTimeFTE.value != "") {
                        fullTimeLookupCodeFK = parseInt(fullTimeFTE.value);
                    }

                    //Get supports
                    let supports = [];
                    let supportsForFTE = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
                    for (let support of supportsForFTE) {
                        let supportElement = <HTMLElement>support;

                        if ("supportPk" in supportElement.dataset) {

                            //buildingName
                            let frcppInstitutionFK = null;
                            let buildingName = supportElement.querySelector(`.buildingName[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (buildingName != null && buildingName.value != "") {
                                frcppInstitutionFK = parseInt(buildingName.value);
                            }
                            //supportTypeGroup
                            let supportTypeGroupCodeFK = null;
                            let supportTypeGroup = supportElement.querySelector(`.supportTypeGroup[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (supportTypeGroup != null && supportTypeGroup.value != "") {
                                supportTypeGroupCodeFK = parseInt(supportTypeGroup.value);
                            }
                            //supportType
                            let supportTypeCodeFK = null;
                            let supportType = supportElement.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (supportType != null && supportType.value != "") {
                                supportTypeCodeFK = parseInt(supportType.value);
                            }
                            //supportLevel
                            let supportLevelCodeFK = null;
                            let supportLevel = supportElement.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (supportLevel != null && supportLevel.value != "") {
                                supportLevelCodeFK = parseInt(supportLevel.value);
                            }
                            //caseLoad
                            let caseLoadCount = null;
                            let caseLoad = supportElement.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (caseLoad != null && caseLoad.value != "") {
                                caseLoadCount = parseInt(caseLoad.value);
                            }
                            //identifyclassroom
                            let classroomLookupCodeFK = null;
                            let identifyclassroom = supportElement.querySelector(`.identifyClassroom[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (identifyclassroom != null && identifyclassroom.value != "") {
                                classroomLookupCodeFK = parseInt(identifyclassroom.value);
                            }
                            //classroomLocationSupport
                            let supportClassroomLocationLookupCodeFK = null;
                            let classroomLocationSupport = supportElement.querySelector(`.classroomLocationSupport[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (classroomLocationSupport != null && classroomLocationSupport.value != "") {
                                supportClassroomLocationLookupCodeFK = parseInt(classroomLocationSupport.value);
                            }
                            //ageRangeFrom
                            let ageRangeFromNbr = null;
                            let ageRangeFrom = supportElement.querySelector(`.ageRangeFrom[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (ageRangeFrom != null && ageRangeFrom.value != "") {
                                ageRangeFromNbr = parseInt(ageRangeFrom.value);
                            }
                            //ageRangeEnd
                            let ageRangeToNbr = null;
                            let ageRangeEnd = supportElement.querySelector(`.ageRangeEnd[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (ageRangeEnd != null && ageRangeEnd.value != "") {
                                ageRangeToNbr = parseInt(ageRangeEnd.value);
                            }
                            //ageRangeJustification
                            let ageRangeJustificationText = "";
                            let ageRangeJustification = supportElement.querySelector(`.ageRangeJustification[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (ageRangeJustification != null && ageRangeJustification.value != "") {
                                ageRangeJustificationText = ageRangeJustification.value;
                            }

                            let fteSupportSaveData: IEducationProgramFTESupportSave = {
                                AgeRangeFromNbr: ageRangeFromNbr,
                                AgeRangeJustification: ageRangeJustificationText,
                                AgeRangeToNbr: ageRangeToNbr,
                                CaseLoadCount: caseLoadCount,
                                ClassroomLocationLookupCodeFK: supportClassroomLocationLookupCodeFK,
                                ClassroomLookupCodeFK: classroomLookupCodeFK,
                                EducationProgramFTEFK: parseInt(ftePK),
                                EducationProgramFTESupportPK: parseInt(supportElement.dataset.supportPk),
                                FRCPPInstitutionFK: frcppInstitutionFK,
                                SupportLevelCodeFK: supportLevelCodeFK,
                                SupportTypeCodeFK: supportTypeCodeFK,
                                UISelectedSupportTypeGroupCodeFK: supportTypeGroupCodeFK
                            }

                            supports.push(fteSupportSaveData);
                        }
                    }

                    let fteSaveData: IEducationProgramFTESave = {
                        ClassroomLocationLookupCodeFK: classroomLocationLookupCodeFK,
                        FullTimeLookupCodeFK: fullTimeLookupCodeFK,
                        FTEIdentifierName: educationProgramFTEId,
                        EducationProgramFTEPK: parseInt(ftePK),
                        PlanFK: that.planFK
                    }

                    let thisFTESaveData: IEducationProgramSave = {
                        FTE: fteSaveData,
                        Supports: supports
                    };

                    saveData.push(thisFTESaveData);
                }
            }

            if(shouldSave || forceSave) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Base/SaveEducationProgramFTE', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = async function () {
                    if (xhr.status === 200) {
                        if (ftePK != null) {
                            const json = JSON.parse(xhr.responseText);
                            const validData = json.validData;
                            SpecialEducationEducationProgramCaseloadFTE.fteValidation(ftePK);
                            const elementButton = <HTMLButtonElement>document.querySelector(`.lazyAccordionTrigger[data-educationprogramftefk='${ftePK}']`);
                            elementButton.dataset.validdata = validData;

                            await SpecialEducationEducationProgramCaseloadFTE.getValidProgramCount();
                            SpecialEducationEducationProgramCaseloadFTE.fullPageValidation();
                            SpecialEducationEducationProgramCaseloadFTE.planPageCompleteCheck();

                            resolve("Successfully saved FTE");
                        } else {
                            await SpecialEducationEducationProgramCaseloadFTE.getValidProgramCount();
                            SpecialEducationEducationProgramCaseloadFTE.fullPageValidation();
                            SpecialEducationEducationProgramCaseloadFTE.planPageCompleteCheck();
                            resolve("Successfully saved");
                        }
                    } else {
                        reject("There was an unexpected error saving");
                    }
                };
                xhr.send(JSON.stringify({
                    "Data": saveData,
                    "PlanFK": that.planFK,
                    "AllowExceedsFTE": that.allowExceedsFTE
                }));
            } else {
                resolve("Nothing To Save");
            }
        });
    }

    //Top level save method used by back/continue buttons, clicking on link, etc. Calls the promiseSave method and controls redirect.
    save(referrer, ftePK: number = null) {
        let that = this;
        let core = that._core;

        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let forceSave = false;
        if (referrer === "save") {
            forceSave = true;
        }

        that.promiseSave(null, forceSave).then((response) => {
            Core.hideLoader();

            if (referrer === "save") {
                //Reset query so that all results show
                window.location.href = window.location.href.split('?')[0]+'?fromSave=true';
            } else if (refreshPage && refreshPage !== "") {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
    }
    
    //Creates a new FTE record in database and prepends partial view to DOM
    createNewFTE() {
        let that = this;

        return new Promise((resolve, reject) => {
            let newFTEID = document.getElementById("createNewFTEId") as HTMLInputElement;
            let newClassroomLocation = document.getElementById("createNewClassroomLocation") as HTMLSelectElement;
            let newFullTime = document.getElementById("createNewFullTime") as HTMLSelectElement;

            let data: IEducationProgramFTESave = {
                EducationProgramFTEPK: 0,
                ClassroomLocationLookupCodeFK: parseInt(newClassroomLocation.value),
                FullTimeLookupCodeFK: parseInt(newFullTime.value),
                FTEIdentifierName: newFTEID.value,
                PlanFK: that.planFK
            };

            let saveData = {
                FTE: data,
                AllowExceedsFTE: that.allowExceedsFTE
            }

            if (newFTEID != null && newFTEID.value != "" && newClassroomLocation != null && newClassroomLocation.selectedIndex != 0 && newFullTime != null && newFullTime.selectedIndex != 0) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/CreateNewFTE', true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {

                        let fteList = document.getElementById("fteContainerInner");
                        if (fteList != null) {
                            $(fteList).prepend(xhr.responseText);
                            newFTEID.value = "";
                            newClassroomLocation.selectedIndex = 0;
                            newFullTime.selectedIndex = 0;

                            let newFTEAccordion = fteList.querySelector(".lazyAccordion-trigger") as HTMLButtonElement;
                            if (newFTEAccordion != null) {
                                new SpecialEducationProgramCaseloadFTELazyAccordion(newFTEAccordion.id);
                            } else {
                                new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                            }

                            resolve("Successfully created new FTE");
                        }
                    } else if (xhr.status === 409) {
                        reject("This FTE ID already exists, please use a unique name.");
                    } else {
                        reject("There was an unexpected error creating new FTE");
                    }
                };
                xhr.send(JSON.stringify(saveData));
            } else {
                reject("All fields must have a value to create a new FTE");
            }
        });  
    }

    //Creates a new support record in database and appends partial view to DOM
    createNewSupport(ftePK: number) {
        let that = this;

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CreateNewSupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {

                    let supportList = document.querySelector(`.supportsContainer[data-fte-pk='${ftePK}']`);
                    if (supportList != null) {
                        $(supportList).append(xhr.responseText);
                        resolve("Successfully added support");
                    } else {
                        reject("There was an unexpected error adding the support");
                    }
                } else {
                    reject("There was an unexpected error creating new support");
                }
            };
            xhr.send("ftePK=" + ftePK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
        });
    }

    //Soft deletes support from database
    deleteSupport(supportPK: number) {
        let that = this;

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteSupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {

                    let supportContainer = document.querySelector(`.supportRow[data-support-pk='${supportPK}']`);
                    if (supportContainer != null) {

                        let deleteSupportButton = document.querySelector(`.deleteSupport[data-support-pk='${supportPK}']`) as HTMLElement;
                        let nextFocusable = Core.getNextFocusableElement(deleteSupportButton);

                        supportContainer.parentNode.removeChild(supportContainer);
                        nextFocusable.focus();
                        resolve("Successfully deleted support");
                    } else {
                        reject("There was an unexpected error removing this support from the page");
                    }
                } else {
                    reject("There was an unexpected error deleting this support");
                }
            };
            xhr.send("supportPK=" + supportPK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
        });
    }

    //Determines whether to show the message that the FTE has no supports in the UI
    checkSupportCount(ftePK: number) {
        let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
        let noSupportsMessage = document.querySelector(`.noSupportsMessage[data-fte-pk='${ftePK}']`) as HTMLElement;
        if(noSupportsMessage != null) {
            if (supports.length > 0) {
                noSupportsMessage.classList.add("hide");
            } else {
                noSupportsMessage.classList.remove("hide");
            }
        }
    }

    //Soft deletes FTE record from database
    deleteFTE(ftePK: number) {
        let that = this;

        that.deleteFTEConfirmModal.hide();
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFTE', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {

                let allFTEContainer = document.getElementById("fteContainerInner");
                let fteContainer = document.querySelector(`.fteContainer[data-fte-pk='${ftePK}']`);
                let accordion = Core.findClosest(fteContainer, ".lazyAccordion") as HTMLElement;

                //Get next focusable accordion
                let accordionTriggers = allFTEContainer.querySelectorAll(".lazyAccordion-trigger");
                if (accordionTriggers.length > 1) {
                    let arr = Array.prototype.slice.call(accordionTriggers);
                    let thisAccordionTrigger = accordion.querySelector(".lazyAccordion-trigger");
                    if (thisAccordionTrigger != null) {
                        let index = arr.indexOf(thisAccordionTrigger);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                } else {
                    //Focus on create fte if no fte left
                    let create = document.getElementById("createNewFTEId");
                    if (create != null) {
                        create.focus();
                    }
                }

                if (accordion != null) {
                    accordion.parentNode.removeChild(accordion);
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted FTE", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error removing this FTE from the page", 'error', 3000, null);
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE", 'error', 3000, null);
            }
        };
        xhr.send("ftePK="+ftePK+"&planFK="+that.planFK+"&allowExceedsFTE="+that.allowExceedsFTE);
    }

    //Refreshes the possible Support Types that can be selected in the UI based on the Support Type Group that was selected
    refreshSupportTypeSubList(supportPK: number) {
        let that = this;

        let supportTypeGroup = document.querySelector(`.supportTypeGroup[data-support-pk='${supportPK}']`) as HTMLSelectElement;

        return new Promise<void>((resolve, reject) => {
            if (supportTypeGroup != null) {
                if (supportTypeGroup.value != "") {
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/SpecialEducation/RefreshSupportTypeSubList', true);
                    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            let supportTypeListContainer = document.querySelector(`.supportTypesContainer[data-support-pk='${supportPK}']`);
                            if (supportTypeListContainer != null) {
                                supportTypeListContainer.innerHTML = xhr.responseText;

                                let supportTypeDropdown = supportTypeListContainer.querySelector(".supportType[data-forcerequired='true']") as HTMLElement;
                                if (supportTypeDropdown != null) {
                                    that._core.forceElementRequired(supportTypeDropdown);
                                }
                            }

                            resolve();
                        } else {
                            reject("Error refreshing support types");
                        }
                    };
                    xhr.send(`supportPK=${supportPK}&ftePK=${supportTypeGroup.dataset.ftePk}&supportTypeGroupCodeFK=${supportTypeGroup.value}&templateFK=${that.templateFK}`);
                } else {
                    let container = document.querySelector(`.supportTypesContainer[data-support-pk='${supportTypeGroup.dataset.supportPk}']`);
                    if (container != null) {
                        container.innerHTML = "";
                    }
                    resolve();
                }
            } else {
                reject("Support type group not found");
            }
        });
    }

    //Refreshes the possible Support Levels that can be selected in the UI based on the Support Type that was selected
    refreshSupportLevels(supportPK: number) {
        let that = this;

        let currentLevelPK = null;
        let currentTypePK = null;

        let currentLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`) as HTMLSelectElement;
        if (currentLevel != null && currentLevel.value != "") {
            currentLevelPK = currentLevel.value;
        }

        let currentType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`) as HTMLSelectElement;
        if (currentType != null && currentType.value != "") {
            currentTypePK = currentType.value;
        }

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/RefreshSupportLevels', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {

                if (xhr.status === 200) {
                    let supportLevelListContainer = document.querySelector(`.supportLevelsContainer[data-support-pk='${supportPK}']`);
                    if (supportLevelListContainer != null) {
                        supportLevelListContainer.innerHTML = xhr.responseText;

                        let supportLevelDropdown = supportLevelListContainer.querySelector(".supportLevel[data-forcerequired='true']") as HTMLElement;
                        if (supportLevelDropdown != null) {
                            that._core.forceElementRequired(supportLevelDropdown);
                        }
                    }
                    resolve()
                } else {
                    reject("Error refreshing support levels");
                }
            };
            xhr.send(`supportPK=${supportPK}&ftePK=${currentLevel.dataset.ftePk}&currentLevelPK=${currentLevelPK}&currentTypePK=${currentTypePK}&templateFK=${that.templateFK}`);
        });
    }

    //Shows the max possible caseload count in the UI given the currently selected Support Level and Type for the specified Support
    refreshMaxPossibleCaseLoad(supportPK: number) {
        let that = this;

        let supportType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`) as HTMLSelectElement;
        let supportLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`) as HTMLSelectElement;

        return new Promise<void>((resolve, reject) => {
            if (supportType != null && supportType.value != "" && supportLevel != null && supportLevel.value != "") {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/RefreshMaxPossibleCaseLoad', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    let jsonResponse = JSON.parse(xhr.response);
                    if (xhr.status === 200 && jsonResponse.success) {
                        let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`) as HTMLElement;
                        if (maxCaseLoadContainer != null) {
                            maxCaseLoadContainer.classList.remove("hide");
                        }

                        let maxCaseLoadElement = document.querySelector(`.maxCaseLoad[data-support-pk='${supportPK}']`) as HTMLElement;
                        if (maxCaseLoadElement != null) {
                            maxCaseLoadElement.innerHTML = jsonResponse.maxCaseLoadCount;
                        }

                        resolve();
                    } else {
                        reject("Error refreshing max caseload");
                    }
                };
                xhr.send(`supportTypePK=${supportType.value}&supportLevelPK=${supportLevel.value}`);
            } else {
                let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`) as HTMLElement;
                if (maxCaseLoadContainer != null) {
                    maxCaseLoadContainer.classList.add("hide");
                }
            }
        });
    }

    //Refreshes the FTE for each support in the FTE and the FTE as a whole. If the FTE is over the limit, the error will display.
    refreshFTEPercentage(ftePK: number) {
        let that = this;

        return new Promise<void>((resolve, reject) => {

            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];

            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }

            let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = <HTMLElement>support
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;

                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;

                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }

                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }

                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }

                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CalculateFTEDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;

                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-fte-pk='${ftePK}']`) as HTMLElement;
                    if (overOneMessage != null) {

                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }

                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        } else {
                            overOneMessage.classList.add("hide");
                        }
                    }

                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`) as HTMLInputElement;
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        } else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }

                    resolve();
                } else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }

    //The classroom location for a support is dependent upon the selected classroom location for the FTE. This function handles this in the UI
    adjustSupportClassroomLocation(ftePK: number) {
        return new Promise<void>((resolve, reject) => {
            let classroomLocationLookupCode: string = "";
            let fteClassroomLocation = document.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
            if (fteClassroomLocation != null) {
                classroomLocationLookupCode = fteClassroomLocation.options[fteClassroomLocation.selectedIndex].dataset.lookupCode;
            }
            //Get the supports for this FTE
            let classroomLocationsSupport = document.querySelectorAll(`.classroomLocationSupport[data-fte-pk='${ftePK}']`);
            for (let classroomLocationDropdown of classroomLocationsSupport) {
                let classroomLocationDropdownElement = <HTMLSelectElement>classroomLocationDropdown;

                if (classroomLocationLookupCode === "specialeducationlocationelementary" || classroomLocationLookupCode === "specialeducationlocationsecondary") {
                    for (var i = 0; i < classroomLocationDropdownElement.options.length; ++i) {
                        if (classroomLocationDropdownElement.options[i].dataset.lookupCode === classroomLocationLookupCode) {
                            classroomLocationDropdownElement.selectedIndex = i;
                            classroomLocationDropdownElement.disabled = true;
                            break;
                        }
                    }
                } else {
                    classroomLocationDropdownElement.disabled = false;
                }
            }
            resolve();
        });
    }

    //Based on the selection of "Classroom Location" and "Age Range", make the "Age Range Justification" optional or required
    checkAgeRangeJustification(supportPK: number) {
        let that = this;

        return new Promise<void>((resolve, reject) => {

            let makeRequired = false;

            let classroomLocation = document.querySelector(`.classroomLocationSupport[data-support-pk='${supportPK}']`) as HTMLSelectElement;
            let ageRangeFrom = document.querySelector(`.ageRangeFrom[data-support-pk='${supportPK}']`) as HTMLInputElement;
            let ageRangeTo = document.querySelector(`.ageRangeEnd[data-support-pk='${supportPK}']`) as HTMLInputElement;
            if (classroomLocation != null && ageRangeFrom != null && ageRangeTo != null) {
                if (ageRangeFrom.value != "" && ageRangeTo.value != "" && parseInt(ageRangeFrom.value) && parseInt(ageRangeTo.value)) {

                    let range = parseInt(ageRangeTo.value) - parseInt(ageRangeFrom.value);

                    let classroomLocationLookupCode = classroomLocation.options[classroomLocation.selectedIndex].dataset.lookupCode;

                    switch (classroomLocationLookupCode) {
                        case 'specialeducationlocationelementary':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationsecondary':
                            if (range > 4) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationmultiple':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                    }
                }
            }

            let justification = document.querySelector(`.ageRangeJustification[data-support-pk='${supportPK}']`) as HTMLInputElement;
            if(justification != null)
            {
                if (makeRequired) {
                    that._core.forceElementRequired(justification);
                } else {
                    that._core.forceElementOptional(justification);
                }
            }

            resolve();
        });
    }

    //Shows a loader next to the FTE percentage while application figures out what the new percentage is
    toggleFTESupportLoader(supportPK: number, show: boolean) {
        let loaderContainer = document.querySelector(`.loaderContainer[data-support-pk='${supportPK}']`) as HTMLElement;
        if (loaderContainer != null) {
            if (show) {
                loaderContainer.classList.remove("hide");
            } else {
                loaderContainer.classList.add("hide");
            }
        }
    }

    //Searches FTE based on criteria and refreshes DOM with partial view. Also pushes history state so that back and continue buttons in browser work.
    search(fromHistory: boolean = false) {
        let that = this;

        let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
        let building = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

        let fteIDValue = fteID != null ? fteID.value : "";
        let buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
        let supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;

        let query = {
            PlanFK: that.planFK,
            FTEIdentifierName: fteIDValue,
            FRCPPInstitutionFK: buildingValue,
            SupportTypeCodePK: supportTypeValue
        }

        let queryString = `?fteId=${query.FTEIdentifierName}&frcppInstitutionFK=${query.FRCPPInstitutionFK}&supportTypeCodeFK=${query.SupportTypeCodePK}`; 

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/FTESearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let container = document.getElementById("fteContainer");
                    if (container != null) {
                        container.innerHTML = xhr.responseText;

                        //Add to history so back button works
                        if (!fromHistory) {
                            history.pushState(query, '', queryString);
                        }

                        let searchResultsMessage = document.getElementById("searchResultsMessage");
                        if (searchResultsMessage != null) {
                            document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
                            searchResultsMessage.focus();
                        }

                        let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE") as HTMLButtonElement;
                        exportToExcelFilteredButton.classList.remove("hide");

                        SpecialEducationFTETransferOfEntity.fullPageValidation();

                        resolve();
                    }
                    else {
                        reject("There was an error loading FTE");
                    }
                } else {
                    reject("There was an unexpected error during search");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }

    //Clears search fields, saves page (in case there were changes), and refreshes DOM
    clearSearch() {
        let that = this;

        let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
        let building = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

        if (fteID != null) {
            fteID.value = "";
        }

        if (building != null) {
            building.selectedIndex = 0;
        }

        if (supportType != null) {
            supportType.selectedIndex = 0;
        }

        Core.showLoader();
        that.promiseSave()
        .then(() => {
            return that.search(true);
        })
        .then(() => {
            that.initializeFTEHashes();
            that.handleClearDisabled();
            that._core.initializeRequiredFields(that.validationClasses);
            //reinitialize accordions
            new SpecialEducationProgramCaseloadFTELazyAccordion(null);
            Core.hideLoader();

            let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE") as HTMLButtonElement;
            exportToExcelFilteredButton.classList.add("hide");
        })
        .catch((error) => {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });

        history.pushState(null, '',  window.location.href.split('?')[0]);
        document.title = "Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
    }

    //Calculates a hash for an FTE. This is used to determine whether the FTe has changed for saving purposes.
    calculateFTEHash(ftePK: number) {
        let that = this;
        let hash = "0";
        let value;
        let newHash;

        let allElements = document.querySelectorAll(`[data-hashable][data-fte-pk='${ftePK}']`);

        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                let inputElement = <HTMLInputElement>element;

                if (inputElement.value === "") {
                    value = 0
                    hash = hash + value;
                }
                else if (inputElement.value !== "") {
                    value = inputElement.value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            } else if (element instanceof HTMLSelectElement) {
                let selectElement = <HTMLSelectElement>element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            } else if (element instanceof HTMLTextAreaElement) {
                let textAreaElement = <HTMLTextAreaElement>element;

                if (textAreaElement.value === "") {
                    value = 0
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            }
        }

        return hash;
    }

    //Determines whether an FTE has changed by comparing the current hash value to a prior hash value
    fteHasChanged(ftePK: number) {
        let that = this;
        let changed = false;
        if (that.fteHashes[ftePK] !== undefined) {
            let newHash = that.calculateFTEHash(ftePK);
            if (newHash !== that.fteHashes[ftePK]) {
                changed = true;
            }
        } else {
            //Be on the safe side and say it has changed
            changed = true;
        }

        return changed;
    }

    //Calculates a hash value for each FTE on the page and is stored in an object as a property of this class.
    initializeFTEHashes() {
        let that = this;

        that.fteHashes = {};

        let fteContainers = document.getElementsByClassName("fteContainer") as HTMLCollectionOf<HTMLElement>;
        for (let fte of fteContainers) {
            let ftePK = parseInt(fte.dataset.ftePk);
            let hash = that.calculateFTEHash(ftePK);
            that.fteHashes[ftePK] = hash;
        }
    }

    //The save button only becomes available for an FTE if there is a change within the FTE
    handleFTESaveDisabled(ftePK: number) {
        let saveButton = document.querySelector(`.saveFTEButton[data-fte-pk='${ftePK}']`) as HTMLButtonElement;
        if (saveButton != null) {
            if (this.fteHasChanged(ftePK) && "canEdit" in saveButton.dataset) {
                saveButton.disabled = false;
            } else {
                saveButton.disabled = true;
            }
        }
    }

    //Determines whether the clear button should become enabled
    handleClearDisabled() {
        let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
        let building = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;
        let clearButton = document.getElementById("searchClear") as HTMLButtonElement;

        if (fteID != null && building != null && supportType != null && clearButton != null) {
            if (fteID.value !== "" || building.selectedIndex > 0 || supportType.selectedIndex > 0) {
                clearButton.disabled = false;
            } else {
                clearButton.disabled = true;
            }
        }
    }

    //Custom client-side validation for the page
    //Collapses all FTE accordions on the page
    collapseAllFTE() {
        let fteContainer = document.getElementById("fteContainerInner");
        if (fteContainer != null) {
            let openToggles = fteContainer.querySelectorAll(".Accordion-trigger.open");
            for (let toggle of openToggles) {
                let toggleElement = <HTMLElement>toggle;
                toggleElement.click();
            }
        }
    }

    //Expands all FTE accordions on the page
    expandAllFTE() {
        let fteContainer = document.getElementById("fteContainerInner");
        if (fteContainer != null) {
            let closedToggles = fteContainer.querySelectorAll(".Accordion-trigger:not(.open)");
            for (let toggle of closedToggles) {
                let toggleElement = <HTMLElement>toggle;
                toggleElement.click();
            }
        }
    }

    //Exports FTE and support data to excel. If they click the Filtered button, the data in the Excel file will be filtered based on search criteria
    exportToExcel(filtered: boolean = false) {
        let that = this;

        return new Promise<void>((resolve, reject) => {
            let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
            let building = document.getElementById("searchBuilding") as HTMLSelectElement;
            let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

            let fteIDValue = "";
            let buildingValue = null;
            let supportTypeValue = null;

            if (filtered) {
                fteIDValue = fteID != null ? fteID.value : "";
                buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
                supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;
            }

            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportExcel/EducationProgramFTEDataExport?planFK=${that.planFK}&fteId=${fteIDValue}&frcppInstitutionFK=${buildingValue}&supportTypeCodeFK=${supportTypeValue}`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = "EducationProgramFTE.xlsx";

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var a = <HTMLAnchorElement>document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                } else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            }
            xhr.send();
        });
    }

    static fteValidation(educationProgramFTEFK: number) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);

        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    } else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }

        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = <HTMLSelectElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    } else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }

        this.updateFTEPercentage(educationProgramFTEFK);
    }

    static updateFTEPercentage(ftePK: number) {
        let that = this;

        return new Promise<void>((resolve, reject) => {

            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];

            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-ftetransferofentity-pk='${ftePK}']`) as HTMLSelectElement;
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }

            let supports = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = <HTMLElement>support
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;

                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;

                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }

                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }

                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }

                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CalculateFTEDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;

                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-ftetransferofentity-pk='${ftePK}']`) as HTMLElement;
                    if (overOneMessage != null) {

                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }

                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        } else {
                            overOneMessage.classList.add("hide");
                        }
                    }

                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`) as HTMLInputElement;
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        } else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }

                    resolve();
                } else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }

    static initializeLoadedFTE(educationProgramFTEFK: number) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);

        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }

        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = <HTMLSelectElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }

        this.updateFTEPercentage(educationProgramFTEFK);
    }

    static fullPageValidation(fromPageLoad:boolean = false) {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = <HTMLInputElement>document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownInvalidAccordions = document.querySelectorAll(".lazyAccordionTrigger[data-validdata='false']");
        const totalErrorsNotShown = totalErrors - shownInvalidAccordions.length;
        let errorCount: number = 0;
        for (const accordion of allAccordions) {
            const accElement = <HTMLButtonElement>accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;

            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            } else {
                accElement.classList.remove("error");
            }
        }

        const messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        const validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        if(!fromPageLoad || errorCount > 0 || totalErrorsNotShown > 0) {
            messageContainerColumn.classList.add("show");
        }

        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");

        const message = <HTMLDivElement>document.getElementById("validationMessage");

        if (errorCount > 0) {
            let word = "are";
            let facility = "FTEs"
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "FTE";
                error = "error";
            }

            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = '';
            if(!fromPageLoad) {
                message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;
            }

            let facilityWord = "are";
            let facilityFacility = "FTEs";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "FTE";
            }

            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;

            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            if (message !== null) {
                message.innerHTML = "The page has been successfully saved."
                message.classList.add("show");
            }
        }
    }

    static clearValidationMessage() {
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }

    static async getValidProgramCount() {

        return new Promise(async (resolve, reject) => {

            const planForm = <HTMLDivElement>document.getElementById("specialEducationEducationProgramCaseloadFTEForm");
            const planFK = planForm.dataset.planfk;

            const response = await fetch(`/SpecialEducation/GetValidProgramFTECountAsync/${planFK}`, { credentials: 'include' })
            if (response.ok) {
                const value = await response.text();
                const field = <HTMLInputElement>document.getElementById("validCount");
                field.value = value;
                resolve("Valid program count updated");
            } else {
                reject("Error updating program count");
            }
        });
    }

    static async planPageCompleteCheck() {
        const form = document.getElementById("specialEducationEducationProgramCaseloadFTEForm");
        const planFK = form.dataset.planfk;
        const pageCode = form.dataset.pagecode;

        const response = await fetch(`/SpecialEducation/GetPageCompleteAsync/${planFK}/${pageCode}`, { credentials: 'include' })
        if (response.ok) {
            const value = await response.json();

            const menuElement = <HTMLDivElement>document.querySelector("#leftBarspecialeducationprogramcaseloadParent .status-indicator");
            const parentMenuElement = <HTMLDivElement>document.querySelector("#leftBarspecialeducationprogramprofileParent .status-indicator");

            if (value.thisPage) { 
                menuElement.classList.add("complete");
            } else {
                menuElement.classList.remove("complete");
            }

            if (value.parentPage) {
                parentMenuElement.classList.add("complete");
            } else {
                parentMenuElement.classList.remove("complete");
            }
        }
    }
    //Mark FTE Complete
    markFTEComplete(ftePK: number) {
        let that = this;

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/MarkFTECompleteByAdmin', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve("FTE Marked Complete");
                } else {
                    reject("There was an unexpected error");
                }
            };
            xhr.send("programFTEFK=" + ftePK);
        });
    }
}

// Special Education Special Education Facilities
class SpecialEducationSpecialEducationFacilities {

    planFK: number;
    validationClasses: string[];
    static staticValidationClasses: string[];
    private _core: Core;
    private static _staticCore: Core;
    private static facilityHashes: {};
    constructor() {
        let that = this;
        // Boilerplate
        this._core = new Core();
        SpecialEducationSpecialEducationFacilities._staticCore = new Core();
        SpecialEducationSpecialEducationFacilities.staticValidationClasses = ["specialEducationSpecialEducationFacilitiesField"];

        if (this._core.Workflows.length > 0) {
            for (let seprnWorkflow of this._core.Workflows) {
                SpecialEducationSpecialEducationFacilities.initializeSEPRNWorkflow(seprnWorkflow);
            }
        }

        new SpecialEducationFacilitiesLazyAccordion();

        this._core.leftnav(this);
        this.validationClasses = ['specialEducationSpecialEducationFacilitiesField'];

        const deleteFacilityConfirmButton = <HTMLButtonElement>document.getElementById("deleteFacilityConfirm");
        if (deleteFacilityConfirmButton !== null)
            deleteFacilityConfirmButton.addEventListener("click", (e: Event) => this.deleteSpecialEducationFacility(deleteFacilityConfirmButton));

        const deleteFacilityCancelButton = document.getElementById("deleteFacilityCancel");
        if (deleteFacilityCancelButton !== null)
            deleteFacilityCancelButton.addEventListener("click", (e: Event) => this.deleteFacilityCancel());

        this._core.initializeRequiredFields(this.validationClasses, false, true);
        const hidden = this._core.createHash(this.validationClasses);

        const hiddenInput = <HTMLInputElement>document.getElementById('hashValue');
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        // Get form element and PlanFK
        const form = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
        if (form != null) {
            this.planFK = parseInt(form.dataset.planfk);
        }

        // Set search event on search button
        const searchButton = <HTMLButtonElement>document.getElementById("facilitiesSearchButton");
        if (searchButton !== null) {
            //1. Save
            //2. Run Search
            searchButton.addEventListener("click", () => {
                Core.showLoader();
                SpecialEducationSpecialEducationFacilities.promiseSave(null, true, false, false, true).then(() => {
                    return SpecialEducationSpecialEducationFacilities.search();

                }).then(() => {
                    // reinitialize accordions
                    new SpecialEducationFacilitiesLazyAccordion();
                    Core.hideLoader();

                    SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
                    SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
                    SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();

                    that._core.initializeWorkflows();
                    if (that._core.Workflows.length > 0) {
                        for (let seprnWorkflow of this._core.Workflows) {
                            SpecialEducationSpecialEducationFacilities.initializeSEPRNWorkflow(seprnWorkflow);
                        }
                    }

                    Tab.Init();
                }).catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }

        // Set clear event on clear button
        const clearButton = document.getElementById("facilitiesClearButton");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                SpecialEducationSpecialEducationFacilities.clearSearch();
            });
        }

        // Set file upload/delete events
        SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
        SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
        SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();

        // Set add a location event on button
        const addLocationButton = document.getElementById('specialEducationSpecialEducationFacilitiesAddALocation');
        if (addLocationButton != null) {
            addLocationButton.addEventListener('click', () => {
                Core.showLoader();
                this.addLocation()
                    .then((response) => {
                        Core.hideLoader();
                        this._core.initializeRequiredFields(this.validationClasses);
                        Core.createHTMLAlert('alertMessageDiv', response, 'success', 3000, null);
                        SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
                        SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
                        SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
                    });
            });
        }

        document.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;

            //Adding a support
            if (target.classList.contains("createSEPRNButton") && "facilityPk" in target.dataset) {
                SpecialEducationSpecialEducationFacilities.createSEPRN(parseInt(target.dataset.facilityPk));
            } else if (target.classList.contains("showDifferences") && "facilityPk" in target.dataset) {
                that.showSEPRNDifferences(parseInt(target.dataset.facilityPk));
            } else if (target.classList.contains("delete-facility-button")) {
                that.showDeleteFacilityConfirmation(<HTMLButtonElement>target);
            }
        });

        document.addEventListener("change", (e) => {
            let target = e.target as HTMLElement;
            if (target.classList.contains("proposedAction") && "facilityPk" in target.dataset) {
                let proposedActionElement = <HTMLSelectElement>target;
                let selectedProposedAction = <HTMLOptionElement>proposedActionElement.options[proposedActionElement.selectedIndex];
                if (selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                    //Open up editable fields
                    let allEditable = document.querySelectorAll(`[data-editable][data-facility-pk='${proposedActionElement.dataset.facilityPk}']`);
                    for (let editable of allEditable) {
                        editable.removeAttribute("readonly");
                        editable.removeAttribute("disabled");
                    }
                } else {
                    //disable editable fields
                    let allEditable = document.querySelectorAll(`[data-editable][data-facility-pk='${proposedActionElement.dataset.facilityPk}']:not([data-propertypk='1184'])`);
                    for (let editable of allEditable) {
                        if (editable instanceof HTMLInputElement) {

                            if (editable.hasAttribute("type") && editable.getAttribute("type") == "file" || editable.getAttribute("type") == "radio") {
                                editable.disabled = true;
                            } else {
                                editable.readOnly = true;
                            }

                        } else if (editable instanceof HTMLSelectElement || editable instanceof HTMLButtonElement) {
                            editable.disabled = true;
                        }
                    }
                }

                var additionalInfo = document.querySelector(`.additionalInformation[data-facility-pk='${proposedActionElement.dataset.facilityPk}']`) as HTMLElement;
                if (selectedProposedAction.dataset.lookupCode == "proposedActionDelete" || selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                    if (additionalInfo != null) {
                        that._core.forceElementRequired(additionalInfo);
                    }
                } else {
                    if (additionalInfo != null) {
                        that._core.forceElementOptional(additionalInfo);
                    }
                }
            }
        });

        let closeSEPRNCompareButton = document.getElementById('closeModalcompareFacilitiesModal') as HTMLButtonElement;
        if (closeSEPRNCompareButton != null) {
            closeSEPRNCompareButton.addEventListener("click", () => {
                const modal: Modal = new Modal('compareFacilitiesModal', null);
                modal.hide();
            });
        }

        const facilitiesExportToExcelButton = document.getElementById("specialEducationFacilitiesExportToExcel");
        if (facilitiesExportToExcelButton !== null) {
            facilitiesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        SpecialEducationSpecialEducationFacilities.initializeFacilityHashes();
        SpecialEducationSpecialEducationFacilities.fullPageValidation(true);
    }

    deleteSpecialEducationFacility(button: HTMLButtonElement) {
        const facilityFK = button.dataset.facilitypk;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteSpecialEducationFacility', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = () => {
            const response = JSON.parse(xhr.response)
            if (xhr.status === 200 && response.success) {
                const accordionTrigger = <HTMLButtonElement>document.querySelector(`.lazyAccordionTrigger[data-specialeducationfacilityfk='${facilityFK}']`);
                accordionTrigger.parentElement.parentElement.remove();

                const modal: Modal = new Modal("deleteFacilityModal", null);
                modal.hide();
                Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted the facility', 'success', 3000, null);
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred deleting the facility, please try again.', 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(facilityFK));
    }

    showSEPRNDifferences(facilityPK: number) {
        let that = this;
        const xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('GET', '/SpecialEducation/GetFacilityComparison?facilityPK=' + facilityPK, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const modal: Modal = new Modal('compareFacilitiesModal', null);
                modal.show();
                const compareFacilitiesBody = document.getElementById('compareFacilitiesBody');
                if (compareFacilitiesBody != null) {
                    $(compareFacilitiesBody).html(xhr.responseText);
                    that.constructAutofillInputsSEPRN();
                    that.highlightChangedFieldsSEPRN();
                }
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred', 'error', 3000, null);
            }
        };
        xhr.send();
    }

    handleMaintained3YearsAssurance() {
        let facilities = document.querySelectorAll(`.facilityContainer[data-seprn-comparison='false'][data-facility-pk]`);
        for (let facility of facilities) {
            let facilityEl = <HTMLElement>facility;
            let assuranceRowSchoolMaintained3Years = document.querySelector(`.assurance-check-row[data-facility-pk='${facilityEl.dataset.facilityPk}'][data-property-code='specialEducationFacilitiesLocationMaintained3Years']`) as HTMLElement;
            if (assuranceRowSchoolMaintained3Years != null) {
                assuranceRowSchoolMaintained3Years.classList.add("hide");
            }

            let proposedAction = document.querySelector(`.proposedAction[data-facility-pk='${facilityEl.dataset.facilityPk}']`) as HTMLSelectElement;
            if (proposedAction != null) {
                proposedAction.addEventListener("change", function () {
                    let selectedOption = proposedAction.options[proposedAction.selectedIndex];
                    if (selectedOption.dataset.lookupCode == "proposedActionChange" || selectedOption.dataset.lookupCode == "proposedActionDelete") {
                        assuranceRowSchoolMaintained3Years.classList.remove("hide");
                    } else {
                        assuranceRowSchoolMaintained3Years.classList.add("hide");
                    }
                });
            }
        }
    }

    static initializeSEPRNWorkflow(seprnWorkflow: Workflow) {
        let seprnContainer = document.querySelector(`.seprnContainer[data-facility-pk][data-workflow-instance-pk='${seprnWorkflow.WorkflowInstancePK}']`) as HTMLElement;
        if (seprnContainer != null) {
            let specialEducationFacilityPK = parseInt(seprnContainer.dataset.facilityPk);
            //Override so that review submission stays on current page instead of going to review admin
            seprnWorkflow.ReviewURL = '';
            seprnWorkflow.BeforeSave = () => this.promiseSave(specialEducationFacilityPK, false, false, true);

            let proposedChangeAction = seprnWorkflow.ActionResponses.filter(ac => ac.ActionCode == "seprnType");
            if (proposedChangeAction.length > 0) {
                let proposedChangeActionRadio = proposedChangeAction[0] as WorkflowActionResponseRadio;
                let deletedRadio = proposedChangeActionRadio.RadioElements.filter(re => "lookupCode" in re.dataset && re.dataset.lookupCode == "proposedActionDelete") as Array<HTMLInputElement>;
                if (deletedRadio != null && deletedRadio.length > 0) {
                    if (deletedRadio[0].checked) {
                        const actionsToDisable = ['onsiteReviewSEPRN', 'ebbFlowSEPRN', 'noiseInterfereSEPRN', 'designedForInstructionSEPRN', 'readilyAccessibleSEPRN', 'squareFeet28SEPRN'];

                        for (let action of seprnWorkflow.ActionResponses.filter(a => actionsToDisable.indexOf(a.ActionCode) >= 0 && a instanceof WorkflowActionResponseRadio)) {
                            let radioAction = action as WorkflowActionResponseRadio;
                            for (let radio of radioAction.RadioElements) {
                                radio.disabled = true;
                                if ("lookupCode" in radio.dataset && radio.dataset.lookupCode == "actionNA") {
                                    radio.checked = true;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    static constructAutofillInputs() {
        const areaMeasurementEles = document.querySelectorAll('.specialEducationSpeicalEducationFacilitiesAreMeasurementField');
        for (let ele of areaMeasurementEles) {
            const element = <HTMLElement>ele;
            ele.addEventListener('change', (e: Event) => this.autofillInputs(element.dataset.facilityPk, false));
        }

        const containers = document.querySelectorAll('.facilityContainer');
        for (let c of containers) {
            const container = <HTMLElement>c;
            const facilityPK = container.dataset.facilityPk;
            this.autofillInputs(facilityPK, true);
        }
    }

    constructAutofillInputsSEPRN() {
        // Get measurement values from the inputs
        const originalAreaMeasurements = {
            WidthFeet: {
                Element: <HTMLInputElement>document.querySelector(`#originalContainer .feet.width-ft`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#originalContainer .feet.width-ft`)).value),
            },
            WidthInches: {
                Element: <HTMLInputElement>document.querySelector(`#originalContainer .inches.width-in`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.inches.width-in`)).value)
            },
            LengthFeet: {
                Element: <HTMLInputElement>document.querySelector(`#originalContainer .feet.length-ft`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#originalContainer .feet.length-ft`)).value),
            },
            LengthInches: {
                Element: <HTMLInputElement>document.querySelector(`#originalContainer .inches.length-in`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#originalContainer .inches.length-in`)).value)
            }
        };

        // Calculate total inches (inches + feet*12) 
        const originalTotalWidthInches = originalAreaMeasurements.WidthInches.Value + (originalAreaMeasurements.WidthFeet.Value * 12);
        const originalTotalLengthInches = originalAreaMeasurements.LengthInches.Value + (originalAreaMeasurements.LengthFeet.Value * 12);

        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const originalAreaInches = originalTotalWidthInches * originalTotalLengthInches;
        const originalAreaSqFeet = Math.floor(originalAreaInches / 144);
        const originalMaxStudents = Math.floor(originalAreaSqFeet / 28);

        // Update UI
        const originalAreaEle = document.querySelector(`#originalContainer .specialEducationFacilitiesAreaMeasurement`);
        (<HTMLInputElement>originalAreaEle).value = `${originalAreaSqFeet}sqft`;
        const originalMaxStudentsEle = document.querySelector(`#originalContainer .specialEducationFacilitiesMaxStudents`);
        (<HTMLInputElement>originalMaxStudentsEle).value = `${originalMaxStudents}`;


        // Get measurement values from the inputs
        const newAreaMeasurements = {
            WidthFeet: {
                Element: <HTMLInputElement>document.querySelector(`#newContainer .feet.width-ft`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#newContainer .feet.width-ft`)).value),
            },
            WidthInches: {
                Element: <HTMLInputElement>document.querySelector(`#newContainer .inches.width-in`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.inches.width-in`)).value)
            },
            LengthFeet: {
                Element: <HTMLInputElement>document.querySelector(`#newContainer .feet.length-ft`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#newContainer .feet.length-ft`)).value),
            },
            LengthInches: {
                Element: <HTMLInputElement>document.querySelector(`#newContainer .inches.length-in`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#newContainer .inches.length-in`)).value)
            }
        };

        // Calculate total inches (inches + feet*12) 
        const newTotalWidthInches = newAreaMeasurements.WidthInches.Value + (newAreaMeasurements.WidthFeet.Value * 12);
        const newTotalLengthInches = newAreaMeasurements.LengthInches.Value + (newAreaMeasurements.LengthFeet.Value * 12);

        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const newAreaInches = newTotalWidthInches * newTotalLengthInches;
        const newAreaSqFeet = Math.floor(newAreaInches / 144);
        const newMaxStudents = Math.floor(newAreaSqFeet / 28);

        // Update UI
        const newAreaEle = document.querySelector(`#newContainer .specialEducationFacilitiesAreaMeasurement`);
        (<HTMLInputElement>newAreaEle).value = `${newAreaSqFeet}sqft`;
        const newMaxStudentsEle = document.querySelector(`#newContainer .specialEducationFacilitiesMaxStudents`);
        (<HTMLInputElement>newMaxStudentsEle).value = `${newMaxStudents}`;
    }

    highlightChangedFieldsSEPRN() {
        let originalFields = document.querySelectorAll('#originalContainer .specialEducationSpecialEducationFacilitiesField[data-propertypk]');
        for (let field of originalFields) {
            let fieldElement = <HTMLElement>field;

            let usedRadios: string[] = [];
            if (fieldElement instanceof HTMLInputElement) {
                let correspondingFieldElement = <HTMLInputElement>document.querySelector(`#newContainer [data-propertypk='${fieldElement.dataset.propertypk}']`);
                if(correspondingFieldElement !== null) {
                    if (fieldElement.hasAttribute("type") && fieldElement.type == "radio" && usedRadios.indexOf(fieldElement.name) == -1) {
                        usedRadios.push(fieldElement.name);
                        //Get checked value of original and compare to new
                        let checkedOriginal = document.querySelector(`[name='${fieldElement.name}']:checked`) as HTMLInputElement;
                        let checkedNew = document.querySelector(`[name='${correspondingFieldElement.name}']:checked`) as HTMLInputElement;

                        if (checkedOriginal != null && checkedNew != null && checkedOriginal.value != checkedNew.value) {
                            let trContainer = Core.findClosest(checkedNew, "tr");
                            if (trContainer != null) {
                                trContainer.classList.add("different");
                            }
                        }
                    }
                    else {
                        if (fieldElement.value != correspondingFieldElement.value) {
                            correspondingFieldElement.classList.add("different");
                        }
                    }
                }
            } else if (fieldElement instanceof HTMLSelectElement) {
                let correspondingFieldElement = <HTMLSelectElement>document.querySelector(`#newContainer [data-propertypk='${fieldElement.dataset.propertypk}']`);
                if (correspondingFieldElement !== null && fieldElement.value != correspondingFieldElement.value) {
                    correspondingFieldElement.classList.add("different");
                }
            }
        }

        //Files
        let originalFile = document.querySelector('#originalContainer .uploadFileColumn[data-fileupload-pk]') as HTMLElement;
        let newFile = document.querySelector('#newContainer .uploadFileColumn[data-fileupload-pk]') as HTMLElement;

        if ((originalFile != null && newFile != null && originalFile.dataset.fileuploadPk != newFile.dataset.fileuploadPk) || (originalFile == null && newFile != null)) {
            let fileContainer = newFile.querySelector('.uploaded-file-container') as HTMLElement;
            if (fileContainer != null) {
                fileContainer.classList.add("different");
            }
        } else if (originalFile != null && newFile == null) {
            let fileContainer = originalFile.querySelector('.uploaded-file-container') as HTMLElement;
            if (fileContainer != null) {
                fileContainer.classList.add("different");
            }
        }
    }

    static constructFileUploadElements() {
        const containers = document.querySelectorAll('.facilityContainer');
        for (let c of containers) {
            const container = <HTMLElement>c;
            const facilityPK = container.dataset.facilityPk;

            const fileUploader = document.querySelector(`.classroom-features-upload[data-facility-pk='${facilityPK}']`);
            if (fileUploader !== null) {
                fileUploader.addEventListener('change', (e: Event) => SpecialEducationSpecialEducationFacilities.uploadFile(<HTMLInputElement>e.target, facilityPK));
            }
        }
    }

    static constructFileDeleteElements(facilityFK = null) {
        if (facilityFK) {
            const deleteButton = document.querySelector(`.deleteFile[data-facility-pk='${facilityFK}']`) as HTMLButtonElement;
            if (deleteButton.dataset.fileuploadPk && parseInt(deleteButton.dataset.fileuploadPk) > 0) {
                deleteButton.addEventListener('click', (e: Event) => this.showDeleteFileConfirmation(deleteButton));
            }
        } else {
            const deleteFileButtons = document.getElementsByClassName('deleteFile') as HTMLCollectionOf<HTMLButtonElement>;
            for (let deleteButton of deleteFileButtons) {
                if (deleteButton.dataset.fileuploadPk && parseInt(deleteButton.dataset.fileuploadPk) > 0) {
                    deleteButton.addEventListener('click', (e: Event) => this.showDeleteFileConfirmation(deleteButton));
                }
            }
        }

        const deleteFileConfirmButton = <HTMLButtonElement>document.getElementById('deleteFileConfirm');
        if (deleteFileConfirmButton !== null) {
            deleteFileConfirmButton.addEventListener('click', (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));
        }

        const deleteFileCancelButton = <HTMLButtonElement>document.getElementById('deleteFileCancel');
        if (deleteFileCancelButton !== null) {
            deleteFileCancelButton.addEventListener('click', (e: Event) => this.deleteFileCancel());
        }
    }

    addLocation() {
        const frcppInstitutionSelect = document.getElementById('specialEducationFacilitiesNewBuildingName') as HTMLSelectElement;
        const frcppInstitution = frcppInstitutionSelect[frcppInstitutionSelect.selectedIndex].dataset.lookupCode;

        const roomDescInput = document.getElementById('specialEducationFacilitiesRoomNewNumber') as HTMLInputElement;
        const roomDesc = roomDescInput.value;

        return new Promise((resolve, reject) => {
            const data: IFacility = {
                SpecialEducationFacilityPK: 0,
                PlanFK: this.planFK,
                FRCPPInstitutionFK: parseInt(frcppInstitution),
                RoomDesc: roomDesc
            };

            if (roomDescInput != null && roomDesc != '' && frcppInstitutionSelect != null && frcppInstitutionSelect.selectedIndex != 0) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/CreateNewFacility', true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const facilityList = document.getElementById('facilitiesContainerInner');
                        if (facilityList != null) {
                            $(facilityList).prepend(xhr.responseText);

                            const newFacilityAccordion = facilityList.querySelector('.lazy-accordion-trigger') as HTMLButtonElement;
                            if (newFacilityAccordion != null) {
                                new SpecialEducationFacilitiesLazyAccordion(newFacilityAccordion.id);
                                SpecialEducationSpecialEducationFacilities.bindSaveFacilities();
                            }

                            resolve('Successfully created new facility.');
                        }
                    } else {
                        reject('There was an unexpected error creating a new facility, please try again.');
                    }
                };
                xhr.send(JSON.stringify(data));
            } else {
                reject('Both a Building and a Room must be specified to add a location.');
            }
        });
    }

    static autofillInputs(facilityFK, onPageLoad: boolean = false) {

        // Get measurement values from the inputs
        const areaMeasurements = {
            WidthFeet: {
                Element: <HTMLInputElement>document.querySelector(`.feet.width-ft[data-facility-pk='${facilityFK}']`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.feet.width-ft[data-facility-pk='${facilityFK}']`)).value),
            },
            WidthInches: {
                Element: <HTMLInputElement>document.querySelector(`.inches.width-in[data-facility-pk='${facilityFK}']`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.inches.width-in[data-facility-pk='${facilityFK}']`)).value)
            },
            LengthFeet: {
                Element: <HTMLInputElement>document.querySelector(`.feet.length-ft[data-facility-pk='${facilityFK}']`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.feet.length-ft[data-facility-pk='${facilityFK}']`)).value),
            },
            LengthInches: {
                Element: <HTMLInputElement>document.querySelector(`.inches.length-in[data-facility-pk='${facilityFK}']`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.inches.length-in[data-facility-pk='${facilityFK}']`)).value)
            }
        };

        if (!onPageLoad) {
            // Validate: user has entered a number >= 0, and if it is an inches measurement, 12 > number >= 0
            for (let am in areaMeasurements) {
                const measurement = areaMeasurements[am];
                if (measurement.Value === '' || measurement.Value === null || isNaN(measurement.Value) || parseInt(measurement.Value) < 0) {
                    measurement.Element.value = 0;
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter a number greater than or equal to 0.', 'error', 3000, null);
                    return;
                } else if ((am === 'WidthInches' || am === 'LengthInches') && parseInt(measurement.Value) > 11) {
                    measurement.Element.value = 0
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter an inches value between 0 and 11.', 'error', 3000, null);
                }
            }
        }

        // Calculate total inches (inches + feet*12) 
        const totalWidthInches = areaMeasurements.WidthInches.Value + (areaMeasurements.WidthFeet.Value * 12);
        const totalLengthInches = areaMeasurements.LengthInches.Value + (areaMeasurements.LengthFeet.Value * 12);

        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const areaInches = totalWidthInches * totalLengthInches;
        const areaSqFeet = Math.floor(areaInches / 144);
        const maxStudents = Math.floor(areaSqFeet / 28);

        // Update UI
        const areaEle = document.querySelector(`.specialEducationFacilitiesAreaMeasurement[data-facility-pk='${facilityFK}']`);
        (<HTMLInputElement>areaEle).value = `${areaSqFeet}sqft`;
        const maxStudentsEle = document.querySelector(`.specialEducationFacilitiesMaxStudents[data-facility-pk='${facilityFK}']`);
        (<HTMLInputElement>maxStudentsEle).value = `${maxStudents}`;
    }

    collapseAllFacilities() {
        const facilitiesContainer = document.getElementById('facilitiesContainerInner');
        if (facilitiesContainer != null) {
            const openToggles = facilitiesContainer.querySelectorAll('.lazy-accordion-trigger.open');
            for (let toggle of openToggles) {
                const toggleElement = <HTMLElement>toggle;
                toggleElement.click();
            }
        }
    }

    expandAllFacilities() {
        const facilitiesContainer = document.getElementById('facilitiesContainerInner');
        if (facilitiesContainer != null) {
            const closedToggles = facilitiesContainer.querySelectorAll('.lazy-accordion-trigger:not(.open)');
            for (let toggle of closedToggles) {
                const toggleElement = <HTMLElement>toggle;
                toggleElement.click();
            }
        }
    }

    static search(fromHistory: boolean = false, fromClear: boolean = false) {
        const frcppInstitutionSelect = document.getElementById('specialEducationFacilitiesBuildingName') as HTMLSelectElement;
        const frcppInstitutionFK = frcppInstitutionSelect != null && frcppInstitutionSelect.selectedIndex > 0
            ? parseInt(frcppInstitutionSelect[frcppInstitutionSelect.selectedIndex].dataset.lookupCode)
            : null;

        const roomDescInput = document.getElementById('specialEducationFacilitiesRoomNumber') as HTMLInputElement;
        const roomDesc = roomDescInput != null && roomDescInput.value != ''
            ? roomDescInput.value
            : null;

        const planFormElement = <HTMLElement>document.getElementById("specialEducationSpecialEducationFacilitiesForm");
        const planFK = planFormElement.dataset.planfk;

        let query = {
            PlanFK: planFK,
            FRCPPInstitutionFK: frcppInstitutionFK,
            RoomDesc: roomDesc
        };

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/FacilitiesSearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const container = document.getElementById('facilitiesContainer');
                    if (container) {
                        container.innerHTML = xhr.responseText;

                        SpecialEducationSpecialEducationFacilities.fullPageValidation();

                        resolve();
                    }
                    else {
                        reject("There was an error loading the facilities, please try again.");
                    }
                } else {
                    reject("There was an error loading the facilities, please try again.");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }

    static clearSearch() {
        const frcppInstitutionSelect = document.getElementById("specialEducationFacilitiesBuildingName") as HTMLSelectElement;
        const roomDescInput = document.getElementById("specialEducationFacilitiesRoomNumber") as HTMLInputElement;

        const planFormElement = <HTMLElement>document.getElementById("specialEducationSpecialEducationFacilitiesForm");
        const planFK = planFormElement.dataset.planFK;

        if (frcppInstitutionSelect != null) {
            frcppInstitutionSelect.selectedIndex = 0;
        }

        if (roomDescInput != null) {
            roomDescInput.value = '';
        }

        Core.showLoader();
        this.promiseSave()
            .then(() => {
                return this.search(true, true);
            })
            .then(() => {
                //reinitialize accordions
                new SpecialEducationFacilitiesLazyAccordion(null);
                Core.hideLoader();

                SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
                SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
                SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();
            })
            .catch((error) => {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
            });
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    static uploadFile(e: HTMLInputElement, facilityFK) {
        Core.showLoader();
        const uploadFeaturesForm = <HTMLFormElement>document.querySelector(`.specialEducationFacilitiesUploadFeatures[data-facility-pk='${facilityFK}']`);
        const formData = new FormData(uploadFeaturesForm);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/UploadFacilitiesFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);

                        const formFile = document.querySelector(`.classroom-features-upload[data-facility-pk='${facilityFK}']`) as HTMLInputElement;
                        formFile.value = '';
                        formFile.dataset.hasuploaded = 'true';

                        const fileUpload = response.fileUpload;

                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/SpecialEducation/GetFacilityFileUploadPartialView?fileUploadPK=${fileUpload.fileUploadPK}&fileContentType=${fileUpload.fileContentType}&fileName=${fileUpload.fileName}&specialEducationFacilityPK=${fileUpload.specialEducationFacilityPK}`);
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.file-upload-div[data-facility-pk='${facilityFK}']`) as HTMLDivElement;
                                fileUploadDiv.innerHTML = xhrForPartialView.response;
                                SpecialEducationSpecialEducationFacilities.constructFileDeleteElements(facilityFK);
                            }
                        };
                        xhrForPartialView.send();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    static showDeleteFileConfirmation(deleteButton: HTMLButtonElement) {
        const fileUploadPK = deleteButton.dataset.fileuploadPk;
        const facilityPK = deleteButton.dataset.facilityPk;

        if (fileUploadPK && parseInt(fileUploadPK) > 0) {
            const modal: Modal = new Modal('deleteFileModal', null);
            modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "facilitypk", facilityPK);
            modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "fileuploadpk", fileUploadPK);
            modal.show();
        }
    }

    static deleteFileConfirm(confirmButton: HTMLButtonElement) {
        const facilityFK = parseInt(confirmButton.dataset.facilityPk);
        const containerElement = <HTMLElement>document.querySelector(`.file-upload-div[data-facility-pk='${facilityFK}']`);
        if (containerElement !== null) {
            containerElement.removeChild(containerElement.firstChild);
        }

        const modal: Modal = new Modal('deleteFileModal', null);
        modal.hide();
    }

    static deleteFileCancel() {
        const modal: Modal = new Modal('deleteFileModal', null);
        modal.hide();
    }

    showDeleteFacilityConfirmation(deleteButton: HTMLButtonElement) {
        const facilityPK = deleteButton.dataset.facilityPk;
        const modal: Modal = new Modal('deleteFacilityModal', null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFacilityConfirm", "facilitypk", facilityPK);

        const id = <HTMLButtonElement>document.getElementById("deleteFacilityConfirm");
        id.dataset.facilitypk = facilityPK;

        modal.show();
    }

    deleteFacilityCancel() {
        const modal: Modal = new Modal('deleteFacilityModal', null);
        modal.hide();
    }

    customValidation(facilityPK: number = null) {

        let hasInputErrors = false;
        let hasSelectErrors = false;
        let errorElements = [];
        let totalErrors = 0;
        let showMessage: boolean = false;

        let parent = <Element>(document as unknown);
        if (facilityPK != null) {
            let facility = document.querySelector(`.facilityContainer[data-facility-pk='${facilityPK}']`);
            if (facility != null) {
                parent = facility;
            }
        }

        const roomDescInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.room-desc');

        for (let i of roomDescInputs) {
            const input = <HTMLInputElement>i;
            if (!input.value) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: input, type: 'input' });
            } else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }

        const footInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.feet');

        for (let i of footInputs) {
            const input = <HTMLInputElement>i;
            var inputValue = parseInt(input.value);
            if (inputValue === 0) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: input, type: 'input' });
            } else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }

        const requriedSelects = parent.querySelectorAll('select.specialEducationSpecialEducationFacilitiesField.required-facility-field');

        for (let s of requriedSelects) {
            const select = <HTMLSelectElement>s;
            if (select.value === '0') {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a school building and building description', 'error', 3000, null);
                select.classList.add('missing-field');
                select.setAttribute('aria-invalid', 'true');
                hasSelectErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: select, type: 'select' });
            } else {
                select.classList.remove('missing-field');
                select.removeAttribute('aria-invalid');
            }
        }

        for (let element of errorElements) {
            const accordion = element.element.closest('.Accordion-panel');
            accordion.classList.add('open');
        }
        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    firstFocusableEl.focus();
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }

        return !(hasInputErrors || hasSelectErrors);
    }

    static validateElements(facilityPK: number = null) {

        let hasInputErrors = false;
        let hasSelectErrors = false;
        let errorElements = [];

        let parent = <Element>(document as unknown);
        if (facilityPK != null) {
            let facility = document.querySelector(`.facilityContainer[data-facility-pk='${facilityPK}']`);
            if (facility != null) {
                parent = facility;
            }
        }

        const roomDescInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.room-desc');

        for (let i of roomDescInputs) {
            const input = <HTMLInputElement>i;
            if (!input.value) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                errorElements.push({ element: input, type: 'input' });
            } else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }

        const requriedSelects = parent.querySelectorAll('select.specialEducationSpecialEducationFacilitiesField.required-facility-field');

        for (let s of requriedSelects) {
            const select = <HTMLSelectElement>s;
            if (select.value === '0') {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a school building and building description', 'error', 3000, null);
                select.classList.add('missing-field');
                select.setAttribute('aria-invalid', 'true');
                hasSelectErrors = true;
                errorElements.push({ element: select, type: 'select' });
            } else {
                select.classList.remove('missing-field');
                select.removeAttribute('aria-invalid');
            }
        }

        if (hasInputErrors || hasSelectErrors) {
            for (let element of errorElements) {
                const accordion = Core.findClosest(element, '.lazyAccordionTrigger');
                if(accordion) {
                    accordion.classList.add('open');
                }
            }

            const firstErrorElement = errorElements[0];
            if (firstErrorElement.type === 'input') {
                Core.createHTMLAlert('alertMessageDiv', 'Please enter a room number', 'error', 3000, null);
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a school building and building description', 'error', 3000, null);
            }
            firstErrorElement.element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        return !(hasInputErrors || hasSelectErrors);
    }

    static promiseSave(specialEducationFacilityPK: number = null, forceSave: boolean = false, onWindowUnload: boolean = false, forSEPRN: boolean = false, fromBackOrContinue: boolean = false) {
        const formElement = <HTMLElement>document.getElementById("specialEducationSpecialEducationFacilitiesForm");
        const planFK = parseInt(formElement.dataset.planfk);

        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData: IFacilitySave[] = [];

            let facilitiesToSave = [];
            if (fromBackOrContinue) {
                const listOfLoadedFacilities = document.querySelectorAll(`.lazyAccordionTrigger[data-loaded='true']`);
                for (const openFacility of listOfLoadedFacilities) {
                    const loadedFacility = <HTMLButtonElement>openFacility;
                    const facilityFK = parseInt(loadedFacility.dataset.specialeducationfacilityfk);

                    if (this.facilityHasChanged(facilityFK)) {
                        const theseFacilities = document.querySelectorAll(`.facilityContainer[data-seprn-comparison="false"][data-facility-pk='${facilityFK}']`);
                        for (const fac of theseFacilities)
                            facilitiesToSave.push(fac);
                    }
                }
            }
            else if (specialEducationFacilityPK !== null) {
                const theseFacilities = document.querySelectorAll(`.facilityContainer[data-seprn-comparison="false"][data-facility-pk='${specialEducationFacilityPK}']`);
                for (const fac of theseFacilities)
                    facilitiesToSave.push(fac);
            }

            for (let facilityToSave of facilitiesToSave) {
                const facility = <HTMLElement>facilityToSave
                let assuranceChecksData: IAssuranceCheck[] = [];

                if ('facilityPk' in facility.dataset) {
                    shouldSave = true;
                    let facilityPK = facility.dataset.facilityPk;

                    // Get FRCPPInstitutionFK
                    let frcppInstitutionFK = null;
                    let instition = facility.querySelector(`.frcpp-institution[data-facility-pk='${facilityPK}']`) as HTMLSelectElement;
                    if (instition != null && instition.value != '') {
                        frcppInstitutionFK = instition[instition.selectedIndex].dataset.lookupCode;
                    }

                    // Get RoomDesc
                    let roomDesc = null;
                    let room = facility.querySelector(`.room-desc[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    if (room != null && room.value != '') {
                        roomDesc = room.value;
                    } else if (onWindowUnload) {
                        roomDesc = 0;
                    }

                    // Get SchoolBuildingLookupCodeFK
                    let schoolBuildingLookupCodeFK = null;
                    let schoolBuilding = facility.querySelector(`.school-building[data-facility-pk='${facilityPK}']`) as HTMLSelectElement;
                    if (schoolBuilding != null && schoolBuilding.value != '') {
                        schoolBuildingLookupCodeFK = schoolBuilding[schoolBuilding.selectedIndex].dataset.lookupCode;
                    }

                    // Get BuildingDescriptionLookupCodeFK
                    let buildingDescriptionLookupCodeFK = null;
                    let buildingDescription = facility.querySelector(`.building-description[data-facility-pk='${facilityPK}']`) as HTMLSelectElement;
                    if (buildingDescription != null && buildingDescription.value != '') {
                        buildingDescriptionLookupCodeFK = buildingDescription[buildingDescription.selectedIndex].dataset.lookupCode;
                    }

                    // Get ClassroomWidth
                    let classroomWidth = null;
                    let widthFt = facility.querySelector(`.width-ft[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    let widthIn = facility.querySelector(`.width-in[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    if (widthFt != null && widthFt.value != '' && widthIn != null && widthIn.value != '') {
                        let width = parseInt(widthIn.value) + (parseInt(widthFt.value) * 12);
                        classroomWidth = width;
                    }

                    // Get Classroomlength
                    let classroomLength = null;
                    let lengthFt = facility.querySelector(`.length-ft[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    let lengthIn = facility.querySelector(`.length-in[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    if (lengthFt != null && lengthFt.value != '' && lengthIn != null && lengthIn.value != '') {
                        let length = parseInt(lengthIn.value) + (parseInt(lengthFt.value) * 12);
                        classroomLength = length;
                    }

                    // Get FileUploadFK
                    let fileUploadFK = null;
                    let file = facility.querySelector(`button.delete-row.deleteFile[data-facility-pk='${facilityPK}']`) as HTMLButtonElement;
                    if (file != null) {
                        fileUploadFK = file.dataset.fileuploadPk;
                    }

                    // Get ImplementationDate
                    let implementationDate = null;
                    let date = facility.querySelector(`.implementation-date[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    if (date != null && date.value != '') {
                        implementationDate = date.value;
                    }

                    // Get assurance checks
                    let assuranceChecks = [];
                    let assuranceChecksForFacility = facility.querySelectorAll(`.assurance-check-row[data-facility-pk='${facilityPK}']`);
                    for (let assuranceCheck of assuranceChecksForFacility) {
                        let assuranceCheckElement = <HTMLElement>assuranceCheck;
                        if ('facilityPk' in assuranceCheckElement.dataset) {

                            // Get assurance PK
                            let specialEducationFacilityAssuranceCheckPK = null;
                            let assurancePK = assuranceCheckElement.dataset.assurancePk;
                            if (assurancePK != null && assurancePK != '') {
                                specialEducationFacilityAssuranceCheckPK = assurancePK;
                            }

                            // Get PropertyFK
                            let propertyFK = null;
                            let property = assuranceCheckElement.querySelector(`.assurance-check-input[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                            propertyFK = property.dataset.propertypk;

                            // Get LookupCodeFK (Yes/No)
                            let lookupCodeFK = null;
                            let lookupCode = assuranceCheckElement.querySelector(`.assurance-check-input[data-facility-pk='${facilityPK}']:checked`) as HTMLInputElement;
                            lookupCodeFK = lookupCode.value;

                            const facilityAssuranceCheckSaveData: IAssuranceCheck = {
                                SpecialEducationFacilityAssuranceCheckPK: parseInt(specialEducationFacilityAssuranceCheckPK),
                                SpecialEducationFacilityFK: parseInt(facilityPK),
                                PropertyFK: parseInt(propertyFK),
                                LookupCodeFK: parseInt(lookupCodeFK)
                            }

                            assuranceChecks.push(facilityAssuranceCheckSaveData);
                        }
                    }

                    const facilitiesSaveData: IFacility = {
                        SpecialEducationFacilityPK: parseInt(facilityPK),
                        FRCPPInstitutionFK: frcppInstitutionFK,
                        RoomDesc: roomDesc,
                        SchoolBuildingLookupCodeFK: schoolBuildingLookupCodeFK,
                        BuildingDescriptionLookupCodeFK: buildingDescriptionLookupCodeFK,
                        ClassroomWidth: classroomWidth,
                        ClassroomLength: classroomLength,
                        FileUploadFK: fileUploadFK,
                        ImplementationDate: implementationDate,
                        PlanFK: planFK
                    }

                    const thisFacilitiesSaveData: IFacilitySave = {
                        Facility: facilitiesSaveData,
                        AssuranceChecks: assuranceChecks
                    }

                    saveData.push(thisFacilitiesSaveData);
                }
            }

            if (shouldSave || forceSave) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSpecialEducationFacilities', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        if (specialEducationFacilityPK != null) {

                            const response = JSON.parse(xhr.responseText);
                            const accordionButton = <HTMLButtonElement>document.querySelector(`.lazyAccordionTrigger[data-specialeducationfacilityfk='${specialEducationFacilityPK}']`);
                            if (accordionButton)
                                accordionButton.dataset.validdata = response.isValid.toString().toLowerCase();

                            resolve('Successfully saved Facility');
                        } else {
                            resolve('Successfully saved');
                        }
                    } else {
                        reject('There was an unexpected error saving');
                    }
                };
                xhr.send(JSON.stringify({
                    'Data': saveData,
                    'PlanFK': planFK,
                    'CanSaveSEPRN': forSEPRN
                }));
            } else {
                resolve('Nothing To Save');
            }
        });
    }

    save(referrer, facilityPK: number = null) {
        let refreshPage = '';

        if (referrer === 'continue') {
            refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url');
        }
        if (referrer === 'back') {
            refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        }
        else {
            refreshPage = referrer;
        }

        Core.showLoader();
        SpecialEducationSpecialEducationFacilities.promiseSave(null, true, false, false, true).then((response) => {
            Core.hideLoader();
            if (referrer === 'save') {
                //Reset query so that all results show
                window.location.href = '/SpecialEducation/SpecialEducationSpecialEducationFacilities/' + this.planFK + '?fromSave=true';
            } else if (refreshPage && refreshPage !== '') {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
            Core.hideLoader();
        });
    }

    static createSEPRN(facilityPK: number) {
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        let proposedActionValue = "";
        let additionalInformationValue = "";

        let proposedActionElement = document.querySelector(`.proposedAction[data-facility-pk='${facilityPK}']`) as HTMLSelectElement;
        if (proposedActionElement != null) {
            proposedActionValue = proposedActionElement.value;
        }

        let additionalInformationElement = document.querySelector(`.additionalInformation[data-facility-pk='${facilityPK}']`) as HTMLTextAreaElement;
        if (additionalInformationElement != null) {
            additionalInformationValue = additionalInformationElement.value;
        }

        if (proposedActionElement.value != "") {
            let selected = proposedActionElement[proposedActionElement.selectedIndex] as HTMLOptionElement;

            if ((selected.dataset.lookupCode == "proposedActionDelete" || selected.dataset.lookupCode == "proposedActionChange") && additionalInformationValue == "") {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'Please specify a reason/explanation for this SEPRN', 'error', 3000, null);
            }
            else if (selected.dataset.lookupCode == "proposedActionNew" && !this.validateElements(facilityPK)) {
                Core.hideLoader();
            } else {
                fetch('/SpecialEducation/CreateSEPRN', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        FacilityPK: facilityPK,
                        ProposedAction: proposedActionValue,
                        AdditionalInformation: additionalInformationValue
                    })
                })
                .then((response) => {
                    if(response.ok) {
                        return this.promiseSave(facilityPK, false, false, true);
                    } else {
                        throw new Error('Unsuccesfully created SEPRN');
                    }
                })
                .then((response) => {
                    Core.createHTMLAlert('alertMessageDiv', 'Successfully submitted SEPRN', 'success', 3000, window.location.reload());
                    Core.hideLoader();
                })
                .catch((error) => {
                    Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue submitting SEPRN', 'error', 3000, null);
                    Core.hideLoader();
                });
            }
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'Please select a proposed action for the SEPRN', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationFacilitiesExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationFacilties.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    static bindSaveFacilities() {
        const facilitiesSave = document.getElementsByClassName("facilitiesSave");
        for (const facility of facilitiesSave)
            facility.addEventListener("click", (e: Event) => SpecialEducationSpecialEducationFacilities.saveFacility(e.target as HTMLButtonElement));
    }

    static saveFacility(saveButton: HTMLButtonElement) {
        const facilityFK = parseInt(saveButton.dataset.facilitypk);
        let needsSave: boolean = false;

        if (this.facilityHasChanged(facilityFK)) {
            this.showFacilityLoader(facilityFK);

            SpecialEducationSpecialEducationFacilities.promiseSave(facilityFK, true).then(async (response) => {
                this.hideFacilityLoader(facilityFK);
                Core.createHTMLAlert('alertMessageDiv', "Facility saved successfully.", 'success', 3000, null);

                //rebuild the hash. We don't want to be able to save this facility again without changes.
                this.addFacilityHash(facilityFK, true);

                SpecialEducationSpecialEducationFacilities.validateElements(facilityFK);
                await SpecialEducationSpecialEducationFacilities.getValidFacilityCount();
                SpecialEducationSpecialEducationFacilities.fullPageValidation();
                SpecialEducationSpecialEducationFacilities.planPageCompleteCheck();
            }).catch((error) => {
                Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
                this.hideFacilityLoader(facilityFK);
            });
        } else {
            Core.createHTMLAlert("alertMessageDiv", "No changes to save.", "warning", 3000, null);
        }       
    }

    static facilityHasChanged(facilityFK: number) {
        let needsSave: boolean = false;

        //If the hash doesn't exist, it's likely a new school record, so it will need to be saved.
        if (!SpecialEducationSpecialEducationFacilities.facilityHashes[facilityFK]) {
            needsSave = true;
        } else {
            const newHashValue = SpecialEducationSpecialEducationFacilities.calculateFacilityHash(facilityFK);

            if (SpecialEducationSpecialEducationFacilities.facilityHashes[facilityFK] !== newHashValue) {
                needsSave = true;
            }
        }

        return needsSave;
    }

    static calculateFacilityHash(facilityFK: number) {
        const that = this;
        let hash = "0";
        let value;
        let newHash;

        let allElements = document.querySelectorAll(`[data-hashable][data-facilityfk='${facilityFK}']`);

        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>element;

                if (inputElement.type === "radio") {
                    if (inputElement.checked) {
                        value = inputElement.value;
                        newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
                else {
                    if (inputElement.value === "") {
                        value = 0
                        hash = hash + value;
                    }
                    else if (inputElement.value !== "") {
                        value = inputElement.value;
                        newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
            } else if (element instanceof HTMLSelectElement) {
                const selectElement = <HTMLSelectElement>element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value
                    newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            } else if (element instanceof HTMLTextAreaElement) {
                const textAreaElement = <HTMLTextAreaElement>element;

                if (textAreaElement.value === "") {
                    value = 0
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            } else if (element instanceof HTMLAnchorElement) {
                const anchorElement = <HTMLAnchorElement>element;

                value = anchorElement.dataset.fileuploadPk;
                newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                hash = hash + newHash;
            }
        }

        return hash;
    }

    static initializeFacilityHashes() {
        this.facilityHashes = {};
    }

    //add the facility hash the array. If the facility already exists, don't add or update the hash UNLESS rebuild is true (in this case, it's)
    //likely coming from a save, so a new hash should be generated.
    static addFacilityHash(facilityFK: number, rebuild: boolean = false) {
        if (facilityFK > 0) {
            if (!this.facilityHashes[facilityFK] || rebuild) {
                this.facilityHashes[facilityFK] = this.calculateFacilityHash(facilityFK);
            }
        }
    }

    static showFacilityLoader(facilityFK: number) {
        const savingContainer = document.querySelector(`.special-education-saving[data-facilityfk='${facilityFK}']`);
        const saverContainer = document.querySelector(`.special-education-saver[data-facilityfk='${facilityFK}']`);

        savingContainer.classList.add("save");
        saverContainer.classList.add("save");
    }

    static hideFacilityLoader(facilityFK: number) {
        const savingContainer = document.querySelector(`.special-education-saving[data-facilityfk='${facilityFK}']`);
        const saverContainer = document.querySelector(`.special-education-saver[data-facilityfk='${facilityFK}']`);

        savingContainer.classList.remove("save");
        saverContainer.classList.remove("save");
    }

    static fullPageValidation(fromPageLoad:boolean = false) {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = <HTMLInputElement>document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownInvalidAccordions = document.querySelectorAll(".lazyAccordionTrigger[data-validdata='false']");
        const totalErrorsNotShown = totalErrors - shownInvalidAccordions.length;
        let errorCount: number = 0;
        for (const accordion of allAccordions) {
            const accElement = <HTMLButtonElement>accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;

            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            } else {
                accElement.classList.remove("error");
            }
        }

        const messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        const validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        if(!fromPageLoad || errorCount > 0 || totalErrorsNotShown > 0) {
            messageContainerColumn.classList.add("show");
        }
        const message = <HTMLDivElement>document.getElementById("validationMessage");

        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");

        if (errorCount > 0) {
            let word = "are";
            let facility = "facilities"
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "facility";
            }

            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = '';
            if(!fromPageLoad) {
                message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;
            }

            let facilityWord = "are";
            let facilityFacility = "facilities";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "facility";
            }

            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;

            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            if (message !== null) {
                message.innerHTML = "The page has been successfully saved."
                message.classList.add("show");
            }
        }
    }

    static async getValidFacilityCount() {
        return new Promise(async (resolve, reject) => {
            const planForm = <HTMLDivElement>document.getElementById("specialEducationSpecialEducationFacilitiesForm");
            const planFK = planForm.dataset.planfk;

            const response = await fetch(`/SpecialEducation/GetValidFacilitiesCount/${planFK}`, { credentials: 'include' })
            if (response.ok) {
                const value = await response.text();
                const field = <HTMLInputElement>document.getElementById("validCount");
                field.value = value;
                resolve("Valid facility count updated");
            } else {
                reject("Error updating facility count");
            }
        });
    }

    static facilityValidation(specialEducationFacilityFK: number) {
        const elementsInsideAccordion = document.querySelectorAll(`.specialEducationFacilityAccordionField[data-facility-pk='${specialEducationFacilityFK}']`);
        for (const elementInside of elementsInsideAccordion) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>elementInside;
                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                    if (inputElement.hasAttribute("name")) {
                        const radioName = inputElement.getAttribute("name");

                        const radioButtons = document.getElementsByName(radioName);
                        let isChecked: boolean = false;

                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                            let radioElement = <HTMLInputElement>radioButtons[i];

                            if (radioElement.checked) {
                                isChecked = true;
                            }
                        }

                        if (!isChecked) {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                        } else {
                            inputElement.classList.remove("missing-field");
                            inputElement.setAttribute("aria-invalid", "false");
                            Core.removeErrorLabelForInput(inputElement);
                        }
                    }
                } else {
                    if (inputElement.dataset.percent === "1.00") {
                        if (inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                        } else {
                            inputElement.classList.remove("missing-field");
                            inputElement.setAttribute("aria-invalid", "false");
                            Core.removeErrorLabelForInput(inputElement);
                        }
                    }
                }
            }
        }
    }

    static initializeLoadedFacility(specialEducationFacilityFK: number) {
        this._staticCore.initializeRequiredFields(this.staticValidationClasses, false, true);
    }

    static clearValidationMessage() {
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }

    static async planPageCompleteCheck() {
        const form = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
        const planFK = form.dataset.planfk;
        const pageCode = form.dataset.pagecode;

        const response = await fetch(`/SpecialEducation/GetPageCompleteAsync/${planFK}/${pageCode}`, { credentials: 'include' })
        if (response.ok) {
            const value = await response.json();

            const menuElement = <HTMLDivElement>document.querySelector("#leftBarspecialeducationfacilitiesParent .status-indicator");
            const parentMenuElement = <HTMLDivElement>document.querySelector("#leftBarspecialeducationprogramprofileParent .status-indicator");

            if (value.thisPage) {
                menuElement.classList.add("complete");
            } else {
                menuElement.classList.remove("complete");
            }

            if (value.parentPage) {
                parentMenuElement.classList.add("complete");
            } else {
                parentMenuElement.classList.remove("complete");
            }
        }
    }
}

// Special Education Education Program (Caseload FTE)
class SpecialEducationFTETransferOfEntity {
    validationClasses: string[];
    planFK: number;
    templateFK: number;
    deleteSupportConfirmModal: Modal;
    deleteFTEConfirmModal: Modal;
    private typingTimer: number;
    private static fteHashes: Object;
    private allowExceedsFTE: boolean;
    private canCreate: boolean;

    private _core: Core;
    private static _staticCore: Core;
    private static _staticPlanFK: number;
    private static _staticAllowExceedsFTE: boolean;
    constructor() {
        const that = this;
        this._core = new Core();
        SpecialEducationFTETransferOfEntity._staticCore = new Core();

        const form = document.getElementById("specialEducationEducationProgramFTETransferOfEntityForm");
        if (form != null) {
            that.planFK = parseInt(form.dataset.planfk);
            that.templateFK = parseInt(form.dataset.templatefk);
            SpecialEducationFTETransferOfEntity._staticPlanFK = parseInt(form.dataset.planfk);
        }

        const canCreateElement = <HTMLInputElement>document.getElementById("canCreate");
        if (canCreateElement !== null)
            if (canCreateElement.value === "true")
                that.canCreate = true;
            else
                that.canCreate = false;
        else
            that.canCreate == false;

        const hdnAllowExceedsFTE = document.getElementById("hdnAllowExceedsFTE") as HTMLInputElement;
        if (hdnAllowExceedsFTE != null && hdnAllowExceedsFTE.value === "True") {
            that.allowExceedsFTE = true;
        } else {
            that.allowExceedsFTE = false;
        }

        if (this.canCreate) {

            new SpecialEducationProgramCaseloadFTETransferOfEntityLazyAccordion();

            that.deleteSupportConfirmModal = new Modal("deleteSupportConfirmModal", null);
            that.deleteFTEConfirmModal = new Modal("deleteFTEConfirmModal", null);

            this.validationClasses = [
                "fteID",
                "classroomLocationFTE",
                "buildingName",
                "supportTypeGroup",
                "supportType",
                "supportLevel",
                "caseLoad",
                "identifyClassroom",
                "classroomLocationSupport",
                "ageRangeFrom",
                "ageRangeEnd",
                "ageRangeJustification",
                "alertedIU",
                "afterMarch",
                "implementationDate",
            ];

            this._core.leftnav(this);
            this._core.tabLinkSave(this);
            this._core.initializeRequiredFields(this.validationClasses);

            SpecialEducationFTETransferOfEntity.initializeFTEHashes();
            that.handleClearDisabled();

            const hidden = this._core.createHash(this.validationClasses);
            const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
            if (hiddenInput !== null) {
                hiddenInput.value = hidden;
            }

            //Event Listener for creating new FTE
            const createNewFTE = document.getElementById("createNewFTETransferOfEntity");
            if (createNewFTE != null) {
                createNewFTE.addEventListener("click", () => {
                    Core.showLoader();
                    that.createNewFTETransferOfEntity()
                        .then((response) => {
                            Core.hideLoader();
                            that._core.initializeRequiredFields(that.validationClasses);
                            Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                        })
                        .catch((error) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });
                });
            }

            //Event listener for deleting a support
            const deleteSupportConfirmButton = document.getElementById("deleteSupportConfirm") as HTMLButtonElement;
            if (deleteSupportConfirmButton != null) {

                deleteSupportConfirmButton.addEventListener("click", () => {
                    that.deleteSupportConfirmModal.hide();
                    if ("supportPk" in deleteSupportConfirmButton.dataset && "ftetransferofentityPk" in deleteSupportConfirmButton.dataset) {
                        Core.showLoader();
                        that.deleteSupport(parseInt(deleteSupportConfirmButton.dataset.supportPk))
                            .then((response) => {
                                Core.hideLoader();
                                Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                                that.handleFTESaveDisabled(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                                that.checkSupportCount(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                                that.refreshFTEPercentage(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                            })
                            .catch((error) => {
                                Core.hideLoader();
                                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                            });
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this support. Please try again later.", 'error', 3000, null);
                    }
                });
            }

            //Event listener for confirming deletion of FTE
            const deleteFTEConfirmButton = document.getElementById("deleteFTEConfirm") as HTMLButtonElement;
            if (deleteFTEConfirmButton != null) {

                deleteFTEConfirmButton.addEventListener("click", () => {

                    if ("ftetransferofentityPk" in deleteFTEConfirmButton.dataset) {
                        that.deleteFTE(parseInt(deleteFTEConfirmButton.dataset.ftetransferofentityPk));
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE. Please try again later.", 'error', 3000, null);
                    }
                });
            }

            //Event listener for cancelling the delete of a support
            const deleteSupportCancelButton = document.getElementById("deleteSupportCancel") as HTMLButtonElement;
            if (deleteSupportCancelButton != null) {
                deleteSupportCancelButton.addEventListener("click", () => {
                    that.deleteSupportConfirmModal.hide();
                });
            }

            //Event listener for cancelling the delete of an FTE
            const deleteFTECancelButton = document.getElementById("deleteFTECancel") as HTMLButtonElement;
            if (deleteFTECancelButton != null) {
                deleteFTECancelButton.addEventListener("click", () => {
                    that.deleteFTEConfirmModal.hide();
                });
            }

            //Event listener for clicking the search button
            const searchButton = <HTMLButtonElement>document.getElementById("searchButton");
            if (searchButton !== null) {
                //1. Save page (will only save FTE that have changed)
                //2. Run Search
                searchButton.addEventListener("click", () => {

                    Core.showLoader();
                    SpecialEducationFTETransferOfEntity.promiseSave()
                        .then(() => {
                            return that.search();
                        })
                        .then(() => {
                            SpecialEducationFTETransferOfEntity.initializeFTEHashes();
                            that._core.initializeRequiredFields(that.validationClasses);
                            //reinitialize accordions
                            new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                            Core.hideLoader();
                        })
                        .catch((error) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });

                });
            }

            //Event listener for searching when hitting enter
            const fteIDSearch = <HTMLInputElement>document.getElementById("searchFTEId");
            if (fteIDSearch !== null) {
                fteIDSearch.addEventListener("keypress", (e: KeyboardEvent) => {
                    if (e.keyCode === 13) {
                        Core.showLoader();
                        SpecialEducationFTETransferOfEntity.promiseSave()
                            .then(() => {
                                return that.search();
                            })
                            .then(() => {
                                that._core.initializeRequiredFields(that.validationClasses);
                                SpecialEducationFTETransferOfEntity.initializeFTEHashes();

                                //reinitialize accordions
                                new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                                Core.hideLoader();
                            })
                            .catch((error) => {
                                Core.hideLoader();
                                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                            });
                    }
                });

                fteIDSearch.addEventListener("input", () => {
                    that.handleClearDisabled();
                });
            }

            //Clears the search fields
            const clearButton = document.getElementById("searchClear");
            if (clearButton !== null) {
                clearButton.addEventListener("click", () => {
                    that.clearSearch();
                });
            }

            //When changing building filter, determine whether clear button should be enabled
            const buildingSearch = document.getElementById("searchBuilding") as HTMLSelectElement;
            const supportTypeSearch = document.getElementById("searchSupportType") as HTMLSelectElement;
            if (buildingSearch != null) {
                buildingSearch.addEventListener("change", () => {
                    that.handleClearDisabled();
                });
            }

            //When changing support type filter, determine whether clear button should be enabled
            if (supportTypeSearch != null) {
                supportTypeSearch.addEventListener("change", () => {
                    that.handleClearDisabled();
                });
            }

            //When user has searched, focus on search result message
            const searchResultsMessage = document.getElementById("searchResultsMessage");
            if (searchResultsMessage != null) {
                document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
                searchResultsMessage.focus();
            }

            //Event listener for exporting all data to excel
            const exportToExcelButton = document.getElementById("specialEducationTransferOfEntityExportToExcel");
            if (exportToExcelButton != null) {
                exportToExcelButton.addEventListener("click", () => {
                    Core.showLoader();
                    that.exportToExcel()
                        .then((response) => {
                            Core.hideLoader();
                        })
                        .catch((error) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });
                });
            }

            document.addEventListener("click", (e) => {
                const target = e.target as HTMLElement;

                //Adding a support
                if (target.classList.contains("addSupportButton") && "ftetransferofentityPk" in target.dataset) {
                    Core.showLoader();
                    that.createNewSupport(parseInt(target.dataset.ftetransferofentityPk))
                        .then((response) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                            that.checkSupportCount(parseInt(target.dataset.ftetransferofentityPk));
                            that.adjustSupportClassroomLocation(parseInt(target.dataset.ftetransferofentityPk));
                        })
                        .then(() => {
                            that._core.initializeRequiredFields(that.validationClasses);
                            that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                        })
                        .catch((error) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });
                }

                //Deleting a support
                else if (target.classList.contains("deleteSupport") && "supportPk" in target.dataset && "ftetransferofentityPk" in target.dataset) {
                    that.deleteSupportConfirmModal.show();
                    deleteSupportConfirmButton.dataset.supportPk = target.dataset.supportPk;
                    deleteSupportConfirmButton.dataset.ftetransferofentityPk = target.dataset.ftetransferofentityPk;
                    that.deleteSupportConfirmModal.callingId = target.id;
                }

                //Deleting an FTE
                else if (target.classList.contains("deleteFTE") && "ftetransferofentityPk" in target.dataset) {
                    that.deleteFTEConfirmModal.show();
                    deleteFTEConfirmButton.dataset.ftetransferofentityPk = target.dataset.ftetransferofentityPk;
                    that.deleteFTEConfirmModal.callingId = target.id;
                }

                //Saving an FTE
                else if (target.classList.contains("saveFTEButton") && "ftetransferofentityPk" in target.dataset) {

                    Core.showLoader();
                    SpecialEducationFTETransferOfEntity.promiseSave(parseInt(target.dataset.ftetransferofentityPk))
                        .then((response) => {

                            //Recalculate hash and handle disabling the save button for the FTE
                            SpecialEducationFTETransferOfEntity.fteHashes[parseInt(target.dataset.ftetransferofentityPk)] = SpecialEducationFTETransferOfEntity.calculateFTEHash(parseInt(target.dataset.ftetransferofentityPk));
                            that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));

                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                        })
                        .catch((error) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });

                }

                // Submitting a SEPRN action
                else if (target.classList.contains("createSEPRNButton") && "ftetransferofentityPk" in target.dataset) {
                    SpecialEducationFTETransferOfEntity.createSEPRN(parseInt(target.dataset.ftetransferofentityPk));
                }
            });

            document.addEventListener("change", (e) => {
                const target = e.target as HTMLElement;

                //Handle Support Type Group changing
                if (target.classList.contains("supportTypeGroup") && "supportPk" in target.dataset && "ftetransferofentityPk" in target.dataset) {

                    //1. Refresh the support type list
                    //2. Refresh the support level list (1 needs to happen first)
                    //3. Refresh FTE percent (1 and 2 need to happen first)
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);

                    that.refreshSupportTypeSubList(parseInt(target.dataset.supportPk))
                        .then(() => {
                            return that.refreshSupportLevels(parseInt(target.dataset.supportPk));
                        })
                        .then(() => {
                            that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                            return that.refreshFTEPercentage(parseInt(target.dataset.ftetransferofentityPk));
                        })
                        .then(() => {
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        })
                        .catch((error) => {
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        });

                }

                //Handle Support Type changing
                else if (target.classList.contains("supportType") && "supportPk" in target.dataset) {

                    //1. Refresh the support level list
                    //2. Refresh FTE percent (1 needs to happen first)
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                    that.refreshSupportLevels(parseInt(target.dataset.supportPk))
                        .then(() => {
                            that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                            const ftePK = parseInt(target.dataset.ftetransferofentityPk);
                            return that.refreshFTEPercentage(ftePK);
                        })
                        .then(() => {
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        })
                        .catch((error) => {
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        });

                }

                //Handle Support Level changing
                else if (target.classList.contains("supportLevel") && "supportPk" in target.dataset) {

                    //1. Refresh FTE percent
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    that.refreshFTEPercentage(parseInt(target.dataset.ftetransferofentityPk))
                        .then(() => {
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        })
                        .catch((error) => {
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        });

                }

                //Handle Classroom Location for FTE changing
                else if (target.classList.contains("classroomLocationFTE") && "ftetransferofentityPk" in target.dataset) {
                    that.adjustSupportClassroomLocation(parseInt(target.dataset.ftetransferofentityPk))
                        .then(() => {
                            that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                        });
                }

                //Handle Full-time/Part-time changing
                else if (target.classList.contains("fullTime") && "ftetransferofentityPk" in target.dataset) {

                    //1. Refresh FTE percent
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    that.refreshFTEPercentage(parseInt(target.dataset.ftetransferofentityPk))
                        .then(() => {
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        })
                        .catch((error) => {
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        });

                }

                //Handle Classroom Location for Support changing
                else if (target.classList.contains("classroomLocationSupport") && "supportPk" in target.dataset) {
                    that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                        .then(() => {
                            that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                        });
                }

                //The radio buttons for the 2 yes/no questions need to be handled separately here, so we're not getting them using the HTMLInputElement type for a catch all
                else if ("hashable" in target.dataset && "ftetransferofentityPk" in target.dataset && (target.classList.contains("alertedIU") || target.classList.contains("afterMarch"))) {
                    const ftePK = parseInt(target.dataset.ftetransferofentityPk);
                    that.handleFTESaveDisabled(ftePK);
                }

                //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
                else if ("hashable" in target.dataset && "ftetransferofentityPk" in target.dataset && !(target instanceof HTMLInputElement) && !(target instanceof HTMLTextAreaElement)) {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                }

                //Handle the proposed action change
                else if (target.classList.contains("proposedAction") && "fteTransferOfEntityPk" in target.dataset) {
                    let proposedActionElement = <HTMLSelectElement>target;
                    let selectedProposedAction = <HTMLOptionElement>proposedActionElement.options[proposedActionElement.selectedIndex];
                    if (selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                        //Open up editable fields
                        let allEditable = document.querySelectorAll(`[data-editable][data-ftetransferofentity-pk='${proposedActionElement.dataset.ftetransferofentityPk}']`);
                        for (let editable of allEditable) {
                            editable.removeAttribute("readonly");
                            editable.removeAttribute("disabled");
                        }
                    } else {
                        //disable editable fields
                        let allEditable = document.querySelectorAll(`[data-editable][data-ftetransferofentity-pk='${proposedActionElement.dataset.facilityPk}']:not([data-propertypk='1184'])`);
                        for (let editable of allEditable) {
                            if (editable instanceof HTMLInputElement) {

                                if (editable.hasAttribute("type") && editable.getAttribute("type") == "file" || editable.getAttribute("type") == "radio") {
                                    editable.disabled = true;
                                } else {
                                    editable.readOnly = true;
                                }

                            } else if (editable instanceof HTMLSelectElement || editable instanceof HTMLButtonElement) {
                                editable.disabled = true;
                            }
                        }
                    }

                    var additionalInfo = document.querySelector(`.additionalInformation[data-ftetransferofentity-pk='${proposedActionElement.dataset.facilityPk}']`) as HTMLElement;
                    if (selectedProposedAction.dataset.lookupCode == "proposedActionDelete" || selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                        if (additionalInfo != null) {
                            that._core.forceElementRequired(additionalInfo);
                        }
                    } else {
                        if (additionalInfo != null) {
                            that._core.forceElementOptional(additionalInfo);
                        }
                    }
                }
            });

            document.addEventListener("input", (e) => {
                //On a slight delay so that ajax call isn't made until finished typing
                const target = e.target as HTMLElement;

                //Handle Caseload changing
                if (target.classList.contains("caseLoad") && "supportPk" in target.dataset) {

                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk))

                    if (typeof (that.typingTimer) !== 'undefined') {
                        clearTimeout(that.typingTimer);
                    }
                    that.typingTimer = setTimeout(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);

                        that.refreshFTEPercentage(parseInt(target.dataset.ftetransferofentityPk))
                            .then(() => {
                                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                            })
                            .catch((error) => {
                                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                            });
                    }, 500);
                }

                //Handle age Range From for Support changing
                else if (target.classList.contains("ageRangeFrom") && "supportPk" in target.dataset) {
                    that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                        .then(() => {
                            that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                        });
                }

                //Handle age Range End for Support changing
                else if (target.classList.contains("ageRangeEnd") && "supportPk" in target.dataset) {
                    that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                        .then(() => {
                            that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                        });
                }

                //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
                else if ("hashable" in target.dataset && "ftetransferofentityPk" in target.dataset && (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement)) {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                }
            });

            //This is called when clicking the back/forward buttons in the browser. This is using the History API
            window.addEventListener('popstate', (e) => {
                const query = e.state;

                const fteID = document.getElementById("searchFTEId") as HTMLInputElement;
                const building = document.getElementById("searchBuilding") as HTMLSelectElement;
                const supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

                if (fteID != null) {
                    if (query != null && query.FTEIdentifierName != null) {
                        fteID.value = query.FTEIdentifierName;
                    } else {
                        fteID.value = "";
                    }
                }

                if (building != null) {
                    if (query != null && query.FRCPPInstitutionFK != null) {
                        building.value = query.FRCPPInstitutionFK.toString();
                    } else {
                        building.selectedIndex = 0;
                    }
                }

                if (supportType != null) {
                    if (query != null && query.SupportTypeCodePK != null) {
                        supportType.value = query.SupportTypeCodePK.toString();
                    } else {
                        supportType.selectedIndex = 0;
                    }
                }
                that.handleClearDisabled();
                Core.showLoader();
                SpecialEducationFTETransferOfEntity.promiseSave()
                    .then(() => {
                        return that.search(true);
                    })
                    .then(() => {
                        SpecialEducationFTETransferOfEntity.initializeFTEHashes();

                        //reinitialize accordions
                        new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });

            SpecialEducationFTETransferOfEntity.fullPageValidation(true);
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    //Top level save method used by back/continue buttons, clicking on link, etc. Calls the promiseSave method and controls redirect.
    save(referrer, ftePK: number = null) {
        let that = this;
        let core = that._core;

        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let forceSave = false;
        if (referrer === "save") {
            forceSave = true;
        }

        SpecialEducationFTETransferOfEntity.promiseSave(null, forceSave).then((response) => {
            Core.hideLoader();

            if (referrer === "save") {
                //Reset query so that all results show
                window.location.href = window.location.href.split('?')[0] + '?fromSave=true';
            } else if (refreshPage && refreshPage !== "") {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
    }

    //Creates a new FTE record in database and prepends partial view to DOM
    createNewFTETransferOfEntity() {
        const that = this;

        return new Promise((resolve, reject) => {
            const newFTEID = document.getElementById("createNewFTETransferOfEntityId") as HTMLInputElement;
            const newClassroomLocation = document.getElementById("createNewClassroomLocation") as HTMLSelectElement;
            const newFullTime = document.getElementById("createNewFullTime") as HTMLSelectElement;

            const data: IEducationProgramFTETransferOfEntitySave = {
                EducationProgramFTETransferOfEntityPK: 0,
                ClassroomLocationLookupCodeFK: parseInt(newClassroomLocation.value),
                FullTimeLookupCodeFK: parseInt(newFullTime.value),
                FTEIdentifierName: newFTEID.value,
                PlanFK: that.planFK,
                AlertedTheIULookupCodeFK: null,
                TransferAfterMarchLookupCodeFK: null,
                ImplementationDate: null
            };

            const saveData = {
                FTE: data,
                AllowExceedsFTE: that.allowExceedsFTE
            }

            if (newFTEID != null && newFTEID.value != "" && newClassroomLocation != null && newClassroomLocation.selectedIndex != 0 && newFullTime != null && newFullTime.selectedIndex != 0) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/CreateNewFTETransferOfEntity', true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {

                        const fteList = document.getElementById("fteContainerInner");
                        if (fteList != null) {
                            $(fteList).prepend(xhr.responseText);
                            newFTEID.value = "";
                            newClassroomLocation.selectedIndex = 0;
                            newFullTime.selectedIndex = 0;

                            const newFTEAccordion = fteList.querySelector(".lazyAccordion-trigger") as HTMLButtonElement;
                            if (newFTEAccordion != null) {
                                new SpecialEducationProgramCaseloadFTETransferOfEntityLazyAccordion(newFTEAccordion.id);
                            } else {
                                new SpecialEducationProgramCaseloadFTETransferOfEntityLazyAccordion(null);
                            }

                            resolve("Successfully created new FTE Transfer of Entity");
                        }
                    } else if (xhr.status === 409) {
                        reject("This FTE ID already exists, please use a unique name.");
                    } else {
                        reject("There was an unexpected error creating new FTE Transfer of Entity");
                    }
                };
                xhr.send(JSON.stringify(saveData));
            } else {
                reject("All fields must have a value to create a new FTE Transfer of Entity");
            }
        });
    }

    //Creates a new support record in database and appends partial view to DOM
    createNewSupport(fteTransferOfEntityPK: number) {
        let that = this;

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CreateNewTransferOfEntitySupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {

                    let supportList = document.querySelector(`.supportsContainer[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
                    if (supportList != null) {
                        $(supportList).append(xhr.responseText);
                        resolve("Successfully added support");
                    } else {
                        reject("There was an unexpected error adding the support");
                    }
                } else {
                    reject("There was an unexpected error creating new support");
                }
            };
            xhr.send("fteTransferOfEntityPK=" + fteTransferOfEntityPK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
        });
    }

    //Soft deletes support from database
    deleteSupport(supportPK: number) {
        let that = this;

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteTransferOfEntitySupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {

                    let supportContainer = document.querySelector(`.supportRow[data-support-pk='${supportPK}']`);
                    if (supportContainer != null) {

                        let deleteSupportButton = document.querySelector(`.deleteSupport[data-support-pk='${supportPK}']`) as HTMLElement;
                        let nextFocusable = Core.getNextFocusableElement(deleteSupportButton);

                        supportContainer.parentNode.removeChild(supportContainer);
                        nextFocusable.focus();
                        resolve("Successfully deleted support");
                    } else {
                        reject("There was an unexpected error removing this support from the page");
                    }
                } else {
                    reject("There was an unexpected error deleting this support");
                }
            };
            xhr.send("supportPK=" + supportPK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
        });
    }

    //Determines whether to show the message that the FTE has no supports in the UI
    checkSupportCount(ftePK: number) {
        let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
        let noSupportsMessage = document.querySelector(`.noSupportsMessage[data-fte-pk='${ftePK}']`) as HTMLElement;
        if (noSupportsMessage != null) {
            if (supports.length > 0) {
                noSupportsMessage.classList.add("hide");
            } else {
                noSupportsMessage.classList.remove("hide");
            }
        }
    }

    //Soft deletes FTE record from database
    deleteFTE(ftePK: number) {
        let that = this;

        that.deleteFTEConfirmModal.hide();
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFTETransferOfEntity', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {

                let allFTEContainer = document.getElementById("fteContainerInner");
                let fteContainer = document.querySelector(`.fteContainer[data-ftetransferofentity-pk='${ftePK}']`);
                let accordion = Core.findClosest(fteContainer, ".lazyAccordion") as HTMLElement;

                //Get next focusable accordion
                let accordionTriggers = allFTEContainer.querySelectorAll(".lazyAccordion-trigger");
                if (accordionTriggers.length > 1) {
                    let arr = Array.prototype.slice.call(accordionTriggers);
                    let thisAccordionTrigger = accordion.querySelector(".lazyAccordion-trigger");
                    if (thisAccordionTrigger != null) {
                        let index = arr.indexOf(thisAccordionTrigger);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                } else {
                    //Focus on create fte if no fte left
                    let create = document.getElementById("createNewFTEId");
                    if (create != null) {
                        create.focus();
                    }
                }

                if (accordion != null) {
                    accordion.parentNode.removeChild(accordion);
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted FTE", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error removing this FTE from the page", 'error', 3000, null);
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE", 'error', 3000, null);
            }
        };
        xhr.send("ftePK=" + ftePK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
    }

    //Refreshes the possible Support Types that can be selected in the UI based on the Support Type Group that was selected
    refreshSupportTypeSubList(supportPK: number) {
        let that = this;

        let supportTypeGroup = document.querySelector(`.supportTypeGroup[data-support-pk='${supportPK}']`) as HTMLSelectElement;

        return new Promise<void>((resolve, reject) => {
            if (supportTypeGroup != null) {
                if (supportTypeGroup.value != "") {
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/SpecialEducation/RefreshSupportTypeSubListTransferOfEntity', true);
                    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            let supportTypeListContainer = document.querySelector(`.supportTypesContainer[data-support-pk='${supportPK}']`);
                            if (supportTypeListContainer != null) {
                                supportTypeListContainer.innerHTML = xhr.responseText;

                                let supportTypeDropdown = supportTypeListContainer.querySelector(".supportType[data-forcerequired='true']") as HTMLElement;
                                if (supportTypeDropdown != null) {
                                    that._core.forceElementRequired(supportTypeDropdown);
                                }
                            }

                            resolve();
                        } else {
                            reject("Error refreshing support types");
                        }
                    };
                    xhr.send(`supportPK=${supportPK}&fteTransferOfEntityPK=${supportTypeGroup.dataset.ftetransferofentityPk}&supportTypeGroupCodeFK=${supportTypeGroup.value}&templateFK=${that.templateFK}`);
                } else {
                    let container = document.querySelector(`.supportTypesContainer[data-support-pk='${supportTypeGroup.dataset.supportPk}']`);
                    if (container != null) {
                        container.innerHTML = "";
                    }
                    resolve();
                }
            } else {
                reject("Support type group not found");
            }
        });
    }

    //Refreshes the possible Support Levels that can be selected in the UI based on the Support Type that was selected
    refreshSupportLevels(supportPK: number) {
        let that = this;

        let currentLevelPK = null;
        let currentTypePK = null;

        let currentLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`) as HTMLSelectElement;
        if (currentLevel != null && currentLevel.value != "") {
            currentLevelPK = currentLevel.value;
        }

        let currentType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`) as HTMLSelectElement;
        if (currentType != null && currentType.value != "") {
            currentTypePK = currentType.value;
        }

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/RefreshSupportLevelsTransferOfEntity', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {

                if (xhr.status === 200) {
                    let supportLevelListContainer = document.querySelector(`.supportLevelsContainer[data-support-pk='${supportPK}']`);
                    if (supportLevelListContainer != null) {
                        supportLevelListContainer.innerHTML = xhr.responseText;

                        let supportLevelDropdown = supportLevelListContainer.querySelector(".supportLevel[data-forcerequired='true']") as HTMLElement;
                        if (supportLevelDropdown != null) {
                            that._core.forceElementRequired(supportLevelDropdown);
                        }
                    }
                    resolve()
                } else {
                    reject("Error refreshing support levels");
                }
            };
            xhr.send(`supportPK=${supportPK}&fteTransferOfEntityPK=${currentLevel.dataset.ftetransferofentityPk}&currentLevelPK=${currentLevelPK}&currentTypePK=${currentTypePK}&templateFK=${that.templateFK}`);
        });
    }

    //Shows the max possible caseload count in the UI given the currently selected Support Level and Type for the specified Support
    refreshMaxPossibleCaseLoad(supportPK: number) {
        let that = this;

        let supportType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`) as HTMLSelectElement;
        let supportLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`) as HTMLSelectElement;

        return new Promise<void>((resolve, reject) => {
            if (supportType != null && supportType.value != "" && supportLevel != null && supportLevel.value != "") {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/RefreshMaxPossibleCaseLoad', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    let jsonResponse = JSON.parse(xhr.response);
                    if (xhr.status === 200 && jsonResponse.success) {
                        let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`) as HTMLElement;
                        if (maxCaseLoadContainer != null) {
                            maxCaseLoadContainer.classList.remove("hide");
                        }

                        let maxCaseLoadElement = document.querySelector(`.maxCaseLoad[data-support-pk='${supportPK}']`) as HTMLElement;
                        if (maxCaseLoadElement != null) {
                            maxCaseLoadElement.innerHTML = jsonResponse.maxCaseLoadCount;
                        }

                        resolve();
                    } else {
                        reject("Error refreshing max caseload");
                    }
                };
                xhr.send(`supportTypePK=${supportType.value}&supportLevelPK=${supportLevel.value}`);
            } else {
                let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`) as HTMLElement;
                if (maxCaseLoadContainer != null) {
                    maxCaseLoadContainer.classList.add("hide");
                }
            }
        });
    }

    //Refreshes the FTE for each support in the FTE and the FTE as a whole. If the FTE is over the limit, the error will display.
    refreshFTEPercentage(ftePK: number) {
        let that = this;

        return new Promise<void>((resolve, reject) => {

            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];

            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-ftetransferofentity-pk='${ftePK}']`) as HTMLSelectElement;
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }

            let supports = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = <HTMLElement>support
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;

                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;

                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }

                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }

                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }

                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CalculateFTETransferOfEntityDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;

                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-ftetransferofentity-pk='${ftePK}']`) as HTMLElement;
                    if (overOneMessage != null) {

                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }

                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        } else {
                            overOneMessage.classList.add("hide");
                        }
                    }

                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`) as HTMLInputElement;
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        } else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }

                    resolve();
                } else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }

    //The classroom location for a support is dependent upon the selected classroom location for the FTE. This function handles this in the UI
    adjustSupportClassroomLocation(ftePK: number) {
        return new Promise<void>((resolve, reject) => {
            let classroomLocationLookupCode: string = "";
            let fteClassroomLocation = document.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
            if (fteClassroomLocation != null) {
                classroomLocationLookupCode = fteClassroomLocation.options[fteClassroomLocation.selectedIndex].dataset.lookupCode;
            }
            //Get the supports for this FTE
            let classroomLocationsSupport = document.querySelectorAll(`.classroomLocationSupport[data-fte-pk='${ftePK}']`);
            for (let classroomLocationDropdown of classroomLocationsSupport) {
                let classroomLocationDropdownElement = <HTMLSelectElement>classroomLocationDropdown;

                if (classroomLocationLookupCode === "specialeducationlocationelementary" || classroomLocationLookupCode === "specialeducationlocationsecondary") {
                    for (var i = 0; i < classroomLocationDropdownElement.options.length; ++i) {
                        if (classroomLocationDropdownElement.options[i].dataset.lookupCode === classroomLocationLookupCode) {
                            classroomLocationDropdownElement.selectedIndex = i;
                            classroomLocationDropdownElement.disabled = true;
                            break;
                        }
                    }
                } else {
                    classroomLocationDropdownElement.disabled = false;
                }
            }
            resolve();
        });
    }

    //Based on the selection of "Classroom Location" and "Age Range", make the "Age Range Justification" optional or required
    checkAgeRangeJustification(supportPK: number) {
        let that = this;

        return new Promise<void>((resolve, reject) => {

            let makeRequired = false;

            let classroomLocation = document.querySelector(`.classroomLocationSupport[data-support-pk='${supportPK}']`) as HTMLSelectElement;
            let ageRangeFrom = document.querySelector(`.ageRangeFrom[data-support-pk='${supportPK}']`) as HTMLInputElement;
            let ageRangeTo = document.querySelector(`.ageRangeEnd[data-support-pk='${supportPK}']`) as HTMLInputElement;
            if (classroomLocation != null && ageRangeFrom != null && ageRangeTo != null) {
                if (ageRangeFrom.value != "" && ageRangeTo.value != "" && parseInt(ageRangeFrom.value) && parseInt(ageRangeTo.value)) {

                    let range = parseInt(ageRangeTo.value) - parseInt(ageRangeFrom.value);

                    let classroomLocationLookupCode = classroomLocation.options[classroomLocation.selectedIndex].dataset.lookupCode;

                    switch (classroomLocationLookupCode) {
                        case 'specialeducationlocationelementary':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationsecondary':
                            if (range > 4) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationmultiple':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                    }
                }
            }

            let justification = document.querySelector(`.ageRangeJustification[data-support-pk='${supportPK}']`) as HTMLInputElement;
            if (justification != null) {
                if (makeRequired) {
                    that._core.forceElementRequired(justification);
                } else {
                    that._core.forceElementOptional(justification);
                }
            }

            resolve();
        });
    }

    //Shows a loader next to the FTE percentage while application figures out what the new percentage is
    toggleFTESupportLoader(supportPK: number, show: boolean) {
        let loaderContainer = document.querySelector(`.loaderContainer[data-support-pk='${supportPK}']`) as HTMLElement;
        if (loaderContainer != null) {
            if (show) {
                loaderContainer.classList.remove("hide");
            } else {
                loaderContainer.classList.add("hide");
            }
        }
    }

    //Searches FTE based on criteria and refreshes DOM with partial view. Also pushes history state so that back and continue buttons in browser work.
    search(fromHistory: boolean = false) {
        let that = this;

        let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
        let building = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

        let fteIDValue = fteID != null ? fteID.value : "";
        let buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
        let supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;

        let query = {
            PlanFK: that.planFK,
            FTEIdentifierName: fteIDValue,
            FRCPPInstitutionFK: buildingValue,
            SupportTypeCodePK: supportTypeValue
        }

        let queryString = `?fteId=${query.FTEIdentifierName}&frcppInstitutionFK=${query.FRCPPInstitutionFK}&supportTypeCodeFK=${query.SupportTypeCodePK}`;

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/TransferOfEntitySearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let container = document.getElementById("fteContainer");
                    if (container != null) {
                        container.innerHTML = xhr.responseText;

                        //Add to history so back button works
                        if (!fromHistory) {
                            history.pushState(query, '', queryString);
                        }

                        let searchResultsMessage = document.getElementById("searchResultsMessage");
                        if (searchResultsMessage != null) {
                            document.title = searchResultsMessage.textContent + " - Transfer of Entity - Future Ready Comprehensive Planning Portal";
                            searchResultsMessage.focus();
                        }

                        SpecialEducationFTETransferOfEntity.fullPageValidation();

                        resolve();
                    }
                    else {
                        reject("There was an error loading Transfer of Entity records");
                    }
                } else {
                    reject("There was an unexpected error during search");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }

    //Clears search fields, saves page (in case there were changes), and refreshes DOM
    clearSearch() {
        let that = this;

        let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
        let building = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

        if (fteID != null) {
            fteID.value = "";
        }

        if (building != null) {
            building.selectedIndex = 0;
        }

        if (supportType != null) {
            supportType.selectedIndex = 0;
        }

        Core.showLoader();
        SpecialEducationFTETransferOfEntity.promiseSave()
            .then(() => {
                return that.search(true);
            })
            .then(() => {
                SpecialEducationFTETransferOfEntity.initializeFTEHashes();
                that.handleClearDisabled();
                that._core.initializeRequiredFields(that.validationClasses);
                //reinitialize accordions
                new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                Core.hideLoader();

                let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE") as HTMLButtonElement;
                exportToExcelFilteredButton.classList.add("hide");
            })
            .catch((error) => {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
            });

        history.pushState(null, '', window.location.href.split('?')[0]);
        document.title = "Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
    }

    //The save button only becomes available for an FTE if there is a change within the FTE
    handleFTESaveDisabled(ftePK: number) {
        let saveButton = document.querySelector(`.saveFTEButton[data-ftetransferofentity-pk='${ftePK}']`) as HTMLButtonElement;
        if (saveButton != null) {
            if (SpecialEducationFTETransferOfEntity.fteHasChanged(ftePK) && "canEdit" in saveButton.dataset) {
                saveButton.disabled = false;
            } else {
                saveButton.disabled = true;
            }
        }
    }

    //Determines whether the clear button should become enabled
    handleClearDisabled() {
        let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
        let building = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;
        let clearButton = document.getElementById("searchClear") as HTMLButtonElement;

        if (fteID != null && building != null && supportType != null && clearButton != null) {
            if (fteID.value !== "" || building.selectedIndex > 0 || supportType.selectedIndex > 0) {
                clearButton.disabled = false;
            } else {
                clearButton.disabled = true;
            }
        }
    }

    //Exports FTE Transfer of Entity and support data to excel.
    exportToExcel() {
        let that = this;

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportExcel/EducationProgramFTETransferOfEntityDataExport?planFK=${that.planFK}`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = "EducationProgramFTETransferOfEntity.xlsx";

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var a = <HTMLAnchorElement>document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                } else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            }
            xhr.send();
        });
    }

    //Save method that returns a promise. If ftePK is passed, only the specified FTE will be saved. Otherwise, all FTE on page will be saved (if there are changes)
    static promiseSave(ftePK: number = null, forceSave: boolean = false) {
        //let that = this;
        let core = this._staticCore;

        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData: IEducationProgramTransferOfEntitySave[] = [];

            let fteToSave: NodeListOf<HTMLElement>;
            if (ftePK !== null) {
                fteToSave = document.querySelectorAll(`.fteContainer[data-ftetransferofentity-pk='${ftePK}']`) as NodeListOf<HTMLElement>;
            } else {
                //Get all the FTE on the page
                fteToSave = document.querySelectorAll(".fteContainer") as NodeListOf<HTMLElement>;
            }
            for (let fte of fteToSave) {
                let fteSupportsData: IEducationProgramFTESupportSave[] = [];

                if ("ftetransferofentityPk" in fte.dataset && this.fteHasChanged(parseInt(fte.dataset.ftetransferofentityPk))) {
                    shouldSave = true;
                    let ftePK = fte.dataset.ftetransferofentityPk;

                    //Get FTEId
                    let educationProgramFTEId = null;
                    let fteID = fte.querySelector(`.fteID[data-ftetransferofentity-pk='${ftePK}']`) as HTMLInputElement;
                    if (fteID != null && fteID.value != "") {
                        educationProgramFTEId = fteID.value;
                    }

                    //Get Classroom Location Lookup
                    let classroomLocationLookupCodeFK = null;
                    let classroomLocationFTE = fte.querySelector(`.classroomLocationFTE[data-ftetransferofentity-pk='${ftePK}']`) as HTMLSelectElement;
                    if (classroomLocationFTE != null && classroomLocationFTE.value != "") {
                        classroomLocationLookupCodeFK = parseInt(classroomLocationFTE.value);
                    }

                    //Get Full Time Lookup
                    let fullTimeLookupCodeFK = null;
                    let fullTimeFTE = fte.querySelector(`.fullTime[data-ftetransferofentity-pk='${ftePK}']`) as HTMLSelectElement;
                    if (fullTimeFTE != null && fullTimeFTE.value != "") {
                        fullTimeLookupCodeFK = parseInt(fullTimeFTE.value);
                    }

                    //Get March 31 question
                    let march31LookupCodeFK = null;
                    const march31FTE = fte.querySelector(`.afterMarch[data-ftetransferofentity-pk='${ftePK}']:checked`) as HTMLInputElement;
                    if (march31FTE != null && march31FTE.value != "") {
                        march31LookupCodeFK = parseInt(march31FTE.value);
                    }

                    //Get Alerted IU question
                    let alertedIULookupCodeFK = null;
                    const alertedIUFTE = fte.querySelector(`.alertedIU[data-ftetransferofentity-pk='${ftePK}']:checked`) as HTMLInputElement;
                    if (alertedIUFTE != null && alertedIUFTE.value != "") {
                        alertedIULookupCodeFK = parseInt(alertedIUFTE.value);
                    }

                    //Implementation Date
                    let implementationDate = null;
                    const implementationDateFTE = fte.querySelector(`.implementationDate[data-ftetransferofentity-pk='${ftePK}']`) as HTMLInputElement;
                    if (implementationDateFTE != null && implementationDateFTE.value != "") {
                        implementationDate = implementationDateFTE.value;
                    }

                    //Get supports
                    let supports = [];
                    let supportsForFTE = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${ftePK}']`);
                    for (let support of supportsForFTE) {
                        let supportElement = <HTMLElement>support;

                        if ("supportPk" in supportElement.dataset) {

                            //buildingName
                            let frcppInstitutionFK = null;
                            let buildingName = supportElement.querySelector(`.buildingName[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (buildingName != null && buildingName.value != "") {
                                frcppInstitutionFK = parseInt(buildingName.value);
                            }
                            //supportTypeGroup
                            let supportTypeGroupCodeFK = null;
                            let supportTypeGroup = supportElement.querySelector(`.supportTypeGroup[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (supportTypeGroup != null && supportTypeGroup.value != "") {
                                supportTypeGroupCodeFK = parseInt(supportTypeGroup.value);
                            }
                            //supportType
                            let supportTypeCodeFK = null;
                            let supportType = supportElement.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (supportType != null && supportType.value != "") {
                                supportTypeCodeFK = parseInt(supportType.value);
                            }
                            //supportLevel
                            let supportLevelCodeFK = null;
                            let supportLevel = supportElement.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (supportLevel != null && supportLevel.value != "") {
                                supportLevelCodeFK = parseInt(supportLevel.value);
                            }
                            //caseLoad
                            let caseLoadCount = null;
                            let caseLoad = supportElement.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (caseLoad != null && caseLoad.value != "") {
                                caseLoadCount = parseInt(caseLoad.value);
                            }
                            //identifyclassroom
                            let classroomLookupCodeFK = null;
                            let identifyclassroom = supportElement.querySelector(`.identifyClassroom[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (identifyclassroom != null && identifyclassroom.value != "") {
                                classroomLookupCodeFK = parseInt(identifyclassroom.value);
                            }
                            //classroomLocationSupport
                            let supportClassroomLocationLookupCodeFK = null;
                            let classroomLocationSupport = supportElement.querySelector(`.classroomLocationSupport[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (classroomLocationSupport != null && classroomLocationSupport.value != "") {
                                supportClassroomLocationLookupCodeFK = parseInt(classroomLocationSupport.value);
                            }
                            //ageRangeFrom
                            let ageRangeFromNbr = null;
                            let ageRangeFrom = supportElement.querySelector(`.ageRangeFrom[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (ageRangeFrom != null && ageRangeFrom.value != "") {
                                ageRangeFromNbr = parseInt(ageRangeFrom.value);
                            }
                            //ageRangeEnd
                            let ageRangeToNbr = null;
                            let ageRangeEnd = supportElement.querySelector(`.ageRangeEnd[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (ageRangeEnd != null && ageRangeEnd.value != "") {
                                ageRangeToNbr = parseInt(ageRangeEnd.value);
                            }
                            //ageRangeJustification
                            let ageRangeJustificationText = "";
                            let ageRangeJustification = supportElement.querySelector(`.ageRangeJustification[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (ageRangeJustification != null && ageRangeJustification.value != "") {
                                ageRangeJustificationText = ageRangeJustification.value;
                            }

                            let fteSupportSaveData: IEducationProgramFTETransferOfEntitySupportSave = {
                                AgeRangeFromNbr: ageRangeFromNbr,
                                AgeRangeJustification: ageRangeJustificationText,
                                AgeRangeToNbr: ageRangeToNbr,
                                CaseLoadCount: caseLoadCount,
                                ClassroomLocationLookupCodeFK: supportClassroomLocationLookupCodeFK,
                                ClassroomLookupCodeFK: classroomLookupCodeFK,
                                EducationProgramFTETransferOfEntityFK: parseInt(ftePK),
                                EducationProgramFTETransferOfEntitySupportPK: parseInt(supportElement.dataset.supportPk),
                                FRCPPInstitutionFK: frcppInstitutionFK,
                                SupportLevelCodeFK: supportLevelCodeFK,
                                SupportTypeCodeFK: supportTypeCodeFK,
                                UISelectedSupportTypeGroupCodeFK: supportTypeGroupCodeFK
                            }

                            supports.push(fteSupportSaveData);
                        }
                    }

                    const fteSaveData: IEducationProgramFTETransferOfEntitySave = {
                        ClassroomLocationLookupCodeFK: classroomLocationLookupCodeFK,
                        FullTimeLookupCodeFK: fullTimeLookupCodeFK,
                        FTEIdentifierName: educationProgramFTEId,
                        EducationProgramFTETransferOfEntityPK: parseInt(ftePK),
                        PlanFK: this._staticPlanFK,
                        TransferAfterMarchLookupCodeFK: march31LookupCodeFK,
                        AlertedTheIULookupCodeFK: alertedIULookupCodeFK,
                        ImplementationDate: implementationDate
                    }

                    const thisFTESaveData: IEducationProgramTransferOfEntitySave = {
                        FTE: fteSaveData,
                        Supports: supports
                    };

                    saveData.push(thisFTESaveData);
                }
            }

            if (shouldSave || forceSave) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Base/SaveEducationProgramFTETransferOfEntity', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = async function () {
                    if (xhr.status === 200) {
                        if (ftePK != null) {
                            const json = JSON.parse(xhr.responseText);
                            const validData = json.validData;
                            SpecialEducationFTETransferOfEntity.fteValidation(ftePK);
                            const elementButton = <HTMLButtonElement>document.querySelector(`.lazyAccordionTrigger[data-educationprogramftetransferofentityfk='${ftePK}']`);
                            elementButton.dataset.validdata = validData;

                            await SpecialEducationFTETransferOfEntity.getValidProgramCount();
                            SpecialEducationFTETransferOfEntity.fullPageValidation();

                            resolve("Successfully saved FTE");
                        } else {
                            await SpecialEducationFTETransferOfEntity.getValidProgramCount();
                            SpecialEducationFTETransferOfEntity.fullPageValidation();
                            resolve("Successfully saved");
                        }
                    } else {
                        reject("There was an unexpected error saving");
                    }
                };
                xhr.send(JSON.stringify({
                    "Data": saveData,
                    "PlanFK": this._staticPlanFK,
                    "AllowExceedsFTE": this._staticAllowExceedsFTE
                }));
            } else {
                resolve("Nothing To Save");
            }
        });
    }

    //Determines whether an FTE has changed by comparing the current hash value to a prior hash value
    static fteHasChanged(ftePK: number) {
        let changed = false;
        if (SpecialEducationFTETransferOfEntity.fteHashes[ftePK] !== undefined) {
            let newHash = this.calculateFTEHash(ftePK);
            if (newHash !== this.fteHashes[ftePK]) {
                changed = true;
            }
        } else {
            //Be on the safe side and say it has changed
            changed = true;
            //And initialize the hashes again.
            this.initializeFTEHashes();
        }

        return changed;
    }

    //Calculates a hash value for each FTE on the page and is stored in an object as a property of this class.
    static initializeFTEHashes() {
        this.fteHashes = {};

        let fteContainers = document.getElementsByClassName("lazy-accordion-panel") as HTMLCollectionOf<HTMLElement>;
        for (let fte of fteContainers) {
            let ftePK = parseInt(fte.dataset.educationprogramftetransferofentityfk);
            let hash = this.calculateFTEHash(ftePK);
            this.fteHashes[ftePK] = hash;
        }
    }

    //Calculates a hash for an FTE. This is used to determine whether the FTe has changed for saving purposes.
    static calculateFTEHash(ftePK: number) {
        let that = this;
        let hash = "0";
        let value;
        let newHash;

        let allElements = document.querySelectorAll(`[data-hashable][data-ftetransferofentity-pk='${ftePK}']`);

        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                let inputElement = <HTMLInputElement>element;

                if (inputElement.type === "radio") {
                    if (inputElement.checked) {
                        value = inputElement.value;
                        newHash = that._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                } else {
                    if (inputElement.value === "") {
                        value = 0
                        hash = hash + value;
                    }
                    else if (inputElement.value !== "") {
                        value = inputElement.value;
                        newHash = that._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
            } else if (element instanceof HTMLSelectElement) {
                let selectElement = <HTMLSelectElement>element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value
                    newHash = that._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            } else if (element instanceof HTMLTextAreaElement) {
                let textAreaElement = <HTMLTextAreaElement>element;

                if (textAreaElement.value === "") {
                    value = 0
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = that._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            }
        }

        return hash;
    }

    static fteValidation(educationProgramFTEFK: number) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);

        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    } else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }

        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = <HTMLSelectElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    } else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }

        this.updateFTEPercentage(educationProgramFTEFK);
    }

    static updateFTEPercentage(ftePK: number) {
        let that = this;

        return new Promise<void>((resolve, reject) => {

            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];

            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-ftetransferofentity-pk='${ftePK}']`) as HTMLSelectElement;
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }

            let supports = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = <HTMLElement>support
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;

                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;

                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }

                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }

                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }

                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CalculateFTETransferOfEntityDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;

                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-ftetransferofentity-pk='${ftePK}']`) as HTMLElement;
                    if (overOneMessage != null) {

                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }

                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        } else {
                            overOneMessage.classList.add("hide");
                        }
                    }

                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`) as HTMLInputElement;
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        } else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }

                    resolve();
                } else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }

    static updateHash(educationProgramFTEFK: number) {
        const hash = SpecialEducationFTETransferOfEntity.calculateFTEHash(educationProgramFTEFK);
        SpecialEducationFTETransferOfEntity.fteHashes[educationProgramFTEFK] = hash;
    }

    static initializeLoadedFTE(educationProgramFTEFK: number) {
        this.updateHash(educationProgramFTEFK);

        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftetransferofentityfk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftetansferofentityfk='${educationProgramFTEFK}'] select`);

        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }

        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = <HTMLSelectElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }

        this.updateFTEPercentage(educationProgramFTEFK);
    }

    static fullPageValidation(fromPageLoad: boolean = false) {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = <HTMLInputElement>document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownInvalidAccordions = document.querySelectorAll(".lazyAccordionTrigger[data-validdata='false']");
        const totalErrorsNotShown = totalErrors - shownInvalidAccordions.length;
        let errorCount: number = 0;
        for (const accordion of allAccordions) {
            const accElement = <HTMLButtonElement>accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;

            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            } else {
                accElement.classList.remove("error");
            }
        }

        const messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        const validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        if (!fromPageLoad || errorCount > 0 || totalErrorsNotShown > 0) {
            messageContainerColumn.classList.add("show");
        }

        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");

        const message = <HTMLDivElement>document.getElementById("validationMessage");

        if (errorCount > 0) {
            let word = "are";
            let facility = "FTEs"
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "FTE";
                error = "error";
            }

            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = '';
            if (!fromPageLoad) {
                message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;
            }

            let facilityWord = "are";
            let facilityFacility = "FTEs";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "FTE";
            }

            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;

            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            if (message !== null) {
                message.innerHTML = "The page has been successfully saved."
                message.classList.add("show");
            }
        }
    }

    static clearValidationMessage() {
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }

    static async getValidProgramCount() {

        return new Promise(async (resolve, reject) => {

            const planForm = <HTMLDivElement>document.getElementById("specialEducationEducationProgramFTETransferOfEntityForm");
            const planFK = planForm.dataset.planfk;

            const response = await fetch(`/SpecialEducation/GetValidProgramFTETransferOfEntityCountAsync/${planFK}`, { credentials: 'include' })
            if (response.ok) {
                const value = await response.text();
                const field = <HTMLInputElement>document.getElementById("validCount");
                field.value = value;
                resolve("Valid program count updated");
            } else {
                reject("Error updating program count");
            }
        });
    }

    static createSEPRN(fteTransferOfEntityPK: number) {
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        let proposedActionValue = "";
        let additionalInformationValue = "";

        let proposedActionElement = document.querySelector(`.proposedAction[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`) as HTMLSelectElement;
        if (proposedActionElement != null) {
            proposedActionValue = proposedActionElement.value;
        }

        let additionalInformationElement = document.querySelector(`.additionalInformation[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`) as HTMLTextAreaElement;
        if (additionalInformationElement != null) {
            additionalInformationValue = additionalInformationElement.value;
        }

        if (proposedActionElement.value != "") {
            const selected = proposedActionElement[proposedActionElement.selectedIndex] as HTMLOptionElement;

            if ((selected.dataset.lookupCode == "proposedActionDelete" || selected.dataset.lookupCode == "proposedActionChange") && additionalInformationValue == "") {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'Please specify a reason/explanation for this Transfer of Entity', 'error', 3000, null);
            }
            else if (selected.dataset.lookupCode == "proposedActionNew" && !this.validateElements(fteTransferOfEntityPK, true)) {
                Core.hideLoader();
            } else {
                fetch('/SpecialEducation/CreateTransferOfEntitySEPRN', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        FTETransferOfEntityFK: fteTransferOfEntityPK,
                        ProposedAction: proposedActionValue,
                        AdditionalInformation: additionalInformationValue
                    })
                })
                .then((response) => {
                    if (response.ok) {
                        return this.promiseSave(fteTransferOfEntityPK, false);
                    } else {
                        throw new Error('Unsuccesfully created this Transfer of Entity');
                    }
                })
                .then((response) => {
                    Core.createHTMLAlert('alertMessageDiv', 'Successfully submitted this Transfer of Entity', 'success', 3000, window.location.reload());
                    Core.hideLoader();
                })
                .catch((error) => {
                    Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue submitting this Transfer of Entity', 'error', 3000, null);
                    Core.hideLoader();
                });
            }
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'Please select a proposed action for the SEPRN', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    static checkAgeRange(supportFK: number) {
        let makeRequired = false;

        const classroomLocation = document.querySelector(`.classroomLocationSupport[data-support-pk='${supportFK}']`) as HTMLSelectElement;
        const ageRangeFrom = document.querySelector(`.ageRangeFrom[data-support-pk='${supportFK}']`) as HTMLInputElement;
        const ageRangeTo = document.querySelector(`.ageRangeEnd[data-support-pk='${supportFK}']`) as HTMLInputElement;
        if (classroomLocation != null && ageRangeFrom != null && ageRangeTo != null) {
            if (ageRangeFrom.value != "" && ageRangeTo.value != "" && parseInt(ageRangeFrom.value) && parseInt(ageRangeTo.value)) {

                const range = parseInt(ageRangeTo.value) - parseInt(ageRangeFrom.value);

                const classroomLocationLookupCode = classroomLocation.options[classroomLocation.selectedIndex].dataset.lookupCode;

                switch (classroomLocationLookupCode) {
                    case 'specialeducationlocationelementary':
                        if (range > 3) {
                            makeRequired = true;
                        }
                        break;
                    case 'specialeducationlocationsecondary':
                        if (range > 4) {
                            makeRequired = true;
                        }
                        break;
                    case 'specialeducationlocationmultiple':
                        if (range > 3) {
                            makeRequired = true;
                        }
                        break;
                }
            }
        }

        return makeRequired;
    }

    static validateElements(fteTransferOfEntityPK: number = null, showAlerts: boolean) {
        let hasErrors = false;
        let hasSupportErrors = false;
        
        const fullTime = <HTMLSelectElement>document.querySelector(`.fullTime[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (fullTime !== null) {
            if (fullTime.value === "") {
                hasErrors = true;
                if (showAlerts) {
                    Core.createHTMLAlert('alertMessageDiv', 'Please select a Full or Part Time identifier', 'error', 3000, null);
                }
            }
        }

        const classroomLocationFTE = <HTMLSelectElement>document.querySelector(`.classroomLocationFTE[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (classroomLocationFTE !== null) {
            if (classroomLocationFTE.value === "") {
                hasErrors = true;
                if (showAlerts) {
                    Core.createHTMLAlert('alertMessageDiv', 'Please select a Classroom Location', 'error', 3000, null);
                }
            }
        }

        const fteID = <HTMLInputElement>document.querySelector(`.fteID[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (fteID !== null) {
            if (fteID.value === "") {
                hasErrors = true;
                if (showAlerts) {
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter a value for the FTE ID', 'error', 3000, null);
                }
            }
        }

        const afterMarch = <HTMLInputElement>document.querySelector(`.afterMarch[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']:checked`);
        if (afterMarch !== null && afterMarch.value === "") {
            hasErrors = true;
            if (showAlerts) {
                Core.createHTMLAlert('alertMessageDiv', 'Please select an option for the question "Is the transfer of entity after March 31?"', 'error', 3000, null);
            }
        }

        const alertedIU = <HTMLInputElement>document.querySelector(`.alertedIU[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']:checked`);
        if (alertedIU !== null && alertedIU.value === "") {
            hasErrors = true;
            if (showAlerts) {
                Core.createHTMLAlert('alertMessageDiv', 'Please select an option for the question "Have you alerted the IU?"', 'error', 3000, null);
            }
        }

        const implementationDate = <HTMLInputElement>document.querySelector(`.implementationDate[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (implementationDate !== null) {
            if (implementationDate.value === "") {
                hasErrors = true;
                if (showAlerts) {
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter a date for the Implementation Date', 'error', 3000, null);
                }
            }
        }

        const allSupports = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        for (const thisSupport of allSupports) {
            const supportElement = <HTMLDivElement>thisSupport;
            const supportPK = supportElement.dataset.supportPk;

            const buildingName = <HTMLSelectElement>document.querySelector(`.buildingName[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (buildingName !== null) {
                if (buildingName.value === "") {
                    hasSupportErrors = true;
                }
            }

            let supportTypeGroupCode = "";
            const supportTypeGroup = <HTMLSelectElement>document.querySelector(`.supportTypeGroup[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (supportTypeGroup !== null) {
                if (supportTypeGroup.value === "") {
                    hasSupportErrors = true;
                    supportTypeGroupCode = supportTypeGroup.dataset.lookupcode;
                }
            }

            if (supportTypeGroupCode === "lifeskillssupport") {
                const supportType = <HTMLSelectElement>document.querySelector(`.supportType[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
                if (supportType !== null) {
                    if (supportType.value === "") {
                        hasSupportErrors = true;

                    }
                }
            }

            const supportLevel = <HTMLSelectElement>document.querySelector(`.supportLevel[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (supportLevel !== null) {
                if (supportLevel.value === "") {
                    hasSupportErrors = true;
                }
            }

            const caseLoad = <HTMLInputElement>document.querySelector(`.caseLoad[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (caseLoad !== null) {
                if (caseLoad.value === "") {
                    hasSupportErrors = true;
                }
            }

            const identifyClassroom = <HTMLSelectElement>document.querySelector(`.identifyClassroom[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (identifyClassroom !== null) {
                if (identifyClassroom.value === "") {
                    hasSupportErrors = true;
                }
            }

            const ageRangeFrom = <HTMLInputElement>document.querySelector(`.ageRangeFrom[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (ageRangeFrom !== null) {
                if (ageRangeFrom.value === "") {
                    hasSupportErrors = true;
                }
            }

            const ageRangeEnd = <HTMLInputElement>document.querySelector(`.buildingName[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (ageRangeEnd !== null) {
                if (ageRangeEnd.value === "") {
                    hasSupportErrors = true;
                }
            }

            if (this.checkAgeRange(parseInt(supportPK))) {
                const ageRangeJustification = <HTMLInputElement>document.querySelector(`.ageRangeJustification[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
                if (ageRangeJustification !== null) {
                    if (ageRangeJustification.value === "") {
                        hasSupportErrors = true;
                    }
                }
            }

        }

        if (hasSupportErrors && showAlerts) {
            Core.createHTMLAlert('alertMessageDiv', 'One or more supports have errors. Please correct them before proceeding.', 'error', 3000, null);
        }
        
        return !(hasErrors || hasSupportErrors);
    }
}

// Special Education Special Education Support Services
class SpecialEducationSpecialEducationSupportServices {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationSpecialEducationSupportServicesField", "specialEducationSpecialEducationSupportServicesSelectField"];

        let specialEducationSpecialEducationSupportServicesSaveButton = document.getElementById("specialEducationSpecialEducationSupportServicesSave");
        if (specialEducationSpecialEducationSupportServicesSaveButton !== null)
            specialEducationSpecialEducationSupportServicesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let steeringCommitteeAddRows = document.getElementById("specialEducationSupportServicesAddRows");
        if (steeringCommitteeAddRows !== null)
            steeringCommitteeAddRows.addEventListener("click", (e: Event) => this.supportServicesAddRows(e));

        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let deleteSteeringCommitteeConfirmButton = document.getElementById("specialEducationDeleteSupportServicesDeleteConfirm");
        if (deleteSteeringCommitteeConfirmButton !== null)
            deleteSteeringCommitteeConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteSteeringCommitteeCancelButton = document.getElementById("specialEducationDeleteSupportServicesDeleteCancel");
        if (deleteSteeringCommitteeCancelButton !== null)
            deleteSteeringCommitteeCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        this.bindDeleteRowButtons();

        let wordPluralizer = document.getElementById("specialEducationSupportServicesAddRowsNumber");
        if (wordPluralizer !== null)
            wordPluralizer.addEventListener("change", (e: Event) => this.checkForPlural());

        const supportServicesExportToExcelButton = document.getElementById("specialEducationSupportServicesExportToExcel");
        if (supportServicesExportToExcelButton !== null) {
            supportServicesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationSpecialEducationSupportServicesFormElement = <HTMLDivElement>document.getElementById("specialEducationSpecialEducationSupportServicesForm");
        let inputs = document.getElementsByClassName("specialEducationSpecialEducationSupportServicesField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationSpecialEducationSupportServicesFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selects = document.getElementsByClassName("specialEducationSpecialEducationSupportServicesSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationSpecialEducationSupportServicesFormElement.dataset.planfk);

            let element = <HTMLSelectElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSpecialEducationSupportServices', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    supportServicesAddRows(e: Event) {
        let target = <HTMLButtonElement>e.target;
        let core = this._core;
        let value = (<HTMLInputElement>document.getElementById("specialEducationSupportServicesAddRowsNumber"));
        let _that = this;
        let planFK = target.dataset.planfk;

        if (value.value === "") {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        } else {
            let rows = parseInt(value.value);
            let row = 0;
            let table = (<HTMLTableElement>document.getElementById("supportServices"));
            let trs = table.rows;
            for (let tr of trs) {
                if (parseInt(tr.getAttribute("data-row")) > row)
                    row = parseInt(tr.getAttribute("data-row"));
            }

            row++;

            for (let i = 0; i < rows; i++) {

                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/SupportServicesRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        Core.hideLoader();
                        $("#supportServices tbody").append(xhr.responseText);
                        value.value = "";

                        let rows = document.getElementsByClassName("removeSupportServicesRow");
                        let row = rows[rows.length - 1];

                        _that.bindDeleteRowButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Support Service Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send(`row=${row}&planFK=${planFK}`);
                row++;
            }
        }
    }

    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSupportServicesRow");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("specialEducationDeleteSupportServicesRow", null);
        modal.addAttributeToElement("specialEducationDeleteSupportServicesRow", "#specialEducationDeleteSupportServicesDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("specialEducationDeleteSupportServicesRow", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("specialEducationSpecialEducationSupportServicesForm");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        Core.showLoader();

        let committeeFields = document.getElementsByClassName("specialEducationSpecialEducationSupportServicesField");
        for (let comm of committeeFields) {
            let inputElement = <HTMLInputElement>comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-1")
                    allBlank = false;
            }
        }

        let committeeSelectFields = document.getElementsByClassName("specialEducationSpecialEducationSupportServicesSelectField");
        for (let comm of committeeSelectFields) {
            let inputElement = <HTMLSelectElement>comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-1")
                    allBlank = false;
            }
        }

        if (allBlank) {
            let allTheRows = document.getElementsByClassName("supportServicesRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = <HTMLDivElement>thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);

                    let modal: Modal = new Modal("specialEducationDeleteSupportServicesRow", null);
                    modal.hide();

                    Core.createHTMLAlert("alertMessageDiv", "The support services row has been removed.", 'success', 3000, null);
                }
            }
            Core.hideLoader();
        } else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteSupportServicesRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let allTheRows = document.getElementsByClassName("supportServicesRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = <HTMLDivElement>thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }

                    let modal: Modal = new Modal("specialEducationDeleteSupportServicesRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The support services row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("specialEducationSupportServicesAddRowsNumber")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }

    initializeRequiredFieldsCustom() {

        //If any field in a row is filled out, force rest of row required (except email)
        let steeringCommitteeTable = document.getElementById("supportServices");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        for (let row of steeringCommitteeTableRows) {
            let elements = row.getElementsByClassName("specialEducationSpecialEducationSupportServicesTable") as HTMLCollectionOf<HTMLElement>;

            for (let element of elements) {
                if (element instanceof HTMLSelectElement) {
                    if (element.selectedIndex > 0) {
                        for (let otherElement of elements) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                } else if (element instanceof HTMLInputElement) {
                    if (element.value !== "") {
                        for (let otherElement of elements) {
                            otherElement.dataset.forcerequired = "true";                            
                        }
                        break;
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationSpecialEducationSupportServicesForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationSupportServicesExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationSupportServices.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}

// Special Education Special Education Personnel Development
class SpecialEducationSpecialEducationPersonnelDevelopment {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationPersonnelDevelopmentField", "specialEducationPersonnelDevelopmentMultiSelectField"];

        let specialEducationSpecialEducationPersonnelDevelopmentSaveButton = document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentSave");
        if (specialEducationSpecialEducationPersonnelDevelopmentSaveButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton = document.getElementById("specialEducationPersonnelDevelopmentAddTrainingTopic");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton.addEventListener("click", (e: Event) => this.addTrainingTopic());

        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton = document.getElementById("specialEducationAddTrainingTopicNameCreate");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton.addEventListener("click", (e: Event) => this.addTrainingTopicCreate());

        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton = document.getElementById("specialEducationAddTrainingTopicNameCancel");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton.addEventListener("click", (e: Event) => this.addTrainingTopicCancel());       

        this.bindAddTrainingButtons();

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        this.bindDeleteTraining();

        const deleteTrainingConfirm = document.getElementById("deleteTrainingConfirm");
        if(deleteTrainingConfirm !== null)
            deleteTrainingConfirm.addEventListener("click", (e: Event) => this.deleteTrainingConfirm(e));

        const deleteTrainingCancel = document.getElementById("deleteTrainingCancel");
        if (deleteTrainingCancel !== null)
            deleteTrainingCancel.addEventListener("click", (e: Event) => this.deleteTrainingCancel());

        this.bindDeleteTrainingTopic();

        const deleteTrainingTopicConfirm = document.getElementById("deleteTrainingTopicConfirm");
        if (deleteTrainingTopicConfirm !== null)
            deleteTrainingTopicConfirm.addEventListener("click", (e: Event) => this.deleteTrainingTopicConfirm(e));

        const deleteTrainingTopicCancel = document.getElementById("deleteTrainingTopicCancel");
        if (deleteTrainingTopicCancel !== null)
            deleteTrainingTopicCancel.addEventListener("click", (e: Event) => this.deleteTrainingTopicCancel());

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let saveButton = <HTMLButtonElement>document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentSave");
        saveButton.disabled = true;
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationSpecialEducationPersonnelDevelopmentFormElement = <HTMLDivElement>document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentForm");
        let inputs = document.getElementsByClassName("specialEducationPersonnelDevelopmentField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            let dataPointPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;

            planPK = parseInt(specialEducationSpecialEducationPersonnelDevelopmentFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            let dataPointPlanPropertyTextValue = element.dataset.datapointplanpropertytextvalue;
            let dataPointRowNumber = 0;

            if (element.dataset.datapointpropertypk !== "" && element.dataset.datapointpropertypk !== "0")
                dataPointPropertyPK = parseInt(element.dataset.datapointpropertypk);

            if (element.dataset.datapointplanpropertypk !== "" && element.dataset.datapointplanpropertypk !== "0")
                dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);

            if (element.dataset.datapointrownumber !== "" && element.dataset.datapointrownumber !== "0")
                dataPointRowNumber = parseInt(element.dataset.datapointrownumber);


            if (element.value !== "" || hadValue) {
                let saveItem: IPersonnelDevelopmentSaveData = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPropertyPK: dataPointPropertyPK,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    DataPointPlanPropertyTextValue: dataPointPlanPropertyTextValue,
                    DataPointRowNumber: dataPointRowNumber
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("specialEducationPersonnelDevelopmentMultiSelectCheckField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                let dataPointPropertyPK = 0;
                let dataPointPlanPropertyPK = 0;

                let dataPointPlanPropertyTextValue = element.dataset.datapointplanpropertytextvalue;
                let dataPointRowNumber = 0;

                if (element.dataset.datapointpropertypk !== "" && element.dataset.datapointpropertypk !== "0")
                    dataPointPropertyPK = parseInt(element.dataset.datapointpropertypk);

                if (element.dataset.datapointplanpropertypk !== "" && element.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);

                if (element.dataset.datapointrownumber !== "" && element.dataset.datapointrownumber !== "0")
                    dataPointRowNumber = parseInt(element.dataset.datapointrownumber);

                if (element.checked || hadValue) {
                    let val = "";
                    if (element.checked)
                        val = "on";
                    else
                        val = "off";
                    let saveItem: IPersonnelDevelopmentSaveData = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: val,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPropertyPK: dataPointPropertyPK,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        DataPointPlanPropertyTextValue: dataPointPlanPropertyTextValue,
                        DataPointRowNumber: dataPointRowNumber
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSpecialEducationPersonnelDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    saveButton.disabled = false;
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                    saveButton.disabled = false;
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    saveButton.disabled = false;
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                saveButton.disabled = false;
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
                    saveButton.disabled = false;
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindAddTrainingButtons() {
        let trainingButtons = document.getElementsByClassName("specialEducationPersonnelDevelopmentAddTraining");
        for (let trainingButton of trainingButtons) {
            let element = <HTMLButtonElement>trainingButton;
            if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.addTraining(e))
                element.addEventListener("click", (e: Event) => this.addTraining(e));
                element.dataset.listener = "true";
            }
        }
    }

    addTraining(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let propertyCode = button.dataset.propertycode;
        let planPropertyPK = button.dataset.datapointplanpropertypk;
        let newRow = 0;
        let dataPointRowNumber = 0;
        let newTopicPlanPropertyPK = 0;
        const accordionPropertyFK = button.dataset.accordionpropertyfk;

        let personnelRows = document.querySelectorAll(`.personnel-development-row[data-propertyCode='${propertyCode}'] .specialEducationPersonnelDevelopmentField`);

        if (propertyCode.indexOf("Other") > 0) {
            if (button.dataset.datapointrownumber !== "" && button.dataset.datapointrownumber !== "0") {
                dataPointRowNumber = parseInt(button.dataset.datapointrownumber);
            }
            personnelRows = document.querySelectorAll(`.personnel-development-row[data-propertycode='${propertyCode}'] .specialEducationPersonnelDevelopmentField[data-datapointrownumber='${dataPointRowNumber}']`);
        }

        if (button.dataset.datapointrownumber !== "" && button.dataset.datapointrownumber !== "0")
            dataPointRowNumber = parseInt(button.dataset.datapointrownumber);
        if (button.dataset.datapointplanpropertypk !== "" && button.dataset.datapointplanpropertypk !== "0")
            newTopicPlanPropertyPK = parseInt(button.dataset.datapointplanpropertypk);

        for (let thisRow of personnelRows) {
            let thisRowElement = <HTMLInputElement>thisRow;
            if (newRow < parseInt(thisRowElement.dataset.row))
                newRow = parseInt(thisRowElement.dataset.row);
        }

        newRow++;

        const that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentTraining', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`.personnelDevelopmentTraining[data-propertycode='${propertyCode}']`);

                if (propertyCode.indexOf("Other") > 0)
                    element = <HTMLDivElement>document.querySelector(`.personnelDevelopmentTraining[data-propertycode='${propertyCode}'][data-datapointplanpropertypk='${planPropertyPK}']`);

                var div = document.createElement("div");
                div.classList.add("personnel-development-row");
                div.dataset.propertycode = propertyCode;
                div.innerHTML = xhr.responseText;
                element.appendChild(div);

                $('.multiSelect').MultiSelect();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCode=${propertyCode}&newTopicPlanPropertyPK=${newTopicPlanPropertyPK}&OtherRowNumber=${dataPointRowNumber}&AccordionPropertyFK=${accordionPropertyFK}`);
    }

    addTrainingTopic() {
        let modal = new Modal("addATrainingModal", null);
        modal.show();
    }

    addTrainingTopicCancel() {
        let modal = new Modal("addATrainingModal", null);
        modal.hide();
    }

    addTrainingTopicCreate() {
        let newTopicNameElement = <HTMLInputElement>document.getElementById("specialEducationAddTrainingTopicName");
        let newTopicName = newTopicNameElement.value;
        let core = this._core;
        let planFK = 0;
        let specialEducationSpecialEducationPersonnelDevelopmentFormElement = <HTMLDivElement>document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentForm");
        planFK = parseInt(specialEducationSpecialEducationPersonnelDevelopmentFormElement.dataset.planfk);
        let that = this;
        let row = 0;
        let rowElements = document.querySelectorAll(".personnelDevelopmentTraining");
        
        for (let rowElement of rowElements) {
            let ele = <HTMLDivElement>rowElement;
            if (row < parseInt(ele.dataset.row))
                row = parseInt(ele.dataset.row);
        }

        row++;

        if (newTopicName !== "") {
            let trainingTopic = {
                "PlanFK": planFK,
                "TopicName": newTopicName,
                "NewRow": row.toString()
            };

            let specialEducationAddTrainingTopicErrorMessage = <HTMLDivElement>document.getElementById("specialEducationAddTrainingTopicErrorMessage");
            specialEducationAddTrainingTopicErrorMessage.classList.add("hide-message");

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentAddTrainingTopic', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let newId = `${newTopicName.replace(/\s/g, '')}customAccordion`;

                    let div = <HTMLDivElement>document.createElement("div");
                    div.innerHTML = xhr.responseText;
                    div.id = newId;

                    let trainingTopicsElement = <HTMLDivElement>document.getElementById("specialEducationTrainingTopics");
                    trainingTopicsElement.appendChild(div);

                    new CustomAccordion(newId);

                    let modalText = <HTMLInputElement>document.getElementById("specialEducationAddTrainingTopicName");
                    modalText.value = "";

                    $('.multiSelect').MultiSelect();

                    let modal = new Modal("addATrainingModal", null);
                    modal.hide();

                    Core.createHTMLAlert("alertMessageDiv", "Successfully added a new training topic", 'success', 3000, null);

                    that.bindAddTrainingButtons();
                    that.bindDeleteTrainingTopic();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(trainingTopic));
        } else {
            let specialEducationAddTrainingTopicErrorMessage = <HTMLDivElement>document.getElementById("specialEducationAddTrainingTopicErrorMessage");
            specialEducationAddTrainingTopicErrorMessage.classList.remove("hide-message");
        }
    }

    initializeRequiredFieldsCustom(allClasses: string[], refresh: boolean = false) {

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        if (refresh) {
            let allElements = document.querySelectorAll(classesToValidate);

            for (let element of allElements) {
                let htmlElement = <HTMLElement>element;

                htmlElement.removeAttribute("aria-required");
                let label = Core.findLabelForInput(htmlElement);

                if (label !== null) {
                    label.classList.remove("isRequired");
                    let asterisk = label.querySelector(".required-label") as HTMLElement;
                    if (asterisk != null) {
                        asterisk.parentNode.removeChild(asterisk);
                    }
                }
            }
        }

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }

        const allTrainings = document.querySelectorAll(`.specialEducationPersonnelDevelopmentDescriptionOfTraining`);
        for (const training of allTrainings) {
            const ele = <HTMLInputElement>training;

            if (ele.value !== "") {
                const row = ele.dataset.row;

                const allElements = document.querySelectorAll(`.specialEducationPersonnelDevelopmentTrainingRecord[data-row='${row}']`);
                for (const innerElement of allElements) {
                    const thisEle = <HTMLElement>innerElement;

                    if (thisEle.classList.contains("multiSelect")) {
                        let multiselectCheckboxes = thisEle.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                        let hasSelection = false;

                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                hasSelection = true;
                                break;
                            }
                        }

                        if (!hasSelection) {
                            this._core.forceElementRequired(thisEle);
                        }

                    } else {
                        this._core.forceElementRequired(thisEle);
                    }
                }
            }
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = <HTMLInputElement>element;

                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");

                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated: boolean = false;
                                    let isChecked: boolean = false;

                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }

                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }

                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLSelectElement) {
                            let selectElement = <HTMLSelectElement>element;
                            if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = <HTMLTextAreaElement>element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            let hasSelection = false;

                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }

                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (htmlElement.classList.contains("multiSelect")) {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            let hasSelection = false;

                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }

                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    bindDeleteTraining() {
        const allDeletes = document.getElementsByClassName("deleteTraining");
        for (const thisDelete of allDeletes)
            thisDelete.addEventListener("click", (e: Event) => this.showDeleteTraining(e));
    }

    showDeleteTraining(e: Event) {
        const button = <HTMLButtonElement>e.target;

        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const dataPointRowNumber = button.dataset.datapointrownumber;
        const propertyCode = button.dataset.propertycode;

        const modal: Modal = new Modal("deleteTrainingModal", null);
        modal.addAttributeToElement("deleteTrainingModal", "#deleteTrainingConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteTrainingModal", "#deleteTrainingConfirm", "datapointrownumber", dataPointRowNumber);
        modal.addAttributeToElement("deleteTrainingModal", "#deleteTrainingConfirm", "propertycode", propertyCode);

        modal.show();
    }

    deleteTrainingCancel() {
        const modal: Modal = new Modal("deleteTrainingModal", null);
        modal.hide();
    }

    deleteTrainingConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        let dataPointRowNumber = button.dataset.datapointrownumber;
        const propertyCode = button.dataset.propertycode;
        let count = 0;

        let personnelRows = document.querySelectorAll(`.personnel-development-row[data-propertyCode='${propertyCode}']`);

        for (let thisRow of personnelRows) {
            count++;
        }

        if (count > 1) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteTraining', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let theRow = document.querySelector(`.personnel-development-row[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`);
                    if (theRow !== null)
                        theRow.remove();

                    const modal: Modal = new Modal("deleteTrainingModal", null);
                    modal.hide();

                    Core.createHTMLAlert("alertMessageDiv", "The training record has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify({
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                RowNbr: dataPointRowNumber
            }));
        }
        else {
            //show alert to let them know to just change the data
            alert("This is the last row.  Please change your data.");
        }
    }

    bindDeleteTrainingTopic() {
        const allDeletes = document.getElementsByClassName("deleteTrainingTopic");
        for (const thisDelete of allDeletes)
            thisDelete.addEventListener("click", (e: Event) => this.showDeleteTrainingTopic(e));
    }

    showDeleteTrainingTopic(e: Event) {
        const button = <HTMLButtonElement>e.target;

        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const dataPointRowNumber = button.dataset.datapointrownumber;

        const modal: Modal = new Modal("deleteTrainingTopicModal", null);
        modal.addAttributeToElement("deleteTrainingTopicModal", "#deleteTrainingTopicConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteTrainingTopicModal", "#deleteTrainingTopicConfirm", "datapointrownumber", dataPointRowNumber);

        modal.show();
    }

    deleteTrainingTopicCancel() {
        const modal: Modal = new Modal("deleteTrainingTopicModal", null);
        modal.hide();
    }

    deleteTrainingTopicConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const dataPointRowNumber = button.dataset.datapointrownumber;

        let planProperty = [];
        const allPlanPropertyElements = document.querySelectorAll(`input[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`) as NodeListOf<HTMLInputElement>;
        for (const element of allPlanPropertyElements) {
            if (element.dataset.planpropertypk !== null && element.dataset.planpropertypk !== "") {
                if (parseInt(element.dataset.planpropertypk) > 0) {
                    planProperty.push(element.dataset.planpropertypk);
                }
            }
        }

        if (planProperty.length === 0) {
            Core.hideLoader();

            const topRow = <HTMLDivElement>document.querySelector(`.personnelDevelopmentTraining[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const accordionButton = document.querySelector(`#personnelDevelopmentAccordionSpecialEducationPersonnelOther${dataPointPlanPropertyPK}`);
            accordionButton.parentElement.remove();

            topRow.parentElement.remove();

            const modal: Modal = new Modal("deleteTrainingTopicModal", null);
            modal.hide();
        } else {

            const deleteData = {
                "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                "AllPlanProperties": planProperty
            };

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteTrainingTopic', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    const topRow = <HTMLDivElement>document.querySelector(`.personnelDevelopmentTraining[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    const accordionButton = document.querySelector(`#personnelDevelopmentAccordionSpecialEducationPersonnelOther${dataPointPlanPropertyPK}`);
                    accordionButton.parentElement.remove();

                    topRow.parentElement.remove();

                    const modal: Modal = new Modal("deleteTrainingTopicModal", null);
                    modal.hide();

                    Core.createHTMLAlert("alertMessageDiv", "The training record has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(deleteData));
        }
    }
}

// Special Education Signatures & Affirmations
class SpecialEducationSignaturesAndAffirmations {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationSignaturesAndAffirmationsField", "specialEducationSignaturesAndAffirmationsCheckboxField", "specialEducationSignaturesAndAffirmationsFileField"];

        let specialEducationSignaturesAndAffirmationsFormSaveButton = document.getElementById("specialEducationSignaturesAndAffirmationsSave");
        if (specialEducationSignaturesAndAffirmationsFormSaveButton !== null)
            specialEducationSignaturesAndAffirmationsFormSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let fileUploader = document.getElementById("uploadMinutes");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
        }

        let deleteFileConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));

        let deleteFileCancelButton = <HTMLButtonElement>document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteButtons();

        const exports = document.getElementsByClassName("fullReport") as HTMLCollectionOf<HTMLLinkElement>;
        for (const exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLoader();

                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ExportDocX/FullReportSpecialEducationExport', true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = 'blob';
                xhr.onload = function () {

                    Core.hideLoader();

                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = "SpecialEducationReport_Full";
                        filename = filename + ".docx";

                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            var a = <HTMLAnchorElement>document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                }
                xhr.send(`planFK=${exportOption.dataset.planfk}`);
            });
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let specialEducationSignaturesAndAffirmationsFormElement = <HTMLDivElement>document.getElementById("specialEducationSignaturesAndAffirmationsForm");
        let inputs = document.getElementsByClassName("specialEducationSignaturesAndAffirmationsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationSignaturesAndAffirmationsFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("specialEducationSignatureAndAffirmationCheckField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "on",
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSignaturesAndAffirmations', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = <HTMLFormElement>document.getElementById("uploadMinutes");
        let formData = new FormData(uploadSampleForm);
        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("uploadMinutes");
                        formFile.value = "";
                        formFile.dataset.hasUploaded = "true";

                        that.bindDeleteButtons();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");

        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e: Event) => this.deleteFile(e));
    }

    deleteFile(e: Event) {
        let fileButton = <HTMLButtonElement>e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;

        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal: Modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = <HTMLButtonElement>document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }

    deleteFileConfirm(confirmButton: HTMLButtonElement) {
        let core = this._core;
        Core.showLoader();

        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);

                        let containerElement = <HTMLElement>document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}

// Special Education Summary Checklist & Submission
class SpecialEducationSummaryChecklistAndSubmission {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationSummaryChecklistAndSubmissionField"];
        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;

        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

//Common Validation functions (Class)
class ImprovementAndPlanningValidation {
    static doCustomValidation(checkboxFieldClass: string, textboxFieldClass: string) {
        let core: Core = new Core();
        let isChecked: boolean = false;

        let checkboxElements = document.getElementsByClassName(checkboxFieldClass);
        let checkboxElement = document.createElement("input");
        for (let checkbox of checkboxElements) {
            checkboxElement = <HTMLInputElement>checkbox;
        }

        if (checkboxElement.checked)
            isChecked = true;

        let uniqueFields = document.querySelectorAll(`.${textboxFieldClass}[data-percent='1.00']`);

        let rows = [];
        for (let field of uniqueFields) {
            let ele = <HTMLInputElement>field;

            let thisRow = ele.dataset.row;

            if (rows.indexOf(thisRow) < 0)
                rows.push(thisRow);
        }

        let isValid: boolean = false;
        let firstValid: boolean = false;
        let secondValid: boolean = false;

        for (let row of rows) {
            let first: boolean = true;
            firstValid = false;
            secondValid = false;

            let elementsInRow = uniqueFields.forEach(element => {
                if ((<HTMLInputElement>element).dataset.row === row) {
                    if (first) {
                        if ((<HTMLInputElement>element).value !== "")
                            firstValid = true;
                        first = false;
                    } else {
                        if ((<HTMLInputElement>element).value !== "")
                            secondValid = true;
                    }
                }

                if (firstValid && secondValid) {
                    isValid = true;
                    return;
                }
            });
        }

        let showTheMessage = isChecked || isValid;

        let validationClasses = [textboxFieldClass, checkboxFieldClass];

        core.clientSideValidation(validationClasses);

        let showMessage = !showTheMessage;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");

        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
}

// Special Education Monitoring of Personnel Development
class SpecialEducationMonitoringPersonnelDevelopment {
    validationClasses: string[];

    private _core: Core;
    _trainingCompletionToDeletePK: number;
    _trainingCompletionToDeleteButton: HTMLButtonElement;
    _deleteTrainingCompletionConfirmModal: Modal;
    constructor() {
        let that = this;
        this._core = new Core();

        this.validationClasses = ["specialEducationPersonnelDevelopmentField"];

        this._deleteTrainingCompletionConfirmModal = new Modal("deleteTrainingCompletionConfirmModal", null)

        let specialEducationSpecialEducationPersonnelDevelopmentSaveButton = document.getElementById("specialEducationMonitoringPersonnelDevelopmentSave");
        if (specialEducationSpecialEducationPersonnelDevelopmentSaveButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton = document.getElementById("specialEducationPersonnelDevelopmentAddTrainingTopic");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton.addEventListener("click", (e: Event) => this.addTrainingTopic());

        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton = document.getElementById("specialEducationAddTrainingTopicNameCreate");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton.addEventListener("click", (e: Event) => this.addTrainingTopicCreate());

        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton = document.getElementById("specialEducationAddTrainingTopicNameCancel");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton.addEventListener("click", (e: Event) => this.addTrainingTopicCancel());

        this.bindAddTrainingButtons();

        this.bindAddMonitoringButtons();

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        this.bindUploaders();

        this.bindDeleteFiles();

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        document.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;
            
            //Adding a support
            if (target.classList.contains("deleteTrainingCompletion") && "trainingPk" in target.dataset) {
                that.deleteTrainingCompletion(e);
            }
        });

        let deleteTrainingCompletionConfirmButton = document.getElementById("deleteTrainingCompletionConfirm");
        if (deleteTrainingCompletionConfirmButton !== null)
            deleteTrainingCompletionConfirmButton.addEventListener("click", () => { that.deleteTrainingCompletionConfirm(); });

        let deleteTrainingCompletionCancelButton = document.getElementById("deleteTrainingCompletionCancel");
        if (deleteTrainingCompletionCancelButton !== null)
            deleteTrainingCompletionCancelButton.addEventListener("click", () => { that._deleteTrainingCompletionConfirmModal.hide(); });
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let specialEducationSpecialEducationPersonnelDevelopmentFormElement = <HTMLDivElement>document.getElementById("specialEducationMonitoringPersonnelDevelopmentForm");
        let planPK = parseInt(specialEducationSpecialEducationPersonnelDevelopmentFormElement.dataset.planfk);

        let personnelDevelopmentMonitoring = document.getElementsByClassName("personnelDevelopmentMonitoring");
        let planMonitoringPK: number = 0;
        if (personnelDevelopmentMonitoring.length > 0)
            planMonitoringPK = parseInt((<HTMLDivElement>(personnelDevelopmentMonitoring[0])).dataset.planmonitoringfk);

        let monitoringData: IPersonnelDevelopmentMonitoringSaveData; 

        let monitoring: IPersonnelDevelopmentMonitoringSaveData = {
            PlanMonitoringPK: planMonitoringPK,
            PlanFK: planPK,
            PersonnelDevelopment: []
        }

        let personnelDevelopments = document.getElementsByClassName("personnelDevelopmentTraining");
        for (let personnelDevelopment of personnelDevelopments) { //Autism, Parent Training, etc
            let personnelDevelopmentElement = <HTMLDivElement>personnelDevelopment;
          
            let personnelDevelopmentRows = personnelDevelopmentElement.querySelectorAll(".personnel-development-row")

            for (let personnelDevelopmentRow of personnelDevelopmentRows) {
                let personnelDevelopmentRowElement = <HTMLDivElement>personnelDevelopmentRow;
                let dataPointPlanPropertyPK = personnelDevelopmentRowElement.dataset.datapointplanpropertypk;

                let dataPointRowNumber = personnelDevelopmentRowElement.dataset.datapointrownumber;

                let descriptionOfTrainingElement = <HTMLInputElement>personnelDevelopmentRowElement.querySelector(`.specialEducationPersonnelDevelopmentDescriptionOfTraining[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`);

                if (descriptionOfTrainingElement) {

                    let planPropertyPK = parseInt(descriptionOfTrainingElement.dataset.planpropertypk);

                    if (planPropertyPK > 0) {

                        let dataPointRow = parseInt(personnelDevelopmentRowElement.dataset.datapointrownumber);

                        let personnelDevelopmentData: IPersonnelDevelopment = {
                            PlanMonitoringPersonnelDevelopmentPK: 0,
                            PlanPropertyPK: planPropertyPK,
                            RowNbr: dataPointRow,
                            PersonnelDevelopmentTraining: []
                        }

                        let trainingRows = personnelDevelopmentRowElement.querySelectorAll(`.monitoring[data-datapointrownumber='${dataPointRow}'] .monitoring-row`);
                        for (let trainingRow of trainingRows) {
                            let trainingRowElement = <HTMLDivElement>trainingRow;

                            let planMonitoringPersonnelDevelopmentFK = trainingRowElement.dataset.planmonitoringpersonneldevelopmentpk;

                            personnelDevelopmentData.PlanMonitoringPersonnelDevelopmentPK = parseInt(planMonitoringPersonnelDevelopmentFK);

                            let planMonitoringPersonnelDevelopmentTrainingPK = trainingRowElement.dataset.planmonitoringpersonneldevelopmenttrainingpk;

                            let row = parseInt(trainingRowElement.dataset.row);
                            let dateField = <HTMLInputElement>trainingRowElement.querySelector(`.specialEducationPersonnelDevelopmentMonitoringField[data-row='${row}']`)
                            let date = dateField.value;

                            let agendaFiles = trainingRowElement.querySelectorAll(`.uploadAgendaFileList[data-row='${row}'] .uploaded-file-container a`);
                            let agendaFileData: IPersonnelDevelopmentMonitoringFile[] = [];
                            for (let agendaFile of agendaFiles) {
                                let ele = <HTMLDivElement>agendaFile;

                                let file: IPersonnelDevelopmentMonitoringFile = {
                                    FileUploadPK: parseInt(ele.dataset.fileuploadpk),
                                    MonitoringFilePK: parseInt(ele.dataset.planmonitoringpersonneldevelopmentmonitoringfilepk),
                                    RowNbr: row
                                }

                                agendaFileData.push({
                                    FileUploadPK: file.FileUploadPK,
                                    MonitoringFilePK: file.MonitoringFilePK,
                                    RowNbr: file.RowNbr
                                });

                            }

                            let attendanceFiles = trainingRowElement.querySelectorAll(`.uploadAttendanceFileList[data-row='${row}'] .uploaded-file-container a`);
                            let attendanceFileData: IPersonnelDevelopmentMonitoringFile[] = [];
                            for (let attendanceFile of attendanceFiles) {
                                let ele = <HTMLDivElement>attendanceFile;

                                let file: IPersonnelDevelopmentMonitoringFile = {
                                    FileUploadPK: parseInt(ele.dataset.fileuploadpk),
                                    MonitoringFilePK: parseInt(ele.dataset.planmonitoringpersonneldevelopmentmonitoringfilepk),
                                    RowNbr: row
                                }

                                attendanceFileData.push({
                                    FileUploadPK: file.FileUploadPK,
                                    MonitoringFilePK: file.MonitoringFilePK,
                                    RowNbr: file.RowNbr
                                });
                            }

                            let trainingData: IPersonnelDevelopmentTraining = {
                                SpecialEducationMonitoringPersonnelDevelopmentTrainingPK: parseInt(planMonitoringPersonnelDevelopmentTrainingPK),
                                Date: date,
                                RowNbr: row,
                                AgendaFiles: agendaFileData,
                                AttendanceFiles: attendanceFileData
                            };

                            personnelDevelopmentData.PersonnelDevelopmentTraining.push({
                                AgendaFiles: trainingData.AgendaFiles,
                                AttendanceFiles: trainingData.AttendanceFiles,
                                Date: trainingData.Date,
                                RowNbr: trainingData.RowNbr,
                                SpecialEducationMonitoringPersonnelDevelopmentTrainingPK: trainingData.SpecialEducationMonitoringPersonnelDevelopmentTrainingPK
                            });
                        }

                        monitoring.PersonnelDevelopment.push({
                            PersonnelDevelopmentTraining: [...personnelDevelopmentData.PersonnelDevelopmentTraining],
                            PlanPropertyPK: personnelDevelopmentData.PlanPropertyPK,
                            RowNbr: personnelDevelopmentData.RowNbr,
                            PlanMonitoringPersonnelDevelopmentPK: personnelDevelopmentData.PlanMonitoringPersonnelDevelopmentPK
                        });
                    }
                }
            }
        }

        monitoringData = {
            PersonnelDevelopment: [...monitoring.PersonnelDevelopment],
            PlanMonitoringPK: monitoring.PlanMonitoringPK,
            PlanFK: planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSpecialEducationPersonnelDevelopmentMonitoring', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The page information was successfully saved.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (monitoringData.PersonnelDevelopment.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(monitoringData));
        }
    }

    bindAddTrainingButtons() {
        let trainingButtons = document.getElementsByClassName("specialEducationPersonnelDevelopmentAddTraining");
        for (let trainingButton of trainingButtons) {
            let element = <HTMLButtonElement>trainingButton;
            if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.addTraining(e))
                element.addEventListener("click", (e: Event) => this.addTraining(e));
                element.dataset.listener = "true";
            }
        }
    }

    addTraining(e: Event) {
        let core = this._core;
        let button = <HTMLButtonElement>e.target;
        let planFK = button.dataset.planfk;
        let propertyCode = button.dataset.propertycode;
        let newRow = 0;
        let dataPointRowNumber = 0;
        let newTopicPlanPropertyPK = 0;

        let personnelRows = document.querySelectorAll(`.personnel-development-row[data-propertyCode='${propertyCode}'] .specialEducationPersonnelDevelopmentField`);
        for (let thisRow of personnelRows) {
            let thisRowElement = <HTMLInputElement>thisRow;
            
            if (button.dataset.datapointrownumber !== "" && button.dataset.datapointrownumber !== "0")
                dataPointRowNumber = parseInt(button.dataset.datapointrownumber);
            if (button.dataset.datapointplanpropertypk !== "" && button.dataset.datapointplanpropertypk !== "0")
                newTopicPlanPropertyPK = parseInt(button.dataset.datapointplanpropertypk);

            if (newRow < parseInt(thisRowElement.dataset.row))
                newRow = parseInt(thisRowElement.dataset.row);
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentTraining', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`.personnelDevelopmentTraining[data-propertycode='${propertyCode}']`);

                if (propertyCode.indexOf("Other") > 0)
                    element = <HTMLDivElement>document.querySelector(`.personnelDevelopmentTraining[data-propertycode='${propertyCode}'][data-row='${dataPointRowNumber}']`);

                var div = document.createElement("div");
                div.classList.add("personnel-development-row");
                div.dataset.propertycode = propertyCode;
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCode=${propertyCode}&newTopicPlanPropertyPK=${newTopicPlanPropertyPK}`);
    }

    addTrainingTopic() {
        let modal = new Modal("addATrainingModal", null);
        modal.show();
    }

    addTrainingTopicCancel() {
        let modal = new Modal("addATrainingModal", null);
        modal.hide();
    }

    addTrainingTopicCreate() {
        let newTopicNameElement = <HTMLInputElement>document.getElementById("specialEducationAddTrainingTopicName");
        let newTopicName = newTopicNameElement.value;
        let core = this._core;
        let planFK = 0;
        let specialEducationSpecialEducationPersonnelDevelopmentFormElement = <HTMLDivElement>document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentForm");
        planFK = parseInt(specialEducationSpecialEducationPersonnelDevelopmentFormElement.dataset.planfk);
        let that = this;
        let row = 0;
        let rowElements = document.querySelectorAll(".personnel-development-row[data-propertycode='specialEducationPersonnelOther']");

        for (let rowElement of rowElements) {
            let ele = <HTMLDivElement>rowElement;
            if (row < parseInt(ele.dataset.row))
                row = parseInt(ele.dataset.row);
        }

        row++;

        if (newTopicName !== "") {
            let trainingTopic = {
                "PlanFK": planFK,
                "TopicName": newTopicName,
                "NewRow": row.toString()
            };

            let specialEducationAddTrainingTopicErrorMessage = <HTMLDivElement>document.getElementById("specialEducationAddTrainingTopicErrorMessage");
            specialEducationAddTrainingTopicErrorMessage.classList.add("hide-message");

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentAddTrainingTopic', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let newId = `${newTopicName.replace(/\s/g, '')}customAccordion`;

                    let div = <HTMLDivElement>document.createElement("div");
                    div.innerHTML = xhr.responseText;
                    div.id = newId;

                    let trainingTopicsElement = <HTMLDivElement>document.getElementById("specialEducationTrainingTopics");
                    trainingTopicsElement.appendChild(div);

                    new CustomAccordion(newId);

                    let modalText = <HTMLInputElement>document.getElementById("specialEducationAddTrainingTopicName");
                    modalText.value = "";

                    let modal = new Modal("addATrainingModal", null);
                    modal.hide();

                    Core.createHTMLAlert("alertMessageDiv", "Successfully added a new training topic", 'success', 3000, null);

                    that.bindAddTrainingButtons();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(trainingTopic));
        } else {
            let specialEducationAddTrainingTopicErrorMessage = <HTMLDivElement>document.getElementById("specialEducationAddTrainingTopicErrorMessage");
            specialEducationAddTrainingTopicErrorMessage.classList.remove("hide-message");
        }
    }

    uploadFile(e: Event, formClass: string) {
        let core = this._core;
        Core.showLoader();
        let element = <HTMLInputElement>e.target;
        let dataPointPlanPropertyFK = element.dataset.datapointplanpropertypk;
        let rowNumber = element.dataset.row;
        let topLevelRowNumber = element.dataset.toplevelrow;
        let uploadMinutesForm = <HTMLFormElement>document.querySelector(`.${formClass}[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-row='${rowNumber}'][data-toplevelrow='${topLevelRowNumber}']`);
        let formData = new FormData(uploadMinutesForm);

        const that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`.${formClass}File[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-row='${rowNumber}']`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.${formClass}FileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-row='${rowNumber}'][data-toplevelrow='${topLevelRowNumber}']`)

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.monitoringfilepk = "0";
                        fileA.dataset.planmonitoringpersonneldevelopmentmonitoringfilepk = "0";
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let trainingPk = buttonElement.dataset.trainingpk;
        let trainingFileType = buttonElement.dataset.trainingfiletype;
        let trainingFilePk = buttonElement.dataset.trainingfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "trainingpk", trainingPk);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "trainingfiletype", trainingFileType);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "trainingfilepk", trainingFilePk);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let trainingPk = buttonElement.dataset.trainingpk;
        let trainingFileType = buttonElement.dataset.trainingfiletype;
        let trainingFilePk = buttonElement.dataset.trainingfilepk;
        const deleteData = {
            "trainingFilePk": trainingFilePk,
            "trainingFileType": trainingFileType
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-trainingpk='${trainingPk}'][data-trainingfiletype='${trainingFileType}'][data-trainingfilepk='${trainingFilePk}']`);
                    element.remove();

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(deleteData));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindAddMonitoringButtons() {
        let monitoringButtons = document.getElementsByClassName("specialEducationPersonnelDevelopmentAddTrainingCompletion");
        for (let monitoringButton of monitoringButtons)
            monitoringButton.addEventListener("click", (e: Event) => this.addMonitoring(e));
    }

    addMonitoring(e: Event) {
        let button = <HTMLButtonElement>e.target;
        let core = this._core;
        let dataPointRowNumber = button.dataset.datapointrownumber;
        let dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        let newRow = 0;
        let planElement = document.getElementById("specialEducationMonitoringPersonnelDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let planMonitoringPersonnelDevelopmentFK = button.dataset.planmonitoringpersonneldevelopmentfk;

        let monitoringRows = document.querySelectorAll(`.monitoring-row[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`);
        for (let thisRow of monitoringRows) {
            let thisRowElement = <HTMLInputElement>thisRow;

            if (newRow < parseInt(thisRowElement.dataset.row))
                newRow = parseInt(thisRowElement.dataset.row);
        }

        newRow++;

        let that = this;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentMonitoring', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = <HTMLDivElement>document.querySelector(`.monitoring[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`);

                var div = document.createElement("div");
                div.classList.add("monitoring-row");
                div.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                div.dataset.datapointrownumber = dataPointRowNumber;
                div.dataset.row = newRow.toString();
                div.dataset.planmonitoringpersonneldevelopmentpk = planMonitoringPersonnelDevelopmentFK;
                div.dataset.planmonitoringpersonneldevelopmenttrainingpk = "0";
                div.innerHTML = xhr.responseText;
                element.appendChild(div);

                that.bindUploaders();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&dataPointPlanPropertyPK=${dataPointPlanPropertyPK}&dataPointRowNumber=${dataPointRowNumber}&newRow=${newRow}&planMonitoringPersonnelDevelopmentFK=${planMonitoringPersonnelDevelopmentFK}`);
    }

    deleteTrainingCompletion(e: Event) {
        let button = <HTMLButtonElement>e.target;
        this._trainingCompletionToDeletePK = parseInt(button.dataset.trainingPk);
        this._trainingCompletionToDeleteButton = button;
        this._deleteTrainingCompletionConfirmModal.show();
    }

    deleteTrainingCompletionConfirm() {
        let that = this;
        try {
            let container = Core.findClosest(that._trainingCompletionToDeleteButton, ".trainingCompletionContainer");
            let nextFocusable = Core.getNextFocusableElement(that._trainingCompletionToDeleteButton);
            if(that._trainingCompletionToDeletePK > 0) {
                Core.showLoader();
                fetch(`/SpecialEducation/DeleteTrainingCompletion?TrainingCompletionPK=${that._trainingCompletionToDeletePK}`, {
                    method: 'POST'
                })
                .then((response) => {
                    Core.hideLoader();
                    return response.json()
                })
                .then((json) => {
                    if(json.success) {
                        container.parentNode.removeChild(container);
                        Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted training completion', 'success', 3000, null);
                        that._deleteTrainingCompletionConfirmModal.hide();
                        if(nextFocusable) {
                            nextFocusable.focus();
                        }
                    } else {
                        throw new Error("training completion deletion unsuccessful");
                    }
                })
                .catch((error) => {
                    Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue deleting training completion.', 'error', 3000, null);
                    Core.hideLoader()
                })
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted training completion', 'success', 3000, null);
                container.parentNode.removeChild(container);
                that._deleteTrainingCompletionConfirmModal.hide();
                if(nextFocusable) {
                    nextFocusable.focus();
                }
            }
        } catch(error) {
            Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue deleting training completion.', 'error', 3000, null);
        }
    }

    bindUploaders() {
        let agendaFileUploaders = document.getElementsByClassName("uploadAgenda");
        for (let agendaFileUploader of agendaFileUploaders)
            agendaFileUploader.addEventListener("change", (e: Event) => this.uploadFile(e, "uploadAgenda"));

        let attendanceFileUploaders = document.getElementsByClassName("uploadAttendance");
        for (let attendanceFileUploader of attendanceFileUploaders)
            attendanceFileUploader.addEventListener("change", (e: Event) => this.uploadFile(e, "uploadAttendance"));
    }
}

class SpecialEducationFacilitiesLazyAccordion extends LazyAccordion {

    async loadContent(accordion: HTMLElement, contentElement: HTMLElement) {
        const icon = <HTMLSpanElement>accordion.querySelector(".lazy-accordion-loaded-icon");
        icon.classList.replace("empty", "loading");
        this.show(accordion, contentElement);

        const specialEducationFacilityFK = accordion.dataset.specialeducationfacilityfk;
        const action = accordion.dataset.action;
        const controller = accordion.dataset.controller;
        const url = `/${controller}/${action}/${specialEducationFacilityFK}`;

        //load
        const response = await fetch(`${url}`, { credentials: 'include' })
        if (response.ok) {
            contentElement.innerHTML = await response.text();

            icon.classList.replace("loading", "loaded");

            SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
            SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();
            SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
            SpecialEducationSpecialEducationFacilities.bindSaveFacilities();

            SpecialEducationSpecialEducationFacilities.addFacilityHash(parseInt(specialEducationFacilityFK));
            SpecialEducationSpecialEducationFacilities.initializeLoadedFacility(parseInt(specialEducationFacilityFK));
            if (accordion.dataset.validdata === "false") {
                SpecialEducationSpecialEducationFacilities.facilityValidation(parseInt(specialEducationFacilityFK));
            }

            $('.actionMultiSelect').MultiSelect({
                ShowAllCheckbox: false
            });

            const tabContainer = <HTMLElement>document.querySelector(`.seprnContainer[data-facility-pk='${specialEducationFacilityFK}'] .row .review-container .review-container-actions .tab-container`)
            if (tabContainer)
                new Tab(tabContainer);

            const seprnContainer = <HTMLButtonElement>document.querySelector(`.seprnContainer[data-facility-pk='${specialEducationFacilityFK}']`);
            if (seprnContainer !== null) {
                const workflowPK = seprnContainer.dataset.workflowInstancePk;
                if (workflowPK && parseInt(workflowPK) > 0)
                    new Workflow(parseInt(workflowPK));
            }
        }
    }
}

class SpecialEducationProgramCaseloadFTELazyAccordion extends LazyAccordion {

    async loadContent(accordion: HTMLElement, contentElement: HTMLElement) {
        const icon = <HTMLSpanElement>accordion.querySelector(".lazy-accordion-loaded-icon");
        icon.classList.replace("empty", "loading");
        this.show(accordion, contentElement);

        const educationProgramFTEFK = accordion.dataset.educationprogramftefk;
        const action = accordion.dataset.action;
        const controller = accordion.dataset.controller;
        const url = `/${controller}/${action}/${educationProgramFTEFK}`;

        //load
        const response = await fetch(`${url}`, { credentials: 'include' })
        if (response.ok) {
            contentElement.innerHTML = await response.text();

            icon.classList.replace("loading", "loaded");

            //SpecialEducationFTETransferOfEntity.addFacilityHash(parseInt(specialEducationFacilityFK));
            SpecialEducationFTETransferOfEntity.initializeLoadedFTE(parseInt(educationProgramFTEFK));
            if (accordion.dataset.validdata === "false") {
                SpecialEducationFTETransferOfEntity.fteValidation(parseInt(educationProgramFTEFK));
            }
        }
    }
}

class SpecialEducationProgramCaseloadFTETransferOfEntityLazyAccordion extends LazyAccordion {

    async loadContent(accordion: HTMLElement, contentElement: HTMLElement) {
        const icon = <HTMLSpanElement>accordion.querySelector(".lazy-accordion-loaded-icon");
        icon.classList.replace("empty", "loading");
        this.show(accordion, contentElement);

        const educationProgramFTETransferOfEntityFK = accordion.dataset.educationprogramftetransferofentityfk;
        const action = accordion.dataset.action;
        const controller = accordion.dataset.controller;
        const url = `/${controller}/${action}/${educationProgramFTETransferOfEntityFK}`;

        //load
        const response = await fetch(`${url}`, { credentials: 'include' })
        if (response.ok) {
            contentElement.innerHTML = await response.text();

            icon.classList.replace("loading", "loaded");

            SpecialEducationFTETransferOfEntity.initializeLoadedFTE(parseInt(educationProgramFTETransferOfEntityFK));
            if (accordion.dataset.validdata === "false") {
                SpecialEducationFTETransferOfEntity.fteValidation(parseInt(educationProgramFTETransferOfEntityFK));
            }

            const tabContainer = <HTMLElement>document.querySelector(`.seprnContainer[data-ftetransferofentity-pk='${educationProgramFTETransferOfEntityFK}'] .row .review-container .review-container-actions .tab-container`)
            if (tabContainer)
                new Tab(tabContainer);

            const seprnContainer = <HTMLButtonElement>document.querySelector(`.seprnContainer[data-ftetransferofentity-pk='${educationProgramFTETransferOfEntityFK}']`);
            if (seprnContainer !== null) {
                const workflowPK = seprnContainer.dataset.workflowInstancePk;
                if (workflowPK && parseInt(workflowPK) > 0)
                    new Workflow(parseInt(workflowPK));
            }
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//Profile
class EducatorEffectivenessProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["educatorEffectivenessProfileField", "educatorEffectivenessTeacherSystemsRadioField", "educatorEffectivenessTeacherSystemsSelectField"];

        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessProfileSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("educatorEffectivenessProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("educatorEffectivenessProfileForm");
        let inputs = document.getElementsByClassName("educatorEffectivenessProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("educatorEffectivenessProfileForm");
        let inputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Educator Effectiveness Teacher Evaluation Details
class EducatorEffectivenessTeacherDetails {
    validationClasses: string[];
    customRows: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["educatorEffectivenessAggregateField", "educatorEffectivenessDetailsField"];

        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessTeacherDetailsSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        this._core.initializeRequiredFields(this.validationClasses);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let educatorEffectivenessSchoolAddNotListedButton = document.getElementById("educatorEffectivenessSchoolAddNotListed");
        if (educatorEffectivenessSchoolAddNotListedButton !== null)
            educatorEffectivenessSchoolAddNotListedButton.addEventListener("click", (e: Event) => this.addTeacherSchool(e));

        this.bindCalculations();

        let searchButton = document.getElementById("educatorEffectivenessSchoolNameSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e: Event) => this.search());

        let searchClearButton = document.getElementById("educatorEffectivenessSchoolNameSearchClear");
        if (searchClearButton !== null)
            searchClearButton.addEventListener("click", (e: Event) => this.clearSearch());

        this.populateEmptyZeros();

        let educatorEffectivenessTeacherDetailsExportToExcelButton = document.getElementById("educatorEffectivenessTeacherDetailsExportToExcel");
        if (educatorEffectivenessTeacherDetailsExportToExcelButton !== null) {
            educatorEffectivenessTeacherDetailsExportToExcelButton.addEventListener("click", () => {
                Core.showLongLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLongLoader();
                    })
                    .catch((error) => {
                        Core.hideLongLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        this.bindDeleteAdditionalUserRows();

        const deleteAdditionalUserRowConfirm = document.getElementById("deleteAdditionalUserRowConfirm");
        if (deleteAdditionalUserRowConfirm !== null)
            deleteAdditionalUserRowConfirm.addEventListener("click", (e: Event) => this.deleteAdditionalUserRowConfirm(e));

        const deleteAdditionalUserRowCancel = document.getElementById("deleteAdditionalUserRowCancel");
        if (deleteAdditionalUserRowCancel !== null)
            deleteAdditionalUserRowCancel.addEventListener("click", (e: Event) => this.deleteAdditionalUserRowCancel());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        let addRows: boolean = false;
        if (referrer !== "save" && this._core.checkSave(this) === false) {

            //if the element to create (global) is not empty, run a separate function to create
            //empty elements in the back end for the principal and the non-teaching professionals.
            //then just continue normally.

            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("educatorEffectivenessTeacherDetailsForm");

        let inputs = document.getElementsByClassName("educatorEffectivenessDetailsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let additionalInputs = document.getElementsByClassName("educatorEffectivenessDetailsAdditionalField");
        for (let ele of additionalInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLLabelElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            const theType = element.dataset.columntype;
            let value = "";
            if (theType && theType === "schoolname") {
                value = element.dataset.schoolname;
            } else if (theType && theType == "schoolbranch") {
                value = element.dataset.schoolbranch;
            }

            if (value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        //This is intentional. We need the aggregate text fields to have a different class for calculation purposes, but we still want to save them here
        let aggregates = document.getElementsByClassName("educatorEffectivenessAggregateField");
        for (let ele of aggregates) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessTeacherDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addTeacherSchool(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const schoolNameElement = <HTMLInputElement>document.getElementById("educatorEffectivenessTeacherDetailsAddSchoolName");
        const branchElement = <HTMLInputElement>document.getElementById("educatorEffectivenessTeacherDetailsAddSchoolBranch");
        const planFK = buttonElement.dataset.planfk;
        let showESSA = "false";
        if (buttonElement.dataset.showessa)
            showESSA = buttonElement.dataset.showessa
        const isLockdown = buttonElement.dataset.islockdown;
        const isReview = buttonElement.dataset.isreview;
        const that = this;

        if (branchElement.value !== "" && schoolNameElement.value !== "") {

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/EducatorEffectiveness/AddSchoolToList', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let tr = document.createElement("tr");
                    tr.innerHTML = xhr.responseText;
                    tr.dataset.row = branchElement.value;
                    tr.classList.add("additionalSchoolUserRow")
                    let placementElement = document.querySelector("#schoolList tbody");

                    placementElement.append(tr);

                    that.populateEmptyZeros();
                    that.bindCalculations();

                    Core.createHTMLAlert("alertMessageDiv", "School successfully added to the list", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(`planFK=${planFK}&schoolName=${schoolNameElement.value}&branch=${branchElement.value}&showESSA=${showESSA}&isLockdown=${isLockdown}&isReview=${isReview}`);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "School Name and Branch number are required to add a school.", 'error', 3000, null);
        }
    }

    calculateRows(e: Event) {
        let inputElement = <HTMLInputElement>e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalTR = 0;
        let totalTRTNR = 0;

        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorOthers[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = <HTMLInputElement>item;

            if (ele.value !== "") {
                let parsed = parseInt(ele.value);
                if (!isNaN(parsed)) {
                    totalTR += parsed;
                }
            }
        }

        let elementTR = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorTR[data-row='${thisRowNumber}']`);
        elementTR.value = totalTR.toString();

        let elementTNR = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorTNR[data-row='${thisRowNumber}']`);
        let tnrValue = 0;
        if (elementTNR && elementTNR.value !== "")
            tnrValue = parseInt(elementTNR.value);

        if (!isNaN(tnrValue)) {
            totalTRTNR = totalTR + tnrValue;
        }

        let elementTRTNR = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorTRTNR[data-row='${thisRowNumber}']`);
        elementTRTNR.value = totalTRTNR.toString();

        this.calculateAggregate();
    }

    calculateESSARows(e: Event) {
        let inputElement = <HTMLInputElement>e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalESSA = 0;

        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorThumbs[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = <HTMLInputElement>item;

            if (ele.value !== "") {
                let parsed = parseInt(ele.value);
                if (!isNaN(parsed)) {
                    totalESSA += parsed;
                }
            }
        }

        let elementESSA = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorESSA[data-row='${thisRowNumber}']`);
        elementESSA.value = totalESSA.toString();

        this.calculateAggregate();
    }

    calculateAggregate() {
        let columns = [];
        columns.push("1U");
        columns.push("2U");
        columns.push("2S");
        columns.push("3S");
        columns.push("4S");
        columns.push("TNR");
        columns.push("TR");
        columns.push("TRTNR");
        columns.push("ThumbsDown");
        columns.push("ThumbsUp");
        columns.push("ESSA");

        for (let column of columns) {
            let allColumnElements = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-column='${column}']`);
            let currentColumnTotal = 0;

            for (let currentElement of allColumnElements) {
                let ele = <HTMLInputElement>currentElement;
                let currentValue = parseInt(ele.value);

                if (!isNaN(currentValue)) {
                    currentColumnTotal += currentValue;
                }
            }

            let thisAggregateElement = <HTMLInputElement>document.querySelector(`.educatorEffectivenessAggregateField[data-column='${column}']`);
            thisAggregateElement.value = currentColumnTotal.toString();
        }
    }

    search() {
        //Start by removing all .search-found and .search-hide classes
        this.clearSearch(false);

        let searchTermElement = <HTMLInputElement>document.getElementById("educatorEffectivenessTeacherDetailsSearchSchoolName");

        if (searchTermElement && searchTermElement.value !== "") {
            let allSchoolNames = document.querySelectorAll(".educatorEffectivenessSchoolName[data-columntype='schoolname']");
            for (let school of allSchoolNames) {
                let element = <HTMLLabelElement>school;
                if (element.textContent.toLowerCase().indexOf(searchTermElement.value.toLowerCase()) >= 0) {
                    //label    td              tr
                    element.parentElement.parentElement.classList.add("search-found");
                }
                else {
                    element.parentElement.parentElement.classList.add("search-hide");
                }
            }
        }
    }

    clearSearch(clearText: boolean = true) {
        let allFoundClassess = document.querySelectorAll(".school-list .search-found");
        for (let ele of allFoundClassess)
            ele.classList.remove("search-found");

        let allHideClassess = document.querySelectorAll(".school-list .search-hide");
        for (let ele of allHideClassess)
            ele.classList.remove("search-hide");

        if (clearText) {
            let textElement = <HTMLInputElement>document.getElementById("educatorEffectivenessTeacherDetailsSearchSchoolName");
            if (textElement !== null)
                textElement.value = "";
        }
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("educatorEffectivenessTeacherDetailsForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/EducatorEffectivenessTeacherEducatorEffectivenessTeacherDetailsExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `EducatorEffectivenessTeacherDetails.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    populateEmptyZeros() {
        let allFields = document.querySelectorAll("#schoolList .educatorEffectivenessDetailsField");

        for (let field of allFields) {
            let ele = <HTMLInputElement>field;

            if (ele.value === "")
                ele.value = "0";
        }
    }

    bindCalculations() {
        const educatorEffectivenessCalculations = document.getElementsByClassName("educatorEffectivenessCalculatorOthers");
        for (let educatorEffectivenessCalculation of educatorEffectivenessCalculations)
            educatorEffectivenessCalculation.addEventListener("blur", (e: Event) => this.calculateRows(e));

        const educatorEffectivenessCalculationsTNR = document.getElementsByClassName("educatorEffectivenessCalculatorTNR");
        for (let educatorEffectivenessCalculationTNR of educatorEffectivenessCalculationsTNR)
            educatorEffectivenessCalculationTNR.addEventListener("blur", (e: Event) => this.calculateRows(e));

        const educatorEffectivenessCalculationsThumbs = document.getElementsByClassName("educatorEffectivenessCalculatorThumbs");
        for (let educatorEffectivenessCalculationsThumb of educatorEffectivenessCalculationsThumbs)
            educatorEffectivenessCalculationsThumb.addEventListener("blur", (e: Event) => this.calculateESSARows(e));
    }

    bindDeleteAdditionalUserRows() {
        const deleteAdditionalUserRows = document.getElementsByClassName("deleteAdditionalUserRow");
        for (const deleteAdditionalUserRow of deleteAdditionalUserRows)
            deleteAdditionalUserRow.addEventListener("click", (e: Event) => this.showDeleteAdditionalUserRow(e));
    }

    showDeleteAdditionalUserRow(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;

        const modal: Modal = new Modal("deleteAdditionalUserRowModal", null);
        modal.addAttributeToElement("deleteAdditionalUserRowModal", "#deleteAdditionalUserRowConfirm", "row", row);
        modal.show();
    }

    async deleteAdditionalUserRowConfirm(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;

        const allPlanProperties = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-row='${row}']`);
        const planProps = [];
        for (const pp of allPlanProperties) {
            const ele = <HTMLInputElement>pp;

            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0") {
                planProps.push(ele.dataset.planpropertypk);
            }
        }

        const schoolName = <HTMLLabelElement>document.querySelector(`.educatorEffectivenessDetailsAdditionalField[data-row='${row}'][data-columntype='schoolname']`);
        const schoolBranch = <HTMLLabelElement>document.querySelector(`.educatorEffectivenessDetailsAdditionalField[data-row='${row}'][data-columntype='schoolbranch']`);

        planProps.push(schoolName.dataset.planpropertypk);
        planProps.push(schoolBranch.dataset.planpropertypk);

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(planProps)
        };

        const response = await fetch(`/EducatorEffectiveness/DeleteTeacherDetailsAdditionalUserRow`, settings);
        if (response.ok) {
            const thisTR = document.querySelector(`.additionalSchoolUserRow[data-row='${row}']`);
            thisTR.remove();

            const modal: Modal = new Modal("deleteAdditionalUserRowModal", null);
            modal.hide();

            Core.createHTMLAlert("alertMessageDiv", "The additional school row has been removed", 'success', 3000, null);
        } else {
            const modal: Modal = new Modal("deleteAdditionalUserRowModal", null);
            modal.hide();

            Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the additional school row. Please try again.", 'success', 3000, null);
        }
    }

    deleteAdditionalUserRowCancel() {
        const modal: Modal = new Modal("deleteAdditionalUserRowModal", null);
        modal.hide();
    }
}

//Educator Effectiveness Principal Evaluation Details
class EducatorEffectivenessPrincipalDetails {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["educatorEffectivenessAggregateField", "educatorEffectivenessDetailsField"];

        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessPrincipalDetailsSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let educatorEffectivenessSchoolAddNotListedButton = document.getElementById("educatorEffectivenessSchoolAddNotListed");
        if (educatorEffectivenessSchoolAddNotListedButton !== null)
            educatorEffectivenessSchoolAddNotListedButton.addEventListener("click", (e: Event) => this.addTeacherSchool(e));

        let educatorEffectivenessCalculations = document.getElementsByClassName("educatorEffectivenessCalculatorOthers");
        for (let educatorEffectivenessCalculation of educatorEffectivenessCalculations)
            educatorEffectivenessCalculation.addEventListener("blur", (e: Event) => this.calculateRows(e));

        let educatorEffectivenessCalculationsTNR = document.getElementsByClassName("educatorEffectivenessCalculatorTNR");
        for (let educatorEffectivenessCalculationTNR of educatorEffectivenessCalculationsTNR)
            educatorEffectivenessCalculationTNR.addEventListener("blur", (e: Event) => this.calculateRows(e));

        let educatorEffectivenessCalculationsThumbs = document.getElementsByClassName("educatorEffectivenessCalculatorThumbs");
        for (let educatorEffectivenessCalculationsThumb of educatorEffectivenessCalculationsThumbs)
            educatorEffectivenessCalculationsThumb.addEventListener("blur", (e: Event) => this.calculateESSARows(e));

        let searchButton = document.getElementById("educatorEffectivenessSchoolNameSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e: Event) => this.search());

        let searchClearButton = document.getElementById("educatorEffectivenessSchoolNameSearchClear");
        if (searchClearButton !== null)
            searchClearButton.addEventListener("click", (e: Event) => this.clearSearch());

        this.populateEmptyZeros();

        let educatorEffectivenessPrincipalDetailsExportToExcelButton = document.getElementById("educatorEffectivenessPrincipalDetailsExportToExcel");
        if (educatorEffectivenessPrincipalDetailsExportToExcelButton !== null) {
            educatorEffectivenessPrincipalDetailsExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("educatorEffectivenessPrincipalDetailsForm");

        let inputs = document.getElementsByClassName("educatorEffectivenessDetailsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        //This is intentional. We need the aggregate text fields to have a different class for calculation purposes, but we still want to save them here
        let aggregates = document.getElementsByClassName("educatorEffectivenessAggregateField");
        for (let ele of aggregates) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessPrincipalDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addTeacherSchool(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let schoolNameElement = <HTMLInputElement>document.getElementById("educatorEffectivenessPrincipalDetailsAddSchoolName");
        let branchElement = <HTMLInputElement>document.getElementById("educatorEffectivenessPrincipalDetailsAddSchoolBranch");
        let planFK = buttonElement.dataset.planfk;
        let showESSA = "false";
        if (buttonElement.dataset.showessa)
            showESSA = buttonElement.dataset.showessa
        let isLockdown = buttonElement.dataset.islockdown;
        let isReview = buttonElement.dataset.isreview;

        if (branchElement.value !== "" && schoolNameElement.value !== "") {

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/EducatorEffectiveness/AddSchoolToList', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let tr = document.createElement("tr");
                    tr.innerHTML = xhr.responseText;

                    let placementElement = document.querySelector("#schoolList tbody");

                    placementElement.append(tr);

                    Core.createHTMLAlert("alertMessageDiv", "School successfully added to the list", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(`planFK=${planFK}&schoolName=${schoolNameElement.value}&branch=${branchElement.value}&showESSA=${showESSA}&isLockdown=${isLockdown}&isReview=${isReview}`);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "School Name and Branch number are required to add a school.", 'error', 3000, null);
        }
    }

    calculateRows(e: Event) {
        let inputElement = <HTMLInputElement>e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalTR = 0;
        let totalTRTNR = 0;

        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorOthers[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = <HTMLInputElement>item;

            if (ele.value !== "") {
                let parsed = parseFloat(ele.value);
                if (!isNaN(parsed)) {
                    totalTR += parsed;
                }
            }
        }

        let elementTR = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorTR[data-row='${thisRowNumber}']`);
        elementTR.value = totalTR.toFixed(2);

        let elementTNR = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorTNR[data-row='${thisRowNumber}']`);
        let tnrValue = 0;
        if (elementTNR && elementTNR.value !== "")
            tnrValue = parseFloat(elementTNR.value);

        if (!isNaN(tnrValue)) {
            totalTRTNR = totalTR + tnrValue;
        }

        let elementTRTNR = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorTRTNR[data-row='${thisRowNumber}']`);
        elementTRTNR.value = totalTRTNR.toFixed(2);

        this.calculateAggregate();
    }

    calculateESSARows(e: Event) {
        let inputElement = <HTMLInputElement>e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalESSA = 0;

        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorThumbs[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = <HTMLInputElement>item;

            if (ele.value !== "") {
                let parsed = parseFloat(ele.value);
                if (!isNaN(parsed)) {
                    totalESSA += parsed;
                }
            }
        }

        let elementESSA = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorESSA[data-row='${thisRowNumber}']`);
        elementESSA.value = totalESSA.toFixed(2);

        this.calculateAggregate();
    }

    calculateAggregate() {
        let columns = [];
        columns.push("1U");
        columns.push("2U");
        columns.push("2S");
        columns.push("3S");
        columns.push("4S");
        columns.push("TNR");
        columns.push("TR");
        columns.push("TRTNR");
        columns.push("ThumbsDown");
        columns.push("ThumbsUp");
        columns.push("ESSA");

        for (let column of columns) {
            let allColumnElements = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-column='${column}']`);
            let currentColumnTotal = 0;

            for (let currentElement of allColumnElements) {
                let ele = <HTMLInputElement>currentElement;
                let currentValue = parseFloat(ele.value);

                if (!isNaN(currentValue)) {
                    currentColumnTotal += currentValue;
                }
            }

            let thisAggregateElement = <HTMLInputElement>document.querySelector(`.educatorEffectivenessAggregateField[data-column='${column}']`);
            thisAggregateElement.value = currentColumnTotal.toFixed(2);
        }
    }

    search() {
        //Start by removing all .search-found and .search-hide classes
        this.clearSearch(false);

        let searchTermElement = <HTMLInputElement>document.getElementById("educatorEffectivenessPrincipalDetailsSearchSchoolName");

        if (searchTermElement && searchTermElement.value !== "") {
            let allSchoolNames = document.querySelectorAll(".educatorEffectivenessSchoolName[data-columntype='schoolname']");
            for (let school of allSchoolNames) {
                let element = <HTMLLabelElement>school;
                if (element.textContent.toLowerCase().indexOf(searchTermElement.value.toLowerCase()) >= 0) {
                    //label    td              tr
                    element.parentElement.parentElement.classList.add("search-found");
                } else {
                    element.parentElement.parentElement.classList.add("search-hide");
                }
            }
        }
    }

    clearSearch(clearText: boolean = true) {
        let allFoundClassess = document.querySelectorAll(".school-list .search-found");
        for (let ele of allFoundClassess)
            ele.classList.remove("search-found");

        let allHideClassess = document.querySelectorAll(".school-list .search-hide");
        for (let ele of allHideClassess)
            ele.classList.remove("search-hide");

        if (clearText) {
            let textElement = <HTMLInputElement>document.getElementById("educatorEffectivenessPrincipalDetailsSearchSchoolName");
            if (textElement !== null)
                textElement.value = "";
        }
    }

    exportToExcel() {
        let that = this;
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("educatorEffectivenessPrincipalDetailsForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/EducatorEffectivenessTeacherEducatorEffectivenessPrincipalDetailsExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `EducatorEffectivenessPrincipalDetails.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    populateEmptyZeros() {
        let allFields = document.querySelectorAll("#schoolList .educatorEffectivenessDetailsField");

        for (let field of allFields) {
            let ele = <HTMLInputElement>field;

            if (ele.value === "")
                ele.value = "0";
        }
    }
}

//Educator Effectiveness Non-Teaching Professional Evaluation Details
class EducatorEffectivenessNonTeachingProfessionalDetails {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["educatorEffectivenessAggregateField", "educatorEffectivenessDetailsField"];

        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let educatorEffectivenessSchoolAddNotListedButton = document.getElementById("educatorEffectivenessSchoolAddNotListed");
        if (educatorEffectivenessSchoolAddNotListedButton !== null)
            educatorEffectivenessSchoolAddNotListedButton.addEventListener("click", (e: Event) => this.addTeacherSchool(e));

        let educatorEffectivenessCalculations = document.getElementsByClassName("educatorEffectivenessCalculatorOthers");
        for (let educatorEffectivenessCalculation of educatorEffectivenessCalculations)
            educatorEffectivenessCalculation.addEventListener("blur", (e: Event) => this.calculateRows(e));

        let educatorEffectivenessCalculationsTNR = document.getElementsByClassName("educatorEffectivenessCalculatorTNR");
        for (let educatorEffectivenessCalculationTNR of educatorEffectivenessCalculationsTNR)
            educatorEffectivenessCalculationTNR.addEventListener("blur", (e: Event) => this.calculateRows(e));

        let educatorEffectivenessCalculationsThumbs = document.getElementsByClassName("educatorEffectivenessCalculatorThumbs");
        for (let educatorEffectivenessCalculationsThumb of educatorEffectivenessCalculationsThumbs)
            educatorEffectivenessCalculationsThumb.addEventListener("blur", (e: Event) => this.calculateESSARows(e));

        let searchButton = document.getElementById("educatorEffectivenessSchoolNameSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e: Event) => this.search());

        let searchClearButton = document.getElementById("educatorEffectivenessSchoolNameSearchClear");
        if (searchClearButton !== null)
            searchClearButton.addEventListener("click", (e: Event) => this.clearSearch());

        this.populateEmptyZeros();

        let educatorEffectivenessNonTeachingProfessionalDetailsExportToExcelButton = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsExportToExcel");
        if (educatorEffectivenessNonTeachingProfessionalDetailsExportToExcelButton !== null) {
            educatorEffectivenessNonTeachingProfessionalDetailsExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsForm");

        let inputs = document.getElementsByClassName("educatorEffectivenessDetailsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        //This is intentional. We need the aggregate text fields to have a different class for calculation purposes, but we still want to save them here
        let aggregates = document.getElementsByClassName("educatorEffectivenessAggregateField");
        for (let ele of aggregates) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessNonTeachingProfessionalDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addTeacherSchool(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let schoolNameElement = <HTMLInputElement>document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsAddSchoolName");
        let branchElement = <HTMLInputElement>document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsAddSchoolBranch");
        let planFK = buttonElement.dataset.planfk;
        let showESSA = "false";
        if (buttonElement.dataset.showessa)
            showESSA = buttonElement.dataset.showessa
        let isLockdown = buttonElement.dataset.islockdown;
        let isReview = buttonElement.dataset.isreview;

        if (branchElement.value !== "" && schoolNameElement.value !== "") {

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/EducatorEffectiveness/AddSchoolToList', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let tr = document.createElement("tr");
                    tr.innerHTML = xhr.responseText;

                    let placementElement = document.querySelector("#schoolList tbody");

                    placementElement.append(tr);

                    Core.createHTMLAlert("alertMessageDiv", "School successfully added to the list", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(`planFK=${planFK}&schoolName=${schoolNameElement.value}&branch=${branchElement.value}&showESSA=${showESSA}&isLockdown=${isLockdown}&isReview=${isReview}`);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "School Name and Branch number are required to add a school.", 'error', 3000, null);
        }
    }

    calculateRows(e: Event) {
        let inputElement = <HTMLInputElement>e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalTR = 0;
        let totalTRTNR = 0;

        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorOthers[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = <HTMLInputElement>item;

            if (ele.value !== "") {
                let parsed = parseFloat(ele.value);
                if (!isNaN(parsed)) {
                    totalTR += parsed;
                }
            }
        }

        let elementTR = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorTR[data-row='${thisRowNumber}']`);
        elementTR.value = totalTR.toFixed(2);

        let elementTNR = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorTNR[data-row='${thisRowNumber}']`);
        let tnrValue = 0;
        if (elementTNR && elementTNR.value !== "")
            tnrValue = parseFloat(elementTNR.value);

        if (!isNaN(tnrValue)) {
            totalTRTNR = totalTR + tnrValue;
        }

        let elementTRTNR = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorTRTNR[data-row='${thisRowNumber}']`);
        elementTRTNR.value = totalTRTNR.toFixed(2);

        this.calculateAggregate();
    }

    calculateESSARows(e: Event) {
        let inputElement = <HTMLInputElement>e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalESSA = 0;

        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorThumbs[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = <HTMLInputElement>item;

            if (ele.value !== "") {
                let parsed = parseFloat(ele.value);
                if (!isNaN(parsed)) {
                    totalESSA += parsed;
                }
            }
        }

        let elementESSA = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCalculatorESSA[data-row='${thisRowNumber}']`);
        elementESSA.value = totalESSA.toFixed(2);

        this.calculateAggregate();
    }

    calculateAggregate() {
        let columns = [];
        columns.push("1U");
        columns.push("2U");
        columns.push("2S");
        columns.push("3S");
        columns.push("4S");
        columns.push("TNR");
        columns.push("TR");
        columns.push("TRTNR");
        columns.push("ThumbsDown");
        columns.push("ThumbsUp");
        columns.push("ESSA");

        for (let column of columns) {
            let allColumnElements = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-column='${column}']`);
            let currentColumnTotal = 0;

            for (let currentElement of allColumnElements) {
                let ele = <HTMLInputElement>currentElement;
                let currentValue = parseFloat(ele.value);

                if (!isNaN(currentValue)) {
                    currentColumnTotal += currentValue;
                }
            }

            let thisAggregateElement = <HTMLInputElement>document.querySelector(`.educatorEffectivenessAggregateField[data-column='${column}']`);
            thisAggregateElement.value = currentColumnTotal.toFixed(2);
        }
    }

    search() {
        this.clearSearch(false);

        let searchTermElement = <HTMLInputElement>document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsSearchSchoolName");

        if (searchTermElement && searchTermElement.value !== "") {
            let allSchoolNames = document.querySelectorAll(".educatorEffectivenessSchoolName[data-columntype='schoolname']");
            for (let school of allSchoolNames) {
                let element = <HTMLLabelElement>school;
                if (element.textContent.toLowerCase().indexOf(searchTermElement.value.toLowerCase()) >= 0) {
                    //label    td              tr
                    element.parentElement.parentElement.classList.add("search-found");
                } else {
                    element.parentElement.parentElement.classList.add("search-hide");
                }
            }
        }
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/EducatorEffectivenessTeacherEducatorEffectivenessNonTeachingProfessionalDetailsExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `EducatorEffectivenessNonTeachingProfessionalDetails.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    populateEmptyZeros() {
        let allFields = document.querySelectorAll("#schoolList .educatorEffectivenessDetailsField");

        for (let field of allFields) {
            let ele = <HTMLInputElement>field;

            if (ele.value === "")
                ele.value = "0";
        }
    }

    clearSearch(clearText: boolean = true) {
        let allFoundClassess = document.querySelectorAll(".school-list .search-found");
        for (let ele of allFoundClassess)
            ele.classList.remove("search-found");

        let allHideClassess = document.querySelectorAll(".school-list .search-hide");
        for (let ele of allHideClassess)
            ele.classList.remove("search-hide");

        if (clearText) {
            let textElement = <HTMLInputElement>document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsSearchSchoolName");
            if (textElement !== null)
                textElement.value = "";
        }
    }
}

//Educator Effectiveness Signatures and Assurances
class EducatorEffectivenessSignaturesAndAssurance {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["flexibleInstructionDaysField"];

        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessSignaturesAndAssuranceSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let formElement = <HTMLDivElement>document.getElementById("educatorEffectivenessSignaturesAndAssuranceForm");
        let inputs = document.getElementsByClassName("flexibleInstructionDaysField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessSignaturesAndAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Educator Effectiveness Summary Checklist & Submission
class EducatorEffectivenessSummaryChecklistAndSubmission {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/EducatorEffectiveness/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
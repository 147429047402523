/// <reference path="core.ts" />

//AnalyzingStrengthsAndChallenges
class AnalyzingStrengthsAndChallenges {

    validationClasses: string[];
    private uncheckChallengeForPriorityModal: Modal;
    private _core: Core;

    constructor() {
        this._core = new Core();

        this.validationClasses = [
            "analyzingStrengthsAndChallengesChallenges",
            "analyzingStrengthsAndChallengesPriority",
            "analyzingStrengthsAndChallengesStrengths",
            "analyzingStrengthsAndChallengesChallenges"
        ];

        this.uncheckChallengeForPriorityModal = new Modal("uncheckChallengeForPriorityModal", null);

        let that = this;
        let uncheckChallengeConfirmButton = document.getElementById("uncheckChallengeForPriorityConfirm");
        if (uncheckChallengeConfirmButton !== null) {
            uncheckChallengeConfirmButton.addEventListener("click", function () {
                if ("prioritycheckplanpropertypk" in uncheckChallengeConfirmButton.dataset && parseInt(uncheckChallengeConfirmButton.dataset.prioritycheckplanpropertypk) > 0) {
                    that.uncheckChallengeForPriority(uncheckChallengeConfirmButton.dataset.prioritycheckplanpropertypk);
                } else {
                    that.uncheckChallengeForPriorityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this challenge for priority", "error", 3000, null);
                }
            });
        }

        let uncheckChallengeCancelButton = document.getElementById("uncheckChallengeForPriorityCancel");
        if (uncheckChallengeCancelButton !== null) {
            uncheckChallengeCancelButton.addEventListener("click", function () {
                that.uncheckChallengeForPriorityModal.hide();

                if ("prioritycheckplanpropertypk" in uncheckChallengeCancelButton.dataset) {
                    let checkbox = document.querySelector(`[data-planpropertypk='${uncheckChallengeCancelButton.dataset.prioritycheckplanpropertypk}']`) as HTMLInputElement;
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }

        let checkboxes = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityStatement");
        for (let check of checkboxes) {
            check.addEventListener("change", (e: Event) => this.addPriorityChallengeRow(e));
        }

        let saveButton = document.getElementById("analyzingStrengthsAndChallengesSave");
        if(saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customValidation();
        }

        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportWord = document.getElementById("exportAnalyzingStrengthsChallengesWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "AnalyzingStrengthsAndChallengesDataExport", "analyzingstrengthschallenges"));
        }

        that.limitPriorityChallengesCheck();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allChallengeElements = [];
        let allChallengeCheckElements = [];
        let allStrengthElements = [];
        let allPriorityElements = [];
        let planFK = 0;

        let planFKElement = document.getElementById("analyzingForm");
        planFK = parseInt(planFKElement.dataset.planpk);

        //Get all the textboxes for challenges
        let challengeElements = document.getElementsByClassName("analyzingStrengthsAndChallengesChallenges");
        for (let element of challengeElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let thisData: IAnalyzingStrengthsAndChallenges = {
                Row: parseInt(htmlElement.dataset.row),
                Value: htmlElement.value,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allChallengeElements.push(thisData);
        }

        //Get all the checkboxes for challenges
        let challengeCheckElements = document.getElementsByClassName("analyzingStrengthsAndChallengesChallengesCheckbox");
        for (let element of challengeCheckElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let checkValue: string = "";
            if (htmlElement.checked)
                checkValue = "on";

            let thisData: IAnalyzingStrengthsAndChallenges = {
                Row: parseInt(htmlElement.dataset.row),
                Value: checkValue,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allChallengeCheckElements.push(thisData);
        }

        //Get all the textboxes for Strengths
        let strengthElements = document.getElementsByClassName("analyzingStrengthsAndChallengesStrengths");
        for (let element of strengthElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let thisData: IAnalyzingStrengthsAndChallenges = {
                Row: parseInt(htmlElement.dataset.row),
                Value: htmlElement.value,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allStrengthElements.push(thisData);
        }

        //Get all the textboxes for Priority Statements
        let priorityElements = document.getElementsByClassName("analyzingStrengthsAndChallengesPriority");
        for (let element of priorityElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let thisData: IAnalyzingStrengthsAndChallenges = {
                Row: parseInt(htmlElement.dataset.row),
                Value: htmlElement.value,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allPriorityElements.push(thisData);
        }

        let allSaveData: IAnalyzingStrengthsAndChallengesSaveData = {
            Challenges: allChallengeElements,
            ChallengesCheckboxes: allChallengeCheckElements,
            Strengths: allStrengthElements,
            PriorityChallenges: allPriorityElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveAnalyzeStrengthsAndChallengesData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveData.Challenges.length === 0 && allSaveData.ChallengesCheckboxes.length === 0 && allSaveData.PriorityChallenges.length === 0 && allSaveData.Strengths.length === 0) {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                this.customValidation();
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveData));
        }
    }

    customValidation() {
        let errorCount = 0;

        //Check to see if at least two challenges have been checked for priority
        let priorityCheckboxes = document.getElementsByClassName("analyzingStrengthsAndChallengesChallengesCheckbox") as HTMLCollectionOf<HTMLInputElement>;
        let checkedCount = 0;

        for (let check of priorityCheckboxes) {
            if (check.checked) {
                checkedCount++;
            }
        }

        if (checkedCount < 2) {
            errorCount++;
            let checkboxErrorMessage = document.getElementById("priorityChallengesErrorContainer");
            if (checkboxErrorMessage !== null) {
                checkboxErrorMessage.classList.add("show");
            }
        }

        //Check to see if all priority statements have values
        let priorityStatements = document.getElementsByClassName("analyzingStrengthsAndChallengesPriority") as HTMLCollectionOf<HTMLTextAreaElement>;
        let allPriorityCompleted = true;
        for (let statement of priorityStatements) {
            if (statement.value.length == 0) {
                errorCount++;
                allPriorityCompleted = false;
                statement.classList.add("missing-field");
            }
        }

        if (!allPriorityCompleted) {
            let priorityErrorMessage = document.getElementById("priorityStatementsErrorContainer");
            if (priorityErrorMessage != null) {
                priorityErrorMessage.classList.add("show");
            }
        }

        if (errorCount > 0) {
            //Set text for error message
            let message = <HTMLDivElement>document.getElementById("validationMessage");

            if (errorCount === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            } else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");

            if (goToError !== null) {

                let firstFocusableEl = <HTMLElement>document.querySelector(".custom-error.show");

                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        firstFocusableEl.focus();
                    });
                } else {
                    goToError.parentNode.removeChild(goToError);
                }
            }

            this._core.doValidation(null, true);

        } else {
            this._core.doValidation(null, false);
        }
    }

    addPriorityChallengeRow(e: Event) {
        let core = this._core;
        let that = this;
        var element = <HTMLInputElement>e.target;
        let planFK = 0;
        let planFKElement = document.getElementById("analyzingForm");
        planFK = parseInt(planFKElement.dataset.planpk);

        if (element.readOnly) {
            if (element.checked)
                element.checked = false;
            else
                element.checked = true;

            return false;
        }
        else {
            if (element.checked) {

                let dataPointPropertyPK = element.dataset.propertypk; //The checkbox Property PK becomes the datapoint
                let dataPointPlanPropertyPK = element.dataset.planpropertypk; //The checkbox Plan Property PK becomes the datapoint
                let discussionPointDataPointPropertyPK = element.dataset.datapointpropertypk;
                let discussionPointDataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
                let currentRow = element.dataset.row;

                let allElements = document.getElementsByClassName("readOnlyChallenges");
                let discussionPointElement: HTMLInputElement;
                for (let discussionElement of allElements) {
                    let htmlElement = <HTMLInputElement>discussionElement;
                    if (htmlElement.dataset.row === currentRow && htmlElement.dataset.planpropertypk === discussionPointDataPointPlanPropertyPK) {
                        discussionPointElement = htmlElement;
                    }
                }

                if (discussionPointElement.value !== "") {

                    that.limitPriorityChallengesCheck();

                    let discussionPointPlanPropertyPK: number = 0;
                    if (discussionPointElement.dataset.planpropertypk !== "")
                        discussionPointPlanPropertyPK = parseInt(discussionPointElement.dataset.planpropertypk);
                    let discussionPointPropertyPK: number = 0;
                    if (discussionPointElement.dataset.propertypk !== "")
                        discussionPointPropertyPK = parseInt(discussionPointElement.dataset.propertypk);
                    let priorityPlanPropertyPK: number = 0;
                    if (element.dataset.planpropertypk !== "")
                        priorityPlanPropertyPK = parseInt(element.dataset.planpropertypk);

                    let allDiscussionPointReadOnlyElements = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityDiscussionPointReadOnly");
                    let discussionPointReadOnlyElement: HTMLInputElement;

                    for (let discussionReadOnlyElement of allDiscussionPointReadOnlyElements) {
                        let htmldiscussionPointReadOnlyElement = <HTMLInputElement>discussionReadOnlyElement;
                        if (htmldiscussionPointReadOnlyElement.dataset.row === currentRow && htmldiscussionPointReadOnlyElement.dataset.planpropertypk === discussionPointDataPointPlanPropertyPK) {
                            discussionPointReadOnlyElement = htmldiscussionPointReadOnlyElement;
                        }
                    }

                    if (discussionPointReadOnlyElement != null) {
                        discussionPointReadOnlyElement.value = discussionPointElement.value;
                    }
                    else {
                        let newRowItem: IPriorityChallengeData = {
                            DiscussionPointPlanPropertyPK: discussionPointPlanPropertyPK,
                            DiscussionPointPropertyPK: discussionPointPropertyPK,
                            DiscussionPointTextValue: discussionPointElement.value,
                            PriorityPlanPropertyPK: priorityPlanPropertyPK,
                            PriorityPropertyPK: parseInt(element.dataset.propertypk),
                            PriorityValue: "on",
                            RowNumber: parseInt(currentRow),
                            PlanFK: planFK
                        };

                        let table = (<HTMLTableElement>document.getElementById("analyzingStrengthsAndChallengesTable"));
                        let xhr = new XMLHttpRequest();
                        xhr.open('POST', '/Go/PriorityChallengesRow', true);
                        xhr.setRequestHeader('Content-Type', 'application/json');
                        xhr.onload = function () {
                            if (xhr.status === 200) {
                                let element = table.querySelector("tbody");
                                $(element).append(xhr.responseText);
                                Core.createHTMLAlert("alertMessageDiv", "New Priority Challenge Row Added", 'success', 3000, null);

                                //Reinitialize required fields
                                core.initializeRequiredFields(that.validationClasses);
                            } else {
                                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                            }
                        };
                        xhr.send(JSON.stringify(newRowItem));
                    }
                } else {
                    element.checked = false;
                }
            } else {
                //If the checkbox was initially checked and is now unchecked, show confirmation modal to delete hierarchy (challenge > checked for priority, checked for priority > priority statement)
                if ("planpropertypk" in element.dataset && parseInt(element.dataset.planpropertypk) > 0 && "initiallychecked" in element.dataset && element.dataset.initiallychecked == "true") {

                    //recheck the checkbox just in case they close the modal (we don't want this to actually be unchecked until call to delete checkbox and hierarchy)
                    element.checked = true;

                    this.uncheckChallengeForPriorityModal.show();
                    this.uncheckChallengeForPriorityModal.addAttributeToElement(this.uncheckChallengeForPriorityModal.id, "#uncheckChallengeForPriorityConfirm", "prioritycheckplanpropertypk", element.dataset.planpropertypk);
                    this.uncheckChallengeForPriorityModal.addAttributeToElement(this.uncheckChallengeForPriorityModal.id, "#uncheckChallengeForPriorityCancel", "prioritycheckplanpropertypk", element.dataset.planpropertypk);

                    if (element.id) {
                        this.uncheckChallengeForPriorityModal.callingId = element.id;
                    }
                } else {
                    //remove priority statement from DOM
                    if ("row" in element.dataset) {
                        let priorityStatement = document.querySelector(`.analyzingStrengthsAndChallengesPriorityContainer[data-row='${element.dataset.row}']`);
                        if (priorityStatement != null) {
                            priorityStatement.parentNode.removeChild(priorityStatement);
                        }
                    }
                }

                that.limitPriorityChallengesCheck();
            }
        }

    }

    limitPriorityChallengesCheck() {
        const max = 4;

        var numberChecked = 0;
        let checkboxes = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityStatement");
        for (let check of checkboxes) {
            var elementCheckbox = <HTMLInputElement>check;
            if (elementCheckbox.checked) {
                numberChecked++;
            }
        }

        for (let check of checkboxes) {
            var elementCheckbox = <HTMLInputElement>check;
            if (!elementCheckbox.checked && numberChecked >= max) {
                elementCheckbox.setAttribute("disabled", "disabled");
                elementCheckbox.classList.add("disabled");
                elementCheckbox.style.cursor = "cursor";
            }
            else {
                elementCheckbox.removeAttribute("disabled");
                elementCheckbox.classList.remove("disabled");
                elementCheckbox.style.cursor = "pointer";
            }
        }
    }

    //Make call to delete hierarchy (challenge > checked for priority, checked for priority > priority statement)
    uncheckChallengeForPriority(priorityCheckPlanpropertyPK: string) {
        let that = this;
        that.uncheckChallengeForPriorityModal.hide();

        let checkbox = document.querySelector(`[data-planpropertypk='${priorityCheckPlanpropertyPK}']`) as HTMLInputElement;

        if (checkbox != null) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/UncheckChallengeForPriority', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Challenge successfully unchecked for priority", 'success', 3000, null);

                    //Uncheck checkbox and reset values
                    checkbox.checked = false;
                    checkbox.dataset.initiallychecked = "false";
                    checkbox.dataset.planpropertypk = "0";

                    that.limitPriorityChallengesCheck();

                    //remove priority statement from DOM
                    if ("row" in checkbox.dataset) {
                        let priorityStatement = document.querySelector(`.analyzingStrengthsAndChallengesPriorityContainer[data-row='${checkbox.dataset.row}']`);
                        if (priorityStatement != null) {
                            priorityStatement.parentNode.removeChild(priorityStatement);
                        }
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for priority. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(priorityCheckPlanpropertyPK);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for priority. Please try again later.", "error", 3000, null);
        }
    }
}

//GoalSetting
class GoalSetting {

    validationClasses: string[];
    private deleteGoalModal: Modal;
    private _core: Core;

    constructor() {
        this._core = new Core();
        this.validationClasses = ["goalSettingField", "goalSettingLookupField"];
        this.deleteGoalModal = new Modal("deleteGoalModal", null);

        let saveButton = document.getElementById("goalSettingSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let checkboxes = document.querySelectorAll(".goalSettingCheckbox") as NodeListOf<HTMLInputElement>;
        for (let check of checkboxes) {
            check.addEventListener("change", (e: Event) => this.omitQuarterlyTargets(check));
            this.omitQuarterlyTargets(check);
        }

        let goalStatements = document.querySelectorAll(".goalSettingField[data-propertycode='goalSettingMeasurableGoalStatement']") as NodeListOf<HTMLInputElement>;
        for (let statement of goalStatements) {
            let targetYear3 = <HTMLInputElement>document.querySelector(`.goalSettingField[data-propertycode='goalSettingTargetYear3'][data-buttonrow='${statement.dataset.buttonrow}'][data-row='${statement.dataset.row}'`);
            if (targetYear3 != null) {
                statement.addEventListener("focusout", function(){
                    targetYear3.value = statement.value;
                });
            }
        }

        let that = this;
        let deleteGoalButtons = document.querySelectorAll(".deleteGoal") as NodeListOf<HTMLButtonElement>;
        for (let button of deleteGoalButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteGoalModal.show();
                that.deleteGoalModal.addAttributeToElement(that.deleteGoalModal.id, "#deleteGoalConfirm", "goalplanpropertypk", button.dataset.goalplanpropertypk);

                if (button.id) {
                    that.deleteGoalModal.callingId = button.id;
                }
            });
        }

        let deleteGoalConfirmButton = document.getElementById("deleteGoalConfirm");
        if (deleteGoalConfirmButton !== null) {
            deleteGoalConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("goalplanpropertypk" in deleteGoalConfirmButton.dataset && parseInt(deleteGoalConfirmButton.dataset.goalplanpropertypk) > 0) {
                    that.deleteGoal(deleteGoalConfirmButton.dataset.goalplanpropertypk);
                } else {
                    that.deleteGoalModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal", "error", 3000, null);
                }
            });
        }

        let deleteGoalCancelButton = document.getElementById("deleteGoalCancel");
        if (deleteGoalCancelButton !== null) {
            deleteGoalCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteGoalModal.hide();
            });
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportWord = document.getElementById("exportGoalSettingWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "GoalSettingDataExport", "goalsetting"));
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    omitQuarterlyTargets(element: HTMLInputElement) {
        let core = this._core;

        if (element.readOnly && !("omitset" in element.dataset)) {
            if (element.checked) {
                element.checked = false;
            } else {
                element.checked = true;
            }
            return false;
        } else {
            let quarterlyTargets = document.querySelectorAll(`.goalSettingField[data-quarterlytarget='true'][data-row='${element.dataset.row}'][data-buttonrow='${element.dataset.buttonrow}']`) as NodeListOf<HTMLInputElement>;

            for (let target of quarterlyTargets) {
                element.dataset.omitset = "true";
                if (element.checked) {
                    //Make optional
                    core.forceElementOptional(target);
                    target.readOnly = true;
                    target.value = '';
                } else {
                    //Make required (if first)
                    if (("index" in target.dataset) && target.dataset.index == "0") {
                        core.forceElementRequired(target);
                    } else {
                        core.forceElementOptional(target);
                    }
                    target.readOnly = false;
                }    
            }
        }
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("goalSettingForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let pageFK = parseInt(formElement.dataset.pagefk);
        let goals = [];

        let goalStatements = document.querySelectorAll(".goalSettingField[data-propertycode='goalSettingMeasurableGoalStatement']");
        for (let goal of goalStatements) {

            let goalElement = <HTMLTextAreaElement>goal;
            let goalStatementPropertyPK: number = 0;
            let goalStatement: IPlanPropertyExtended = {
                PlanPropertyPK: parseInt(goalElement.dataset.planpropertypk),
                PlanFK: planFK,
                PropertyFK: parseInt(goalElement.dataset.propertypk),
                TextValue: goalElement.value,
                LookupCodeFK: null,
                RowNbr: parseInt(goalElement.dataset.row),
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(goalElement.dataset.datapointplanpropertypk),
                ButtonRow: parseInt(goalElement.dataset.buttonrow)
            };

            //Get fields associated with goal statement
            let goalDetails = [];
            let goalDetailsElements = document.querySelectorAll(`.goalSettingField[data-row='${goalElement.dataset.row}']:not([data-propertycode='goalSettingMeasurableGoalStatement']), .goalSettingLookupField[data-row='${goalElement.dataset.row}']:not([data-propertycode='goalSettingMeasurableGoalStatement'])`);
            for (let detail of goalDetailsElements) {
                if (detail.classList.contains("goalSettingLookupField")) {
                    let htmlElement = <HTMLSelectElement>detail;
                    let planPropertyPK: number = 0;
                    let dataPointPlanPropertyPK: number = 0;
                    let rowNumber: number = parseInt(htmlElement.dataset.row);
                    let buttonRow: number = parseInt(htmlElement.dataset.buttonrow);

                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

                    if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                        dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

                    if (htmlElement.value !== "" || planPropertyPK > 0) {
                        let saveItem: IPlanPropertyExtended = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planFK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: "",
                            LookupCodeFK: parseInt(htmlElement.value),
                            RowNbr: rowNumber,
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                            ButtonRow: buttonRow
                        };

                        goalDetails.push(saveItem);
                    }
                } else {
                    let htmlElement = <HTMLInputElement>detail;
                    let planPropertyPK: number = 0;
                    let doNotInclude: boolean = false;
                    let rowNumber: number = parseInt(htmlElement.dataset.row);
                    let buttonRow: number = parseInt(htmlElement.dataset.buttonrow);

                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

                    let value = htmlElement.value;

                    if (htmlElement.classList.contains("goalSettingCheckbox")) {
                        if (htmlElement.checked) {
                            value = "on";
                        } else {
                            value = "off";
                            if (planPropertyPK === 0) {
                                doNotInclude = true;
                            }
                        }
                    }

                    if ((value || planPropertyPK > 0) && !doNotInclude) {
                        let saveItem: IPlanPropertyExtended = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planFK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: value,
                            LookupCodeFK: null,
                            RowNbr: rowNumber,
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: goalStatement.PlanPropertyPK,
                            ButtonRow: buttonRow
                        };

                        goalDetails.push(saveItem);
                    }
                }

            }

            let goalSettingSaveData: IGoalSettingMeasurableGoal = {
                GoalDetails: goalDetails,
                GoalStatement: goalStatement
            }

            goals.push(goalSettingSaveData);
        }

        let saveData = JSON.stringify({
            "PlanFK": planFK,
            "PageFK": pageFK,
            "MeasurableGoals": goals
        });

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveGoalSetting', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (goals.length === 0) {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(saveData);
        }
    }

    deleteGoal(goalPlanPropertyPK: string) {
        let that = this;
        that.deleteGoalModal.hide();

        let goal = document.querySelector(`[data-planpropertypk='${goalPlanPropertyPK}']`) as HTMLTextAreaElement;

        if (goal != null && "row" in goal.dataset) {
            let container = document.getElementById(goal.dataset.container) as HTMLDivElement;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/DeleteGoal', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Goal successfully deleted", 'success', 3000, null);

                    //reset fields
                    let goalFields = document.querySelectorAll(`.goalSettingField[data-row='${goal.dataset.row}'], .goalSettingLookupField[data-row='${goal.dataset.row}']`);
                    for (let field of goalFields) {
                        let htmlElement = <HTMLElement>field;
                        //Lookup
                        if (field instanceof HTMLSelectElement) {
                            let htmlField = <HTMLSelectElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.selectedIndex = 0;
                        } else if (field.classList.contains("goalSettingCheckbox")) {
                            //checkbox
                            let htmlField = <HTMLInputElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.checked = false;
                            that.omitQuarterlyTargets(htmlField);
                        } else if (field instanceof HTMLInputElement ) {
                            let htmlField = <HTMLInputElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        } else if (field instanceof HTMLTextAreaElement) {
                            let htmlField = <HTMLTextAreaElement>field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        }

                        //remove delete button for the goal
                        let deleteButton = document.querySelector(`.deleteGoal[data-goalplanpropertypk='${goalPlanPropertyPK}']`);
                        if (deleteButton != null) {
                            deleteButton.parentNode.removeChild(deleteButton);
                        }
                    }
                    let firstField = goalFields[0] as HTMLElement;
                    firstField.focus();
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted goal", "success", 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(goalPlanPropertyPK);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
        }
    }
}

//ActionPlan
class ActionPlan {

    validationClasses: string[];
    private deleteActionPlanModal: Modal;
    private deleteActionStepModal: Modal;
    private deleteSystemicChallengeModal: Modal;
    private _core: Core;

    constructor() {
        let that = this;
        this._core = new Core();
        this.deleteActionPlanModal = new Modal("deleteActionPlanModal", null);
        this.deleteActionStepModal = new Modal("deleteActionStepModal", null);
        this.deleteSystemicChallengeModal = new Modal("deleteSystemicChallengeModal", null);
        this.validationClasses = [
            "actionPlanField",
            "actionPlanSelectedMeasurableGoals",
            "actionPlanPDStepField",
            "actionPlanComStepField",
            "actionPlanDesignatedSchoolField",
            "actionPlanCheckboxField",
            "actionPlanDesignatedSchoolSelectField"
        ];

        let addEvidenceBasedStrategyButton = document.getElementById("addMoreEvidenceBasedStrategies");
        if (addEvidenceBasedStrategyButton !== null) {
            addEvidenceBasedStrategyButton.addEventListener("click", (e: Event) => this.addEvidenceBasedStrategies("actionPlanEvidenceBasedStrategy"));
        }

        let numberTextBox = document.getElementById("addMoreEvidenceBasedStrategiesNumber");
        if (numberTextBox !== null) {
            numberTextBox.addEventListener("change", (e: Event) => this.checkForPlural());
        }

        let saveButton = document.getElementById("actionPlanSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let measurableGoalsCheckboxes = document.getElementsByClassName("actionPlanSelectedMeasurableGoals");
        for (let measurableGoalsCheckbox of measurableGoalsCheckboxes)
            measurableGoalsCheckbox.addEventListener("click", (e: Event) => this.checkForDisabled(e));

        this.bindCreateActionPlans();
        this.bindAddAnActionStep();
        this.bindMeasurableGoals();
        
        let deleteActionPlanButtons = document.querySelectorAll(".deleteActionPlan") as NodeListOf<HTMLButtonElement>;
        for (let button of deleteActionPlanButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActionPlanModal.show();
                that.deleteActionPlanModal.addAttributeToElement(that.deleteActionPlanModal.id, "#deleteActionPlanConfirm", "actionplanplanpropertypk", button.dataset.actionplanplanpropertypk);

                if (button.id) {
                    that.deleteActionPlanModal.callingId = button.id;
                }
            });
        }

        let deleteActionPlanConfirmButton = document.getElementById("deleteActionPlanConfirm");
        if (deleteActionPlanConfirmButton !== null) {
            deleteActionPlanConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("actionplanplanpropertypk" in deleteActionPlanConfirmButton.dataset && parseInt(deleteActionPlanConfirmButton.dataset.actionplanplanpropertypk) > 0) {
                    that.deleteActionPlan(deleteActionPlanConfirmButton.dataset.actionplanplanpropertypk);
                } else {
                    that.deleteActionPlanModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action plan", "error", 3000, null);
                }
            });
        }

        let deleteActionPlanCancelButton = document.getElementById("deleteActionPlanCancel");
        if (deleteActionPlanCancelButton !== null) {
            deleteActionPlanCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActionPlanModal.hide();
            });
        }

        let deleteActionStepButtons = document.querySelectorAll(".deleteActionStep") as NodeListOf<HTMLButtonElement>;
        for (let button of deleteActionStepButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActionStepModal.show();
                that.deleteActionStepModal.addAttributeToElement(that.deleteActionStepModal.id, "#deleteActionStepConfirm", "actionstepplanpropertypk", button.dataset.actionstepplanpropertypk);
                if (button.id) {
                    that.deleteActionStepModal.callingId = button.id;
                }
            });
        }

        let deleteActionStepConfirmButton = document.getElementById("deleteActionStepConfirm");
        if (deleteActionStepConfirmButton !== null) {
            deleteActionStepConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("actionstepplanpropertypk" in deleteActionStepConfirmButton.dataset && parseInt(deleteActionStepConfirmButton.dataset.actionstepplanpropertypk) > 0) {
                    that.deleteActionStep(deleteActionStepConfirmButton.dataset.actionstepplanpropertypk);
                } else {
                    that.deleteActionStepModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action step", "error", 3000, null);
                }
            });
        }

        let deleteActionStepCancelButton = document.getElementById("deleteActionStepCancel");
        if (deleteActionStepCancelButton !== null) {
            deleteActionStepCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActionStepModal.hide();
            });
        }

        this.checkForCSISchools();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidation(this.validationClasses);
        }

        this.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportWord = document.getElementById("exportActionPlanWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "ActionPlanDataExport", "actionplan"));
        }

        //use event delegation for up/down buttons that are possibly added dynamically
        document.addEventListener("click", function (event) {
            let target = <HTMLElement>event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("up-down") && target.classList.contains("up")) {
                that.moveActionStep(event, true);
            }
        });

        document.addEventListener("click", function (event) {
            let target = <HTMLElement>event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("up-down") && target.classList.contains("down")) {
                that.moveActionStep(event, false);
            }
        });

        this.recalculateUpDown();

        const addDesignatedSchoolSupportButtons = document.getElementsByClassName("addDesignatedSchoolSupport");
        for (const addDesignatedSchoolSupportButton of addDesignatedSchoolSupportButtons)
            addDesignatedSchoolSupportButton.addEventListener("click", (e: Event) => this.addDesignatedSchoolSupport(e));

        const actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools = document.getElementById("actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools");
        if (actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools !== null)
            actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools.addEventListener("change", (e: Event) => this.checkForCSISchools());

        this.bindDeleteSystemicLEAChallenge();

        const deleteSystemicLEAChallengeConfirm = document.getElementById("deleteSystemicLEAChallengeConfirm");
        if (deleteSystemicLEAChallengeConfirm !== null)
            deleteSystemicLEAChallengeConfirm.addEventListener("click", (e: Event) => this.deleteLEASystemicChallengeConfirm(e.target as HTMLButtonElement));

        const deleteSystemicLEAChallengeCancel = document.getElementById("deleteSystemicLEAChallengeCancel");
        if (deleteSystemicLEAChallengeCancel !== null)
            deleteSystemicLEAChallengeCancel.addEventListener("click", (e: Event) => this.deleteLEASystemicChallengeCancel());
    }

    initializeRequiredFields(allClasses: string[]) {

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");
        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }

    doValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk and datapointplanpropertypk)
                var otherElements = document.querySelectorAll(`[data-datapointplanpropertypk='${htmlElement.dataset.datapointplanpropertypk}'][data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    } else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");

                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked: boolean = false;
                                    let isAlreadyValidated: boolean = false;

                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }

                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }

                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            } else {
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                if (OtherInputElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "checkbox") {
                                    if (OtherInputElement.checked) {
                                        alreadyExists = true;
                                    }
                                } else {
                                    if (OtherInputElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                            }
                        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            } else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    checkForDisabled(e: Event) {
        let checkbox = <HTMLInputElement>e.target;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;

            return false;
        }
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("actionPlanForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let allActionPlans = [];
        let allActionSteps = [];
        let allPdSteps = [];
        let allComSteps = [];
        let allGoals = [];
        let allOthers = [];
        let actionStepOthers = [];

        let allElements = document.getElementsByClassName("actionPlanField");
        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionPlanSelectedMeasurableGoals")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.checked) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("actionPlanPDStepField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }

                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("actionPlanComStepField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let toDelete = (htmlElement.value === "");

                let value = htmlElement.value;

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("evidenceBasedStrategyName") && saveItem !== undefined)
                allActionPlans.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanActionStepField") && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanPDStepField") && saveItem !== undefined)
                allPdSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanComStepField") && saveItem !== undefined)
                allComSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanSelectedMeasurableGoals") && saveItem !== undefined)
                allGoals.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined)
                actionStepOthers.push(saveItem);
            else
                if (saveItem !== undefined)
                    allOthers.push(saveItem);
        }

        let allActionPlanCheckboxElements = document.getElementsByClassName("actionPlanCheckboxField");
        for (let element of allActionPlanCheckboxElements) {
            const ele = <HTMLInputElement>element;
            let textValue = "off";
            if (ele.checked)
                textValue = "on";

            const planPropertyPK = ele.dataset.planpropertypk;
            const rowNumber = 0;
            const dataPointPlanPropertyPK = 0;
            const buttonRow = -1;


            const saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planFK,
                PropertyFK: parseInt(ele.dataset.propertypk),
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                ButtonRow: buttonRow
            }

            allOthers.push(saveItem);
        }

        const allDesignatedSchoolsSaveElements = [];
        const allDesignatedSchoolElements = document.getElementsByClassName("actionPlanDesignatedSchoolField");
        for (const element of allDesignatedSchoolElements) {
            const ele = <HTMLTextAreaElement>element;
            const dataPointPlanPropertyPK = ele.dataset.datapointplanpropertypk;
            const propertyPK = ele.dataset.propertypk;
            const row = ele.dataset.row;
            const planPropertyPK = ele.dataset.planpropertypk;
            const textValue = ele.value;

            if (textValue !== '' || parseInt(planPropertyPK) > 0) {
                const saveItem: IPlanPropertyExtended = {
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                    IsDeletedInd: false,
                    LookupCodeFK: null,
                    PlanFK: planFK,
                    PlanPropertyPK: parseInt(planPropertyPK),
                    PropertyFK: parseInt(propertyPK),
                    RowNbr: parseInt(row),
                    TextValue: textValue
                };

                allDesignatedSchoolsSaveElements.push(saveItem);
            }
        }

        const allDesignatedSchoolSelectElements = document.getElementsByClassName("actionPlanDesignatedSchoolSelectField");
        for (const element of allDesignatedSchoolSelectElements) {
            const ele = <HTMLSelectElement>element;
            const dataPointPlanPropertyPK = ele.dataset.datapointplanpropertypk;
            const propertyPK = ele.dataset.propertypk;
            const row = ele.dataset.row;
            const planPropertyPK = ele.dataset.planpropertypk;
            const lookupCodeFK = parseInt(ele.value);

            if (lookupCodeFK > 0 || parseInt(planPropertyPK) > 0) {
                const saveItem: IPlanPropertyExtended = {
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                    IsDeletedInd: false,
                    LookupCodeFK: lookupCodeFK,
                    PlanFK: planFK,
                    PlanPropertyPK: parseInt(planPropertyPK),
                    PropertyFK: parseInt(propertyPK),
                    RowNbr: parseInt(row),
                    TextValue: ""
                };

                allDesignatedSchoolsSaveElements.push(saveItem);
            }
        }

        let saveElement = {
            "PlanFK": planFK,
            "ActionPlans": allActionPlans,
            "ActionSteps": allActionSteps,
            "PDSteps": allPdSteps,
            "ComSteps": allComSteps,
            "GoalSteps": allGoals,
            "ActionStepOther": actionStepOthers,
            "Others": allOthers,
            "DesignatedSchoolData": allDesignatedSchoolsSaveElements
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveActionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    //Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLongLoader();
                    //Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader(); //Core.hideLoader();
                }
                else {
                    Core.hideLongLoader(); //Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionPlans.length === 0 && saveElement.ActionStepOther.length === 0 && saveElement.ActionSteps.length === 0 && saveElement.ComSteps.length === 0 && saveElement.GoalSteps.length === 0 && saveElement.Others.length === 0 && saveElement.PDSteps.length === 0 && saveElement.DesignatedSchoolData.length === 0) {
            if (referrer === "save") {
                core.pageReload(true, planFK);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader(); //Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }

    addActionStep(buttonRow: number) {
        let that = this;
        let core = this._core;
        let newRow: number = 0;

        let formElement = document.getElementById("actionPlanForm");
        let planFK = parseInt(formElement.dataset.planfk);

        let actionStepFields = document.getElementsByClassName("actionPlanActionStepField");
        for (let action of actionStepFields) {
            let actionElement = <HTMLInputElement>action;
            if (buttonRow === parseInt(actionElement.dataset.buttonrow)) {
                if (newRow < parseInt(actionElement.dataset.row)) {
                    newRow = parseInt(actionElement.dataset.row);
                }
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/AddActionStep', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".actionStepContainer[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "New Action Step Added", 'success', 3000, null);
                that.recalculateUpDown();
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&newRow=" + newRow + "&buttonRow=" + buttonRow);
    }

    addEvidenceBasedStrategies(classNameBase: string) {
        let core = this._core;
        let addEvidenceBasedStrategyNumber = <HTMLInputElement>document.getElementById("addMoreEvidenceBasedStrategiesNumber");
        let planFKElement = document.getElementById("actionPlanForm");
        let planFK = parseInt(planFKElement.dataset.planfk);
        let _that = this;

        if (addEvidenceBasedStrategyNumber.value === "") {

        } else {
            let value = parseInt(addEvidenceBasedStrategyNumber.value);

            let table = (<HTMLTableElement>document.getElementById("evidenceBasedStrategy"));

            let findRows = document.getElementsByClassName("addAnActionStep");
            let rowCount = 0;
            for (let row of findRows) {
                let element = <HTMLButtonElement>row;
                if (rowCount < parseInt(element.dataset.buttonrow)) {
                    rowCount = parseInt(element.dataset.buttonrow);
                }
            }

            for (let i = 1; i <= value; i++) {
                rowCount++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Go/EvidenceBasedStrategy');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#evidenceBasedStrategy > tbody").append("<tr><td>" + xhr.responseText + "</td></tr>");

                        $('.multiSelectMeasurableGoals').MultiSelect();
                        _that.bindCreateActionPlans();
                        _that.bindAddAnActionStep();
                        new CustomAccordion("actionPlanActionPlanAccordion" + rowCount.toString());
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "New Evidence-Based Strategy Row Added", 'success', 3000, null);
                        _that.recalculateUpDown();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount + "&planFK=" + planFK);
            }
        }
    }

    bindAddAnActionStep() {
        let addActionSteps = document.getElementsByClassName("addAnActionStep");
        for (let action of addActionSteps) {
            let addActionStep = <HTMLButtonElement>action;
            if (addActionStep.dataset.listener === "true") {

            } else {
                let buttonRow = parseInt(addActionStep.dataset.buttonrow);
                addActionStep.addEventListener("click", (e: Event) => this.addActionStep(buttonRow))
                addActionStep.dataset.listener = "true";
            }
        }
    }

    bindCreateActionPlans() {
        let actionPlanButtons = document.getElementsByClassName("createActionPlan");

        for (let button of actionPlanButtons) {
            let but = <HTMLButtonElement>button;
            if (but.dataset.listener === "true") {

            } else {
                but.addEventListener("click", (e: Event) => this.addActionPlan((<HTMLButtonElement>e.target)));
                but.dataset.listener = "true";
            }
        }
    }

    addActionPlan(button: HTMLButtonElement) {
        let core = this._core;
        //Make sure there is a title in the Evidence Based-Strategy name
        let _that = this;
        let dontDoIt: boolean = false;
        let row = button.dataset.row;
        let textElement = document.getElementsByClassName("evidenceBasedStrategyName");
        let hasName: boolean = true;
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                    hasName = false;
                }
            }
        }

        let selectedSequence = this.itemsSelected(row);
        if (selectedSequence.length === 0) {
            dontDoIt = true;
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.row;
            let textBoxId = "actionPlanEvidenceBasedStrategyName" + rowNumber.toString();
            let evidenceNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "Action Plan for: " + evidenceNameElement.value;
            let accordionParent = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.row == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            evidenceNameElement.readOnly = true;

            //Now show the accordion.
            let actionPlanElements = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var actionPlanElement: HTMLDivElement;

            for (let ap of actionPlanElements) {
                let ape = <HTMLDivElement>ap;
                if (ape.dataset.row === rowNumber) {
                    actionPlanElement = ape;
                }
            }
            //Get the measurable goals list to display inside the accordion
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/MeasurableGoalsList', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let element = document.getElementById("actionPlanActionPlanSection" + rowNumber);
                    let divElement = <HTMLDivElement>element;
                    let measurableGoals = divElement.querySelector(".actionPlanMeasurableGoalsReaderList");
                    let measurableGoalsElement = <HTMLUListElement>measurableGoals;

                    $(measurableGoalsElement).append(xhr.responseText);

                    let allMeasurableGoals = divElement.querySelectorAll(".measurableGoalModal");
                    for (let goal of allMeasurableGoals) {
                        let goalElement = <HTMLAnchorElement>goal;
                        let modalValue: string = goalElement.dataset.measurablegoal;
                        goal.addEventListener("click", (e: Event) => _that.showGoalModal(modalValue));
                    }
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Plan Row Added", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(selectedSequence));

            actionPlanElement.classList.remove("hide-action-plan");
            actionPlanElement.classList.add("open");

            button.classList.add("hide-action-plan");
            _that.recalculateUpDown();
        } else {
            if (!hasName) {
                Core.createHTMLAlert("alertMessageDiv", "A name for the evidence-based strategy must be provided", 'error', 3000, null);
            } else if (selectedSequence.length === 0) {
                Core.createHTMLAlert("alertMessageDiv", "At least one measurable goal must be selected", 'error', 3000, null);
            }
                
        }
    }

    bindMeasurableGoals() {
        let allMeasurableGoals = document.querySelectorAll(".measurableGoalModal");
        for (let goal of allMeasurableGoals) {
            let goalElement = <HTMLAnchorElement>goal;
            let modalValue: string = goalElement.dataset.measurablegoal;
            goal.addEventListener("click", (e: Event) => this.showGoalModal(modalValue));
        }
    }

    showGoalModal(modalText: string) {
        let element = <HTMLDivElement>document.getElementById("actionPlanMeasurableGoalsDescription");

        element.innerHTML = modalText;

        let modal = new Modal('ActionPlanMeasurableGoalDescriptionModal', null);
        modal.show();
    }

    itemsSelected(row: string): string[] {
        let selectElements = document.getElementsByClassName("multiSelectMeasurableGoals");
        let elementSequence = [];

        for (let selectElement of selectElements) {
            let element = <HTMLDivElement>selectElement;

            if (element.dataset.row === row) {
                let selection = element.querySelectorAll("ul li.selected");
                if (selection.length > 0) {
                    for (let s of selection) {
                        let sElement = <HTMLLIElement>s;
                        let nextElement = sElement.querySelector("input");
                        let inputElement = <HTMLElement>nextElement;
                        elementSequence.push(inputElement.dataset.planpropertypk);
                    }
                }
            }
        }

        return elementSequence;
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("strategyWord");
        let num = <HTMLInputElement>document.getElementById("addMoreEvidenceBasedStrategiesNumber")
        let newWord = "strategies";
        if (parseInt(num.value) === 1) {
            newWord = "strategy";
        }

        word.textContent = newWord;
    }

    deleteActionPlan(actionPlanPlanPropertyPK: string) {
        let that = this;
        that.deleteActionPlanModal.hide();

        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteActionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Action Plan successfully deleted", 'success', 3000, null);

                //Get next focusable evidence based strategy name
                let strategyNames = document.querySelectorAll(".evidenceBasedStrategyName");
                if (strategyNames.length > 1) {
                    let arr = Array.prototype.slice.call(strategyNames);
                    let thisStrategyName = document.querySelector(`.evidenceBasedStrategyName[data-planpropertypk='${actionPlanPlanPropertyPK}']`);
                    if (thisStrategyName != null) {
                        let index = arr.indexOf(thisStrategyName);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                } else {
                    //Focus on add button if no strategies left
                    let addButton = document.getElementById("addMoreEvidenceBasedStrategies");
                    if (addButton != null) {
                        addButton.focus();
                    }
                }

                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractionplanplanpropertypk='${actionPlanPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action plan. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(actionPlanPlanPropertyPK);
    }

    deleteActionStep(actionStepPlanPropertyPK: string) {
        let that = this;
        that.deleteActionStepModal.hide();

        let actionStepDeleteButton = document.querySelector(`.deleteActionStep[data-actionstepplanpropertypk='${actionStepPlanPropertyPK}']`) as HTMLButtonElement;

        if (actionStepDeleteButton != null && "container" in actionStepDeleteButton.dataset) {
            let container = document.getElementById(actionStepDeleteButton.dataset.container);
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/DeleteActionStep', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Action Step successfully deleted", 'success', 3000, null);

                    //remove from DOM
                    if (container != null) {
                        let nextFocusable = Core.getNextFocusableElement(actionStepDeleteButton);
                        container.parentNode.removeChild(container);
                        that.recalculateUpDown();
                        that._core.initializeRequiredFields(that.validationClasses, true);
                        nextFocusable.focus();
                    }

                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action step. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(actionStepPlanPropertyPK);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action step. Please try again later.", "error", 3000, null);
        }
    }

    recalculateUpDown() {
        let actionStepContainers = document.getElementsByClassName("actionStepContainer") as HTMLCollectionOf<HTMLDivElement>;
        for (let container of actionStepContainers) {
            let actionSteps = container.querySelectorAll(".actionStepsRow");
            if (actionSteps != null) {
                let i = 1;
                for (let actionStep of actionSteps) {

                    let upArrow = actionStep.querySelector("button.up") as HTMLButtonElement;
                    let downArrow = actionStep.querySelector("button.down") as HTMLButtonElement;

                    if (upArrow != null && downArrow != null && upArrow.dataset.canedit == "true" && downArrow.dataset.canedit == "true") {
                        //Reset the state up the up and down arrows
                        upArrow.classList.remove("hide");
                        upArrow.disabled = false;
                        downArrow.classList.remove("hide");
                        downArrow.disabled = false;

                        if (actionSteps.length == 1) {
                            //Only one action step so hide the up/down arrows
                            upArrow.classList.add("hide");
                            downArrow.classList.add("hide");
                        } else {
                            if (i === 1) {
                                upArrow.disabled = true;
                            }

                            if (i === actionSteps.length) {
                                downArrow.disabled = true;
                            }
                        }
                    }
                    i++;
                }
            }
        }
    }

    moveActionStep(e: Event, up: boolean) {
        let that = this;
        e.preventDefault();
        let button = <HTMLButtonElement>e.target;
        if (button != null) {
            let actionStepContainer = <HTMLDivElement>Core.findClosest(button, ".action-steps-row");
            if (actionStepContainer != null) {
                let sibling: Element;
                if (up) {
                    sibling = actionStepContainer.previousElementSibling;
                    actionStepContainer.parentNode.insertBefore(actionStepContainer, sibling);
                } else {
                    sibling = actionStepContainer.nextElementSibling;
                    actionStepContainer.parentNode.insertBefore(sibling, actionStepContainer);
                }

                let siblingElement = <HTMLElement>sibling;
                let thisRow = actionStepContainer.dataset.overriderow;
                let siblingRow = siblingElement.dataset.overriderow;

                //swap override row number for the two
                actionStepContainer.dataset.overriderow = siblingRow;
                let thisActionStepFields = actionStepContainer.querySelectorAll(`[data-overriderow='${thisRow}']`);
                for (let field of thisActionStepFields) {
                    let htmlField = <HTMLElement>field;
                    htmlField.dataset.overriderow = siblingRow;
                }

                siblingElement.dataset.overriderow = thisRow;
                let siblingActionStepFields = siblingElement.querySelectorAll(`[data-overriderow='${siblingRow}']`);
                for (let field of siblingActionStepFields) {
                    let htmlField = <HTMLElement>field;
                    htmlField.dataset.overriderow = thisRow;
                }

                this.recalculateUpDown();

                if (button.disabled) {
                    if (up) {
                        let downButton = <HTMLButtonElement>actionStepContainer.querySelector("button.up-down.down");
                        if (downButton != null && !downButton.disabled) {
                            downButton.focus();
                        }
                    } else {
                        let upButton = <HTMLButtonElement>actionStepContainer.querySelector("button.up-down.up");
                        if (upButton != null && !upButton.disabled) {
                            upButton.focus();
                        }
                    }
                } else {
                    button.focus();
                }

                Core.createHTMLAlert("alertMessageDiv", "Action Step Has Been Reordered", "success", 3000, null);
            }
        }

        this._core.initializeRequiredFields(this.validationClasses, true);
    }

    async addDesignatedSchoolSupport(e: Event) {
        Core.showLoader();
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const formElement = <HTMLDivElement>document.getElementById("actionPlanForm");
        const planFK = formElement.dataset.planfk;


        const allRows = document.querySelectorAll(`.actionPlanDesignatedSchoolSupportRecord[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let maxRow = 0;
        for (const theRow of allRows) {
            const ele = <HTMLDivElement>theRow;
            const row = parseInt(ele.dataset.row);
            if (!isNaN(row)) {
                if (row > maxRow)
                    maxRow = row;
            }
        }

        maxRow++;

        const response = await fetch(`/Go/AddActionPlanDesignatedSchoolsSupport/${planFK}/${dataPointPlanPropertyPK}/${maxRow}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.text();

            const newDiv = <HTMLDivElement>document.createElement("div");
            newDiv.classList.add("actionPlanDesignatedSchoolSupportRecord");
            newDiv.classList.add("technical-support-row");
            newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
            newDiv.dataset.row = maxRow.toString();
            newDiv.innerHTML = output;

            const container = document.querySelector(`.designatedSchoolsSystemicLEAChallenge[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            container.append(newDiv);

            this.bindDeleteSystemicLEAChallenge();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", `New support successfully added.`, 'success', 3000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${response.status}'`, 'error', 3000, null);
        }
    }

    checkForCSISchools() {
        const actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools = <HTMLInputElement>document.getElementById("actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools");
        if (actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools !== null) {

            const fundingSources = <HTMLInputElement>document.getElementById("fundingSources");

            if (actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools.checked) {
                this._core.forceElementOptional(fundingSources);
                fundingSources.readOnly = true;
                fundingSources.disabled = true;
            } else {
                this._core.forceElementRequired(fundingSources);
                fundingSources.readOnly = false;
                fundingSources.disabled = false;
            }
        }
    }

    bindDeleteSystemicLEAChallenge() {
        const deleteTechnicalSupportButtons = document.getElementsByClassName("deleteTechnicalSupport");
        for (const deleteTechnicalSupportButton of deleteTechnicalSupportButtons)
            deleteTechnicalSupportButton.addEventListener("click", (e: Event) => this.showDeleteLEASystemicChallenge(e));
    }

    showDeleteLEASystemicChallenge(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const row = button.dataset.row;

        this.deleteSystemicChallengeModal.addAttributeToElement("deleteSystemicChallengeModal", "#deleteSystemicLEAChallengeConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        this.deleteSystemicChallengeModal.addAttributeToElement("deleteSystemicChallengeModal", "#deleteSystemicLEAChallengeConfirm", "row", row);

        this.deleteSystemicChallengeModal.show();
    }

    async deleteLEASystemicChallengeConfirm(button: HTMLButtonElement) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const row = button.dataset.row;

        const allPlanPropElements = document.querySelectorAll(`.actionPlanDesignatedSchoolField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
        const allPlanProps = [];
        for (const planProp of allPlanPropElements) {
            const ele = <HTMLElement>planProp;
            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "0")
                allPlanProps.push(ele.dataset.planpropertypk);
        }

        const allSelectPlanPropElements = document.querySelectorAll(`.actionPlanDesignatedSchoolSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
        for (const planProp of allSelectPlanPropElements) {
            const ele = <HTMLElement>planProp;
            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "0")
                allPlanProps.push(ele.dataset.planpropertypk);
        }        

        const actionPlanForm = <HTMLElement>document.getElementById("actionPlanForm");
        const planFK = actionPlanForm.dataset.planfk;

        if (allPlanProps.length > 0) {

            const deleteData = {
                "PlanProperties": allPlanProps,
                "PlanFK": planFK,
                "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                "Row": row
            }

            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(deleteData)
            };

            const response = await fetch("/Go/DeleteActionPlanSystemicChallengeRow", settings);
            if (response.ok) {
                const output = await response.json();

                if (output.success === true) {
                    const container = document.querySelector(`.actionPlanDesignatedSchoolSupportRecord[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    container.remove();
                }

                this.deleteSystemicChallengeModal.hide();
            }
        }
        else {
            const allInputs = document.querySelectorAll(`.actionPlanDesignatedSchoolField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
            const allSelects = document.querySelectorAll(`.actionPlanDesignatedSchoolSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}'] select`);
            for (const input of allInputs) {
                const ele = <HTMLInputElement>input;
                ele.value = "";
            }
            for (const select of allSelects) {
                const ele = <HTMLSelectElement>select;
                ele.selectedIndex = 0;
            }

            this.deleteSystemicChallengeModal.hide();
        }
    }

    deleteLEASystemicChallengeCancel() {
        this.deleteSystemicChallengeModal.hide();
    }
}

// SchoolPlanExpenditureTables
class SchoolPlanExpenditureTables {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.sumAllAmounts();
        this.sumAllSchoolwideAmounts();
        this.allNoFunding();

        this.validationClasses = ["schoolPlanExpenditureTablesField", "schoolPlanExpenditureTablesCheckboxField", "schoolPlanExpenditureTablesSelectField", "schoolPlanExpenditureTablesMultiSelectField"];
        
        let schoolPlanExpenditureTablesSaveButton = document.getElementById("schoolPlanExpenditureTablesSave");
        if (schoolPlanExpenditureTablesSaveButton !== null)
            schoolPlanExpenditureTablesSaveButton.addEventListener("click", (e: Event) => this.save("save"));
        this.initializeRequiredFieldsCustom();
        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const schoolPlanExpenditureTablesSchoolImprovementAddRow = document.getElementById("schoolPlanExpenditureTablesSchoolImprovementAddRow");
        if (schoolPlanExpenditureTablesSchoolImprovementAddRow !== null)
            schoolPlanExpenditureTablesSchoolImprovementAddRow.addEventListener("click", (e: Event) => this.addSchoolImprovementRow());

        const schoolPlanExpenditureTablesSchoolwideAddRow = document.getElementById("schoolPlanExpenditureTablesSchoolwideTitle1AddRow");
        if (schoolPlanExpenditureTablesSchoolwideAddRow !== null)
            schoolPlanExpenditureTablesSchoolwideAddRow.addEventListener("click", (e: Event) => this.addSchoolwideRow());

        this.bindSumAllAmounts();

        this.bindDeleteButtons();

        const deleteExpenditureRowConfirm = document.getElementById("deleteExpenditureRowConfirm");
        if(deleteExpenditureRowConfirm !== null)
            deleteExpenditureRowConfirm.addEventListener("click", (e: Event) => this.deleteExpenditureConfirm(e));

        const deleteExpenditureRowCancel = document.getElementById("deleteExpenditureRowCancel");
        if (deleteExpenditureRowCancel !== null)
            deleteExpenditureRowCancel.addEventListener("click", (e: Event) => this.deleteExpenditureCancel());

        const noFundingCheckboxes = document.getElementsByClassName("schoolPlanExpenditureTablesSchoolDoesNot");
        for (const noFundingCheckbox of noFundingCheckboxes)
            noFundingCheckbox.addEventListener("change", (e: Event) => this.noFunding(e.target as HTMLInputElement));

        const schoolPlanExpenditureTablesSchoolwideTitle1ExportToExcel = document.getElementById("schoolPlanExpenditureTablesSchoolwideTitle1ExportToExcel");
        if (schoolPlanExpenditureTablesSchoolwideTitle1ExportToExcel !== null)
            schoolPlanExpenditureTablesSchoolwideTitle1ExportToExcel.addEventListener("click", (e: Event) => this.exportTitle1ToExcel());

        const schoolPlanExpenditureTablesSchoolImprovementExportToExcel = document.getElementById("schoolPlanExpenditureTablesSchoolImprovementExportToExcel");
        if (schoolPlanExpenditureTablesSchoolImprovementExportToExcel !== null)
            schoolPlanExpenditureTablesSchoolImprovementExportToExcel.addEventListener("click", (e: Event) => this.exportSchoolImprovementToExcel());

        const closeComingSoon = document.getElementById("closeComingSoon");
        if (closeComingSoon !== null)
            closeComingSoon.addEventListener("click", (e: Event) => this.closeComingSoon());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const allMultiSaveElements = [];
        const core = this._core;

        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("schoolPlanExpenditureTablesForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("schoolPlanExpenditureTablesField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const selects = document.getElementsByClassName("schoolPlanExpenditureTablesSelectField");
        for (const ele of selects) {
            let planPropertyPK = 0;
            const element = <HTMLSelectElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if ((element.value !== "" && element.value !== "0") || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("schoolPlanExpenditureTablesCheckboxField");
        for (const ele of checkboxes) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        const multiCheckboxes = document.getElementsByClassName("schoolPlanExpenditureTablesMultiCheckboxField");
        for (const ele of multiCheckboxes) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;

            const dropDownPropertyPK = element.parentElement.parentElement.parentElement.parentElement.dataset.propertypk;

            const buttonRowNumber = element.parentElement.parentElement.parentElement.parentElement.dataset.row;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            const multiDropdownDataPointPlanPropertyPK = element.dataset.multidropdowndatapointplanpropertypk;
            const actionPlanDataPointPlanPropertyPK = element.dataset.actionplandatapointplanpropertypk;

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    ButtonRowNbr: parseInt(buttonRowNumber),
                    IsDeletedInd: false,
                    ActionPlanDataPointPlanPropertyPK: actionPlanDataPointPlanPropertyPK,
                    MultiDropdownDataPointPlanPropertyPK: multiDropdownDataPointPlanPropertyPK,
                    DropdownPropertyPK: dropDownPropertyPK
                };
                allMultiSaveElements.push(saveItem);
            }
        }

        const saveData = {
            "SaveElements": allSaveElements,
            "MultiSaveElements": allMultiSaveElements,
            "PlanFK": planPK
        };

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveSchoolPlanExpenditureTables', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allMultiSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    async addSchoolImprovementRow() {
        const formElement = <HTMLDivElement>document.getElementById("schoolPlanExpenditureTablesForm");
        const planFK = formElement.dataset.planfk;

        let newRow = 0;

        let allRows = document.querySelectorAll(".schoolImprovementExpenditureTable .expenditureTableRow");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const response = await fetch(`/Go/AddSchoolImprovementExpenditureTableRow/${planFK}/${newRow}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.text();
            const tableHead = document.querySelector(`.schoolImprovementExpenditureTable tbody`);
            const newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();
            newTR.classList.add("expenditureTableRow");
            newTR.innerHTML = output;

            tableHead.appendChild(newTR);

            $('.multiSelectActionPlans').MultiSelect();

            Core.createHTMLAlert("alertMessageDiv", `A new Expenditure Row has been added.`, 'success', 3000, null);

            this.bindDeleteButtons();
            this.bindSumAllAmounts();
        }
    }

    async addSchoolwideRow() {
        const formElement = <HTMLDivElement>document.getElementById("schoolPlanExpenditureTablesForm");
        const planFK = formElement.dataset.planfk;

        let newRow = 0;

        let allRows = document.querySelectorAll(".schoolwideTitle1ExpenditureTable .expenditureTableSchoolwideRow");
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        const response = await fetch(`/Go/AddSchoolwideExpenditureTableRow/${planFK}/${newRow}`, { credentials: 'include' })
        if (response.ok) {
            const output = await response.text();
            const tableHead = document.querySelector(`.schoolwideTitle1ExpenditureTable tbody`);
            const newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.dataset.row = newRow.toString();
            newTR.classList.add("expenditureTableSchoolwideRow");
            newTR.innerHTML = output;

            tableHead.appendChild(newTR);

            $('.multiSelectActionPlans').MultiSelect();

            Core.createHTMLAlert("alertMessageDiv", `A new Expenditure Row has been added.`, 'success', 3000, null);

            this.bindDeleteButtons();
            this.bindSumAllAmounts();
        }
    }

    sumAllAmounts() {
        const schoolPlanExpenditureTablesAmountFields = document.getElementsByClassName("schoolPlanExpenditureTablesAmount");
        let totalValue = 0;
        for (const schoolPlanExpenditureTablesAmountField of schoolPlanExpenditureTablesAmountFields) {
            const element = <HTMLInputElement>schoolPlanExpenditureTablesAmountField;
            const thisValue = parseFloat(element.value);
            if (!isNaN(thisValue))
                totalValue += thisValue;
        }

        const totalElement = <HTMLInputElement>document.getElementById("schoolPlanExpenditureTablesTotalExpenditures");
        totalElement.value = totalValue.toString();
    }

    sumAllSchoolwideAmounts() {
        const schoolPlanExpenditureTablesAmountFields = document.getElementsByClassName("schoolPlanExpenditureTablesSchoolwideAmount");
        let totalValue = 0;
        for (const schoolPlanExpenditureTablesAmountField of schoolPlanExpenditureTablesAmountFields) {
            const element = <HTMLInputElement>schoolPlanExpenditureTablesAmountField;
            const thisValue = parseFloat(element.value);
            if (!isNaN(thisValue))
                totalValue += thisValue;
        }

        const totalElement = <HTMLInputElement>document.getElementById("schoolPlanExpenditureTablesSchoolwideTotalExpenditures");
        totalElement.value = totalValue.toString();
    }

    bindSumAllAmounts() {
        let schoolPlanExpenditureTablesAmountFields = document.getElementsByClassName("schoolPlanExpenditureTablesAmount");
        for (let schoolPlanExpenditureTablesAmountField of schoolPlanExpenditureTablesAmountFields)
            schoolPlanExpenditureTablesAmountField.addEventListener("input", (e: Event) => this.sumAllAmounts());

        let schoolPlanExpenditureTablesSchoolwideAmountFields = document.getElementsByClassName("schoolPlanExpenditureTablesSchoolwideAmount");
        for (let schoolPlanExpenditureTablesSchoolwideAmountField of schoolPlanExpenditureTablesSchoolwideAmountFields)
            schoolPlanExpenditureTablesSchoolwideAmountField.addEventListener("input", (e: Event) => this.sumAllSchoolwideAmounts());
    }

    bindDeleteButtons() {
        let deleteSchoolImprovementExpenditureRows = document.getElementsByClassName("deleteSchoolImprovementExpenditureRow");
        for (let deleteSchoolImprovementExpenditureRow of deleteSchoolImprovementExpenditureRows)
            deleteSchoolImprovementExpenditureRow.addEventListener("click", (e: Event) => this.showDeleteExpenditureRow(e));
    }

    showDeleteExpenditureRow(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let table = buttonElement.dataset.table;

        let modal: Modal = new Modal("deleteExpenditureRowModal", null);
        modal.addAttributeToElement("deleteExpenditureRowModal", "#deleteExpenditureRowConfirm", "row", row);
        modal.addAttributeToElement("deleteExpenditureRowModal", "#deleteExpenditureRowConfirm", "table", table);
        modal.show();

    }

    deleteExpenditureConfirm(e: Event) {
        let that = this;
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let table = buttonElement.dataset.table;

        const rowData = document.querySelectorAll(`.${table}[data-row='${row}'] .expenditureTableField`);
        const rowMultiData = document.querySelectorAll(`.${table}[data-row='${row}'] .schoolPlanExpenditureTablesMultiSelectField`);

        const allPlanProperties = [];
        const allPlanPropertiesMulti = [];

        for (const data of rowData) {
            var ele = <HTMLElement>data;
            if (ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                allPlanProperties.push(parseInt(ele.dataset.planpropertypk));
        }

        for (const multiData of rowMultiData) {
            var divele = <HTMLDivElement>multiData;
            if (divele.dataset.planpropertypk !== "" && divele.dataset.planpropertypk !== "0")
                allPlanPropertiesMulti.push(parseInt(divele.dataset.planpropertypk));
        }

        const allData = {
            "RowData": allPlanProperties,
            "MultiRowData": allPlanPropertiesMulti
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteExpenditureRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`tr.${table}[data-row='${row}']`);
                    element.remove();
                    that.sumAllSchoolwideAmounts();
                    that.sumAllAmounts();
                    Core.createHTMLAlert("alertMessageDiv", "Expenditure row successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this row. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteExpenditureRowModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteExpenditureRowModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allData));
    }

    deleteExpenditureCancel() {
        let modal: Modal = new Modal("deleteExpenditureRowModal", null);
        modal.hide();
    }

    allNoFunding() {
        const noFundingCheckboxes = document.getElementsByClassName("schoolPlanExpenditureTablesSchoolDoesNot");
        for (const noFundingCheckbox of noFundingCheckboxes)
            this.noFunding(noFundingCheckbox as HTMLInputElement);
    }

    noFunding(checkbox: HTMLInputElement) {
        const table = checkbox.dataset.table;
        const allRows = document.querySelectorAll(`tr.${table}`);
        const allAddButtons = document.querySelectorAll(`.addSchoolImprovementRow[data-table='${table}']`);

        for (const row of allRows) {
            const allFields = row.querySelectorAll(".expenditureTableField");
            const allMultiFields = row.querySelectorAll("input[type='checkbox']");

            for (const field of allFields) {
                if (checkbox.checked) {
                    (field as HTMLInputElement).readOnly = true;
                    (field as HTMLInputElement).disabled = true;
                } else {
                    (field as HTMLInputElement).readOnly = false;
                    (field as HTMLInputElement).disabled = false;
                }
            }

            for (const multiField of allMultiFields) {
                if (checkbox.checked) {
                    (multiField as HTMLInputElement).readOnly = true;
                    (multiField as HTMLInputElement).disabled = true;
                } else {
                    (multiField as HTMLInputElement).readOnly = false;
                    (multiField as HTMLInputElement).disabled = false;
                }
            }
        }

        for (const button of allAddButtons) {
            if (checkbox.checked) {
                (button as HTMLButtonElement).disabled = true;
            } else {
                (button as HTMLButtonElement).disabled = false;
            }
        }
    }

    exportTitle1ToExcel() {
        Core.showLoader();

        let planElement = document.getElementById("schoolPlanExpenditureTablesForm");
        let planFK = planElement.dataset.planfk;
        window.open(`/ExportExcel/SchoolPlanExpenditureTablesSchoolwideTitle1Export/${planFK}`);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);

    }

    exportSchoolImprovementToExcel() {
        Core.showLoader();

        let planElement = document.getElementById("schoolPlanExpenditureTablesForm");
        let planFK = planElement.dataset.planfk;
        window.open(`/ExportExcel/SchoolPlanExpenditureTablesSchoolImprovementExcelExport/${planFK}`);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    closeComingSoon() {
        const modal: Modal = new Modal("comingSoonModal", null);
        modal.hide();
    }

    initializeRequiredFieldsCustom() {
        let that = this;
        //If any field in a row is filled out, force rest of row required
        /*let expenditureTables = document.getElementsByClassName("expenditureTable");*/
        let schoolImprovementExpenditureTable = document.getElementById("schoolImprovementExpenditureTable");
        let schoolImprovementExpenditureTableCheckbox = <HTMLInputElement>document.getElementById("schoolPlanExpenditureTablesSchooldoesnotreceiveSchoolImprovementSetAsideGrant");

        let schoolwideTitle1ExpenditureTable = document.getElementById("schoolwideTitle1ExpenditureTable");
        let schoolwideTitle1ExpenditureTableCheckbox = <HTMLInputElement>document.getElementById("schoolPlanExpenditureTablesSchooldoesnotreceiveSchoolwideTitle1funding");

        if (!schoolImprovementExpenditureTableCheckbox.checked) {
            let expenditureTableRows = schoolImprovementExpenditureTable.querySelectorAll("tr[data-row]");
            for (let row of expenditureTableRows) {
                let elements = row.getElementsByClassName("fieldToBeValidated") as HTMLCollectionOf<HTMLElement>;

                for (let element of elements) {
                    if (element instanceof HTMLSelectElement) {
                        let htmlElement = <HTMLSelectElement>element;
                        if (element.selectedIndex > 0) {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    } else if (element instanceof HTMLInputElement) {
                        let htmlElement = <HTMLInputElement>element;
                        if (element.value != "" || element.checked) {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    } else if(element.hasAttribute("data-multiselectvalidate")) {
                        //See if any options have been checked in multiselect
                        let multiselectCheckboxes = element.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                for (let otherElement of elements) {
                                    otherElement.dataset.forcerequired = "true";
                                }
                                break;
                            }
                        }
                    } else if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = <HTMLTextAreaElement>element;
                        if (element.value != "") {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                }
            }
        }
        else {
            let expenditureTableRows = schoolImprovementExpenditureTable.querySelectorAll("tr[data-row]");
            for (let row of expenditureTableRows) {
                let elements = row.getElementsByClassName("fieldToBeValidated") as HTMLCollectionOf<HTMLElement>;

                for (let element of elements) {

                    this._core.forceElementOptional(element);
                }
            }
            let schoolPlanExpenditureTablesTotalExpenditures = <HTMLElement>document.getElementById("schoolPlanExpenditureTablesTotalExpenditures");
            this._core.forceElementOptional(schoolPlanExpenditureTablesTotalExpenditures);
        }

        if (!schoolwideTitle1ExpenditureTableCheckbox.checked) {
            let Title1ExpenditureTableRows = schoolwideTitle1ExpenditureTable.querySelectorAll("tr[data-row]");
            for (let row of Title1ExpenditureTableRows) {
                let elements = row.getElementsByClassName("fieldToBeValidated") as HTMLCollectionOf<HTMLElement>;

                for (let element of elements) {

                    if (element instanceof HTMLSelectElement) {
                        let htmlElement = <HTMLSelectElement>element;
                        if (element.selectedIndex > 0) {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    } else if (element instanceof HTMLInputElement) {
                        let htmlElement = <HTMLInputElement>element;
                        if (element.value != "" || element.checked) {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    } else if(element.hasAttribute("data-multiselectvalidate")) {
                        //See if any options have been checked in multiselect
                        let multiselectCheckboxes = element.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                for (let otherElement of elements) {
                                    otherElement.dataset.forcerequired = "true";
                                }
                                break;
                            }
                        }
                    } else if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = <HTMLTextAreaElement>element;
                        if (element.value != "") {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                }
            }
        }
        else {
            let Title1ExpenditureTableRows = schoolwideTitle1ExpenditureTable.querySelectorAll("tr[data-row]");
            for (let row of Title1ExpenditureTableRows) {
                let elements = row.getElementsByClassName("fieldToBeValidated") as HTMLCollectionOf<HTMLElement>;

                for (let element of elements) {

                    this._core.forceElementOptional(element);
                }
            }
            let schoolPlanExpenditureTablesSchoolwideTotalExpenditures = <HTMLElement>document.getElementById("schoolPlanExpenditureTablesSchoolwideTotalExpenditures");
            this._core.forceElementOptional(schoolPlanExpenditureTablesSchoolwideTotalExpenditures);
        }

        this._core.initializeRequiredFields(this.validationClasses);

    }
}

//ProfessionalDevelopment
class ProfessionalDevelopment {

    validationClasses: string[];
    private deleteActivityModal: Modal;
    private _core: Core;

    constructor() {
        this._core = new Core();
        this.deleteActivityModal = new Modal("deleteProfessionalDevelopmentActivityModal", null);
        this.validationClasses = [
            "professionalDevelopmentField",
            "addDanielsonFrameworkToListItem",
            "professionalDevelopmentLearningFormatStateRequired",
            "professionalDevelopmentFrequencyField",
            "professionalDevelopmentAddActionStep",
            "professionalDevelopmentSystemicChallenge"
        ];

        this.checkForDesignatedSchools();

        let saveButton = document.getElementById("professionalDevelopmentSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let professionalDevelopmentTableExportExcel = document.getElementById("professionalDevelopmentTableExportExcel");
        if (professionalDevelopmentTableExportExcel !== null)
            professionalDevelopmentTableExportExcel.addEventListener("click", (e: Event) => this.tableExportExcel());

        let professionalDevelopmentExportExcel = document.getElementById("professionalDevelopmentExportExcel");
        if (professionalDevelopmentExportExcel !== null)
            professionalDevelopmentExportExcel.addEventListener("click", (e: Event) => this.exportExcel());

        this._core.leftnav(this);

        let addMoreRowsButton = document.getElementById("addProfessionalDevelopmentRows");
        if(addMoreRowsButton !== null)
            addMoreRowsButton.addEventListener("click", (e: Event) => this.addMoreProfessionalDevelopmentRows());

        let that = this;
        let deleteActivityButtons = document.querySelectorAll(".deleteProfessionalDevelopmentActivity") as NodeListOf<HTMLButtonElement>;
        for (let button of deleteActivityButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.show();
                that.deleteActivityModal.addAttributeToElement(that.deleteActivityModal.id, "#deleteProfessionalDevelopmentActivityConfirm", "activityplanpropertypk", button.dataset.activityplanpropertypk);

                if (button.id) {
                    that.deleteActivityModal.callingId = button.id;
                }
            });
        }

        let deleteActivityConfirmButton = document.getElementById("deleteProfessionalDevelopmentActivityConfirm");
        if (deleteActivityConfirmButton !== null) {
            deleteActivityConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("activityplanpropertypk" in deleteActivityConfirmButton.dataset && parseInt(deleteActivityConfirmButton.dataset.activityplanpropertypk) > 0) {
                    that.deleteProfessionalDevelopmentActivity(deleteActivityConfirmButton.dataset.activityplanpropertypk);
                } else {
                    that.deleteActivityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this professional development activity", "error", 3000, null);
                }
            });
        }

        let deleteActivityCancelButton = document.getElementById("deleteProfessionalDevelopmentActivityCancel");
        if (deleteActivityCancelButton !== null) {
            deleteActivityCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.hide();
            });
        }

        this.bindCreatePDActivityButtons();
        this.bindPDActionStepAddButtons();
        this.bindDanielsonAddButtons();
        this.bindAddLearningFormatButtons();
        this.bindPDActionStepRemoveButtons();
        this.bindDanielsonListRemove();
        this.bindRemoveLearningFormatButtons();
        this.bindAddLEASystemicChallenge();
        this.bindTechnicalAssistanceStepDeleteButtons();
        this.bindNotPartOfThis();

        this.notPartOfThis();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportStepsWord = document.getElementById("exportActionStepsWord");
        if (exportStepsWord !== null) {
            exportStepsWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "ProfessionalDevelopmentActionStepsDataExport", "actionsteps"));
        }

        let exportActivitiesWord = document.getElementById("exportActivitiesWord");
        if (exportActivitiesWord !== null) {
            exportActivitiesWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "ProfessionalDevelopmentDataExport", "professionaldevelopment"));
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formElement.dataset.planfk);
        //let allSaveElements = [];
        let allPDActivities = [];
        let allLearningFormats = [];
        let allActionSteps = [];
        let allOthers = [];
        let learningFormatOthers = [];
        let learningFormatFrameworks = [];
        let learningFormatFrequency = [];
        let learningFormatStateRequired = [];
        const technicalAssistanceSteps = [];

        let allElements = document.getElementsByClassName("professionalDevelopmentField");
        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionStepList")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else if (element.classList.contains("professionalDevelopmentLearningFormatActivity")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let lookupCode: number = 0;

                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                } else {
                    lookupCode = null;
                }

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentLearningFormatStateRequired")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let lookupCode: number = 0;

                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                } else {
                    lookupCode = null;
                }

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentFrequencyField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let toDelete = (htmlElement.value === "");

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("addDanielsonFrameworkToListItem")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else if (element.classList.contains("professionalDevelopmentTASACheckboxField")) {
                let value = "off";
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.checked)
                    value = "on";

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let toDelete = (htmlElement.value === "");

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("professionalDevelopmentActivity") && saveItem !== undefined)
                allPDActivities.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatActivity") && saveItem !== undefined)
                allLearningFormats.push(saveItem);
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined)
                learningFormatOthers.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatStateRequired") && saveItem !== undefined)
                learningFormatStateRequired.push(saveItem);
            else if (htmlElement.classList.contains("addDanielsonFrameworkToListItem") && saveItem !== undefined)
                learningFormatFrameworks.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentFrequencyField") && saveItem !== undefined)
                learningFormatFrequency.push(saveItem);
            else if (htmlElement.classList.contains("technicalAssistanceStep") && saveItem !== undefined)
                technicalAssistanceSteps.push(saveItem);
            else
                if (saveItem !== undefined)
                    allOthers.push(saveItem);
        }

        let saveElement = {
            "PDActivities": allPDActivities,
            "LearningFormats": allLearningFormats,
            "ActionSteps": allActionSteps,
            "LearningFormatOther": learningFormatOthers,
            "LearningFormatDanielsonFrameworks": learningFormatFrameworks,
            "LearningFormatStateRequired": learningFormatStateRequired,
            "LearningFormatFrequency": learningFormatFrequency,
            "Others": allOthers,
            "TechnicalAssistanceSteps": technicalAssistanceSteps
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveProfessionalDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionSteps.length === 0 && saveElement.LearningFormatDanielsonFrameworks.length === 0 && saveElement.LearningFormatFrequency.length === 0 && saveElement.LearningFormatOther.length === 0 && saveElement.LearningFormats.length === 0 && saveElement.LearningFormatStateRequired.length === 0 && saveElement.Others.length === 0 && saveElement.PDActivities.length === 0) {
            if (referrer === "save") {
                core.pageReload(true, planFK);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }

    bindPDActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("professionalDevelopmentActionStepAdd");

        for (let actionStepButton of actionStepButtons) {
            let element = <HTMLButtonElement>actionStepButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addActionStepToActivity(<HTMLButtonElement>e.target))
                element.dataset.listener = "true";
            }
        }
    }

    bindDanielsonAddButtons() {
        let danielsonButtons = document.getElementsByClassName("professionalDevelopmentDanielsonFrameworkAdd");

        for (let danielsonButton of danielsonButtons) {
            let element = <HTMLButtonElement>danielsonButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addDanielsonFrameworkToList(<HTMLButtonElement>e.target))
                element.dataset.listener = "true";
            }
        }
    }

    bindCreatePDActivityButtons() {
        let activityButtons = document.getElementsByClassName("createProfessionalDevelopmentActivity");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.target))
                element.addEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.target))
                element.dataset.listener = "true";
            //}
        }
    }

    bindPDActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deletePDAddedActionStep");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.removeItemFromActionStepList(e))
                element.addEventListener("click", (e: Event) => this.removeItemFromActionStepList(e));
                element.dataset.listener = "true";
            //}
        }
    }

    bindDanielsonListRemove() {
        let activityButtons = document.getElementsByClassName("deletePDDanielsonFramework");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
                element.addEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
                element.dataset.listener = "true";
            //}
        }
    }

    bindAddLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentAddLearningFormat");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addLearningFormat(<HTMLButtonElement>e.target));
                element.dataset.listener = "true";
            }
        }
    }

    bindRemoveLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        //let count = 0;
        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;
            //if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.removeLearningFormat(e));
                element.addEventListener("click", (e: Event) => this.removeLearningFormat(e));
                element.dataset.listener = "true";
            //}
        }
        this.showHideDeleteActivityButtons();

    }

    showHideDeleteActivityButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }

    addActionStepToActivity(button: HTMLButtonElement) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddActionStep") as HTMLCollectionOf<HTMLSelectElement>;

        let actionStepPlanPropertyPK: number;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(selectElement.value);
            }
        }

        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("actionStepList");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }

        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddPDActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToList[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindPDActionStepRemoveButtons();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "This action step has already been added to this activity", 'error', 3000, null);
        }
    }

    removeItemFromActionStepList(e: Event) {
        e.stopPropagation();
        let core = this._core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;

        let planPropertyPK = element.dataset.planpropertypk;

        let allActionSteps = document.getElementsByClassName("actionStepList");
        for (let actionStep of allActionSteps) {
            let thisElement = <HTMLDivElement>actionStep;

            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Action step removed from activity", 'success', 3000, null);
            }
        }
    }

    addDanielsonFrameworkToList(button: HTMLButtonElement) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.row;
        let buttonrow = button.dataset.buttonrow;
        let propertyfk = button.dataset.propertypk;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddDanielsonFramework") as HTMLCollectionOf<HTMLSelectElement>;

        let lookupCodePK: number;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === buttonrow && selectElement.dataset.row === rowNumber) {
                lookupCodePK = parseInt(selectElement.value);
            }
        }
        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (element.dataset.lookupcodepk === lookupCodePK.toString() && element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                isNotInList = false;
            }
        }

        //See if user has selected a danielson framework or if it's still set to default value
        let userHasSelected: boolean = true;
        if (isNaN(lookupCodePK)) {
            userHasSelected = false;
            Core.createHTMLAlert("alertMessageDiv", "A Danielson Framework must first be selected", 'error', 3000, null);
            return;
        }

        if (isNotInList && userHasSelected) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddDanielsonFrameworkToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addDanielsonFrameworkToList[data-row='" + rowNumber + "'][data-buttonrow='" + buttonrow + "']").append(xhr.responseText);
                    _that.bindDanielsonListRemove();
                    Core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Row Added", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&buttonRowNumber=" + buttonrow + "&planFK=" + planFK + "&lookupCodePK=" + lookupCodePK + "&propertyfk=" + propertyfk);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "This Danielson Framework item has already been added", 'error', 3000, null);
        }
    }

    removeDanielsonFrameworkList(e: Event) {
        e.stopPropagation();
        let core = this._core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;

        let lookupCodePK = element.dataset.lookupcodepk;

        let allLookups = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let lookup of allLookups) {
            let thisElement = <HTMLDivElement>lookup;

            if (thisElement.dataset.lookupcodepk === lookupCodePK && thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Danielson Framework Removed", 'success', 3000, null);
            }
        }
    }

    addLearningFormat(button: HTMLButtonElement) {
        let core = this._core;
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow: number = 0;
        let learningFormatList = document.getElementsByClassName("professionalDevelopmentLearningFormatActivity");
        let _that = this;
        let formElement = document.getElementById("professionalDevelopmentForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of learningFormatList) {
            let element = <HTMLSelectElement>activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/AddLearningFormat');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".learningFormats[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindDanielsonAddButtons();
                _that.bindRemoveLearningFormatButtons();
                _that._core.initializeRequiredFields(_that.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "New Learning Format Row Added", 'success', 3000, null);
            } else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }

    removeLearningFormat(e: Event) {
        e.stopPropagation();
        let core = this._core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;
        let nextFocusable = Core.getNextFocusableElement(element);
        let count = 0;

        let allLearningFormats = document.getElementsByClassName("professionalDevelopmentLearningFormat");
        for (let learningFormat of allLearningFormats) {
            let thisElement = <HTMLDivElement>learningFormat;
            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                nextFocusable.focus();
            }
        }
        this.showHideDeleteActivityButtons();
        Core.createHTMLAlert("alertMessageDiv", "Learning Format Removed", 'success', 3000, null);
    }

    createNewPDActivity(button: HTMLButtonElement) {
        let _that = this;
        let core = this._core;
        let dontDoIt: boolean = false;
        let hasName: boolean = false;
        let row = button.dataset.buttonrow;
        let textElement = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                } else {
                    hasName = true;
                }
            }
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = "professionalDevelopmentActivity" + rowNumber.toString();
            let professionalDevelopmentNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "" + professionalDevelopmentNameElement.value;
            let accordionParent = document.getElementsByClassName("professionalDevelopmentAccordion");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            professionalDevelopmentNameElement.readOnly = true;

            //Now show the accordion.
            let professionalDevelopmentElements = document.getElementsByClassName("professionalDevelopmentAccordion");
            var professionalDevelopmentElement: HTMLDivElement;

            for (let ap of professionalDevelopmentElements) {
                let pde = <HTMLDivElement>ap;
                if (pde.dataset.buttonrow === rowNumber) {
                    professionalDevelopmentElement = pde;
                }
            }

            this.bindNotPartOfThis();

            professionalDevelopmentElement.classList.remove("hide-professional-development");
            professionalDevelopmentElement.classList.add("open");
            button.classList.add("hide-professional-development");
            _that._core.initializeRequiredFields(_that.validationClasses);
            Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Added", 'success', 3000, null);
        } else if(!hasName) {
            Core.createHTMLAlert("alertMessageDiv", "A name for the activity must be provided", 'error', 3000, null);
        }
    }

    addMoreProfessionalDevelopmentRows() {
        let core = this._core;
        let _that = this;
        let howManyElement = <HTMLInputElement>document.getElementById("addProfessionalDevelopmentRowsNumber");
        let howMany = parseInt(howManyElement.value);
        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;

        let activityElements = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let activity of activityElements) {
            let activityElement = <HTMLInputElement>activity;

            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }

        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow ++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Go/AddNewProfessionalDevelopmentActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContent").append(xhr.responseText);
                        new CustomAccordion("professionalDevelopmentAccordion" + currentRow.toString());
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();

                        _that.bindAddLEASystemicChallenge();
                        _that.bindTechnicalAssistanceStepDeleteButtons();

                        _that._core.initializeRequiredFields(_that.validationClasses);
                        Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }

    tableExportExcel() {
        let core = this._core;
        Core.showLoader();

        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/ProfessionalDevelopmentTableDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    exportExcel() {
        let core = this._core;
        Core.showLoader();

        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/ProfessionalDevelopmentDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    deleteProfessionalDevelopmentActivity(activityPlanPropertyPK: string) {
        let that = this;
        that.deleteActivityModal.hide();

        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteProfessionalDevelopmentActivity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Professional development activity successfully deleted", 'success', 3000, null);

                //Get next focusable activity name
                let activityNames = document.querySelectorAll(".professionalDevelopmentActivity");
                if (activityNames.length > 1) {
                    let arr = Array.prototype.slice.call(activityNames);
                    let thisActivityName = document.querySelector(`.professionalDevelopmentActivity[data-planpropertypk='${activityPlanPropertyPK}']`);
                    if (thisActivityName != null) {
                        let index = arr.indexOf(thisActivityName);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                } else {
                    //Focus on add button if no strategies left
                    let addButton = document.getElementById("addProfessionalDevelopmentRows");
                    if (addButton != null) {
                        addButton.focus();
                    }
                }

                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractivityplanplanpropertypk='${activityPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }

            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(activityPlanPropertyPK);
    }

    bindAddLEASystemicChallenge() {
        const allAdds = document.getElementsByClassName("professionalDevelopmentSystemicChallengeAdd");
        for (const add of allAdds)
            add.addEventListener("click", (e: Event) => this.addLEASystemicChallenge(e));
    }

    async addLEASystemicChallenge(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const buttonRow = button.dataset.buttonrow;
        const formElement = <HTMLDivElement>document.getElementById("professionalDevelopmentForm");
        const planFK = formElement.dataset.formfk;

        const dropdown = <HTMLSelectElement>document.querySelector(`.professionalDevelopmentSystemicChallenge[data-buttonrow='${buttonRow}']`);
        if (dropdown.value !== "-1") {
            const selectedPlanPropertyPK = parseInt(dropdown.value);

            let isNotInList: boolean = true;

            let allListElements = document.getElementsByClassName("technicalAssistanceStep");
            for (let listElement of allListElements) {
                let element = <HTMLDivElement>listElement;
                if (parseInt(element.dataset.planpropertypk) === selectedPlanPropertyPK && element.dataset.buttonrow === buttonRow) {
                    isNotInList = false;
                }
            }

            if (isNotInList) {
                const response = await fetch(`/Go/AddLEASystemicChallenge/${planFK}/${selectedPlanPropertyPK}/${buttonRow}`, { credentials: 'include' })
                if (response.ok) {
                    const newDiv = document.createElement("div");
                    newDiv.innerHTML = await response.text();

                    const container = document.querySelector(`.addTechnicalAssistanceStepToList[data-row='${buttonRow}']`);
                    container.append(newDiv);

                    this.bindTechnicalAssistanceStepDeleteButtons();


                    Core.createHTMLAlert("alertMessageDiv", "New Technical Assistance/Support Activity added.", 'success', 3000, null);
                }
            } else
                Core.createHTMLAlert("alertMessageDiv", "You can only add each Technical Assistance/Support Activity one time.", "warning", 3000, null);
        } else
            Core.createHTMLAlert("alertMessageDiv", "You must choose a Technical Assistance/Support Activity to add to the list.", "warning", 3000, null);
    }

    bindTechnicalAssistanceStepDeleteButtons() {
        const allDeletes = document.getElementsByClassName("deleteTechnicalAssistanceStep");
        for (const del of allDeletes)
            del.addEventListener("click", (e: Event) => this.deleteTechnicalAssistanceStepItem(e));
    }

    deleteTechnicalAssistanceStepItem(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyPK = buttonElement.dataset.planpropertypk;
        const buttonRow = buttonElement.dataset.buttonrow;
        const elementToDelete = document.querySelector(`.professionalDevelopmentField[data-planpropertypk='${planPropertyPK}'][data-buttonrow='${buttonRow}']`);
        elementToDelete.remove();
    }

    checkForDesignatedSchools() {
        const selects = document.getElementsByClassName("professionalDevelopmentSystemicChallenge");

        for (const sel of selects) {
            const select = <HTMLSelectElement>sel;

            if (select.options.length > 1) {
                this._core.forceElementRequired(select);
            }
        }
    }

    bindNotPartOfThis() {
        const professionalDevelopmentSystemicChallengeNotPartOfThis = document.getElementsByClassName("professionalDevelopmentSystemicChallengeNotPartOfThis");
        for (const element of professionalDevelopmentSystemicChallengeNotPartOfThis)
            element.addEventListener("change", (e: Event) => this.notPartOfThis());
    }

    notPartOfThis() {
        const professionalDevelopmentSystemicChallengeNotPartOfThis = document.getElementsByClassName("professionalDevelopmentSystemicChallengeNotPartOfThis");
        for (const element of professionalDevelopmentSystemicChallengeNotPartOfThis) {
            const ele = <HTMLInputElement>element;
            const row = ele.dataset.row;

            const technicalAssistanceElement = <HTMLSelectElement>document.querySelector(`.professionalDevelopmentSystemicChallenge[data-row='${row}']`);
            const professionalDevelopmentSystemicChallengeAdd = <HTMLButtonElement>document.querySelector(`.professionalDevelopmentSystemicChallengeAdd[data-buttonrow='${row}']`);

            if (technicalAssistanceElement) {
                if (ele.checked) {
                    this._core.forceElementOptional(technicalAssistanceElement);
                    technicalAssistanceElement.disabled = true;
                    professionalDevelopmentSystemicChallengeAdd.disabled = true;
                } else {
                    this._core.forceElementRequired(technicalAssistanceElement);
                    technicalAssistanceElement.disabled = false;
                    professionalDevelopmentSystemicChallengeAdd.disabled = false;
                }
            }
        }
    }
}

//Communications
class Communications {

    validationClasses: string[];
    private deleteActivityModal: Modal;
    private _core: Core;

    constructor() {
        this._core = new Core();
        this.deleteActivityModal = new Modal("deleteCommunicationsActivityModal", null);

        this.validationClasses = [
            "communicationsField",
            "communicationsFrequencyField",
            "actionStepList",
            "communicationsField",
            "communicationSystemicChallenge"
        ];

        this.checkForDesignatedSchools();

        let saveButton = document.getElementById("communicationsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let communicationsTableExportExcel = document.getElementById("communicationsTableExportExcel");
        if (communicationsTableExportExcel !== null)
            communicationsTableExportExcel.addEventListener("click", (e: Event) => this.tableExportExcel());

        let communicationsExportExcel = document.getElementById("communicationsExportExcel");
        if (communicationsExportExcel !== null)
            communicationsExportExcel.addEventListener("click", (e: Event) => this.exportExcel());

        this._core.leftnav(this);

        let addMoreRowsButton = document.getElementById("addCommunicationsRows");
        if (addMoreRowsButton !== null)
            addMoreRowsButton.addEventListener("click", (e: Event) => this.addMoreCommunicationsRows());

        let numberTextBox = document.getElementById("addCommunicationsRowsNumber");
        if (numberTextBox !== null)
            numberTextBox.addEventListener("change", (e: Event) => this.checkForPlural());

        let that = this;
        let deleteActivityButtons = document.querySelectorAll(".deleteCommunicationsActivity") as NodeListOf<HTMLButtonElement>;
        for (let button of deleteActivityButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.show();
                that.deleteActivityModal.addAttributeToElement(that.deleteActivityModal.id, "#deleteCommunicationsActivityConfirm", "activityplanpropertypk", button.dataset.activityplanpropertypk);

                if (button.id) {
                    that.deleteActivityModal.callingId = button.id;
                }
            });
        }

        let deleteActivityConfirmButton = document.getElementById("deleteCommunicationsActivityConfirm");
        if (deleteActivityConfirmButton !== null) {
            deleteActivityConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("activityplanpropertypk" in deleteActivityConfirmButton.dataset && parseInt(deleteActivityConfirmButton.dataset.activityplanpropertypk) > 0) {
                    that.deleteCommunicationsActivity(deleteActivityConfirmButton.dataset.activityplanpropertypk);
                } else {
                    that.deleteActivityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this communications activity", "error", 3000, null);
                }
            });
        }

        let deleteActivityCancelButton = document.getElementById("deleteCommunicationsActivityCancel");
        if (deleteActivityCancelButton !== null) {
            deleteActivityCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.hide();
            });
        }

        this.bindCreateCommunicationActivityButtons();
        this.bindCommunicationsActionStepAddButtons();
        this.bindCommunicationsActionStepRemoveButtons();
        this.bindAddCommunicationButtons();
        this.bindRemoveCommunicationButtons();
        this.bindAddLEASystemicChallenge();
        this.bindTechnicalAssistanceStepDeleteButtons();
        this.bindNotPartOfThis();

        this.notPartOfThis();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let exportStepsWord = document.getElementById("exportActionStepsWord");
        if (exportStepsWord !== null) {
            exportStepsWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "CommunicationsActionStepsDataExport", "actionsteps"));
        }

        let exportActivitiesWord = document.getElementById("exportActivitiesWord");
        if (exportActivitiesWord !== null) {
            exportActivitiesWord.addEventListener("click", (e: Event) => this._core.exportDocx(e, "CommunicationsDataExport", "communications"));
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("communicationsForm");
        const planFK = parseInt(formElement.dataset.planfk);
        const allCommActivities = [];
        const allCommunications = [];
        const allActionSteps = [];
        const allOthers = [];
        const communicationOthers = [];
        const communicationFrequency = [];
        const technicalAssistanceSteps = [];

        const allElements = document.getElementsByClassName("communicationsField");
        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionStepList")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else if (element.classList.contains("communicationsCommunicationActivity")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("communicationsFrequencyField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("communicationsTASACheckboxField")) {
                let value = "off";
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.checked)
                    value = "on";

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("communicationsActivity") && saveItem !== null)
                allCommActivities.push(saveItem);
            else if (htmlElement.classList.contains("communicationsCommunicationActivity") && saveItem !== null)
                allCommunications.push(saveItem);
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== null)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== null)
                communicationOthers.push(saveItem);
            else if (htmlElement.classList.contains("communicationsFrequencyField") && saveItem !== null)
                communicationFrequency.push(saveItem);
            else if (htmlElement.classList.contains("technicalAssistanceStep") && saveItem !== undefined)
                technicalAssistanceSteps.push(saveItem);
            else
                if (saveItem !== null)
                    allOthers.push(saveItem);
        }

        let saveElement = {
            "CommActivities": allCommActivities,
            "Communications": allCommunications,
            "ActionSteps": allActionSteps,
            "CommunicationsOther": communicationOthers,
            "CommunicationFrequency": communicationFrequency,
            "Others": allOthers,
            "TechnicalAssistanceSteps": technicalAssistanceSteps
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveCommunications', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionSteps.length === 0 && saveElement.CommActivities.length === 0 && saveElement.CommunicationFrequency.length === 0 && saveElement.Communications.length === 0 && saveElement.CommunicationsOther.length === 0 && saveElement.Others.length === 0) {
            if (referrer === "save") {
                core.pageReload(true, planFK);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }

    bindCommunicationsActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("communicationsActionStepAdd");

        for (let actionStepButton of actionStepButtons) {
            let element = <HTMLButtonElement>actionStepButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addActionStepToActivity(<HTMLButtonElement>e.target))
                element.dataset.listener = "true";
            }
        }
    }

    bindCreateCommunicationActivityButtons() {
        let activityButtons = document.getElementsByClassName("createCommunicationsActivity");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.createNewCommActivity(<HTMLButtonElement>e.target));
            element.addEventListener("click", (e: Event) => this.createNewCommActivity(<HTMLButtonElement>e.target));
            element.dataset.listener = "true";
            //}
        }

    }

    bindCommunicationsActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deleteCommAddedActionStep");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeItemFromActionStepList(e))
            element.addEventListener("click", (e: Event) => this.removeItemFromActionStepList(e));
            element.dataset.listener = "true";
            //}
        }
    }

    bindAddCommunicationButtons() {
        let activityButtons = document.getElementsByClassName("communicationsAddCommunication");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addCommunication(<HTMLButtonElement>e.target));
                element.dataset.listener = "true";
            }
        }
    }

    bindRemoveCommunicationButtons() {
        let activityButtons = document.getElementsByClassName("communicationsDeleteCommunicationRow");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeCommunication(e));
            element.addEventListener("click", (e: Event) => this.removeCommunication(e));
            element.dataset.listener = "true";
            //}
        }
        this.showHideDeleteCommunicationsButtons();
    }

    showHideDeleteCommunicationsButtons() {
        let activityButtons = document.getElementsByClassName("communicationsDeleteCommunicationRow");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }

    addActionStepToActivity(button: HTMLButtonElement) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("communicationsForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("communicationsAddActionStep") as HTMLCollectionOf<HTMLSelectElement>;

        let actionStepPlanPropertyPK: number;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(selectElement.value);
            }
        }

        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("actionStepList");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }

        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddCommActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToList[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindCommunicationsActionStepRemoveButtons();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        } else {
            Core.createHTMLAlert("alertMessageDiv", "This action step has already been added", 'error', 3000, null);
        }
    }

    removeItemFromActionStepList(e: Event) {
        e.stopPropagation();
        let core = this._core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;

        let planPropertyPK = element.dataset.planpropertypk;

        let allActionSteps = document.getElementsByClassName("actionStepList");
        for (let actionStep of allActionSteps) {
            let thisElement = <HTMLDivElement>actionStep;

            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Action step removed from communications activity", 'success', 3000, null);
            }
        }
    }

    addCommunication(button: HTMLButtonElement) {
        let core = this._core;
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow: number = 0;
        let communicationList = document.getElementsByClassName("communicationsCommunicationActivity");
        let _that = this;
        let formElement = document.getElementById("communicationsForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of communicationList) {
            let element = <HTMLSelectElement>activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/AddCommunication');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".communications[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindRemoveCommunicationButtons();
                Core.createHTMLAlert("alertMessageDiv", "New Communications Activity Row Added", 'success', 3000, null);
            } else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }

    removeCommunication(e: Event) {
        e.stopPropagation();
        let core = this._core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;

        let allCommunications = document.getElementsByClassName("communicationsCommunication");
        for (let communication of allCommunications) {
            let thisElement = <HTMLDivElement>communication;

            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
        this.showHideDeleteCommunicationsButtons();
        Core.createHTMLAlert("alertMessageDiv", "Communication Removed", 'success', 3000, null);
    }

    createNewCommActivity(button: HTMLButtonElement) {
        let _that = this;
        let core = this._core;
        let dontDoIt: boolean = false;
        let hasName: boolean = false;
        let row = button.dataset.buttonrow;
        let textElement = document.getElementsByClassName("communicationsActivity");
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                } else {
                    hasName = true;
                }
            }
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = "communicationsActivity" + rowNumber.toString();
            let communicationsNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "" + communicationsNameElement.value;
            let accordionParent = document.getElementsByClassName("communicationsAccordion");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            communicationsNameElement.readOnly = true;

            //Now show the accordion.
            let communicationsElements = document.getElementsByClassName("communicationsAccordion");
            let communicationsElement: HTMLDivElement;

            for (let ap of communicationsElements) {
                let comm = <HTMLDivElement>ap;
                if (comm.dataset.buttonrow === rowNumber) {
                    communicationsElement = comm;
                }
            }

            communicationsElement.classList.remove("hide-communications");
            communicationsElement.classList.add("open");
            button.classList.add("hide-communications");
            Core.createHTMLAlert("alertMessageDiv", "New Communications Activity Added", 'success', 3000, null);
        } else if (!hasName) {
            Core.createHTMLAlert("alertMessageDiv", "A name for the activity must be provided", 'error', 3000, null);
        }
    }

    addMoreCommunicationsRows() {
        let core = this._core;
        let _that = this;
        let howManyElement = <HTMLInputElement>document.getElementById("addCommunicationsRowsNumber");
        let howMany = parseInt(howManyElement.value);
        let planElement = document.getElementById("communicationsForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;

        let activityElements = document.getElementsByClassName("communicationsActivity");
        for (let activity of activityElements) {
            let activityElement = <HTMLInputElement>activity;

            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }

        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Go/AddNewCommunicationsActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#communicationsContent").append(xhr.responseText);
                        new CustomAccordion("communicationsAccordion" + currentRow.toString());
                        _that.bindAddCommunicationButtons();
                        _that.bindCommunicationsActionStepAddButtons();
                        _that.bindCreateCommunicationActivityButtons();
                        _that.bindRemoveCommunicationButtons();

                        _that.bindAddLEASystemicChallenge();
                        _that.bindTechnicalAssistanceStepDeleteButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Communication Row Added", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("pluralWord");
        let num = <HTMLInputElement>document.getElementById("addCommunicationsRowsNumber")
        let newWord = "activities";
        if (parseInt(num.value) === 1) {
            newWord = "activity";
        }

        word.textContent = newWord;
    }

    tableExportExcel() {
        let core = this._core;
        Core.showLoader();

        let planElement = document.getElementById("communicationsForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/CommunicationsTableDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    exportExcel() {
        let core = this._core;
        Core.showLoader();

        let planElement = document.getElementById("communicationsForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/CommunicationsDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    deleteCommunicationsActivity(activityPlanPropertyPK: string) {
        let that = this;
        that.deleteActivityModal.hide();

        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteCommunicationsActivity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Communications activity successfully deleted", 'success', 3000, null);

                //Get next focusable activity name
                let activityNames = document.querySelectorAll(".communicationsActivity");
                if (activityNames.length > 1) {
                    let arr = Array.prototype.slice.call(activityNames);
                    let thisActivityName = document.querySelector(`.communicationsActivity[data-planpropertypk='${activityPlanPropertyPK}']`);
                    if (thisActivityName != null) {
                        let index = arr.indexOf(thisActivityName);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                } else {
                    //Focus on add button if no strategies left
                    let addButton = document.getElementById("addCommunicationsRows");
                    if (addButton != null) {
                        addButton.focus();
                    }
                }

                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractivityplanplanpropertypk='${activityPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }

            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(activityPlanPropertyPK);
    }

    bindAddLEASystemicChallenge() {
        const allAdds = document.getElementsByClassName("communicationSystemicChallengeAdd");
        for (const add of allAdds)
            add.addEventListener("click", (e: Event) => this.addCommunicationLEASystemicChallenge(e));
    }

    async addCommunicationLEASystemicChallenge(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const buttonRow = button.dataset.buttonrow;
        const formElement = <HTMLDivElement>document.getElementById("communicationsForm");
        const planFK = formElement.dataset.formfk;

        const dropdown = <HTMLSelectElement>document.querySelector(`.communicationSystemicChallenge[data-buttonrow='${buttonRow}']`);
        if (dropdown.value !== "-1") {
            const selectedPlanPropertyPK = parseInt(dropdown.value);

            let isNotInList: boolean = true;

            let allListElements = document.getElementsByClassName("technicalAssistanceStep");
            for (let listElement of allListElements) {
                let element = <HTMLDivElement>listElement;
                if (parseInt(element.dataset.planpropertypk) === selectedPlanPropertyPK && element.dataset.buttonrow === buttonRow) {
                    isNotInList = false;
                }
            }

            if (isNotInList) {
                const response = await fetch(`/Go/AddCommunicationLEASystemicChallenge/${planFK}/${selectedPlanPropertyPK}/${buttonRow}`, { credentials: 'include' })
                if (response.ok) {
                    const newDiv = document.createElement("div");
                    newDiv.innerHTML = await response.text();

                    const container = document.querySelector(`.addTechnicalAssistanceStepToList[data-row='${buttonRow}']`);
                    container.append(newDiv);

                    this.bindTechnicalAssistanceStepDeleteButtons();


                    Core.createHTMLAlert("alertMessageDiv", "New Technical Assistance/Support Activity added.", 'success', 3000, null);
                }
            } else
                Core.createHTMLAlert("alertMessageDiv", "You can only add each Technical Assistance/Support Activity one time.", "warning", 3000, null);
        } else
            Core.createHTMLAlert("alertMessageDiv", "You must choose a Technical Assistance/Support Activity to add to the list.", "warning", 3000, null);
    }

    bindTechnicalAssistanceStepDeleteButtons() {
        const allDeletes = document.getElementsByClassName("deleteTechnicalAssistanceStep");
        for (const del of allDeletes)
            del.addEventListener("click", (e: Event) => this.deleteTechnicalAssistanceStepItem(e));
    }

    deleteTechnicalAssistanceStepItem(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyPK = buttonElement.dataset.planpropertypk;
        const buttonRow = buttonElement.dataset.buttonrow;
        const elementToDelete = document.querySelector(`.communicationsField[data-planpropertypk='${planPropertyPK}'][data-buttonrow='${buttonRow}']`);
        elementToDelete.remove();
    }

    checkForDesignatedSchools() {
        const selects = document.getElementsByClassName("communicationSystemicChallenge");

        for (const sel of selects) {
            const select = <HTMLSelectElement>sel;

            if (select.options.length > 1) {
                this._core.forceElementRequired(select);
            }
        }
    }

    bindNotPartOfThis() {
        const communicationsSystemicChallengeNotPartOfThis = document.getElementsByClassName("communicationsSystemicChallengeNotPartOfThis");
        for (const element of communicationsSystemicChallengeNotPartOfThis)
            element.addEventListener("change", (e: Event) => this.notPartOfThis());
    }

    notPartOfThis() {
        const communicationsSystemicChallengeNotPartOfThis = document.getElementsByClassName("communicationsSystemicChallengeNotPartOfThis");
        for (const element of communicationsSystemicChallengeNotPartOfThis) {
            const ele = <HTMLInputElement>element;
            const row = ele.dataset.row;

            const technicalAssistanceElement = <HTMLSelectElement>document.querySelector(`.communicationSystemicChallenge[data-row='${row}']`);
            const communicationsSystemicChallengeAdd = <HTMLButtonElement>document.querySelector(`.communicationSystemicChallengeAdd[data-buttonrow='${row}']`);

            if (technicalAssistanceElement) {
                if (ele.checked) {
                    this._core.forceElementOptional(technicalAssistanceElement);
                    technicalAssistanceElement.disabled = true;
                    communicationsSystemicChallengeAdd.disabled = true;
                } else {
                    this._core.forceElementRequired(technicalAssistanceElement);
                    technicalAssistanceElement.disabled = false;
                    communicationsSystemicChallengeAdd.disabled = false;
                }
            }
        }
    }
}
/// <reference path="page.ts" />

class K12GuidancePlanPage extends Page {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)        

        if (this._fromSaveElement && this._fromSaveElement.value === 'true') this._validationHandler.validate()
    }
}

class K12GuidancePlanGuidancePlanProfile extends K12GuidancePlanPage {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)

        this.setUpProfile()        
    }

    private setUpProfile() {
        const refreshProfileButton = document.getElementById('k12guidancePlanProfileRefreshProfile') as HTMLButtonElement
        refreshProfileButton.addEventListener('click', (e: Event) => this.refreshProfile())

        document.querySelectorAll('[data-percent="1.00"][disabled="disabled"]:not([data-propertygroupcode="801"])')
            .forEach(element => this._core.forceElementOptional(element as HTMLElement))

        const saveExecutiveButton = document.getElementById('k12guidanceplanGuidancePlanProfileSaveButtonExecutive') as HTMLButtonElement
        if (saveExecutiveButton) saveExecutiveButton.addEventListener('click', (e: Event) => this._saveHandler.saveExecutiveFieldsForPDEPlanWriters(['802']))
    }

    private async refreshProfile() {
        const response = await fetch(
            `/${this._navigationCode}/GetICDataForProfile/`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `planFK=${this._planPK}`
            }
        )

        if (response.ok) {
            let wasUpdated = false
            const json = await response.json()

            const leaName = document.getElementById('k12guidancePlanProfileLEAName') as HTMLInputElement
            const aun = document.getElementById('k12guidancePlanProfileAUN') as HTMLInputElement
            const address1 = document.getElementById('k12guidancePlanProfileAddress1') as HTMLInputElement
            const address2 = document.getElementById('k12guidancePlanProfileAddress2') as HTMLInputElement
            const city = document.getElementById('k12guidancePlanProfileCity') as HTMLInputElement
            const state = document.getElementById('k12guidancePlanProfileState') as HTMLInputElement
            const zipCode = document.getElementById('k12guidancePlanProfileZipCode') as HTMLInputElement
            const directorName = document.getElementById('k12guidancePlanProfileDirectorName') as HTMLInputElement
            const directorEmail = document.getElementById('k12guidancePlanProfileDirectorEmail') as HTMLInputElement
            const directorPhone = document.getElementById('k12guidancePlanProfileDirectorPhone') as HTMLInputElement

            if (leaName.value !== json.instDetails.instName) {
                leaName.value = json.instDetails.instName
                wasUpdated = true
            }

            if (parseInt(aun.value) !== json.instDetails.auNumber) {
                aun.value = json.instDetails.auNumber
                wasUpdated = true
            }

            if (address1.value !== json.instDetails.addressLine1) {
                address1.value = json.instDetails.addressLine1
                wasUpdated = true
            }

            if (address2.value !== json.instDetails.addressLine2) {
                address2.value = json.instDetails.addressLine2
                wasUpdated = true
            }

            if (city.value !== json.instDetails.city) {
                city.value = json.instDetails.city
                wasUpdated = true
            }

            if (state.value !== json.instDetails.state) {
                state.value = json.instDetails.state
                wasUpdated = true
            }

            if (zipCode.value !== json.instDetails.zipCode) {
                zipCode.value = json.instDetails.zipCode
                wasUpdated = true
            }

            if (zipCode.value !== json.instDetails.zipCode) {
                zipCode.value = json.instDetails.zipCode
                wasUpdated = true
            }

            if (directorName.value !== json.superintendentInfo.adminName) {
                directorName.value = json.superintendentInfo.adminName
                wasUpdated = true
            }

            if (directorEmail.value !== json.superintendentInfo.email) {
                directorEmail.value = json.superintendentInfo.email
                wasUpdated = true
            }

            if (directorPhone.value !== json.superintendentInfo.phoneNumber) {
                directorPhone.value = json.superintendentInfo.phoneNumber
                wasUpdated = true
            }

            if (wasUpdated) {
                Core.createHTMLAlert('alertMessageDiv', 'Successfully updated the profile with the most recent EdNA data, please save your changes.', 'success', 3000, null)
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'Profile is up to date with the most recent EdNA data.', 'success', 3000, null)
            }
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'There was an error retrieving data from EdNA, please try again.', 'error', 3000, null)
        }
    }
}

class K12GuidancePlanGuidanceMissionStatementAndCTEPrograms extends K12GuidancePlanPage { }

class K12GuidancePlanBoardApprovedGuidancePlan extends K12GuidancePlanPage { }

class K12GuidancePlanCareerAndTechnicalCurricula extends K12GuidancePlanPage { }

class K12GuidancePlanEducationalCareerPlan extends K12GuidancePlanPage { }

class K12GuidancePlanOccupationalInformation extends K12GuidancePlanPage { }

class K12GuidancePlanCumulativeRecords extends K12GuidancePlanPage { }

class K12GuidancePlanOrientationToCareerAndTechnicalOptions extends K12GuidancePlanPage { }

class K12GuidancePlanCareerPlacementServices extends K12GuidancePlanPage { }
      
class K12GuidancePlanConsultationWithTeachersAndAdministrators extends K12GuidancePlanPage { }
      
class K12GuidancePlanAndStakeholderInvolvement extends K12GuidancePlanPage { }
      
class K12GuidancePlanFollowUpStudies extends K12GuidancePlanPage { }
      
class K12GuidancePlanSignaturesAndAssurances extends K12GuidancePlanPage {
    constructor(navigationCode: string, pageCode: string, pageLabel: string, planPK: number, templateFK: number, allowDuplicates: boolean = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates)
        this.constructEventListeners()        
    }

    protected constructEventListeners() {
        const checkboxes = document.querySelectorAll('input[type=checkbox]') as NodeListOf<HTMLInputElement>
        for (const checkbox of checkboxes) {
            if (!checkbox.disabled) {
                checkbox.addEventListener('click', (e: Event) => this.toggleCheckboxControl(checkbox))
                this.toggleCheckboxControl(checkbox)
            }
        }
    }

    private toggleCheckboxControl(checkbox: HTMLInputElement) {
        const checkboxControlledElements = document.querySelectorAll(`input[data-propertygroupcodepk='${checkbox.dataset.propertygroupcodepk}']:not([type=checkbox])`) as NodeListOf<HTMLInputElement>        
        checkboxControlledElements.forEach(element => {
            element.disabled = !checkbox.checked
            if (!checkbox.checked) element.value = ''
        })
    }
}
      
class K12GuidancePlanSummaryChecklistAndSubmission {
    private _core: Core;
    private _lateEmailPlanFK: number;
    private _lateEmailModal: Modal;

    constructor() {
        this._core = new Core();
        this._lateEmailModal = new Modal("sendLateEmailModal", "sendLateEmail");

        const backButton = document.getElementById('backButton')
        if (backButton !== null) backButton.addEventListener('click', (e: Event) => this.back(e))

        const submitButton = document.getElementById('submitPlanButton')
        if (submitButton !== null) submitButton.addEventListener('click', (e: Event) => this.submit(e))

        let sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]') as HTMLButtonElement;
        if (sendLateEmailButton != null) sendLateEmailButton.addEventListener('click', () => {
            this._lateEmailPlanFK = parseInt(sendLateEmailButton.dataset.planFk);
            this._lateEmailModal.show();
        });

        let sendLateEmailConfirmButton = document.querySelector('#sendLateEmailConfirm') as HTMLButtonElement;
        if (sendLateEmailConfirmButton != null) sendLateEmailConfirmButton.addEventListener('click', () => this.sendLateEmail())

        let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate');
        if (sendLateEmailDueDate != null) {
            sendLateEmailDueDate.addEventListener('change', () => {
                if (sendLateEmailConfirmButton != null) {
                    sendLateEmailConfirmButton.disabled = false;
                }
            });
        }

        let withdrawPlanButton = document.getElementById("withdrawPlanButton");
        if (withdrawPlanButton !== null)
            withdrawPlanButton.addEventListener("click", (e: Event) => this.withdrawPlan(e));

        let withdrawPlanConfirmButton = <HTMLButtonElement>document.getElementById("withdrawPlanConfirm");
        if (withdrawPlanConfirmButton !== null)
            withdrawPlanConfirmButton.addEventListener("click", (e: Event) => this.withdrawPlanConfirm(e));

        let withdrawPlanCancelButton = <HTMLButtonElement>document.getElementById("withdrawPlanCancel");
        if (withdrawPlanCancelButton !== null)
            withdrawPlanCancelButton.addEventListener("click", (e: Event) => this.withdrawPlanCancel());

        this._core.leftnav(this)
    }

    public back(e: Event) {
        let newPage = document.getElementById('backButton').getAttribute('data-redirect-url')
        window.location.href = newPage
    }

    public getCore() {
        return this._core
    }

    public submit(e: Event) {
        const element = e.srcElement as HTMLButtonElement

        if (!element.classList.contains('disabled')) {
            Core.showLoader()
            const planFK = parseInt(element.dataset.planfk)

            let xhr = new XMLHttpRequest()
            xhr.open('POST', '/K12GuidancePlan/SubmitK12GuidancePlan', true)
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')

            xhr.onload = () => {
                Core.hideLoader()

                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {                
                    Core.createHTMLAlert('alertMessageDiv', 'This report has been submitted for review!', 'success', 3000, window.location.reload())
                    setTimeout(() => { window.location.href = '/Reports/StateRequiredReports' }, 3000)
                }
                else {
                    Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred while submitting report. Please try again later.', 'error', 3000, null)
                }
            }
            xhr.send('planFK=' + planFK)
        }
    }

    public sendLateEmail() {
        let that = this;
        Core.showLoader();
        let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate') as HTMLInputElement;

        let xhr = new XMLHttpRequest()
        xhr.open('POST', '/K12GuidancePlan/SendLateEmail', true)
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
        xhr.onload = () => {
            xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true
                ? Core.createHTMLAlert('alertMessageDiv', 'Successfully sent late email', 'success', 3000, null)
                : Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error sending late email', 'error', 3000, null)

            Core.hideLoader();
            that._lateEmailModal.hide();

            let sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]') as HTMLButtonElement;
            if (sendLateEmailButton != null) {
                sendLateEmailButton.disabled = true;
            }
        }

        xhr.send(`planFK=${that._lateEmailPlanFK}&newDueDateStr=${sendLateEmailDueDate.value}`);
    }

    public withdrawPlan(e: Event) {
        let fileButton = <HTMLButtonElement>e.srcElement;
        let planFK = fileButton.dataset.planfk;

        let modal: Modal = new Modal("withdrawPlanModal", null);
        modal.addAttributeToElement("withdrawPlanModal", "#withdrawPlanConfirm", "planfk", planFK);

        modal.show();
    }

    public withdrawPlanConfirm(e: Event) {
        let confirmButton = <HTMLButtonElement>e.srcElement;
        let core = this._core;
        Core.showLoader();

        let planFK = confirmButton.dataset.planfk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/K12GuidancePlan/WithdrawPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The plan has been withdrawn.", 'success', 3000, null);

                        //back to report page
                        setTimeout(function () { window.location.href = "/Reports/StateRequiredReports"; }, 3000);

                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected withdrawing this plan.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected withdrawing this plan. Please try again.", 'error', 3000, null);
            }

            let modal: Modal = new Modal("withdrawPlanModal", null);
            modal.hide();
        };
        xhr.send("planFK=" + planFK);
    }

    public withdrawPlanCancel() {
        let modal: Modal = new Modal("withdrawPlanModal", null);
        modal.hide();
    }
}

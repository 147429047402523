/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// ContinuingProfAppIUProfile
class ContinuingProfAppIUProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUProfileField", "continuingProfAppIUProfileRadioField"];

        let continuingProfAppIUProfileSaveButton = document.getElementById("continuingProfAppIUProfileSave");
        if (continuingProfAppIUProfileSaveButton !== null)
            continuingProfAppIUProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("continuingProfAppIUProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("continuingProfAppIUProfileRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";

            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// ContinuingProfAppIUCPE
class ContinuingProfAppIUCPE {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUCPEField", "continuingProfAppIUCPEFileField"];

        let continuingProfAppIUCPESaveButton = document.getElementById("continuingProfAppIUCPESave");
        if (continuingProfAppIUCPESaveButton !== null)
            continuingProfAppIUCPESaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, true);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const instructorInformationAddButton = document.getElementById("continuingProfessionalEdAddInstructor");
        if (instructorInformationAddButton !== null)
            instructorInformationAddButton.addEventListener("click", (e: Event) => this.addInstructor());

        const instructorInformationDeleteCancelButton = document.getElementById("continuingProfessionalEdDeleteInstructorCancel");
        if (instructorInformationDeleteCancelButton !== null)
            instructorInformationDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteInstructorCancel());

        const instructorInformationDeleteConfirmButton = document.getElementById("continuingProfessionalEdDeleteInstructorConfirm");
        if (instructorInformationDeleteConfirmButton !== null)
            instructorInformationDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteInstructorConfirm(e));

        this.bindDeleteInstructor();

        const instructorInformationAddInstructorNumberElement = document.getElementById("continuingProfessionalEdInstructorAddNumber");
        if (instructorInformationAddInstructorNumberElement !== null)
            instructorInformationAddInstructorNumberElement.addEventListener("input", (e: Event) => this.checkPlural(e));

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();
        this.bindUploadFiles();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUCPEForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUCPEField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("continuingProfAppIUCPEFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUCPE', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    bindUploadFiles() {
        const fileResumes = document.querySelectorAll(`.continuingProfAppIUCPEUploadInstructorResume`);
        for (const fileResume of fileResumes)
            fileResume.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;
        const row = e.dataset.row;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.continuingProfAppIUCPEFileField[data-row='${row}']`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = row;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUCPEFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUCPEFileField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    async addInstructor() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("continuingProfAppIUCPEForm");
        let valueElement = <HTMLInputElement>document.getElementById("continuingProfessionalEdInstructorAddNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll(".instrutor");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            let added: boolean = false;
            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/ContinuingProfessionalEdAppIU/AddInstructorInformation/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newDiv = <HTMLDivElement>document.createElement("div");
                    newDiv.classList.add("instructor");
                    newDiv.innerHTML = output;
                    newDiv.dataset.row = newRow.toString();

                    let container = document.querySelector("#instructors");

                    container.append(newDiv);

                    that.bindDeleteInstructor();
                    that.bindUploadFiles();

                    added = true;
                }
            }

            valueElement.value = "1";

            let plural = "s";
            if (numberOfRows === 1) plural = "";

            const message = `${numberOfRows} instructor${plural} added!`

            Core.hideLoader();

            if(added)
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            else
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the instructor. Please try again.", 'error', 2000, null);
        } else {
            valueElement.value = "1";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    checkPlural(e: Event) {
        const instructorInformationAddInstructorNumberElement = <HTMLInputElement>e.srcElement;
        const wordElement = <HTMLSpanElement>document.getElementById("continuingProfessionalEdInstructorAddNumberWord");

        const value = parseInt(instructorInformationAddInstructorNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "instructor";
            else
                wordElement.innerText = "instructors";
        } else {
            wordElement.innerText = "instructors";
        }
    }

    deleteInstructorCancel() {
        let modal: Modal = new Modal("deleteInstructorInformationModal", null);
        modal.hide();
    }

    async deleteInstructorConfirm(e: Event) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.srcElement;
        const row = buttonElement.dataset.row;
        const planProps = [];
        const planPropFiles = [];

        const planPropertyValues = document.querySelectorAll(`.continuingProfAppIUCPEField[data-row='${row}']`);
        for (const planProp of planPropertyValues) {
            const ele = <HTMLInputElement>planProp;
            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                planProps.push(ele.dataset.planpropertypk);
        }
            
        const planPropertyFileValues = document.querySelectorAll(`.continuingProfAppIUCPEFileUploadField[data-row='${row}']`);
        for (const planPropFile of planPropertyFileValues) {
            const ele = <HTMLAnchorElement>planPropFile;
            if (ele.dataset.planpropertyfilepk && ele.dataset.planpropertyfilepk !== "" && ele.dataset.planpropertyfilepk !== "0")
                planPropFiles.push(ele.dataset.planpropertyfilepk);
        }

        const dataModel = {
            "PlanPropertyPKs": planProps,
            "PlanPropertyFilePKs": planPropFiles
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataModel)
        };

        let modal: Modal = new Modal("deleteInstructorInformationModal", null);
        modal.hide();

        const response = await fetch(`/ContinuingProfessionalEdAppIU/DeleteInstructor`, settings)
        if (response.ok) {
            const container = document.querySelector(`.instructor[data-row='${row}']`);
            container.remove();

            Core.hideLoader();

            Core.createHTMLAlert("alertMessageDiv", "The instructor was successfully deleted!", 'success', 3000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the instructor. Please try again.", 'error', 3000, null);
        }
    }

    showDeleteInstructorInformation(e: Event) {
        let deleteButton = <HTMLButtonElement>e.srcElement;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteInstructorInformationModal", null);
        modal.addAttributeToElement("deleteInstructorInformationModal", "#continuingProfessionalEdDeleteInstructorConfirm", "row", row);
        modal.show();
    }

    bindDeleteInstructor() {
        let allDeletes = document.getElementsByClassName("deleteInstructorInformation");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteInstructorInformation(e));
    }
}

// ContinuingProfAppIUStatement
class ContinuingProfAppIUStatement {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUStatementField"];

        let continuingProfAppIUStatementSaveButton = document.getElementById("continuingProfAppIUStatementSave");
        if (continuingProfAppIUStatementSaveButton !== null)
            continuingProfAppIUStatementSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUStatementForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUStatementField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUStatement', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// ContinuingProfAppIUCourseContent
class ContinuingProfAppIUCourseContent {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUCourseContentField", "continuingProfAppIUCourseContentFileField"];

        let continuingProfAppIUCourseContentSaveButton = document.getElementById("continuingProfAppIUCourseContentSave");
        if (continuingProfAppIUCourseContentSaveButton !== null)
            continuingProfAppIUCourseContentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const fileUploads = document.querySelectorAll(`.continuingProfAppIUCourseContentUploadControl`);
        for (const fileUpload of fileUploads)
            fileUpload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();

        const certificationAddButton = document.getElementById("continuingProfessionalEdAddCertification");
        if (certificationAddButton !== null)
            certificationAddButton.addEventListener("click", (e: Event) => this.addCertification());

        const certificationDeleteCancelButton = document.getElementById("continuingProfessionalEdDeleteCertificationCancel");
        if (certificationDeleteCancelButton !== null)
            certificationDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteCertificationCancel());

        const certificationDeleteConfirmButton = document.getElementById("continuingProfessionalEdDeleteCertificationConfirm");
        if (certificationDeleteConfirmButton !== null)
            certificationDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteCertificationConfirm(e));

        this.bindDeleteCertification();

        const instructorInformationAddCertificationNumberElement = document.getElementById("continuingProfessionalEdCertificationAddNumber");
        if (instructorInformationAddCertificationNumberElement !== null)
            instructorInformationAddCertificationNumberElement.addEventListener("input", (e: Event) => this.checkPlural(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUCourseContentForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUCourseContentField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("continuingProfAppIUCourseContentFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUCourseContent', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;
        const fileType = e.dataset.filetype;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.continuingProfAppIUCourseContentFileField[data-filetype='${fileType}']`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList[data-filetype='${fileType}']`);

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUCourseContentFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUCPEFileField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-filetype='${fileType}'] .uploadFileColumn[data-filetype='${fileType}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    async addCertification() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("continuingProfAppIUCourseContentForm");
        let valueElement = <HTMLInputElement>document.getElementById("continuingProfessionalEdCertificationAddNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll(".instrutor");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            let added: boolean = false;
            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/ContinuingProfessionalEdAppIU/AddCertification/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.classList.add("certification");
                    newTR.dataset.row = newRow.toString();
                    newTR.innerHTML = output;
                    

                    let container = document.querySelector("#certificationList tbody");

                    container.append(newTR);

                    that.bindDeleteCertification();

                    added = true;
                }
            }

            valueElement.value = "1";

            let plural = "s";
            if (numberOfRows === 1) plural = "";

            const message = `${numberOfRows} instructor${plural} added!`

            Core.hideLoader();

            if (added)
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            else
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the instructor. Please try again.", 'error', 2000, null);
        } else {
            valueElement.value = "1";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteCertificationCancel() {
        let modal: Modal = new Modal("deleteCertificationModal", null);
        modal.hide();
    }

    async deleteCertificationConfirm(e: Event) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.srcElement;
        const row = buttonElement.dataset.row;
        const planProps = [];
        const planPropFiles = [];

        const planPropertyValues = document.querySelectorAll(`.continuingProfAppIUCertificationField[data-row='${row}']`);
        for (const planProp of planPropertyValues) {
            const ele = <HTMLInputElement>planProp;
            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                planProps.push(ele.dataset.planpropertypk);
        }

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(planProps)
        };

        let modal: Modal = new Modal("deleteCertificationModal", null);
        modal.hide();

        const response = await fetch(`/ContinuingProfessionalEdAppIU/DeleteCertification`, settings)
        if (response.ok) {
            const container = document.querySelector(`.certification[data-row='${row}']`);
            container.remove();

            Core.hideLoader();

            Core.createHTMLAlert("alertMessageDiv", "The certification was successfully deleted!", 'success', 3000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the certification. Please try again.", 'error', 3000, null);
        }
    }

    showDeleteCertification(e: Event) {
        let deleteButton = <HTMLButtonElement>e.srcElement;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteCertificationModal", null);
        modal.addAttributeToElement("deleteCertificationModal", "#continuingProfessionalEdDeleteCertificationConfirm", "row", row);
        modal.show();
    }

    bindDeleteCertification() {
        let allDeletes = document.getElementsByClassName("deleteCertificationRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteCertification(e));
    }

    checkPlural(e: Event) {
        const instructorInformationAddCertificationNumberElement = <HTMLInputElement>e.srcElement;
        const wordElement = <HTMLSpanElement>document.getElementById("continuingProfessionalEdCertificationAddNumberWord");

        const value = parseInt(instructorInformationAddCertificationNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "row";
            else
                wordElement.innerText = "rows";
        } else {
            wordElement.innerText = "rows";
        }
    }
}

// ContinuingProfAppIUDesign
class ContinuingProfAppIUDesign {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUDesignField"];

        let continuingProfAppIUDesignSaveButton = document.getElementById("continuingProfAppIUDesignSave");
        if (continuingProfAppIUDesignSaveButton !== null)
            continuingProfAppIUDesignSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUDesignForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUDesignField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUDesign', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// ContinuingProfAppIUOF
class ContinuingProfAppIUOF {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUOFField", "continuingProfAppIUOFCheckboxField", "continuingProfAppIUOFMultiSelectField"];

        let continuingProfAppIUOFSaveButton = document.getElementById("continuingProfAppIUOFSave");
        if (continuingProfAppIUOFSaveButton !== null)
            continuingProfAppIUOFSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customOFDoValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, true);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const observationalFrameworkAddButton = document.getElementById("continuingProfessionalEdAddOF");
        if (observationalFrameworkAddButton !== null)
            observationalFrameworkAddButton.addEventListener("click", (e: Event) => this.addObservationalFramework());

        const observationalFrameworkDeleteCancelButton = document.getElementById("continuingProfessionalEdDeleteOFCancel");
        if (observationalFrameworkDeleteCancelButton !== null)
            observationalFrameworkDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteObservationalFrameworkCancel());

        const observationalFrameworkDeleteConfirmButton = document.getElementById("continuingProfessionalEdDeleteOFConfirm");
        if (observationalFrameworkDeleteConfirmButton !== null)
            observationalFrameworkDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteObservationalFrameworkConfirm(e));

        this.bindDeleteObservationalFramework();

        const addObservationalFrameworkNumberElement = document.getElementById("continuingProfessionalEdOFAddNumber");
        if (addObservationalFrameworkNumberElement !== null)
            addObservationalFrameworkNumberElement.addEventListener("input", (e: Event) => this.checkPlural(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let allCheckElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUOFForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUOFField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("continuingProfAppIUOFCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const buttonRow = parseInt(element.dataset.buttonrow);

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false,
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                ButtonRow: buttonRow
            };

            allCheckElements.push(saveItem);
        }

        const allData = {
            "PlanFK": planPK,
            "SaveData": allSaveElements,
            "Frameworks": allCheckElements
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/SaveContinuingProfAppIUOF', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData === null) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    async addObservationalFramework() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("continuingProfAppIUOFForm");
        let valueElement = <HTMLInputElement>document.getElementById("continuingProfessionalEdOFAddNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll(".observationalFramework");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            let added: boolean = false;
            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/ContinuingProfessionalEdAppIU/AddObservationalFramework/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.classList.add("observationalFramework");
                    newTR.dataset.row = newRow.toString();
                    newTR.innerHTML = output;


                    let container = document.querySelector("#observationalFrameworks tbody");

                    container.append(newTR);

                    that.bindDeleteObservationalFramework();

                    added = true;

                    $('.multiSelect').MultiSelect();
                }
            }

            valueElement.value = "1";

            let plural = "s";
            if (numberOfRows === 1) plural = "";

            const message = `${numberOfRows} instructor${plural} added!`

            Core.hideLoader();

            if (added)
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            else
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the instructor. Please try again.", 'error', 2000, null);
        } else {
            valueElement.value = "1";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteObservationalFrameworkCancel() {
        let modal: Modal = new Modal("deleteObservationalFrameworkModal", null);
        modal.hide();
    }

    async deleteObservationalFrameworkConfirm(e: Event) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.srcElement;
        const row = buttonElement.dataset.row;
        const planProps = [];
        const planPropFiles = [];

        const planPropertyValues = document.querySelectorAll(`.continuingProfAppIUCertificationField[data-row='${row}']`);
        for (const planProp of planPropertyValues) {
            const ele = <HTMLInputElement>planProp;
            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                planProps.push(ele.dataset.planpropertypk);
        }

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(planProps)
        };

        let modal: Modal = new Modal("deleteObservationalFrameworkModal", null);
        modal.hide();

        const response = await fetch(`/ContinuingProfessionalEdAppIU/DeleteCertification`, settings)
        if (response.ok) {
            const container = document.querySelector(`.certification[data-row='${row}']`);
            container.remove();

            Core.hideLoader();

            Core.createHTMLAlert("alertMessageDiv", "The certification was successfully deleted!", 'success', 3000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the certification. Please try again.", 'error', 3000, null);
        }
    }

    showDeleteObservationalFramework(e: Event) {
        let deleteButton = <HTMLButtonElement>e.srcElement;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteObservationalFrameworkModal", null);
        modal.addAttributeToElement("deleteObservationalFrameworkModal", "#continuingProfessionalEdDeleteOFConfirm", "row", row);
        modal.show();
    }

    bindDeleteObservationalFramework() {
        let allDeletes = document.getElementsByClassName("deleteObservationalFrameworkRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteObservationalFramework(e));
    }

    checkPlural(e: Event) {
        const instructorInformationAddCertificationNumberElement = <HTMLInputElement>e.srcElement;
        const wordElement = <HTMLSpanElement>document.getElementById("continuingProfessionalEdOFAddNumberWord");

        const value = parseInt(instructorInformationAddCertificationNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "row";
            else
                wordElement.innerText = "rows";
        } else {
            wordElement.innerText = "rows";
        }
    }

    customOFDoValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.customOFClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    customOFClientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            } else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

}

// ContinuingProfAppIUPACore
class ContinuingProfAppIUPACore {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUPACoreField"];

        let continuingProfAppIUPACoreSaveButton = document.getElementById("continuingProfAppIUPACoreSave");
        if (continuingProfAppIUPACoreSaveButton !== null)
            continuingProfAppIUPACoreSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const paCoreStandardAddButton = document.getElementById("continuingProfessionalEdAddPACore");
        if (paCoreStandardAddButton !== null)
            paCoreStandardAddButton.addEventListener("click", (e: Event) => this.addPACoreStandard());

        const paCoreStandardDeleteCancelButton = document.getElementById("continuingProfessionalEdDeletePACoreCancel");
        if (paCoreStandardDeleteCancelButton !== null)
            paCoreStandardDeleteCancelButton.addEventListener("click", (e: Event) => this.deletePACoreStandardCancel());

        const paCoreStandardDeleteConfirmButton = document.getElementById("continuingProfessionalEdDeletePACoreConfirm");
        if (paCoreStandardDeleteConfirmButton !== null)
            paCoreStandardDeleteConfirmButton.addEventListener("click", (e: Event) => this.deletePACoreStandardConfirm(e));

        this.bindDeletePAScoreStandard();

        const addPACoreStandardNumberElement = document.getElementById("continuingProfessionalEdPACoreAddNumber");
        if (addPACoreStandardNumberElement !== null)
            addPACoreStandardNumberElement.addEventListener("input", (e: Event) => this.checkPlural(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUPACoreForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUPACoreField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUPACore', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    async addPACoreStandard() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("continuingProfAppIUPACoreForm");
        let valueElement = <HTMLInputElement>document.getElementById("continuingProfessionalEdPACoreAddNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll(".paCoreStandard");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            let added: boolean = false;
            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/ContinuingProfessionalEdAppIU/AddPACoreStandard/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.classList.add("paCoreStandard");
                    newTR.dataset.row = newRow.toString();
                    newTR.innerHTML = output;


                    let container = document.querySelector("#paCoreStandards tbody");

                    container.append(newTR);

                    that.bindDeletePAScoreStandard();

                    added = true;
                }
            }

            valueElement.value = "1";

            let plural = "s";
            if (numberOfRows === 1) plural = "";

            const message = `${numberOfRows} record${plural} added!`

            Core.hideLoader();

            if (added)
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            else
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the record. Please try again.", 'error', 2000, null);
        } else {
            valueElement.value = "1";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deletePACoreStandardCancel() {
        let modal: Modal = new Modal("deletePACoreModal", null);
        modal.hide();
    }

    async deletePACoreStandardConfirm(e: Event) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.srcElement;
        const row = buttonElement.dataset.row;
        const planProps = [];

        const planPropertyValues = document.querySelectorAll(`.continuingProfAppIUCertificationField[data-row='${row}']`);
        for (const planProp of planPropertyValues) {
            const ele = <HTMLInputElement>planProp;
            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                planProps.push(ele.dataset.planpropertypk);
        }

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(planProps)
        };

        let modal: Modal = new Modal("deletePACoreModal", null);
        modal.hide();

        const response = await fetch(`/ContinuingProfessionalEdAppIU/DeletePlanProperties`, settings)
        if (response.ok) {
            const container = document.querySelector(`.paCoreStandard[data-row='${row}']`);
            container.remove();

            Core.hideLoader();

            Core.createHTMLAlert("alertMessageDiv", "The record was successfully deleted!", 'success', 3000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the record. Please try again.", 'error', 3000, null);
        }
    }

    showDeletePACoreStandard(e: Event) {
        let deleteButton = <HTMLButtonElement>e.srcElement;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deletePACoreModal", null);
        modal.addAttributeToElement("deletePACoreModal", "#continuingProfessionalEdDeletePACoreConfirm", "row", row);
        modal.show();
    }

    bindDeletePAScoreStandard() {
        let allDeletes = document.getElementsByClassName("deletePACoreStandard");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeletePACoreStandard(e));
    }

    checkPlural(e: Event) {
        const paCoreNumberElement = <HTMLInputElement>e.srcElement;
        const wordElement = <HTMLSpanElement>document.getElementById("continuingProfessionalEdPACoreAddNumberWord");

        const value = parseInt(paCoreNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "row";
            else
                wordElement.innerText = "rows";
        } else {
            wordElement.innerText = "rows";
        }
    }
}

// ContinuingProfAppIUProject
class ContinuingProfAppIUProject {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUProjectField", "continuingProfAppIUProjectFileField"];

        let continuingProfAppIUProjectSaveButton = document.getElementById("continuingProfAppIUProjectSave");
        if (continuingProfAppIUProjectSaveButton !== null)
            continuingProfAppIUProjectSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();

        const fileUpload = document.querySelector(`.continuingProfAppIUProjectFileField`);
        if(fileUpload !== null)
            fileUpload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUProjectForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUProjectField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("continuingProfAppIUProjectFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUProject', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.continuingProfAppIUProjectFileField`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList`);

                        let uploadedContainer = document.querySelector(`.uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUProjectFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUProjectFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}

// ContinuingProfAppIUSurvey
class ContinuingProfAppIUSurvey {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUSurveyRadioField", "continuingProfAppIUSurveyFileField"];

        let continuingProfAppIUSurveySaveButton = document.getElementById("continuingProfAppIUSurveySave");
        if (continuingProfAppIUSurveySaveButton !== null)
            continuingProfAppIUSurveySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();

        const fileUpload = document.querySelector(`.continuingProfAppIUSurveyFileField`);
        if (fileUpload !== null)
            fileUpload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUSurveyForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let radios = document.getElementsByClassName("continuingProfAppIUSurveyRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("continuingProfAppIUSurveyFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUSurvey', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.continuingProfAppIUSurveyFileField`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList`);

                        let uploadedContainer = document.querySelector(`.uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUSurveyFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUSurveyFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}

// ContinuingProfAppIUPreKImpact
class ContinuingProfAppIUPreKImpact {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUPreKImpactField", "continuingProfAppIUPreKImpactCheckboxsField", "continuingProfAppIUPreKImpactFileField"];

        let continuingProfAppIUPreKImpactSaveButton = document.getElementById("continuingProfAppIUPreKImpactSave");
        if (continuingProfAppIUPreKImpactSaveButton !== null)
            continuingProfAppIUPreKImpactSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindDeleteFiles();

        const fileUpload = document.querySelector(`.continuingProfAppIUPreKImpactFileField`);
        if (fileUpload !== null)
            fileUpload.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUPreKImpactForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUPreKImpactField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("continuingProfAppIUPreKImpactCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("continuingProfAppIUPreKImpactFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUPreKImpact', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.continuingProfAppIUPreKImpactFileField`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList`);

                        let uploadedContainer = document.querySelector(`.uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUPreKImpactFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUPreKImpactFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.querySelector(`.uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}

// ContinuingProfAppIUSignatures
class ContinuingProfAppIUSignatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["continuingProfAppIUSignaturesField"];

        let continuingProfAppIUSignaturesSaveButton = document.getElementById("continuingProfAppIUSignaturesSave");
        if (continuingProfAppIUSignaturesSaveButton !== null)
            continuingProfAppIUSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("continuingProfAppIUSignaturesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// ContinuingProfAppIUSummary
class ContinuingProfAppIUSummary {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/continuingprofessionaledappiu/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

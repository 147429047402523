/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//Profile
class InterscholasticAthleticOpportunitiesProfile {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["interscholasticAthleticProfileField"];

        let interscholasticAthleticOpportunitiesProfileSaveButton = document.getElementById("interscholasticAthleticOpportunitiesProfileSave");
        if (interscholasticAthleticOpportunitiesProfileSaveButton !== null)
            interscholasticAthleticOpportunitiesProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e: Event) => this.executiveSave("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("interscholasticAthleticProfileForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("interscholasticAthleticProfileForm");
        let inputs = document.getElementsByClassName("interscholasticAthleticProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/SaveInterscholasticAthleticOpportunitiesProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("interscholasticAthleticProfileForm");
        let inputs = document.getElementsByClassName("profileICDataSave");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/SaveInterscholasticAthleticOpportunitiesProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Signature & Assurance
class InterscholasticAthleticOpportunitiesSignatureAndAssurance {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["interscholasticAthleticOpportunitiesSignatureAndAssuranceField", "interscholasticAthleticOpportunitiesSignatureAndAssuranceRadioField", "interscholasticAthleticOpportunitiesSignatureAndAssuranceCheckboxField"];

        let interscholasticAthleticOpportunitiesSignatureAndAssuranceSaveButton = document.getElementById("interscholasticAthleticOpportunitiesSignatureAndAssuranceSave");
        if (interscholasticAthleticOpportunitiesSignatureAndAssuranceSaveButton !== null)
            interscholasticAthleticOpportunitiesSignatureAndAssuranceSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.checkForNeedsDetail();
        this.checkForNeedsSchoolList();

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReviews = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview");
        for (const interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview of interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReviews)
            interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview.addEventListener("change", (e: Event) => this.checkForNeedsDetail());

        const interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools");
        for (const interscholasticAthleticOpportunitiesSignatureAndAssuranceSchool of interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools)
            interscholasticAthleticOpportunitiesSignatureAndAssuranceSchool.addEventListener("change", (e: Event) => this.checkForNeedsSchoolList());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let signatureAndAssuranceFormElement = <HTMLDivElement>document.getElementById("interscholasticAthleticOpportunitiesSignatureAndAssuranceForm");
        let inputs = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(signatureAndAssuranceFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(signatureAndAssuranceFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(signatureAndAssuranceFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/SaveInterscholasticAthleticOpportunitiesSignatureAndAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }


    checkForNeedsDetail() {
        const interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReviews = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview");

        const interscholasticAthleticSignatureReviewACSReportNoDetailElement = <HTMLTextAreaElement>document.getElementById("interscholasticAthleticSignatureReviewACSReportNoDetail");
        const interscholasticReviewNoMoreDetailsContainer = <HTMLDivElement>document.getElementById("interscholasticReviewNoMoreDetails");

        for (const interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview of interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReviews) {
            const ele = <HTMLInputElement>interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview;

            if (ele.checked) {
                if (ele.dataset.lookupcode === "yes") {
                    this._core.forceElementOptional(interscholasticAthleticSignatureReviewACSReportNoDetailElement);
                    interscholasticReviewNoMoreDetailsContainer.classList.add("hide");
                } else {
                    this._core.forceElementRequired(interscholasticAthleticSignatureReviewACSReportNoDetailElement);
                    interscholasticReviewNoMoreDetailsContainer.classList.remove("hide");
                }
            }
        }
    }

    checkForNeedsSchoolList(){
        const interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools");

        const interscholasticAthleticSignaturePleaseidentifyalleligibleschoolsnotincludedinthesurveyElement = <HTMLTextAreaElement>document.getElementById("interscholasticAthleticSignaturePleaseidentifyalleligibleschoolsnotincludedinthesurvey");
        const interscholasticIdentifySchoolsContainer = <HTMLDivElement>document.getElementById("interscholasticIdentifySchools");

        for (const interscholasticAthleticOpportunitiesSignatureAndAssuranceSchool of interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools) {
            const ele = <HTMLInputElement>interscholasticAthleticOpportunitiesSignatureAndAssuranceSchool;

            if (ele.checked) {
                if (ele.dataset.lookupcode === "yes") {
                    this._core.forceElementOptional(interscholasticAthleticSignaturePleaseidentifyalleligibleschoolsnotincludedinthesurveyElement);
                    interscholasticIdentifySchoolsContainer.classList.add("hide");
                } else {
                    this._core.forceElementRequired(interscholasticAthleticSignaturePleaseidentifyalleligibleschoolsnotincludedinthesurveyElement);
                    interscholasticIdentifySchoolsContainer.classList.remove("hide");
                }
            }
        }
    }
}

// InterscholasticAthleticACS
class InterscholasticAthleticACS {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["interscholasticAthleticACSField", "interscholasticAthleticACSRadioField"];

        let interscholasticAthleticACSSaveButton = document.getElementById("interscholasticAthleticACSSave");
        if (interscholasticAthleticACSSaveButton !== null)
            interscholasticAthleticACSSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.changePIAASportsTeam();
        this.changeCurrentAndPastYears();
        this.changeIsAccurate();
        this.changeGreaterThan20();

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const acsButton = document.getElementById("interscholasticAthleticOpportunitiesACS");
        if (acsButton !== null)
            acsButton.addEventListener("click", (e: Event) => this.runACSReport());

        const piaaSportsTeamsRadios = document.getElementsByClassName("piaaSportsTeamsRadio");
        for (const piaaSportsTeamsRadio of piaaSportsTeamsRadios)
            piaaSportsTeamsRadio.addEventListener("change", (e: Event) => this.changePIAASportsTeam(true));

        const currentAndPastYearRadios = document.getElementsByClassName("currentAndPastYearRadio");
        for (const currentAndPastYearRadio of currentAndPastYearRadios)
            currentAndPastYearRadio.addEventListener("change", (e: Event) => this.changeCurrentAndPastYears());

        const isThisAccurateRadios = document.getElementsByClassName("isThisAccurateRadio");
        for (const isThisAccurateRadio of isThisAccurateRadios)
            isThisAccurateRadio.addEventListener("change", (e: Event) => this.changeIsAccurate(true));

        const isThisAccurateCloseButton = document.getElementById("isThisAccurateClose");
        if (isThisAccurateCloseButton !== null)
            isThisAccurateCloseButton.addEventListener("click", (e: Event) => this.isThisAccurateCloseModal());

        const piaaSportsTeamCloseButton = document.getElementById("piaaSportsTeamClose");
        if (piaaSportsTeamCloseButton !== null)
            piaaSportsTeamCloseButton.addEventListener("click", (e: Event) => this.piaaSportsTeamCloseModal());

        const greaterThan20Radios = document.getElementsByClassName("greaterThan20Radio");
        for (const greaterThan20Radio of greaterThan20Radios)
            greaterThan20Radio.addEventListener("change", (e: Event) => this.changeGreaterThan20());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("interscholasticAthleticACSForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("interscholasticAthleticACSField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;

            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radioInputs = document.getElementsByClassName("interscholasticAthleticACSRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/SaveInterscholasticAthleticACS', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    runACSReport() {
        let formElement = document.getElementById("interscholasticAthleticACSForm");
        let planFK = formElement.dataset.planfk;

        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/GenerateInterscholasticAthleticOpportunitiesACS', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {

            Core.hideLoader();

            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "InterscholasticAthleticOpportunitiesACS.pdf";

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var a = <HTMLAnchorElement>document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        }
        xhr.send(`planFK=${planFK}`);
    }

    changePIAASportsTeam(fromChange: boolean = false) {
        const piaaSportsTeamsRadios = document.getElementsByClassName("piaaSportsTeamsRadio");

        for (const piaaSportsTeamsRadio of piaaSportsTeamsRadios) {
            const element = <HTMLInputElement>piaaSportsTeamsRadio;

            if (element.checked) {
                const lookup = element.dataset.lookupcode;

                if (lookup === "no" && fromChange) {
                    const modal: Modal = new Modal("piaaSportsTeamModal", null);
                    modal.show();
                }
            }
        }
    }

    changeCurrentAndPastYears() {
        const currentAndPastYearRadios = document.getElementsByClassName("currentAndPastYearRadio");
        for (const currentAndPastYearRadio of currentAndPastYearRadios) {
            const element = <HTMLInputElement>currentAndPastYearRadio;
            const isThisAccurateRadios = document.getElementsByClassName("isThisAccurateRadio");
            const isThisAccurate = <HTMLDivElement>document.getElementById("isThisAccurate");

            for (const isThisAccurateRadio of isThisAccurateRadios) {
                const isThisAccurateElement = <HTMLInputElement>isThisAccurateRadio;

                if (element !== null && element.checked) {
                    if (element.dataset.lookupcode === "yes") {
                        isThisAccurate.classList.remove("hide");
                        this._core.forceElementRequired(isThisAccurateElement);
                    } else {
                        isThisAccurate.classList.add("hide");
                        this._core.forceElementOptional(isThisAccurateElement);
                    }
                }
            }
        }
    }

    changeIsAccurate(fromChange: boolean = false) {
        const isThisAccurateRadios = document.getElementsByClassName("isThisAccurateRadio");
        for (const isThisAccurateRadio of isThisAccurateRadios) {
            const element = <HTMLInputElement>isThisAccurateRadio;
            if (element.checked) {
                if (element.dataset.lookupcode === "no" && fromChange) {
                    const modal: Modal = new Modal("isThisAccurateNoModal", null);
                    modal.show();
                }
            }
        }
    }

    piaaSportsTeamCloseModal() {
        const modal: Modal = new Modal("piaaSportsTeamModal", null);
        modal.hide();
    }

    isThisAccurateCloseModal() {
        const modal: Modal = new Modal("isThisAccurateNoModal", null);
        modal.hide(); 
    }

    changeGreaterThan20() {
        const greaterThan20Radios = document.getElementsByClassName("greaterThan20Radio");

        for (const greaterThan20Radio of greaterThan20Radios) {
            const element = <HTMLInputElement>greaterThan20Radio;

            if (element !== null && element.checked) {
                const explainationElement = <HTMLTextAreaElement>document.getElementById("interscholasticAthleticACSExplainthechangeinstudentnumbers");
                const greaterThan20ReasonContainer = <HTMLDivElement>document.getElementById("greaterThan20Reason");

                if (element.dataset.lookupcode === "yes") {
                    greaterThan20ReasonContainer.classList.remove("hide");
                    this._core.forceElementRequired(explainationElement);
                } else {
                    greaterThan20ReasonContainer.classList.add("hide");
                    this._core.forceElementOptional(explainationElement);
                }
            }
        }
    }

    initializeRequiredFieldsCustom(allValidationClasses: string[]) {
        this._core.initializeRequiredFields(allValidationClasses);
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        /*
            Customized validation for this page:
                if piaaSportsTeamsRadio === no, add error.
                if currentAndPastYearRadio === yes && isThisAccurateRadio === no, add error.
        */

        const piaaSportsTeamsRadios = document.getElementsByClassName("piaaSportsTeamsRadio");
        for (const piaaSportsTeamsRadio of piaaSportsTeamsRadios) {
            const element = <HTMLInputElement>piaaSportsTeamsRadio;
            if (element !== null && element.checked) {
                if (element.dataset.lookupcode === "no") {
                    element.classList.add("missing-field");
                    element.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(element);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }

        const currentAndPastYearRadios = document.getElementsByClassName("currentAndPastYearRadio");
        for (const currentAndPastYearRadio of currentAndPastYearRadios) {
            const element = <HTMLInputElement>currentAndPastYearRadio;

            if (element !== null && element.checked) {
                if (element.dataset.lookupcode === "yes") {
                    const isThisAccurateRadios = document.getElementsByClassName("isThisAccurateRadio");
                    for (const isThisAccurateRadio of isThisAccurateRadios) {
                        const accurateElement = <HTMLInputElement>isThisAccurateRadio;

                        if (accurateElement !== null && accurateElement.checked) {
                            if (accurateElement.dataset.lookupcode === "no") {
                                accurateElement.classList.add("missing-field");
                                accurateElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(accurateElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        

        //Run the normal checks.
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    } else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");

                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked: boolean = false;
                                    let isAlreadyValidated: boolean = false;

                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }

                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }

                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            } else {
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                if (OtherInputElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "checkbox") {
                                    if (OtherInputElement.checked) {
                                        alreadyExists = true;
                                    }
                                } else {
                                    if (OtherInputElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                            }
                        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            } else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

//Summary Checklist & Submission
class InterscholasticAthleticOpportunitiesSummaryChecklistAndSubmission {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this._core.leftnav(this);

        this.validationClasses = ["interscholasticAthleticOpportunitiesSummaryChecklistAndSubmissionField"];

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    save(referrer) {
        let core = this._core;

        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InterscholasticAthleticOpportunities/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}